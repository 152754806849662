import * as ACTION_TYPES from '../actions/types';

const initialState = {
  user: null,
  selectedUser: null,
  auth_loading: true,
  auth_err: null,
  isLoggedIn: false,
  reports: [],
  specailDates: [],
};

if (localStorage.getItem('special_dates') === null) {
  const data = [
    { date: '2023-02-15', label: 'Boat Show', destination: 1447930 },
    { date: '2023-02-16', label: 'Boat Show', destination: 1447930 },
    { date: '2023-02-17', label: 'Boat Show', destination: 1447930 },
    { date: '2023-02-18', label: 'Boat Show', destination: 1447930 },
    { date: '2023-02-19', label: 'Boat Show', destination: 1447930 },
    { date: '2023-02-23', label: 'Wine & Food Festival', destination: 1447930 },
    { date: '2023-02-24', label: 'Wine & Food Festival', destination: 1447930 },
    { date: '2023-02-25', label: 'Wine & Food Festival', destination: 1447930 },
    { date: '2023-02-26', label: 'Wine & Food Festival', destination: 1447930 },
    { date: '2023-03-24', label: 'Ultra', destination: 1447930 },
    { date: '2023-03-25', label: 'Ultra', destination: 1447930 },
    { date: '2023-03-26', label: 'Ultra', destination: 1447930 },
    { date: '2023-04-09', label: 'Easter Sunday', destination: 1447930 },
    { date: '2023-05-14', label: "Mother's Day", destination: 1447930 },
    { date: '2023-05-28', label: 'Fashion Week', destination: 1447930 },
    { date: '2023-05-29', label: 'Fashion Week', destination: 1447930 },
    { date: '2023-05-30', label: 'Fashion Week', destination: 1447930 },
    { date: '2023-05-31', label: 'Fashion Week', destination: 1447930 },
    { date: '2023-06-01', label: 'Fashion Week', destination: 1447930 },
    { date: '2023-06-02', label: 'Fashion Week', destination: 1447930 },
    { date: '2023-06-18', label: "Father's Day", destination: 1447930 },
    { date: '2023-07-04', label: 'Independence Day', destination: 1447930 },
    { date: '2023-09-04', label: 'Labor Day', destination: 1447930 },
    { date: '2023-11-23', label: 'Thanksgiving', destination: 1447930 },
    { date: '2023-12-07', label: 'Art Basel', destination: 1447930 },
    { date: '2023-12-08', label: 'Art Basel', destination: 1447930 },
    { date: '2023-12-09', label: 'Art Basel', destination: 1447930 },
    { date: '2023-12-25', label: 'Christmas Day', destination: 1447930 },
  ];

  localStorage.setItem('special_dates', JSON.stringify(data));
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.REGISTER_USER:
      return {
        ...state,
        selectedUser: action.payload,
        auth_loading: false,
      };
    case ACTION_TYPES.LOGIN_USER:
      return {
        ...state,
        user: action.payload,
        auth_loading: false,
      };
    case ACTION_TYPES.LOGOUT_USER:
      return {
        ...state,
        user: null,
        auth_loading: false,
      };
    case ACTION_TYPES.LOGIN_PROGRESS:
      return {
        ...state,
        user: null,
        auth_loading: true,
      };
    case ACTION_TYPES.REGISTER_PROGRESS:
      return {
        ...state,
        auth_loading: true,
      };
    case ACTION_TYPES.LOGOUT_PROGRESS:
      return {
        ...state,
        auth_loading: true,
      };
    case ACTION_TYPES.LOGIN_FAILED:
      return {
        ...state,
        user: null,
        auth_loading: false,
        auth_err: action.payload,
      };
    case ACTION_TYPES.REGISTER_FAILED:
      return {
        ...state,
        auth_loading: false,
        auth_err: action.payload,
      };
    case ACTION_TYPES.LOGOUT_FAILED:
      return {
        ...state,
        user: null,
        auth_loading: false,
        auth_err: action.payload,
      };
    case ACTION_TYPES.ISLOGGEDIN_TRUE:
      return {
        ...state,
        auth_loading: false,
        isLoggedIn: true,
      };
    case ACTION_TYPES.ISLOGGEDIN_FALSE:
      return {
        ...state,
        auth_loading: false,
        isLoggedIn: false,
        auth_err: action.payload,
      };
    case ACTION_TYPES.ISLOADING_TRUE:
      return {
        ...state,
        auth_loading: true,
      };
    case ACTION_TYPES.ISLOADING_FALSE:
      return {
        ...state,
        auth_loading: false,
      };
    case ACTION_TYPES.GET_USER_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };
    case ACTION_TYPES.SET_SPECIAL_DATES:
      return {
        ...state,
        specailDates: action.payload,
        auth_loading: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
