import React, { useState, useEffect } from 'react';
import { Badge, Col, Container, FormLabel, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  makeStyles,
  Select,
  TextField,
} from '@material-ui/core';

import moment from 'moment';
import {
  fetcSpecialDates,
  setSpecialDates,
} from '../../redux/actions/auth.actions';

import 'rsuite/dist/rsuite.css';

import { DateRangePicker } from 'rsuite';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const SpecialDates = ({ setformNotification }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const { markets } = getClusterDataSet;

  const auth = useSelector((state) => state.auth);
  const { user, specailDates } = auth;

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedMarketIndx, setSelectedMarketIndx] = useState();
  const [selectedMarket, setSelectedMarket] = useState();
  const [formEditale, setFormEditale] = useState(false);

  const [date_label, set_date_label] = useState('');
  const [date_select, set_date_select] = useState([]);

  const [values, setValues] = useState([]);

  const handleSelectedDates = (dates) => {
    if (selectedMarket != -100 && date_label != '' && dates.length > 0) {
      dates.map((_date) => {
        setSelectedDates((oldArr) => [
          ...oldArr,
          {
            label: date_label,
            destination: selectedMarket,
            date: moment(_date).format('YYYY-MM-DD'),
          },
        ]);
      });
    }
    set_date_label('');
    set_date_select([]);
  };

  const set_date_selection = (ranges) => {
    let dates = [];
    let start_date = null;
    let end_date = null;

    if (ranges != null && ranges.length > 0) {
      start_date = moment(ranges[0]);
      end_date = moment(ranges[1]);
    }

    if (start_date != null && end_date != null) {
      for (var current = start_date; current <= end_date; current.add(1, 'd')) {
        dates.push(current.format('YYYY-MM-DD'));
      }
    }

    handleSelectedDates(dates);
  };

  const removeSelectedProperty = (date) => {
    let updatedArr = [...selectedDates];

    updatedArr = updatedArr.filter(function (item) {
      return item.date !== date;
    });
    setSelectedDates(updatedArr);
  };

  const handleConfigureSubmit = async (e) => {
    e.preventDefault();

    await dispatch(setSpecialDates(selectedDates));
    setformNotification({
      text: `Your Special Dates filter updated.`,
      varient: 'success',
    });
    setFormEditale(!formEditale);
  };

  const cancelForm = async (e) => {
    e.preventDefault();
    setFormEditale(!formEditale);
  };

  const handleMarketChange = (event) => {
    if (event.target.value >= 0) {
      const newMarket = event.target.value;
      setSelectedMarket(newMarket);
      setSelectedMarketIndx(newMarket);

      const selected_dates = specailDates.filter(
        (e) => e.destination == newMarket
      );
      setSelectedDates(selected_dates);
    }
  };

  async function getSpecialDates() {
    await dispatch(fetcSpecialDates(user));
  }

  useEffect(() => {
    if (user !== undefined && user !== null) {
      getSpecialDates();
    }
  }, []);

  useEffect(() => {
    async function getHotelList() {}
    if (selectedMarket !== undefined) {
      getHotelList();
    }
  }, [dispatch, selectedMarket]);

  const isAllowed = (date) => {
    const day = date;

    const found = selectedDates.findIndex(
      (e) => e.date == moment(day).format('YYYY-MM-DD')
    );

    if (found == -1) {
      return false;
    } else {
      return true;
    }
  };

  const onDatesChange = (ranges) => {
    // set_date_select((oldArr) => [
    //   ...oldArr,
    //   {
    //     label: date_label,
    //     destination: selectedMarket,
    //     date: moment(date_select).format('YYYY-MM-DD'),
    //   },
    // ]);

    setValues(ranges.map((r) => r.toDate()));
    console.log(ranges.map((r) => r.toDate()));
  };

  return (
    <Container>
      <Form onSubmit={handleConfigureSubmit}>
        <div className="my-3">
          {' '}
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-native-select">
              Select Destination
            </InputLabel>
            <Select
              native
              id="grouped-native-select"
              onChange={handleMarketChange}
              value={selectedMarketIndx}
            >
              <option value={-100}>None</option>
              {(() => {
                if (markets.length > 0) {
                  if (user.role === 'admin' || user.role === 'manager') {
                    return markets.map((d, index) => (
                      <option value={d.id}>&nbsp;{d.name}&nbsp;</option>
                    ));
                  } else {
                    if (user.application.destinations.length > 0) {
                      const allowedMatrkets =
                        user.application.destinations.filter(({ id: id1 }) =>
                          markets.some(({ id: id2 }) => id2 === id1)
                        );
                      return allowedMatrkets.length > 0 ? (
                        allowedMatrkets.map((d, index) => (
                          <option value={d.id}>&nbsp;{d.name}&nbsp;</option>
                        ))
                      ) : (
                        <></>
                      );
                    }
                  }
                }
              })()}
            </Select>
          </FormControl>{' '}
        </div>

        <FormGroup className={classes.formControl}>
          {formEditale ? (
            <>
              <FormLabel htmlFor="grouped-native-select">
                Add Special Dates
              </FormLabel>

              <Row>
                <Col>
                  <TextField
                    id="standard-basic"
                    label="Speciality"
                    variant="standard"
                    placeholder="Description"
                    value={date_label}
                    onChange={(e) => {
                      set_date_label(e.target.value);
                    }}
                  />
                </Col>
                <Col>
                  {' '}
                  <DateRangePicker
                    placeholder="Select Date Range"
                    onChange={set_date_selection}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          {selectedMarketIndx ? (
            <>
              <FormLabel className="mt-5" htmlFor="grouped-native-select">
                Special Dates
              </FormLabel>
              <Box component="span" m={1}>
                {selectedDates.length > 0 ? (
                  (selectedDates.length + ' Properties',
                  selectedDates.map((h, ind) => (
                    <Badge variant="secondary" className="m-1 pe-auto">
                      <span>
                        {formEditale ? (
                          <>
                            <>
                              {h.date} | {h.label} &nbsp;
                            </>
                            <i
                              class="fas fa-times-circle"
                              onClick={() => removeSelectedProperty(h.date)}
                            ></i>
                          </>
                        ) : (
                          <h6>
                            {h.date} | {h.label} &nbsp;
                          </h6>
                        )}
                      </span>
                    </Badge>
                  )))
                ) : (
                  <>No Special Dates </>
                )}
              </Box>{' '}
            </>
          ) : (
            <></>
          )}

          {formEditale ? (
            <>
              <Button
                variant="primary"
                type="submit"
                className={'btn btn-md btn-primary mt-5'}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                className={'btn btn-md btn-secondary my-1'}
                onClick={cancelForm}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              variant="primary"
              type="button"
              className={'btn btn-md btn-primary mt-5'}
              onClick={() => {
                setFormEditale(!formEditale);
              }}
            >
              Edit
            </Button>
          )}
        </FormGroup>
      </Form>
    </Container>
  );
};
