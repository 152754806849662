import {
  Box,
  makeStyles,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { CLUSTER_BACKGROUND, CLUSTER_LABELS, FONT_FAMILY } from '../utils/const';
import { useEffect, useState } from 'react';
import { ClusteredData } from './ClusteredData';
import { useRef } from 'react';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 15,
    minWidth: 100,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'block',
    zIndex: 100,
  },
  rates: {
    fontFamily: FONT_FAMILY,
  },
});

export const LosStrategy = ({ selectedDate, tbl_ref }) => {
  const classes = useStyles();

  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    loading,
    reqHotel,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels,
    report_len,
    clusterData,
    comparison_report,
    date_format,
  } = getClusterDataSet;

  const [load, setLoad] = useState(true);

  const getPrice = (arr) => {
    const price = arr.findIndex((e) => e > 0);
    return price;
  };

  const getTotalHotelsCount = (stars) => {
    let star_hotels = [];
    if (hotels.length > 0) {
      hotels.map((_hotel) => {
        if (Math.floor(_hotel.stars) == stars) {
          star_hotels.push({
            hotel_id: _hotel.hotelID,
            hotel_name: _hotel.hotelName,
            stars: _hotel.stars,
            ratings: _hotel.ratings,
          });
        }
      });
    }

    return star_hotels.length;
  };

  const getFilterHotels = (arr) => {
    if (hotels.length > 0) {
      const allowedMatrkets = arr.filter(({ id: id1 }) =>
        hotels.some(({ hotelID: id2 }) => id2 === id1)
      );
      return allowedMatrkets;
    }
  };

  const getClusterByPrice = (rate, ix) => {
    let clustered = [];
    let res = 4;

    if (cluster1.length > 0 && cluster1[ix]) {
      clustered.push(cluster1[ix]);
    }
    if (cluster2.length > 0 && cluster2[ix]) {
      clustered.push(cluster2[ix]);
    }
    if (cluster3.length > 0 && cluster3[ix]) {
      clustered.push(cluster3[ix]);
    }
    if (cluster4.length > 0 && cluster4[ix]) {
      clustered.push(cluster4[ix]);
    }

    clustered.sort((a, b) => a.mean - b.mean);

    // console.log(clustered);

    try {
      clustered.map((cl, id) => {
        if (rate >= cl.min && rate <= cl.max) {
          res = id;
          return;
        }
      });
    } catch (e) {}

    return res;
  };

  const checkHotelAvailability = (id, day) => {
    let clustered = [];

    if (cluster1.length > 0 && cluster1[day]) {
      clustered.push(cluster1[day].unwanted);
    }
    if (cluster2.length > 0 && cluster2[day]) {
      clustered.push(cluster2[day].unwanted);
    }
    if (cluster3.length > 0 && cluster3[day]) {
      clustered.push(cluster3[day].unwanted);
    }
    if (cluster4.length > 0 && cluster4[day]) {
      clustered.push(cluster4[day].unwanted);
    }
    let hotels_arr = [];

    for (var i = 0; i < clustered.length; i++) {
      hotels_arr = hotels_arr.concat(clustered[i]);
    }

    const exists = hotels_arr.some((obj) => obj.id == id);

    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  const getHotelCountByNights = (cluster, dt, nights) => {
    let nights_hotels = [];
    if (hotels.length > 0) {
      hotels.map((_hotel) => {
        if (checkHotelAvailability(_hotel.hotelID, dt)) {
          if (_hotel.prices[dt]) {
            if (
              cluster ==
              getClusterByPrice(
                _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
                dt
              ) +
                2
            ) {
              if (getPrice(_hotel.prices[dt].price) == nights) {
                nights_hotels.push({
                  hotelID: _hotel.hotelID,
                  hotelName: _hotel.hotelName,
                  prices: _hotel.prices[dt].price,
                  price:
                    _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
                  nights: getPrice(_hotel.prices[dt].price),
                  cluster:
                    getClusterByPrice(
                      _hotel.prices[dt].price[
                        getPrice(_hotel.prices[dt].price)
                      ],
                      dt
                    ) + 2,
                });
              }
            }
          }
        }
      });
    }

    return nights_hotels.length;
  };

  const getStandardDeviation = (array) => {
    const n = array.length;
    if (n > 0) {
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      );
    } else {
      return -1;
    }
  };

  const GetStarHotelCounts = ({ cluster, cluster_idx }) => {
    const [avg_wd, setAvg_wd] = useState(-1);
    const [stdev_wd, setStdev_wd] = useState(-1);

    const [avg_we, setAvg_we] = useState(-1);
    const [stdev_we, setStdev_we] = useState(-1);

    const getNightVarience = (cluster_idx, dt) => {
      let varience = '';
      let day = moment(selectedDate)
        .add(dt, 'days')
        .format('dddd')
        .substring(0, 3);

      let nights_count_1 = getHotelCountByNights(cluster_idx, dt, 0);
      let nights_count_2 = getHotelCountByNights(cluster_idx, dt, 1);
      let nights_count_3 = getHotelCountByNights(cluster_idx, dt, 2);

      let perc = nights_count_2 + nights_count_3;

      if (perc > 0) {
        if (
          perc > 0 &&
          avg_wd > -1 &&
          stdev_wd > -1 &&
          avg_we > -1 &&
          stdev_we > -1
        ) {
          if (day === 'Sat' || day === 'Fri') {
            if (perc >= avg_we + stdev_we * 1.5) {
              varience = 'Very High';
            } else if (perc >= avg_we + stdev_we) {
              varience = 'High';
            }
          } else {
            if (perc >= avg_wd + stdev_wd * 1.5) {
              varience = 'Very High';
            } else if (perc >= avg_wd + stdev_wd) {
              varience = 'High';
            }
          }
        }
      }

      return varience;
    };

    useEffect(() => {
      const MinimumNightsVarience = (cluster) => {
        setLoad(true);
        let los_percentages_wd = [];
        let los_percentages_we = [];
        if (hotels.length > 0) {
          [...Array(100).keys()].map((n, dt) => {
            let nights_hotels = [];
            let showing_hotels = [];

            let day = moment(selectedDate)
              .add(dt, 'days')
              .format('dddd')
              .substring(0, 3);

            hotels.map((_hotel) => {
              if (checkHotelAvailability(_hotel.hotelID, dt)) {
                if (_hotel.prices[dt]) {
                  if (
                    cluster ==
                    getClusterByPrice(
                      _hotel.prices[dt].price[
                        getPrice(_hotel.prices[dt].price)
                      ],
                      dt
                    ) +
                      2
                  ) {
                    showing_hotels.push({
                      hotelID: _hotel.hotelID,
                      hotelName: _hotel.hotelName,
                      prices: _hotel.prices[dt].price,
                      price:
                        _hotel.prices[dt].price[
                          getPrice(_hotel.prices[dt].price)
                        ],
                      nights: getPrice(_hotel.prices[dt].price),
                    });
                    if (getPrice(_hotel.prices[dt].price) > 0) {
                      nights_hotels.push({
                        hotelID: _hotel.hotelID,
                        hotelName: _hotel.hotelName,
                        prices: _hotel.prices[dt].price,
                        price:
                          _hotel.prices[dt].price[
                            getPrice(_hotel.prices[dt].price)
                          ],
                        nights: getPrice(_hotel.prices[dt].price),
                      });
                    }
                  }
                }
              }
            });

            if (day === 'Sat' || day === 'Fri') {
              los_percentages_we.push(nights_hotels.length);
            } else {
              los_percentages_wd.push(nights_hotels.length);
            }
          });
        }

        if (los_percentages_wd.length > 0) {
          const _avg_wd =
            los_percentages_wd.reduce((a, b) => a + b) /
            los_percentages_wd.length;
          const _stdev_wd = getStandardDeviation(los_percentages_wd);

          setAvg_wd(_avg_wd);
          setStdev_wd(_stdev_wd);
        }

        if (los_percentages_we.length > 0) {
          const _avg_we =
            los_percentages_we.reduce((a, b) => a + b) /
            los_percentages_we.length;
          const _stdev_we = getStandardDeviation(los_percentages_we);

          setAvg_we(_avg_we);
          setStdev_we(_stdev_we);
        }
        setLoad(false);
      };

      MinimumNightsVarience(cluster_idx);
    }, [hotels]);

    return (
      <>
        {!load ? (
          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                id="stars5"
                className={classes.table}
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      fontWeight: 'bold',
                      width: '250px',
                      zIndex: 100,
                    }}
                  >
                    {CLUSTER_LABELS[cluster_idx - 2]} Bucket Hotels Count{' '}
                    <div class="dropdown-divider"></div>
                    Days Out
                  </StyledTableCell>
                  {/* <StyledTableCell size="small">Stars</StyledTableCell> */}
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date);
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(e.date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[cluster_idx - 2],
                      }}
                    >
                      MLOS Usage
                    </StyledTableCell>
                    {cluster.length > 0 ? (
                      cluster.map((day, index) => (
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          key={index}
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          <span> {getNightVarience(cluster_idx, index)}</span>
                        </StyledTableCell>
                      ))
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[cluster_idx - 2],
                      }}
                    >
                      &emsp;2 night Stay
                    </StyledTableCell>
                    {cluster.length > 0 ? (
                      cluster.map((day, index) => (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          size="small"
                          key={index}
                          style={{
                            fontSize: '14px',
                          }}
                          className={classes.rates + ' text-center'}
                        >
                          {getHotelCountByNights(cluster_idx, index, 1)}
                        </StyledTableCell>
                      ))
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[cluster_idx - 2],
                      }}
                    >
                      &emsp;3 night Stay
                    </StyledTableCell>
                    {cluster.length > 0 ? (
                      cluster.map((day, index) => (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          size="small"
                          key={index}
                          style={{
                            fontSize: '14px',
                          }}
                          className={classes.rates + ' text-center'}
                        >
                          {getHotelCountByNights(cluster_idx, index, 2)}
                        </StyledTableCell>
                      ))
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  const hotels_count_tbl_2 = useRef(null);
  const hotels_count_tbl_3 = useRef(null);
  const hotels_count_tbl_4 = useRef(null);
  const hotels_count_tbl_5 = useRef(null);

  useEffect(() => {
    const handdle_scroll = () => {
      if (tbl_ref == 1) {
        window.scrollTo(0, hotels_count_tbl_2.current.offsetTop - 250);
      } else if (tbl_ref == 2) {
        window.scrollTo(0, hotels_count_tbl_3.current.offsetTop - 250);
      } else if (tbl_ref == 3) {
        window.scrollTo(0, hotels_count_tbl_4.current.offsetTop - 250);
      } else if (tbl_ref == 4) {
        window.scrollTo(0, hotels_count_tbl_5.current.offsetTop - 250);
      }
    };
    handdle_scroll();
  });

  const GetTotlalMarketCounts = () => {
    const [avg_wd, setAvg_wd] = useState(-1);
    const [stdev_wd, setStdev_wd] = useState(-1);

    const [avg_we, setAvg_we] = useState(-1);
    const [stdev_we, setStdev_we] = useState(-1);

    const getTotalNightVarience = (dt) => {
      let varience = '';
      let day = moment(selectedDate)
        .add(dt, 'days')
        .format('dddd')
        .substring(0, 3);

      let nights_count_2 = 0;
      let nights_count_3 = 0;

      if (cluster1.length > 0) {
        nights_count_2 += getHotelCountByNights(2, dt, 1);
        nights_count_3 += getHotelCountByNights(2, dt, 2);
      }
      if (cluster2.length > 0) {
        nights_count_2 += getHotelCountByNights(3, dt, 1);
        nights_count_3 += getHotelCountByNights(3, dt, 2);
      }
      if (cluster3.length > 0) {
        nights_count_2 += getHotelCountByNights(4, dt, 1);
        nights_count_3 += getHotelCountByNights(4, dt, 2);
      }
      if (cluster4.length > 0) {
        nights_count_2 += getHotelCountByNights(5, dt, 1);
        nights_count_3 += getHotelCountByNights(5, dt, 2);
      }

      let perc = nights_count_2 + nights_count_3;

      if (perc > 0) {
        if (
          perc > 0 &&
          avg_wd > -1 &&
          stdev_wd > -1 &&
          avg_we > -1 &&
          stdev_we > -1
        ) {
          if (day === 'Sat' || day === 'Fri') {
            if (perc >= avg_we + stdev_we * 1.5) {
              varience = 'Very High';
            } else if (perc >= avg_we + stdev_we) {
              varience = 'High';
            }
          } else {
            if (perc >= avg_wd + stdev_wd * 1.5) {
              varience = 'Very High';
            } else if (perc >= avg_wd + stdev_wd) {
              varience = 'High';
            }
          }
        }
      }

      return varience;
    };

    useEffect(() => {
      const MinimumNightsVarience = () => {
        setLoad(true);
        let los_percentages_wd = [];
        let los_percentages_we = [];
        if (hotels.length > 0) {
          [...Array(100).keys()].map((n, dt) => {
            let nights_hotels = [];
            let showing_hotels = [];

            let day = moment(selectedDate)
              .add(dt, 'days')
              .format('dddd')
              .substring(0, 3);

            hotels.map((_hotel) => {
              if (checkHotelAvailability(_hotel.hotelID, dt)) {
                if (_hotel.prices[dt]) {
                  showing_hotels.push({
                    hotelID: _hotel.hotelID,
                    hotelName: _hotel.hotelName,
                    prices: _hotel.prices[dt].price,
                    price:
                      _hotel.prices[dt].price[
                        getPrice(_hotel.prices[dt].price)
                      ],
                    nights: getPrice(_hotel.prices[dt].price),
                  });
                  if (getPrice(_hotel.prices[dt].price) > 0) {
                    nights_hotels.push({
                      hotelID: _hotel.hotelID,
                      hotelName: _hotel.hotelName,
                      prices: _hotel.prices[dt].price,
                      price:
                        _hotel.prices[dt].price[
                          getPrice(_hotel.prices[dt].price)
                        ],
                      nights: getPrice(_hotel.prices[dt].price),
                    });
                  }
                }
              }
            });

            if (day === 'Sat' || day === 'Fri') {
              los_percentages_we.push(nights_hotels.length);
            } else {
              los_percentages_wd.push(nights_hotels.length);
            }
          });
        }

        if (los_percentages_wd.length > 0) {
          const _avg_wd =
            los_percentages_wd.reduce((a, b) => a + b) /
            los_percentages_wd.length;
          const _stdev_wd = getStandardDeviation(los_percentages_wd);

          setAvg_wd(_avg_wd);
          setStdev_wd(_stdev_wd);
        }

        if (los_percentages_we.length > 0) {
          const _avg_we =
            los_percentages_we.reduce((a, b) => a + b) /
            los_percentages_we.length;
          const _stdev_we = getStandardDeviation(los_percentages_we);

          setAvg_we(_avg_we);
          setStdev_we(_stdev_we);
        }
        setLoad(false);
      };

      MinimumNightsVarience();
    }, [hotels]);

    return (
      <>
        {!load ? (
          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                id="stars5"
                className={classes.table}
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      fontWeight: 'bold',
                      width: '250px',
                      zIndex: 100,
                    }}
                  >
                    Total Market Star Hotels Count{' '}
                    <div class="dropdown-divider"></div>
                    Days Out
                  </StyledTableCell>
                  {/* <StyledTableCell size="small">Stars</StyledTableCell> */}
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date);
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(e.date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                      }}
                    >
                      MLOS Usage
                    </StyledTableCell>
                    {cluster1.length > 0 ? (
                      cluster1.map((day, index) => (
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          key={index}
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          <span> {getTotalNightVarience(index)}</span>
                        </StyledTableCell>
                      ))
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                      }}
                    >
                      &emsp;2 night Stay
                    </StyledTableCell>

                    {[...Array(report_len).keys()].map((day, dt) =>
                      (() => {
                        let nights_count_2 = 0;

                        if (cluster1.length > 0) {
                          nights_count_2 += getHotelCountByNights(2, dt, 1);
                        }
                        if (cluster2.length > 0) {
                          nights_count_2 += getHotelCountByNights(3, dt, 1);
                        }
                        if (cluster3.length > 0) {
                          nights_count_2 += getHotelCountByNights(4, dt, 1);
                        }
                        if (cluster4.length > 0) {
                          nights_count_2 += getHotelCountByNights(5, dt, 1);
                        }
                        return (
                          <StyledTableCell
                            component="th"
                            scope="row"
                            size="small"
                            key={dt}
                            style={{
                              fontSize: '14px',
                            }}
                            className={classes.rates + ' text-center'}
                          >
                            {nights_count_2}
                          </StyledTableCell>
                        );
                      })()
                    )}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                      }}
                    >
                      &emsp;3 night Stay
                    </StyledTableCell>
                    {[...Array(report_len).keys()].map((day, dt) =>
                      (() => {
                        let nights_count_3 = 0;

                        if (cluster1.length > 0) {
                          nights_count_3 += getHotelCountByNights(2, dt, 2);
                        }
                        if (cluster2.length > 0) {
                          nights_count_3 += getHotelCountByNights(3, dt, 2);
                        }
                        if (cluster3.length > 0) {
                          nights_count_3 += getHotelCountByNights(4, dt, 2);
                        }
                        if (cluster4.length > 0) {
                          nights_count_3 += getHotelCountByNights(5, dt, 2);
                        }
                        return (
                          <StyledTableCell
                            component="th"
                            scope="row"
                            size="small"
                            key={dt}
                            style={{
                              fontSize: '14px',
                            }}
                            className={classes.rates + ' text-center'}
                          >
                            {nights_count_3}
                          </StyledTableCell>
                        );
                      })()
                    )}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <div>
        <GetTotlalMarketCounts />
      </div>

      <div ref={hotels_count_tbl_5}>
        {cluster4.length > 0 ? (
          <GetStarHotelCounts cluster={cluster4} cluster_idx={5} />
        ) : (
          <></>
        )}
      </div>
      <div ref={hotels_count_tbl_4}>
        {cluster3.length > 0 ? (
          <GetStarHotelCounts cluster={cluster3} cluster_idx={4} />
        ) : (
          <></>
        )}
      </div>
      <div ref={hotels_count_tbl_3}>
        {cluster2.length > 0 ? (
          <GetStarHotelCounts cluster={cluster2} cluster_idx={3} />
        ) : (
          <></>
        )}
      </div>
      <div ref={hotels_count_tbl_2}>
        {cluster1.length > 0 ? (
          <GetStarHotelCounts cluster={cluster1} cluster_idx={2} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
