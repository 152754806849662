import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FONT_FAMILY } from '../utils/const';
import ClusterBucket from './ClusterBucket';
import SimilarRatingStarAverageSratZone from './SimilarRatingAverageStratZone';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tabularNavStyle: {
    backgroundColor: '#607D8B',
    fontFamily: FONT_FAMILY,
    fontWeight: 'bold',
  },
}));

export const RateStrategy = ({ selectedDate, reqHotel, tbl_ref, sub_tab }) => {
  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    clusterData,
    loading,
    err,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels,
    hotelList,
    markets,
    refreshDates,
    ratingCluster,
    comparison_report,
  } = getClusterDataSet;

  const classes = useStyles();
  const [tab, setTab] = useState(sub_tab);

  const [bestRatedHotels, setBestRatedHotels] = useState([]);

  const reqHotelRatings =
    reqHotel.length > 0
      ? reqHotel.find((el) => el.raings != null && el.raings != 'N/A').raings
      : undefined;

  useEffect(() => {
    const filterBestRatedHotels = () => {
      let filtered_hotels = [];
      if (hotels.length > 0) {
        hotels.map((_hotel) => {
          if (_hotel.ratings >= ratingCluster.min_rating) {
            filtered_hotels.push(_hotel);
          }
        });
      }

      setBestRatedHotels(filtered_hotels);
      // console.log(filtered_hotels);
    };

    if (hotels.length > 0 && ratingCluster) {
      filterBestRatedHotels();
    }
  }, [hotels]);

  const TabularNav = () => {
    const [tabularNavCls] = useState(
      'text-light border-bottom-0 border-secondary  ' + classes.tabularNavStyle
    );
    return (
      <div style={{ position: 'sticky', top: 185, zIndex: 200 }}>
        <Nav variant="tabs" justify="space-around">
          <Nav.Item>
            <Nav.Link
              className={
                tab === 1
                  ? tabularNavCls
                  : 'text-dark font-weight-bold bg-light  shadow '
              }
              eventKey="link-1"
              onClick={() => setTab(1)}
            >
              Rate Strategy Zone
            </Nav.Link>
          </Nav.Item>{' '}
          {/* <Nav.Item>
                <Nav.Link
                  className={
                    tab === 2
                      ? tabularNavCls
                      : 'text-dark font-weight-bold bg-light shadow '
                  }
                  eventKey="link-1"
                  onClick={() => setTab(2)}
                >
                  Star Average StratZone
                </Nav.Link>
          </Nav.Item> */}
          <Nav.Item>
                <Nav.Link
                  className={
                    tab === 3
                      ? tabularNavCls
                      : 'text-dark font-weight-bold bg-light shadow '
                  }
                  eventKey="link-1"
                  onClick={() => setTab(3)}
                >
                   Similar Rated Star Average RateZone
                </Nav.Link>
          </Nav.Item>
          {/* {ratingCluster.min_rating && reqHotel.length > 0 ? (
            reqHotelRatings >= ratingCluster.min_rating &&
            ratingCluster.clusterData.length > 0 ? (
              <Nav.Item>
                <Nav.Link
                  className={
                    tab === 2
                      ? tabularNavCls
                      : 'text-dark font-weight-bold bg-light shadow '
                  }
                  eventKey="link-1"
                  onClick={() => setTab(2)}
                >
                  Star Average Strat Zone
                </Nav.Link>
              </Nav.Item>
            ) : (
              <></>
            )
          ) : (
            ''
          )} */}
        </Nav>
      </div>
    );
  };

  return (
    <>
      <TabularNav/>
      <div className='my-5'>
        {
        hotels.length > 0 && tab === 1 && clusterData.length > 0 ? (
          <>
              <ClusterBucket
                selectedDate={selectedDate}
                insights_data={false}
                tbl_ref={tbl_ref}
                reqHotel={reqHotel}
                clusterData={clusterData}
                cluster1={cluster1}
                cluster2={cluster2}
                cluster3={cluster3}
                cluster4={cluster4}
                hotels={hotels}
              />
          </>
        ) 
        // : hotels.length > 0 &&
        //   tab === 2 &&
        //   clusterData.length > 0 ? (
        //   <>
        //     <StarAverageSratZone
        //         selectedDate={selectedDate}
        //         insights_data={false}
        //         tbl_ref={tbl_ref}
        //         reqHotel={reqHotel}
        //         clusterData={clusterData}
        //         cluster1={cluster1}
        //         cluster2={cluster2}
        //         cluster3={cluster3}
        //         cluster4={cluster4}
        //         hotels={hotels}
        //         />
        //   </>
        // ) 
        :
        hotels.length > 0 &&
          tab === 3 &&
          clusterData.length > 0 ? (
          <>
            <SimilarRatingStarAverageSratZone
                selectedDate={selectedDate}
                insights_data={false}
                tbl_ref={tbl_ref}
                reqHotel={reqHotel}
                clusterData={clusterData}
                cluster1={cluster1}
                cluster2={cluster2}
                cluster3={cluster3}
                cluster4={cluster4}
                hotels={hotels}
                />
          </>
        )
        :
        (
          <></>
        )}

      </div>
    </>
  );
};
