import {
    Box,
    Grid,
    makeStyles,
    TableCell,
    TableContainer,
    TableRow,
    TableSortLabel,
    FormControl,
    InputLabel,
    Select,
    withStyles,
  } from '@material-ui/core';
  
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TableHead from '@material-ui/core/TableHead';
  import Paper from '@material-ui/core/Paper';
  import { useSelector } from 'react-redux';
  import moment from 'moment';
  import { CLUSTER_BACKGROUND, FONT_FAMILY } from '../utils/const';
  import { useEffect, useState } from 'react';
  
  import { Line } from 'react-chartjs-2';
  import { useReducer } from 'react';
  import { useRef } from 'react';
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bold',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {},
  }))(TableRow);
  const useStyles = makeStyles({
    table: {
      '& .MuiTableCell-root': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    sticky: {
      position: 'sticky',
      left: 0,
      background: 'white',
      boxShadow: '2px 2px 2px grey',
      display: 'block',
      zIndex: 50,
    },
    rates: {
      fontFamily: FONT_FAMILY,
      textAlign: 'center',
    },
    formControl: {
      minWidth: 120,
    },
  });
  
  export default function SimilarRatingStarAverageSratZone({
    selectedDate,
    reqHotel,
    clusterData,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels, 
    tbl_ref,
    insights_data
  }) {
    const classes = useStyles();
  
    const getClusterDataSet = useSelector((state) => state.clusterDataSet);
    const { 
    loading, err, report_len, date_format } = getClusterDataSet;
  
    const auth = useSelector((state) => state.auth);
    const { user } = auth;
  
    const [load, setLoad] = useState(false);
  
    const [reqAvgHotelStrategyZone, setReqAvgHotelStrategyZone] = useState(undefined);
  
    const reqHotelName =
      reqHotel.length > 0
        ? reqHotel.find((el) => el.name != null).name
        : undefined;

    const reqHotelStars =
    reqHotel.length > 0
      ? reqHotel.find((el) => el.stars != null && el.stars != '-').stars
      : undefined;

    const reqHotelRatings =
      reqHotel.length > 0
        ? reqHotel.find((el) => el.raings != null && el.raings != '-').raings
        : undefined;

  
    const getDateRange = () => {
      let dateRange_arr = [];
      let totalDays = 0;
  
      if (report_len > 0) {
        const no_chuncks = Math.ceil(report_len / 30);
  
        for (let i = 1; i <= no_chuncks; i++) {
          totalDays += 30;
          if (totalDays <= report_len) {
            dateRange_arr.push([(i - 1) * 30, i * 30]);
          } else if (totalDays > report_len) {
            dateRange_arr.push([
              (i - 1) * 30,
              i * 30 - (totalDays - (report_len - 1)),
            ]);
          }
        }
      }
      return dateRange_arr;
    };
  
    const [dateRange, setDateRange] = useState(getDateRange());
  
    const [datePage, setDatePage] = useState(0);
  
    const [lineData, setLineData] = useState({
      labels: cluster1
        .slice(dateRange[datePage][0], dateRange[datePage][1])
        .map((a) => moment(a.date).format(date_format)),
      datasets: [
        {
          label: 'Upper Bound',
          fill: true,
          borderColor: '#0D47A1',
          borderWidth: 5,
          data: [],
        },
        {
          label: 'Freq Rank Range High',
          fill: true,
          borderColor: '#1976D2',
          borderWidth: 5,
          data: [],
        },
        {
          label: 'Freq Rank Range Low',
          fill: true,
          borderColor: '#42A5F5',
          borderWidth: 5,
          data: [],
        },
        {
          label: 'Freq Rank Range Average',
          fill: true,
          borderColor: '#82B1FF',
  
          borderWidth: 5,
          data: [],
        },
        {
          label: 'Lower Bound',
          fill: true,
          borderColor: '#FFCA28',
          borderWidth: 5,
          data: [],
        },
        {
          label: reqHotel.find((el) => el.name != null).name,
          fill: true,
          borderColor: '#616161',
          borderWidth: 5,
          data: [],
        },
      ],
    });
  
    const cluster_bucket_tbl = useRef(null);
  
    const rate_strategy_zone_tbl = useRef(null);
  
    const setChartData = (dp) => {
      try {
        if (reqAvgHotelStrategyZone != undefined) {
          // console.log(reqAvgHotelStrategyZone);
          lineData.labels = cluster1
            .slice(dateRange[dp][0], dateRange[dp][1])
            .map((a) => moment(a.date).format(date_format));
  
          lineData.datasets.map((set, ix) => {
            if (ix === 0) {
              set.data = reqAvgHotelStrategyZone.prices
                .slice(dateRange[dp][0], dateRange[dp][1])
                .map((sz) => {
                  try {
                    return sz.upper_bound_rate.rate;
                  } catch (e) {}
                });
            } else if (ix === 1) {
              set.data = reqAvgHotelStrategyZone.prices
                .slice(dateRange[dp][0], dateRange[dp][1])
                .map((sz) => {
                  try {
                    return sz.upper_start_rate.rate;
                  } catch (e) {}
                });
            } else if (ix === 2) {
              set.data = reqAvgHotelStrategyZone.prices
                .slice(dateRange[dp][0], dateRange[dp][1])
                .map((sz) => {
                  try {
                    return sz.lower_start_rate.rate;
                  } catch (e) {}
                });
            } else if (ix === 3) {
              set.data = reqAvgHotelStrategyZone.prices
                .slice(dateRange[dp][0], dateRange[dp][1])
                .map((sz) => {
                  try {
                    return Math.round(sz.avarage_rate);
                  } catch (e) {}
                });
            } else if (ix === 4) {
              set.data = reqAvgHotelStrategyZone.prices
                .slice(dateRange[dp][0], dateRange[dp][1])
                .map((sz) => {
                  try {
                    return sz.lower_bound_rate.rate;
                  } catch (e) {}
                });
            } else if (ix === 5) {
              set.data = reqAvgHotelStrategyZone.prices
                .slice(dateRange[dp][0], dateRange[dp][1])
                .map((dt) => {
                  try {
                    return dt.star_avg_rate;
                  } catch (e) {}
                });
            }
          });
        }
      } catch (e) {}
    };
  
    useEffect(() => {
      setLoad(true);
      setChartData(0);
  
      setLoad(false);
    }, [reqAvgHotelStrategyZone, hotels]);
  
    const handleDatePage = (e) => {
      setDatePage(e);
      setChartData(e);
    };
  
    const getAverage = (arr) => {
      let array = arr.filter((n) => n == 0 || n);
      if (array.length > 0) {
        let avg = array.reduce((a, b) => a + b) / array.length;
        return avg;
      } else {
        return -1;
      }
    };
  
    const getStandardDeviation = (array) => {
      const n = array.length;
      if (n > 0) {
        const mean = array.reduce((a, b) => a + b) / n;
        return Math.sqrt(
          array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
        );
      } else {
        return -1;
      }
    };
  
    const getRankedHotels = (arr) => {
      var sorted = arr
        .filter((e) => e.rate != 'NaN')
        .sort((a, b) => b.rate - a.rate || b.ratings - a.ratings);
  
      var rank = 1;
      for (var i = 0; i < sorted.length; i++) {
        sorted[i].day_rank = i + 1;
      }
  
      // console.log(sorted);
  
      return sorted;
    };
  
    const getStarPriceByDayRank = (day, rank) => {
        let hotels_list = [];
    
        try {
          if (cluster1[day]) {
            hotels_list = hotels_list.concat(cluster1[day].unwanted);
          }
        } catch (e) {}
        try {
          if (cluster2[day]) {
            hotels_list = hotels_list.concat(cluster2[day].unwanted);
          }
        } catch (e) {}
        try {
          if (cluster3[day]) {
            hotels_list = hotels_list.concat(cluster3[day].unwanted);
          }
        } catch (e) {}
        try {
          if (cluster4[day]) {
            hotels_list = hotels_list.concat(cluster4[day].unwanted);
          }
        } catch (e) {}
    
        hotels_list = hotels_list.filter(_hotel => { 
          if(Math.floor(_hotel.stars) == Math.floor(reqHotelStars)){
            if(_hotel.raings >= reqHotelRatings - 0.2 && _hotel.raings <= reqHotelRatings + 0.2){
              return _hotel
            }
          }
        }).sort(
          (a, b) => b.rate - a.rate || b.raings - a.raings
        );

        const rank_hotel = hotels_list[rank - 1];
          
        if (rank_hotel) {
          return rank_hotel.rate;
        } else {
          return '';
        }
      };
  
    useEffect(() => {
      // console.log(`selectedDate: ${selectedDate}`);
  
      const CalculateHotelRanks = async () => {
        setLoad(true);
        const hotelList = [...hotels]
        let ranked_hotels_list = [];
        if (hotelList.length > 0) {
           await [...Array(report_len).keys()].map((e, index) => {
            let hotel_rates_by_day = [];

            hotelList.map((_hotel) => {
              let dt = _hotel.prices[index];
  
              try {
                if (
                  dt !== null &&
                  checkHotelAvailability(_hotel.hotelID, index)
                ) {
                    if((Math.floor(_hotel.stars) == Math.floor(reqHotelStars))) {
                        if(_hotel.ratings >= reqHotelRatings - 0.2 && _hotel.ratings <= reqHotelRatings + 0.2){
                            hotel_rates_by_day.push({
                                hotel_id: _hotel.hotelID,
                                rate: dt.price[getPrice(dt.price)],
                                date: dt.date,
                                ratings: _hotel.ratings,
                              });
                        }
                       
                    }
                }
              } catch (error) {}
            });
           
            const ranked_hotels = getRankedHotels(hotel_rates_by_day);
            const star_avg_rate = Math.round(getAverage(hotel_rates_by_day.map(hotel => hotel.rate)))
  
            ranked_hotels_list.push(ranked_hotels);

  
            hotelList.map((_hotel) => {
              let dt = _hotel.prices[index];
  
              try {
                if (
                  dt !== null &&
                  checkHotelAvailability(_hotel.hotelID, index)
                ) {
                  let obj =
                    ranked_hotels.findIndex((e) => e.hotel_id == _hotel.hotelID) +
                    1;
                  if (obj > 0) {
                    dt.star_avg_rate = star_avg_rate
                    dt.day_rank = obj;
                    dt.total = ranked_hotels.length;
                  }
                }
              } catch (error) {}
            });
          });
  
          const req_hotel_data = hotelList.find((e) => e.hotelName == reqHotelName);
  
          if (req_hotel_data != undefined) {

              let rank_arr_wd = [];
              let ranks_arr_we = [];
              let ranks_arr_w = [];

              req_hotel_data.prices.map((dt, ix) => {
                  if(Math.floor(req_hotel_data.stars) == Math.floor(reqHotelStars)) {
                      if (dt !== null) {
  
                          if (checkHotelAvailability(req_hotel_data.hotelID, ix)) {
                            ranks_arr_w.push(dt.day_rank);
                            if (moment(dt.date).day() == 5 || moment(dt.date).day() == 6) {
                              ranks_arr_we.push(dt.day_rank);
                            } else {
                              rank_arr_wd.push(dt.day_rank);
                            }
                          }
                        }
                  }
              });

              let avg_rank_wd = getAverage(rank_arr_wd);
              let avg_rank_we = getAverage(ranks_arr_we);
              let avg_rank = getAverage(ranks_arr_w);
              let stdev_wd = getStandardDeviation(rank_arr_wd);
              let stdev_we = getStandardDeviation(ranks_arr_we);
    
              req_hotel_data.avg_rank_wd = avg_rank_wd;
              req_hotel_data.avg_rank_we = avg_rank_we;
              req_hotel_data.avg_rank = avg_rank;
              req_hotel_data.stdev_wd = stdev_wd;
              req_hotel_data.stdev_we = stdev_we;
    
              req_hotel_data.upper_bound_wd = Math.ceil(avg_rank_wd - 2 * stdev_wd);
              req_hotel_data.lower_bound_wd = Math.ceil(avg_rank_wd + 2 * stdev_wd);
              req_hotel_data.upper_start_wd = Math.ceil(avg_rank_wd - 1 * stdev_wd);
              req_hotel_data.lower_start_wd = Math.ceil(avg_rank_wd + 1 * stdev_wd);
    
              req_hotel_data.upper_bound_we = Math.ceil(avg_rank_we - 2 * stdev_we);
              req_hotel_data.lower_bound_we = Math.ceil(avg_rank_we + 2 * stdev_we);
              req_hotel_data.upper_start_we = Math.ceil(avg_rank_we - 1 * stdev_we);
              req_hotel_data.lower_start_we = Math.ceil(avg_rank_we + 1 * stdev_we);
           
    
            
              req_hotel_data.prices.map((dt, ix) => {
                  if(Math.floor(req_hotel_data.stars) == Math.floor(reqHotelStars)) {
                      if (dt !== null) {
                          let day = moment(dt.date).format('dddd').substring(0, 3);
            
                          if (
                            Object.prototype.toString.call(ranked_hotels_list[ix]) ===
                            '[object Array]'
                          ) {
                            if (day === 'Sat' || day === 'Fri') {
                              dt.upper_bound_rate = ranked_hotels_list[ix].find(
                                (obj, i) => obj.day_rank == req_hotel_data.upper_bound_we
                              );
            
                              dt.upper_start_rate = ranked_hotels_list[ix].find(
                                (obj, i) => obj.day_rank == req_hotel_data.upper_start_we
                              );
            
                              dt.lower_bound_rate = ranked_hotels_list[ix].find(
                                (obj, i) => obj.day_rank == req_hotel_data.lower_bound_we
                              );
            
                              dt.lower_start_rate = ranked_hotels_list[ix].find(
                                (obj, i) => obj.day_rank == req_hotel_data.lower_start_we
                              );
            
                              if (dt.upper_bound_rate == undefined) {
                                dt.upper_bound_rate = ranked_hotels_list[ix][0];
                              }
            
                              if (dt.lower_bound_rate == undefined) {
                                dt.lower_bound_rate =
                                  ranked_hotels_list[ix][ranked_hotels_list[ix].length - 1];
                              }
                            } else {
                              dt.upper_bound_rate = ranked_hotels_list[ix].find(
                                (obj, i) => obj.day_rank == req_hotel_data.upper_bound_wd
                              );

                              dt.upper_start_rate = ranked_hotels_list[ix].find(
                                (obj, i) => obj.day_rank == req_hotel_data.upper_start_wd
                              );
                              dt.lower_bound_rate = ranked_hotels_list[ix].find(
                                (obj, i) => obj.day_rank == req_hotel_data.lower_bound_wd
                              );
                              dt.lower_start_rate = ranked_hotels_list[ix].find(
                                (obj, i) => obj.day_rank == req_hotel_data.lower_start_wd
                              );
            
                              if (dt.upper_bound_rate == undefined) {
                                dt.upper_bound_rate = ranked_hotels_list[ix][0];
                              }
            
                              if (dt.lower_bound_rate == undefined) {
                                dt.lower_bound_rate =
                                  ranked_hotels_list[ix][ranked_hotels_list[ix].length - 1];
                              }
                            }
                          }
            
                          if (
                            dt.lower_bound_rate != undefined &&
                            dt.upper_bound_rate != undefined &&
                            dt.upper_start_rate &&
                            dt.lower_start_rate
                          ) {
                            if (
                              dt.star_avg_rate >= dt.lower_bound_rate.rate &&
                              dt.star_avg_rate < dt.lower_start_rate.rate
                            ) {
                              dt.qr = 'LOW';
                            } else if (
                              dt.star_avg_rate >= dt.lower_start_rate.rate &&
                              dt.star_avg_rate < dt.upper_start_rate.rate
                            ) {
                              dt.qr = 'MID';
                            } else if (
                              dt.star_avg_rate >= dt.upper_start_rate.rate &&
                              dt.star_avg_rate <= dt.upper_bound_rate.rate
                            ) {
                              dt.qr = 'HIGH';
                            } else if (
                              dt.star_avg_rate < dt.lower_bound_rate.rate
                            ) {
                              dt.qr = 'BELOW';
                            } else if (
                              dt.star_avg_rate > dt.upper_bound_rate.rate
                            ) {
                              dt.qr = 'OVER';
                            }
                          }
                        }
                  }
              });
         

            let cluster_arr_wd = [];
            let cluster_arr_we = [];
            let day_ranks_wd = [];
            let day_ranks_we = [];
            let qr_wd = [];
            let qr_we = [];
  
            req_hotel_data.prices.map((dt, ix) => {
              if (dt !== null) {
                if (moment(dt.date).day() == 5 || moment(dt.date).day() == 6) {
                  qr_we.push(dt.qr);
                  cluster_arr_we.push(
                    getClusterByPrice(dt.price[getPrice(dt.price)], ix) + 2
                  );
                  day_ranks_we.push(dt.day_rank);
                } else {
                  qr_wd.push(dt.qr);
                  cluster_arr_wd.push(
                    getClusterByPrice(dt.price[getPrice(dt.price)], ix) + 2
                  );
                  day_ranks_wd.push(dt.day_rank);
                }
              }
            });
  
            req_hotel_data.freq_bucket_wd = mode(cluster_arr_wd);
            req_hotel_data.freq_bucket_we = mode(cluster_arr_we);
            
            const avg_day_rank_wd = getAverage(day_ranks_wd);
            req_hotel_data.avg_day_rank_wd = Math.round(avg_day_rank_wd);
  
            const avg_day_rank_we = getAverage(day_ranks_we);
            req_hotel_data.avg_day_rank_we = Math.round(avg_day_rank_we);
  
            req_hotel_data.freq_qr_wd = mode(qr_wd);
            req_hotel_data.freq_qr_we = mode(qr_we);
  
            req_hotel_data.prices.map((dt, ix) => {
              if (dt !== null) {
                if (moment(dt.date).day() == 5 || moment(dt.date).day() == 6) {
                  dt.avarage_rate = getStarPriceByDayRank(
                    ix,
                    Math.round(avg_day_rank_we)
                  );
                } else {
                  dt.avarage_rate = getStarPriceByDayRank(
                    ix,
                    Math.round(avg_day_rank_wd)
                  );
                }
              }
            });
          }
  
          setReqAvgHotelStrategyZone(req_hotel_data);
          // console.log(req_hotel_data);
        }
        setLoad(false);
      };
  
      CalculateHotelRanks();
    }, []);
  
 
    function mode(array) {
      if (array.length == 0) return null;
      var modeMap = {};
      var maxEl = array[0],
        maxCount = 1;
      for (var i = 0; i < array.length; i++) {
        var el = array[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }
      return maxEl;
    }
  
    const getClusterByPrice = (rate, ix) => {
      let clustered = [];
      let res = 4;
  
      if (cluster1.length > 0 && cluster1[ix]) {
        clustered.push(cluster1[ix]);
      }
      if (cluster2.length > 0 && cluster2[ix]) {
        clustered.push(cluster2[ix]);
      }
      if (cluster3.length > 0 && cluster3[ix]) {
        clustered.push(cluster3[ix]);
      }
      if (cluster4.length > 0 && cluster4[ix]) {
        clustered.push(cluster4[ix]);
      }
  
      clustered.sort((a, b) => a.mean - b.mean);
  
      // console.log(clustered);
  
      try {
        clustered.map((cl, id) => {
          if (rate >= cl.min && rate <= cl.max) {
            res = id;
            return;
          }
        });
      } catch (e) {}
  
      return res;
    };
  
    const getPrice = (arr) => {
      const price = arr.findIndex((e) => e > 0);
      return price;
    };
  
    const checkHotelAvailability = (id, day) => {
      let clustered = [];
  
      if (cluster1.length > 0 && cluster1[day]) {
        clustered.push(cluster1[day].unwanted);
      }
      if (cluster2.length > 0 && cluster2[day]) {
        clustered.push(cluster2[day].unwanted);
      }
      if (cluster3.length > 0 && cluster3[day]) {
        clustered.push(cluster3[day].unwanted);
      }
      if (cluster4.length > 0 && cluster4[day]) {
        clustered.push(cluster4[day].unwanted);
      }
      let hotels_arr = [];
  
      for (var i = 0; i < clustered.length; i++) {
        hotels_arr = hotels_arr.concat(clustered[i]);
      }
  
      const exists = hotels_arr.some((obj) => obj.id == id);
  
      if (exists) {
        return true;
      } else {
        return false;
      }
    };
  
    const daily_fetch_len = selectedDate
      ? moment(moment(selectedDate).add(180, 'days'))
          .endOf('month')
          .day('sunday')
          .diff(selectedDate, 'days')
      : 0;
  
    const HotelRanksTable = () => {
      return (
        <>
          {' '}
          {hotels.length > 0 &&
          !load &&
          reqAvgHotelStrategyZone != undefined ? (
            <>
              <div ref={rate_strategy_zone_tbl}>
                <TableContainer
                  component={Paper}
                  className={classes.container + ' mt-3'}
                >
                  <TableContainer component={Paper}>
                    <Box width={100}>
                      <Table
                        className={classes.table}
                        size="medium"
                        aria-label="customized table"
                        bodyStyle={{ overflow: 'visible' }}
                        stickyHeader
                      >
                        <TableHead>
                          <StyledTableCell
                            style={{
                              width: '275px',
                              zIndex: 100,
                              fontFamily: FONT_FAMILY,
                            }}
                          >
                            <TableSortLabel disabled>
                            Similar Rated {Math.floor(reqHotelStars)} Star Average RateZone
                            </TableSortLabel>{' '}
                            <hr />
                            <TableSortLabel disabled>Days Out</TableSortLabel>
                          </StyledTableCell>
                          {reqHotel.map((e, index) =>
                            (() => {
                              let _date = moment(e.checkIn);
                              let daysOut = _date.diff(selectedDate, 'days');
                              let date = _date.format('dddd').substring(0, 3);
                              return (
                                <StyledTableCell
                                  size="small"
                                  key={index}
                                  className={
                                    date === 'Sat' || date === 'Fri'
                                      ? 'bg-secondary text-light text-center'
                                      : 'text-center'
                                  }
                                  style={{
                                    fontSize: '12px',
                                    borderRight:
                                      index == daily_fetch_len
                                        ? '5px solid rgba(66, 66, 66, 1)'
                                        : '',
                                  }}
                                >
                                  <>
                                    {date === 'Sat' || date === 'Fri'
                                      ? 'WEND'
                                      : 'WDAY'}
                                  </>
                                  <br />
                                  <>{date.toUpperCase()}</>
                                  <br />
                                  <>
                                    {moment(e.checkIn).format(date_format)}
                                  </>{' '}
                                  <div class="dropdown-divider"></div>
                                  {daysOut}
                                </StyledTableCell>
                              );
                            })()
                          )}
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell
                              size="medium"
                              component="th"
                              scope="col"
                              className={classes.sticky}
                              style={{ fontWeight: 'bold', width: '275px' }}
                            >
                              Current Rate
                            </StyledTableCell>
                            {[...Array(report_len).keys()].map((e, index) =>
                              (() => {
                                if (reqAvgHotelStrategyZone.prices[index] != null) {
                                  return (
                                    <StyledTableCell
                                      size="small"
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          CLUSTER_BACKGROUND[getClusterByPrice(reqAvgHotelStrategyZone.prices[index].star_avg_rate, index)],
                                        borderRight:
                                          index == daily_fetch_len
                                            ? '5px solid rgba(66, 66, 66, 1)'
                                            : '',
                                      }}
                                      className={classes.rates}
                                    >
                                      {reqAvgHotelStrategyZone.prices[index].star_avg_rate}
                                    </StyledTableCell>
                                  );
                                } else {
                                  return (
                                    <StyledTableCell
                                      size="small"
                                      key={index}
                                      style={{
                                        borderRight:
                                          index == daily_fetch_len
                                            ? '5px solid rgba(66, 66, 66, 1)'
                                            : '',
                                      }}
                                      className={classes.rates}
                                    >
                                      N/A
                                    </StyledTableCell>
                                  );
                                }
                              })()
                            )}
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell
                              size="small"
                              component="th"
                              scope="row"
                              className={classes.sticky}
                              style={{
                                fontWeight: 'bold',
                                width: '275px',
                              }}
                            >
                              Total Market Rank (Highest to Lowest)
                            </StyledTableCell>
  
                            {[...Array(report_len).keys()].map((e, index) =>
                              (() => {
                                if (reqAvgHotelStrategyZone.prices[index] != null) {
                                  return (
                                    <StyledTableCell
                                      size="small"
                                      key={index}
                                      style={{
                                        borderRight:
                                          index == daily_fetch_len
                                            ? '5px solid rgba(66, 66, 66, 1)'
                                            : '',
                                      }}
                                      className={classes.rates}
                                    >
                                      <sup>
                                        {
                                          reqAvgHotelStrategyZone.prices[index]
                                            .day_rank
                                        }
                                      </sup>
                                      &frasl;
                                      <sub>
                                        {reqAvgHotelStrategyZone.prices[index].total}
                                      </sub>
                                    </StyledTableCell>
                                  );
                                } else {
                                  return (
                                    <StyledTableCell
                                      size="small"
                                      key={index}
                                      style={{
                                        borderRight:
                                          index == daily_fetch_len
                                            ? '5px solid rgba(66, 66, 66, 1)'
                                            : '',
                                      }}
                                      className={classes.rates}
                                    >
                                      N/A
                                    </StyledTableCell>
                                  );
                                }
                              })()
                            )}
                          </StyledTableRow>
  
                          <>
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{ fontWeight: 'bold', width: '275px' }}
                              >
                                Strategy Zone Position
                              </StyledTableCell>
  
                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (
                                    reqAvgHotelStrategyZone.prices[index] != null
                                  ) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={index}
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: '12px',
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {reqAvgHotelStrategyZone.prices[index].qr ==
                                        'MID'
                                          ? ''
                                          : reqAvgHotelStrategyZone.prices[index].qr}
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={index}
                                        style={{
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        N/A
                                      </StyledTableCell>
                                    );
                                  }
                                })()
                              )}
                            </StyledTableRow>
  
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{ fontWeight: 'bold', width: '275px' }}
                              >
                               V. High Demand &nbsp; (
                                {reqAvgHotelStrategyZone.upper_bound_wd},{' '}
                                {reqAvgHotelStrategyZone.upper_bound_we})
                              </StyledTableCell>
  
                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (
                                    reqAvgHotelStrategyZone.prices[index] != null
                                  ) {
                                    if (
                                      reqAvgHotelStrategyZone.prices[index]
                                        .upper_bound_rate
                                    ) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            backgroundColor:
                                              CLUSTER_BACKGROUND[
                                                getClusterByPrice(
                                                  reqAvgHotelStrategyZone.prices[
                                                    index
                                                  ].upper_bound_rate.rate,
                                                  index
                                                )
                                              ],
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {
                                            reqAvgHotelStrategyZone.prices[index]
                                              .upper_bound_rate.rate
                                          }
                                        </StyledTableCell>
                                      );
                                    } else {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            fontWeight: 'bold',
  
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          N/A
                                        </StyledTableCell>
                                      );
                                    }
                                  } else {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={index}
                                        style={{
                                          fontWeight: 'bold',
  
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        N/A
                                      </StyledTableCell>
                                    );
                                  }
                                })()
                              )}
                            </StyledTableRow>
  
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{
                                  fontWeight: 'bold',
                                  width: '275px',
                                  zIndex: 100,
                                }}
                              >
                                &emsp;High Demand (
                                {reqAvgHotelStrategyZone.upper_start_wd},{' '}
                                {reqAvgHotelStrategyZone.upper_start_we})
                              </StyledTableCell>
  
                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (
                                    reqAvgHotelStrategyZone.prices[index] != null
                                  ) {
                                    if (
                                      reqAvgHotelStrategyZone.prices[index]
                                        .upper_start_rate
                                    ) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            backgroundColor:
                                              CLUSTER_BACKGROUND[
                                                getClusterByPrice(
                                                  reqAvgHotelStrategyZone.prices[
                                                    index
                                                  ].upper_start_rate.rate,
                                                  index
                                                )
                                              ],
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {parseFloat(
                                            reqAvgHotelStrategyZone.prices[index]
                                              .upper_start_rate.rate
                                          ).toFixed(0)}
                                        </StyledTableCell>
                                      );
                                    } else {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          N/A
                                        </StyledTableCell>
                                      );
                                    }
                                  } else {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={index}
                                        style={{
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        N/A
                                      </StyledTableCell>
                                    );
                                  }
                                })()
                              )}
                            </StyledTableRow>
  
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{
                                  fontWeight: 'bold',
                                  width: '275px',
                                  borderTop: '2px solid grey',
                                  borderBottom: '2px solid grey',
                                }}
                              >
                                &emsp;&ensp;Target Rate (
                                {reqAvgHotelStrategyZone.avg_day_rank_wd},{' '}
                                {reqAvgHotelStrategyZone.avg_day_rank_we})
                              </StyledTableCell>
  
                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (
                                    reqAvgHotelStrategyZone.prices[index] != null
                                  ) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={index}
                                        style={{
                                          backgroundColor:
                                            CLUSTER_BACKGROUND[
                                              getClusterByPrice(
                                                reqAvgHotelStrategyZone.prices[index]
                                                  .avarage_rate,
                                                index
                                              )
                                            ],
                                          borderTop: '2px solid grey',
                                          borderBottom: '2px solid grey',
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {
                                          reqAvgHotelStrategyZone.prices[index]
                                            .avarage_rate
                                        }
                                      </StyledTableCell>
                                    );
                                  } else {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={index}
                                        style={{
                                          borderTop: '2px solid grey',
                                          borderBottom: '2px solid grey',
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        N/A
                                      </StyledTableCell>
                                    );
                                  }
                                })()
                              )}
                            </StyledTableRow>
  
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{
                                  fontWeight: 'bold',
                                  width: '275px',
                                  zIndex: 100,
                                }}
                              >
                                &emsp;Low Demand (
                                {reqAvgHotelStrategyZone.lower_start_wd},{' '}
                                {reqAvgHotelStrategyZone.lower_start_we})
                              </StyledTableCell>
  
                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (
                                    reqAvgHotelStrategyZone.prices[index] != null
                                  ) {
                                    if (
                                      reqAvgHotelStrategyZone.prices[index]
                                        .lower_start_rate
                                    ) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            backgroundColor:
                                              CLUSTER_BACKGROUND[
                                                getClusterByPrice(
                                                  reqAvgHotelStrategyZone.prices[
                                                    index
                                                  ].lower_start_rate.rate,
                                                  index
                                                )
                                              ],
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {parseFloat(
                                            reqAvgHotelStrategyZone.prices[index]
                                              .lower_start_rate.rate
                                          ).toFixed(0)}
                                        </StyledTableCell>
                                      );
                                    } else {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          N/A
                                        </StyledTableCell>
                                      );
                                    }
                                  } else {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={index}
                                        style={{
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        N/A
                                      </StyledTableCell>
                                    );
                                  }
                                })()
                              )}
                            </StyledTableRow>
  
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{
                                  fontWeight: 'bold',
                                  width: '275px',
                                  zIndex: 100,
                                }}
                              >
                                V. Low Demand ({reqAvgHotelStrategyZone.lower_bound_wd}
                                , {reqAvgHotelStrategyZone.lower_bound_we})
                              </StyledTableCell>
  
                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (
                                    reqAvgHotelStrategyZone.prices[index] != null
                                  ) {
                                    if (
                                      reqAvgHotelStrategyZone.prices[index]
                                        .lower_bound_rate
                                    ) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            backgroundColor:
                                              CLUSTER_BACKGROUND[
                                                getClusterByPrice(
                                                  reqAvgHotelStrategyZone.prices[
                                                    index
                                                  ].lower_bound_rate.rate,
                                                  index
                                                )
                                              ],
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {
                                            reqAvgHotelStrategyZone.prices[index]
                                              .lower_bound_rate.rate
                                          }
                                        </StyledTableCell>
                                      );
                                    } else {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          N/A
                                        </StyledTableCell>
                                      );
                                    }
                                  } else {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={index}
                                        style={{
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        N/A
                                      </StyledTableCell>
                                    );
                                  }
                                })()
                              )}
                            </StyledTableRow>
                          </>
                        </TableBody>
                      </Table>
                      <br />
                    </Box>
                  </TableContainer>
                </TableContainer>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    };
  
    const StrategyPositionGraph = () => {
      return (
        <>
          <div className="d-flex justify-content-around mb-5">
          <h4>Similar Rated {Math.floor(reqHotelStars)} Star Average RateZone Graph</h4> {' '}
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-native-select">Date Range</InputLabel>
              <Select
                native={true}
                onChange={(e) => handleDatePage(e.target.value)}
                id="grouped-native-select"
                value={datePage}
              >
                {dateRange.length > 0 ? (
                  dateRange.map((e, i) => (
                    <option value={i}>
                      {clusterData[e[0]]
                        ? moment(clusterData[e[0]][0].date).format(date_format)
                        : ''}{' '}
                      -{' '}
                      {clusterData[e[1]]
                        ? moment(clusterData[e[1]][0].date).format(date_format)
                        : ''}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </div>
  
          <Line
            data={lineData}
            height={100}
            options={{
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Bounds',
                    },
                  },
                ],
              },
            }}
          />
        </>
      );
    };
  
    const GetRateStrategyInfo = () => {
      return (
        <Grid container justify="space-evenly" className="my-5">
          <p className="font-weight-bold">
            Weekday: Most Freq Bucket:{' '}
            {reqAvgHotelStrategyZone != undefined
              ? reqAvgHotelStrategyZone.freq_bucket_wd
              : ''}
            ,&nbsp; Freq. Rate Position:{' '}
            {reqAvgHotelStrategyZone != undefined
              ? reqAvgHotelStrategyZone.freq_qr_wd
              : ''}
            ,&nbsp; Avg Total Market Rank:{' '}
            {reqAvgHotelStrategyZone != undefined
              ? Math.round(reqAvgHotelStrategyZone.avg_day_rank_wd)
              : ''}
          </p>
          <p className="font-weight-bold">
            Weekend: Most Freq Bucket:{' '}
            {reqAvgHotelStrategyZone != undefined
              ? reqAvgHotelStrategyZone.freq_bucket_we
              : ''}
            ,&nbsp; Freq. Rate Position:{' '}
            {reqAvgHotelStrategyZone != undefined
              ? reqAvgHotelStrategyZone.freq_qr_we
              : ''}
            ,&nbsp; Avg Total Market Rank:{' '}
            {reqAvgHotelStrategyZone != undefined
              ? Math.round(reqAvgHotelStrategyZone.avg_day_rank_we)
              : ''}
          </p>
        </Grid>
      );
    };
  
    return (
      <>
        {!loading && clusterData.length > 0 && reqHotel.length > 0 ? (
            <>
              <>

              <HotelRanksTable />
              <hr className="my-5"></hr>
              {/* <RateVarientTable /> */}
             
              <StrategyPositionGraph />
              </>
            </>
          
        ) : (
          <></>
        )}
      </>
    );
  }
  