import {
    Box,
    makeStyles,
    TableCell,
    TableContainer,
    TableRow,
    TableSortLabel,
    withStyles,
  } from '@material-ui/core';
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TableHead from '@material-ui/core/TableHead';
  import Divider from '@material-ui/core/Divider';
  import Paper from '@material-ui/core/Paper';
  import { useSelector } from 'react-redux';
  import moment from 'moment';
  import { CLUSTER_BACKGROUND, CLUSTER_LABELS, FONT_FAMILY } from '../utils/const';
  import { useEffect, useRef, useState } from 'react';
  import { ClusteredData } from './ClusteredData';
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bolder',
    },
    body: {
      fontSize: 12,
      minHeight: 40,
      minWidth: 100
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {},
  }))(TableRow);
  const useStyles = makeStyles({
    table: {
      '& .MuiTableCell-root': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    sticky: {
      position: 'sticky',
      left: 0,
      background: 'white',
      boxShadow: '2px 2px 2px grey',
      display: 'block',
    },
    rates: {
      fontFamily: FONT_FAMILY,
      textAlign: 'center',
    },
  });
  
  export default function NewBucketMovements({
    selectedDate,
    insights_data,
    tbl_ref,
  }) {
    const classes = useStyles();
  
    const getClusterDataSet = useSelector((state) => state.clusterDataSet);
    const {
      loading,
      reqHotel,
      cluster1,
      cluster2,
      cluster3,
      cluster4,
      hotels,
      report_len,
      clusterData,
      comparison_report,
      date_format,
    } = getClusterDataSet;

    const [load, setLoad] = useState(true);
  
  
    const getClusterByPrice = (rate, ix) => {
      let clustered = [];
      let res = 4;
  
      if (cluster1.length > 0 && cluster1[ix]) {
        clustered.push(cluster1[ix]);
      }
      if (cluster2.length > 0 && cluster2[ix]) {
        clustered.push(cluster2[ix]);
      }
      if (cluster3.length > 0 && cluster3[ix]) {
        clustered.push(cluster3[ix]);
      }
      if (cluster4.length > 0 && cluster4[ix]) {
        clustered.push(cluster4[ix]);
      }
  
      clustered.sort((a, b) => a.mean - b.mean);
  
      // console.log(clustered);
  
      try {
        clustered.map((cl, id) => {
          if (rate >= cl.min && rate <= cl.max) {
            res = id;
            return;
          }
        });
      } catch (e) {}
  
      return res;
    };
  
    const getPrice = (arr) => {
      const price = arr.findIndex((e) => e > 0);
      return price;
    };
  
  
    const checkHotelAvailability = (id, day) => {
      let clustered = [];
  
      if (cluster1.length > 0 && cluster1[day]) {
        clustered.push(cluster1[day].unwanted);
      }
      if (cluster2.length > 0 && cluster2[day]) {
        clustered.push(cluster2[day].unwanted);
      }
      if (cluster3.length > 0 && cluster3[day]) {
        clustered.push(cluster3[day].unwanted);
      }
      if (cluster4.length > 0 && cluster4[day]) {
        clustered.push(cluster4[day].unwanted);
      }
  
      let hotels_arr = [];
  
      for (var i = 0; i < clustered.length; i++) {
        hotels_arr = hotels_arr.concat(clustered[i]);
      }
  
      const exists = hotels_arr.some((obj) => obj.id == id);
  
      if (exists) {
        return true;
      } else {
        return false;
      }
    };

    const getAvg = (arr) => {
        if (arr.length > 0) {
          return arr.reduce((a, b) => a + b) / arr.length;
        }
      };
    
      const getVarience = (value, avg, stdev) => {
        if (avg <= 0 || stdev <= 0 || value <= 0) {
          return '';
        } else if (value >= avg + 1.65 * stdev) {
          return 'Very High';
        } else if (value >= avg + stdev) {
          return 'High';
        }
        else {
          return '';
        }
      };

    const getStandardDeviation = (array) => {
        const n = array.length;
        if (n > 0) {
          const mean = array.reduce((a, b) => a + b) / n;
          return Math.sqrt(
            array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
          );
        } else {
          return -1;
        }
      };
  
  
    const BucketMovementsGrid = ({cluster, cluster_idx}) => {
  
      const [inBucket, setInBucket] = useState([]);
      const [pricingUps, setPricingUps] = useState([]);
      const [pricingDowns, setPricingDowns] = useState([]);
      const [outliers, setOutliers] = useState([]);
      const [noRates, setNoRates] = useState([]);

      const [inBucket_stdev_wd, setInBucket_stdev_wd] = useState(0);
      const [inBucket_stdev_we, setInBucket_stdev_we] = useState(0);
      const [inBucket_avg_wd, setInBucket_avg_wd] = useState(0);
      const [inBucket_avg_we, setInBucket_avg_we] = useState(0);

      const [pricingUps_stdev_wd, setPricingUps_stdev_wd] = useState(0);
      const [pricingUps_stdev_we, setPricingUps_stdev_we] = useState(0);
      const [pricingUps_avg_wd, setPricingUps_avg_wd] = useState(0);
      const [pricingUps_avg_we, setPricingUps_avg_we] = useState(0);

      const [pricingDowns_stdev_wd, setPricingDowns_stdev_wd] = useState(0);
      const [pricingDowns_stdev_we, setPricingDowns_stdev_we] = useState(0);
      const [pricingDowns_avg_wd, setPricingDowns_avg_wd] = useState(0);
      const [pricingDowns_avg_we, setPricingDowns_avg_we] = useState(0);

      const [outliers_stdev_wd, setOutliers_stdev_wd] = useState(0);
      const [outliers_stdev_we, setOutliers_stdev_we] = useState(0);
      const [outliers_avg_wd, setOutliers_avg_wd] = useState(0);
      const [outliers_avg_we, setOutliers_avg_we] = useState(0);

      const [noRates_stdev_wd, setNoRates_stdev_wd] = useState(0);
      const [noRates_stdev_we, setNoRates_stdev_we] = useState(0);
      const [noRates_avg_wd, setNoRates_avg_wd] = useState(0);
      const [noRates_avg_we, setNoRates_avg_we] = useState(0);
  
      useEffect(() => {
        const GetHotelsCount = (cluster) => {
  
          let inBucket_ = [];
          let outliers_ = []
          let noRates_ = []
          let pricingUps_ = []
          let pricingDowns_ = []

          let inBucket_wd = [];
          let outliers_wd = []
          let noRates_wd = []
          let pricingUps_wd = []
          let pricingDowns_wd = []

          let inBucket_we = [];
          let outliers_we = []
          let noRates_we = []
          let pricingUps_we = []
          let pricingDowns_we = []
  
          if (hotels.length > 0) {
            [...Array(report_len).keys()].map((n, dt) => {
              let _inBucket = [];
              let _outliers = []
              let _noRates = []
              let _pricingUps = []
              let _pricingDowns = []
            
           

              hotels.map((_hotel) => {
                if (_hotel.prices[dt] != null) {
                  if (checkHotelAvailability(_hotel.hotelID, dt)) {
                    if(Math.floor(_hotel.stars) == cluster_idx + 2) {
                        if(cluster ==
                          getClusterByPrice(
                            _hotel.prices[dt].price[
                              getPrice(_hotel.prices[dt].price)
                            ],
                            dt
                          )) {
                            _inBucket.push({
                            hotelID: _hotel.hotelID,
                            hotelName: _hotel.hotelName
                          });
                        } else if (cluster <
                        getClusterByPrice(
                          _hotel.prices[dt].price[
                            getPrice(_hotel.prices[dt].price)
                          ],
                          dt
                        )) {
                          _pricingUps.push({
                            hotelID: _hotel.hotelID,
                            hotelName: _hotel.hotelName
                          });
                      }else if (cluster >
                        getClusterByPrice(
                          _hotel.prices[dt].price[
                            getPrice(_hotel.prices[dt].price)
                          ],
                          dt
                        )) {
                        _pricingDowns.push({
                          hotelID: _hotel.hotelID,
                          hotelName: _hotel.hotelName
                        });
                      }
                    }
                    
                  }else {
                    if(Math.floor(_hotel.stars) == cluster_idx + 2){
                      _outliers.push({
                        hotelID: _hotel.hotelID,
                        hotelName: _hotel.hotelName,
                      });
                    }
                  }
                }else {
                  if(Math.floor(_hotel.stars) == cluster_idx + 2){
                    _noRates.push({
                      hotelID: _hotel.hotelID,
                      hotelName: _hotel.hotelName,
                    })
                }
                }
              });
  
              inBucket_.push(_inBucket.length)
              pricingUps_.push(_pricingUps.length)
              pricingDowns_.push(_pricingDowns.length)
              outliers_.push(_outliers.length)
              noRates_.push(_noRates.length)

              const day = moment(selectedDate)
            .add(dt, 'days')
            .format('dddd')
            .substring(0, 3);

            if( day !== 'Sat' || day !== 'Fri') {
              inBucket_wd.push(_inBucket.length)
              pricingUps_wd.push(_pricingUps.length)
              pricingDowns_wd.push(_pricingDowns.length)
              outliers_wd.push(_outliers.length)
              noRates_wd.push(_noRates.length)

            }else{
                inBucket_we.push(_inBucket.length)
              pricingUps_we.push(_pricingUps.length)
              pricingDowns_we.push(_pricingDowns.length)
              outliers_we.push(_outliers.length)
              noRates_we.push(_noRates.length)
            }
                
              
            });
          }
          const inBucket_stdev_wd = getStandardDeviation(inBucket_wd)
          const inBucket_stdev_we = getStandardDeviation(inBucket_we)

          const inBucket_avg_wd = getAvg(inBucket_wd)
          const inBucket_avg_we = getAvg(inBucket_we)

          const pricingUps_stdev_wd = getStandardDeviation(pricingUps_wd)
          const pricingUps_stdev_we = getStandardDeviation(pricingUps_we)

          const pricingUps_avg_wd = getAvg(pricingUps_wd)
          const pricingUps_avg_we = getAvg(pricingUps_we)

          const pricingDowns_stdev_wd = getStandardDeviation(pricingDowns_wd)
          const pricingDowns_stdev_we = getStandardDeviation(pricingDowns_we)
          const pricingDowns_avg_wd = getAvg(pricingDowns_wd)
          const pricingDowns_avg_we = getAvg(pricingDowns_we)

          const outliers_stdev_wd = getStandardDeviation(outliers_wd)
          const outliers_stdev_we = getStandardDeviation(outliers_we)
          const outliers_avg_wd = getAvg(outliers_wd)
          const outliers_avg_we = getAvg(outliers_we)

          const noRates_stdev_wd = getStandardDeviation(noRates_wd)
          const noRates_stdev_we = getStandardDeviation(noRates_we)
          const noRates_avg_wd = getAvg(noRates_wd)
          const noRates_avg_we = getAvg(noRates_we)

          setInBucket(inBucket_)
          setPricingUps(pricingUps_)
          setPricingDowns(pricingDowns_)
          setOutliers(outliers_)
          setNoRates(noRates_)

          
          setInBucket_stdev_wd(inBucket_stdev_wd)
          setInBucket_stdev_we(inBucket_stdev_we)
          setInBucket_avg_wd(inBucket_avg_wd)
          setInBucket_avg_we(inBucket_avg_we)

          setPricingDowns_stdev_wd(pricingDowns_stdev_wd)
          setPricingDowns_stdev_we(pricingDowns_stdev_we)
          setPricingDowns_avg_wd(pricingDowns_avg_wd)
          setPricingDowns_avg_we(pricingDowns_avg_we)


          setPricingUps_stdev_wd(pricingUps_stdev_wd)
          setPricingUps_stdev_we(pricingUps_stdev_we)
          setPricingUps_avg_wd(pricingUps_avg_wd)
          setPricingUps_avg_we(pricingUps_avg_we)


          setOutliers_stdev_wd(outliers_stdev_wd)
          setOutliers_stdev_we(outliers_stdev_we)
          setOutliers_avg_wd(outliers_avg_wd)
          setOutliers_avg_we(outliers_avg_we)


          setNoRates_stdev_wd(noRates_stdev_wd)
          setNoRates_stdev_we(noRates_stdev_we)
          setNoRates_avg_wd(noRates_avg_wd)
          setNoRates_avg_we(noRates_avg_we)
  
        };
  
        if (hotels.length > 0) {
          GetHotelsCount(cluster_idx);
        }
      }, [hotels]);
  
      return (
        <>
        <TableContainer component={Paper} className="my-5">
        <Box width={100}>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
            bodyStyle={{ overflow: 'visible' }}
            stickyHeader
          >
            <TableHead>
              <StyledTableCell
                style={{ fontWeight: 'bold', width: '250px', zIndex: 100 }}
              >
                {CLUSTER_LABELS[cluster_idx]} Bucket Movements <hr />
                Days Out
              </StyledTableCell>
              {cluster1.map((e, index) =>
                (() => {
                  let _date = moment(e.date, 'YYYY-MM-DD');
                  let daysOut = _date.diff(selectedDate, 'days');
                  let day = _date.format('dddd').substring(0, 3);
                  return (
                    <StyledTableCell
                      size="small"
                      key={index}
                      className={
                        day === 'Sat' || day === 'Fri'
                          ? 'bg-secondary text-light text-center'
                          : 'text-center'
                      }
                      style={{ fontSize: '12px' }}
                    >
                      <>
                        {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                      </>
                      <br />
                      <>{day.toUpperCase()}</>
                      <br />
                      <>{moment(_date).format(date_format)}</>{' '}
                      <div class="dropdown-divider"></div>
                      {daysOut}
                    </StyledTableCell>
                  );
                })()
              )}
            </TableHead>
            <TableBody>
          
            { cluster_idx != 3 ?  <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                         Pricing Up
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (
                          <StyledTableCell
                          size='small'
                            className={classes.rates + ' text-center'}
                          >
                             {pricingUps[index] }
                          </StyledTableCell>
                        ))
                      }
            </StyledTableRow> : <></> }  
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                         In Bucket 
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (
                          <StyledTableCell
                          size='small'
                            className={classes.rates + ' text-center'}
                          >
                            {inBucket[index] }
                          </StyledTableCell>
                        ))
                      }
            </StyledTableRow>
            { cluster_idx != 0 ? <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                         Pricing Down
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (
                          <StyledTableCell
                          size='small'
                            className={classes.rates + ' text-center'}
                          >
                            {pricingDowns[index] }
                          </StyledTableCell>
                        ))
                      }
            </StyledTableRow> : <></> } 
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                         &emsp;Total Showing
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (
                          <StyledTableCell
                          size='small'
                            className={classes.rates + ' text-center'}
                          >
                                { pricingUps[index] + pricingDowns[index] + inBucket[index] } 
                          </StyledTableCell>
                        ))
                      }
            </StyledTableRow>
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                        Outliers
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (
                          <StyledTableCell
                          size='small'
                            className={classes.rates + ' text-center'}
                          >
                             { outliers[index]}
                          </StyledTableCell>
                        ))
                      }
            </StyledTableRow>
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                        No Rate
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (
                          <StyledTableCell
                          size='small'
                            className={classes.rates + ' text-center'}
                          >
                            { noRates[index]}
                          </StyledTableCell>
                        ))
                      }
            </StyledTableRow>
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                        &emsp;Total Hotels
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (
                          <StyledTableCell
                            size='small'
                            className={classes.rates + ' text-center'}
                          >
                            { pricingUps[index] + inBucket[index] + pricingDowns[index]  + outliers[index] + noRates[index]}
                          </StyledTableCell>
                        ))
                      }
            </StyledTableRow>
            </TableBody>
                  </Table>
                  </Box>
          </TableContainer>
          <TableContainer component={Paper} className="my-5">
        <Box width={100}>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
            bodyStyle={{ overflow: 'visible' }}
            stickyHeader
          >
            <TableHead>
              <StyledTableCell
                style={{ fontWeight: 'bold', width: '250px', zIndex: 100 }}
              >
                {CLUSTER_LABELS[cluster_idx]} Bucket Movements Exception <hr />
                Days Out
              </StyledTableCell>
              {cluster1.map((e, index) =>
                (() => {
                  let _date = moment(e.date, 'YYYY-MM-DD');
                  let daysOut = _date.diff(selectedDate, 'days');
                  let day = _date.format('dddd').substring(0, 3);
                  return (
                    <StyledTableCell
                      size="small"
                      key={index}
                      className={
                        day === 'Sat' || day === 'Fri'
                          ? 'bg-secondary text-light text-center'
                          : 'text-center'
                      }
                      style={{ fontSize: '12px' }}
                    >
                      <>
                        {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                      </>
                      <br />
                      <>{day.toUpperCase()}</>
                      <br />
                      <>{moment(_date).format(date_format)}</>{' '}
                      <div class="dropdown-divider"></div>
                      {daysOut}
                    </StyledTableCell>
                  );
                })()
              )}
            </TableHead>
            <TableBody>
          
            { cluster_idx != 3 ?  <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                         Pricing Up
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) =>  
                        (() => {
                            const day = moment(selectedDate)
                                .add(index, 'days')
                                .format('dddd')
                                .substring(0, 3)

                            if( day === 'Sat' || day === 'Fri') {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(pricingUps[index], pricingUps_avg_we, pricingUps_stdev_we) }
                                    </StyledTableCell>
                                ); 
                            } else {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(pricingUps[index], pricingUps_avg_wd, pricingUps_stdev_wd) }
                                    </StyledTableCell>
                                ); 
                            }
                            
                          })()
                        )}
            </StyledTableRow> : <></> }  
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                         In Bucket 
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (() => {
                            const day = moment(selectedDate)
                                .add(index, 'days')
                                .format('dddd')
                                .substring(0, 3)

                            if( day === 'Sat' || day === 'Fri') {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(inBucket[index], inBucket_avg_we, inBucket_stdev_we) }
                                    </StyledTableCell>
                                ); 
                            } else {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(inBucket[index], inBucket_avg_wd, inBucket_stdev_wd) }
                                    </StyledTableCell>
                                ); 
                            }
                            
                          })()
                        )
                      }
            </StyledTableRow>
            { cluster_idx != 0 ? <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                         Pricing Down
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (() => {
                            const day = moment(selectedDate)
                                .add(index, 'days')
                                .format('dddd')
                                .substring(0, 3)

                            if( day === 'Sat' || day === 'Fri') {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(pricingDowns[index], pricingDowns_avg_we, pricingDowns_stdev_we) }
                                    </StyledTableCell>
                                ); 
                            } else {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(pricingDowns[index], pricingDowns_avg_wd, pricingDowns_stdev_wd) }
                                    </StyledTableCell>
                                ); 
                            }
                            
                          })()
                        )
                      }
            </StyledTableRow> : <></> } 
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                         &emsp;Total Showing
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (() => {
                            const day = moment(selectedDate)
                                .add(index, 'days')
                                .format('dddd')
                                .substring(0, 3)

                            if( day === 'Sat' || day === 'Fri') {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(pricingUps[index]+pricingDowns[index]+inBucket[index], pricingUps_avg_we+pricingDowns_avg_we+inBucket_avg_we, pricingUps_stdev_we+pricingDowns_stdev_we+inBucket_stdev_we) }
                                    </StyledTableCell>
                                ); 
                            } else {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                      { getVarience(pricingUps[index]+pricingDowns[index]+inBucket[index], pricingUps_avg_wd+pricingDowns_avg_wd+inBucket_avg_wd, pricingUps_stdev_wd+pricingDowns_stdev_wd+inBucket_stdev_wd) }
                                   
                                    </StyledTableCell>
                                ); 
                            }
                            
                          })()
                        )
                      }
            </StyledTableRow>
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                        Outliers
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (() => {
                            const day = moment(selectedDate)
                                .add(index, 'days')
                                .format('dddd')
                                .substring(0, 3)

                            if( day === 'Sat' || day === 'Fri') {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(outliers[index], outliers_avg_we, outliers_stdev_we) }
                                    </StyledTableCell>
                                ); 
                            } else {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(outliers[index], outliers_avg_wd, outliers_stdev_wd) }
                                    </StyledTableCell>
                                ); 
                            }
                            
                          })()
                          )
                      }
            </StyledTableRow>
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                        No Rate
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => 
                        (() => {
                            const day = moment(selectedDate)
                                .add(index, 'days')
                                .format('dddd')
                                .substring(0, 3)

                            if( day === 'Sat' || day === 'Fri') {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(noRates[index], noRates_avg_we, noRates_stdev_we) }
                                    </StyledTableCell>
                                ); 
                            } else {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                       { getVarience(noRates[index], noRates_avg_wd, noRates_stdev_wd) }
                                    </StyledTableCell>
                                ); 
                            }
                            
                          })()
                          )
                      }
            </StyledTableRow>
            <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                        }}
                      >
                        &emsp;Total Hotels
                      </StyledTableCell>
                      {
                        [...Array(report_len).keys()].map((day, index) => (() => {
                            const day = moment(selectedDate)
                                .add(index, 'days')
                                .format('dddd')
                                .substring(0, 3)

                            if( day === 'Sat' || day === 'Fri') {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                       { getVarience(pricingUps[index] + inBucket[index] + pricingDowns[index]  + outliers[index] + noRates[index],
                                         pricingUps_avg_we+inBucket_avg_we+pricingDowns_avg_we+outliers_avg_we+noRates_avg_we,
                                         pricingUps_stdev_we+inBucket_stdev_we+pricingDowns_stdev_we+outliers_stdev_we+noRates_stdev_we) }
                                    </StyledTableCell>
                                ); 
                            } else {
                                return (
                                    <StyledTableCell
                                    size='small'
                                      className={classes.rates + ' text-center'}
                                    >
                                      
                                      { getVarience(pricingUps[index] + inBucket[index] + pricingDowns[index]  + outliers[index] + noRates[index],
                                         pricingUps_avg_wd+inBucket_avg_wd+pricingDowns_avg_wd+outliers_avg_wd+noRates_avg_wd,
                                         pricingUps_stdev_wd+inBucket_stdev_wd+pricingDowns_stdev_wd+outliers_stdev_wd+noRates_stdev_wd) }
                                    </StyledTableCell>
                                ); 
                            }
                            
                          })()
                        )
                      }
            </StyledTableRow>
            </TableBody>
                  </Table>
                  </Box>
          </TableContainer>
          </>
      );
    };
  
    return (
      <>
        {!loading && hotels.length > 0  ? (
          <>
          <BucketMovementsGrid cluster={cluster4} cluster_idx={3}/>
        <BucketMovementsGrid cluster={cluster3} cluster_idx={2}/>
        <BucketMovementsGrid cluster={cluster2} cluster_idx={1}/>
        <BucketMovementsGrid cluster={cluster1} cluster_idx={0}/>
  
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  