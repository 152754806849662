import React from 'react';
import { Badge, Container, FormLabel } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import {
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  makeStyles,
  Select,
} from '@material-ui/core';
import { AnalysisSet } from '../Dashboard/AnalysisSet';
import { SpecialDates } from '../Dashboard/SpecialDates';

export const UserCrud = ({ setformNotification }) => {
  return (
    <>
      <h3>Analysis Set</h3>
      <AnalysisSet setformNotification={setformNotification} />
      <hr className="my-5" />
      <h3> Special Dates</h3>
      <SpecialDates setformNotification={setformNotification} />
    </>
  );
};
