import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FONT_FAMILY } from '../utils/const';
import ClusterBucket from './ClusterBucket';
import ClusterDataTable from './ClusterDataTable';
import { Graphs } from './Graphs';
import { HotelsPlot } from './HotelsPlot';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tabularNavStyle: {
    backgroundColor: '#607D8B',
    fontFamily: FONT_FAMILY,
    fontWeight: 'bold',
  },
}));

export const Visuals = ({
  selectedDate,
  sub_tab,
  selectedProperty,
  tbl_ref,
}) => {
  const classes = useStyles();
  const [tab, setTab] = useState(sub_tab);

  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const { clusterData, loading, err, hotels } = getClusterDataSet;

  useEffect(() => {
    if (tbl_ref > 0) {
      setTab(2);
    }
  }, []);

  const TabularNav = () => {
    const [tabularNavCls] = useState(
      'text-light border-bottom-0 border-secondary  ' + classes.tabularNavStyle
    );
    return (
      <div style={{ position: 'sticky', top: 185, zIndex: 200 }}>
        <Nav variant="tabs" justify="space-around">
          <Nav.Item>
            <Nav.Link
              className={
                tab === 1
                  ? tabularNavCls
                  : 'text-dark font-weight-bold bg-light  shadow '
              }
              eventKey="link-1"
              onClick={() => setTab(1)}
            >
              Rates
            </Nav.Link>
          </Nav.Item>{' '}
          <Nav.Item>
            <Nav.Link
              className={
                tab === 2
                  ? tabularNavCls
                  : 'text-dark font-weight-bold bg-light shadow '
              }
              eventKey="link-1"
              onClick={() => setTab(2)}
            >
              Position
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    );
  };

  return (
    <>
      <TabularNav />
      {hotels.length > 0 && tab === 1 && clusterData.length > 0 ? (
        <>
          <div className="my-5">
            <Graphs
              selectedDate={selectedDate}
              selectedProperty={selectedProperty}
            />
          </div>
        </>
      ) : hotels.length > 0 && tab === 2 && clusterData.length > 0 ? (
        <>
          <div className="my-5">
            <HotelsPlot
              hotels={hotels}
              selectedDate={selectedDate}
              tbl_ref={tbl_ref}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
