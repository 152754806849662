import {
  Box,
  makeStyles,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { CLUSTER_BACKGROUND, FONT_FAMILY } from '../utils/const';
import { useEffect, useRef, useState } from 'react';
import { ClusteredData } from './ClusteredData';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 15,
    minWidth: 100,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);
const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'block',
  },
  rates: {
    fontFamily: FONT_FAMILY,
    textAlign: 'center',
  },
});

export default function BucketMovements({
  selectedDate,
  insights_data,
  tbl_ref,
}) {
  const classes = useStyles();

  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    loading,
    reqHotel,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels,
    report_len,
    clusterData,
    comparison_report,
    date_format,
  } = getClusterDataSet;

  const [totalHotelCount, setTotalHotelCount] = useState(0);

  const [load, setLoad] = useState(true);

  const [_load, set_Load] = useState(true);

  const [comp_report_state, set_comp_report_state] = useState(false);

  const [stars5down, setStars5down] = useState([]);
  const [stars4up, setStars4up] = useState([]);
  const [stars4down, setStars4down] = useState([]);
  const [stars3up, setStars3up] = useState([]);
  const [stars3down, setStars3down] = useState([]);
  const [stars2up, setStars2up] = useState([]);

  const [cluster1_bucket_index, set_cluster1_bucket_index] = useState([]);
  const [cluster2_bucket_index, set_cluster2_bucket_index] = useState([]);
  const [cluster3_bucket_index, set_cluster3_bucket_index] = useState([]);
  const [cluster4_bucket_index, set_cluster4_bucket_index] = useState([]);

  const hotels_count_tbl_2 = useRef(null);
  const hotels_count_tbl_3 = useRef(null);
  const hotels_count_tbl_4 = useRef(null);
  const hotels_count_tbl_5 = useRef(null);

  const high_net_bucket_movements_tbl = useRef(null);

  const index_movement_strength_tbl = useRef(null);
  const high_bucket_size_index_tbl = useRef(null);

  const getClusterByPrice = (rate, ix) => {
    let clustered = [];
    let res = 4;

    if (cluster1.length > 0 && cluster1[ix]) {
      clustered.push(cluster1[ix]);
    }
    if (cluster2.length > 0 && cluster2[ix]) {
      clustered.push(cluster2[ix]);
    }
    if (cluster3.length > 0 && cluster3[ix]) {
      clustered.push(cluster3[ix]);
    }
    if (cluster4.length > 0 && cluster4[ix]) {
      clustered.push(cluster4[ix]);
    }

    clustered.sort((a, b) => a.mean - b.mean);

    // console.log(clustered);

    try {
      clustered.map((cl, id) => {
        if (rate >= cl.min && rate <= cl.max) {
          res = id;
          return;
        }
      });
    } catch (e) {}

    return res;
  };

  const getPrice = (arr) => {
    const price = arr.findIndex((e) => e > 0);
    return price;
  };

  const getStandardDeviation = (array) => {
    const n = array.length;
    if (n > 0) {
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      );
    } else {
      return -1;
    }
  };

  const checkHotelAvailability = (id, day) => {
    let clustered = [];

    if (cluster1.length > 0 && cluster1[day]) {
      clustered.push(cluster1[day].unwanted);
    }
    if (cluster2.length > 0 && cluster2[day]) {
      clustered.push(cluster2[day].unwanted);
    }
    if (cluster3.length > 0 && cluster3[day]) {
      clustered.push(cluster3[day].unwanted);
    }
    if (cluster4.length > 0 && cluster4[day]) {
      clustered.push(cluster4[day].unwanted);
    }

    let hotels_arr = [];

    for (var i = 0; i < clustered.length; i++) {
      hotels_arr = hotels_arr.concat(clustered[i]);
    }

    const exists = hotels_arr.some((obj) => obj.id == id);

    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  const getPricingUps = (stars) => {
    [...Array(report_len).keys()].map((day) => {});
  };

  useEffect(() => {
    const CompareReport = async () => {
      setLoad(true);
      cluster1.map((e) => {
        if (e && e != undefined) {
          let dateMatch = comparison_report.cluster1.find((obj) => {
            try {
              if (obj && obj != undefined && obj != undefined) {
                if (
                  moment(e.date).format('YYYY-MM-DD') ===
                  moment(obj.date).format('YYYY-MM-DD')
                ) {
                  return obj;
                }
              }
            } catch (e) {}
          });

          if (dateMatch) {
            delete dateMatch.cluster;
            delete dateMatch.unwanted;

            e.comp_report = dateMatch;
          }
        }
      });

      cluster2.map((e) => {
        if (e && e != undefined) {
          let dateMatch = comparison_report.cluster2.find((obj) => {
            try {
              if (obj && obj != undefined && obj != undefined) {
                if (
                  moment(e.date).format('YYYY-MM-DD') ===
                  moment(obj.date).format('YYYY-MM-DD')
                ) {
                  return obj;
                }
              }
            } catch (e) {}
          });

          if (dateMatch) {
            delete dateMatch.cluster;
            delete dateMatch.unwanted;

            e.comp_report = dateMatch;
          }
        }
      });

      cluster3.map((e) => {
        if (e && e != undefined) {
          let dateMatch = comparison_report.cluster3.find((obj) => {
            try {
              if (obj && obj != undefined && obj != undefined) {
                if (
                  moment(e.date).format('YYYY-MM-DD') ===
                  moment(obj.date).format('YYYY-MM-DD')
                ) {
                  return obj;
                }
              }
            } catch (e) {}
          });

          if (dateMatch) {
            delete dateMatch.cluster;
            delete dateMatch.unwanted;

            e.comp_report = dateMatch;
          }
        }
      });

      cluster4.map((e) => {
        if (e && e != undefined) {
          let dateMatch = comparison_report.cluster4.find((obj) => {
            try {
              if (obj && obj != undefined && obj != undefined) {
                if (
                  moment(e.date).format('YYYY-MM-DD') ===
                  moment(obj.date).format('YYYY-MM-DD')
                ) {
                  return obj;
                }
              }
            } catch (e) {}
          });

          if (dateMatch) {
            delete dateMatch.cluster;
            delete dateMatch.unwanted;

            e.comp_report = dateMatch;
          }
        }
      });
      set_comp_report_state(true);
      setLoad(false);
    };

    if (comparison_report && clusterData.length > 0) {
      CompareReport();
    }
  }, [comparison_report]);

  const getTotalHotelsCount = (stars) => {
    let star_hotels = [];
    if (hotels.length > 0) {
      hotels.map((_hotel) => {
        if (Math.floor(_hotel.stars) == stars) {
          star_hotels.push({
            hotel_id: _hotel.hotelID,
            hotel_name: _hotel.hotelName,
            stars: _hotel.stars,
            ratings: _hotel.ratings,
          });
        }
      });
    }

    return star_hotels.length;
  };

  const GetBucketIndexTable = () => {
    return (
      <>
        {cluster4_bucket_index.length > 0 &&
        cluster3_bucket_index.length > 0 &&
        cluster2_bucket_index.length > 0 &&
        cluster1_bucket_index.length > 0 ? (
          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                id="stars3"
                className={classes.table}
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      fontWeight: 'bold',
                      width: '250px',
                      zIndex: 100,
                    }}
                  >
                    Bucket Size Index <hr />
                    Days Out
                  </StyledTableCell>
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[3],
                        zIndex: 10,
                      }}
                    >
                      5 Star Bucket
                    </StyledTableCell>
                    {cluster4_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          style={{
                            fontWeight: 'bold',
                          }}
                          size="small"
                          className={classes.rates + ' text-center'}
                        >
                          <span>
                            {obj.bucket_index == obj.comp_bucket_index ? (
                              <></>
                            ) : obj.bucket_index > obj.comp_bucket_index ? (
                              <sup
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sup>
                            ) : (
                              <sub
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sub>
                            )}
                            {parseFloat(obj.bucket_index).toFixed(1)}
                          </span>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      size="small"
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[2],
                        zIndex: 10,
                      }}
                    >
                      4 Star Bucket
                    </StyledTableCell>
                    {cluster3_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          style={{
                            fontWeight: 'bold',
                          }}
                          size="small"
                          className={classes.rates + ' text-center'}
                        >
                          <span>
                            {obj.bucket_index == obj.comp_bucket_index ? (
                              <></>
                            ) : obj.bucket_index > obj.comp_bucket_index ? (
                              <sup
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sup>
                            ) : (
                              <sub
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sub>
                            )}
                            {parseFloat(obj.bucket_index).toFixed(1)}
                          </span>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[1],
                        zIndex: 10,
                      }}
                    >
                      3 Star Bucket
                    </StyledTableCell>
                    {cluster2_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          style={{
                            fontWeight: 'bold',
                          }}
                          size="small"
                          className={classes.rates + ' text-center'}
                        >
                          <span>
                            {obj.bucket_index == obj.comp_bucket_index ? (
                              <></>
                            ) : obj.bucket_index > obj.comp_bucket_index ? (
                              <sup
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sup>
                            ) : (
                              <sub
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sub>
                            )}
                            {parseFloat(obj.bucket_index).toFixed(1)}
                          </span>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[0],
                        zIndex: 10,
                      }}
                    >
                      2 Star Bucket
                    </StyledTableCell>
                    {cluster1_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          style={{
                            fontWeight: 'bold',
                          }}
                          size="small"
                          className={classes.rates + ' text-center'}
                        >
                          <span>
                            {obj.bucket_index == obj.comp_bucket_index ? (
                              <></>
                            ) : obj.bucket_index > obj.comp_bucket_index ? (
                              <sup
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sup>
                            ) : (
                              <sub
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sub>
                            )}
                            {parseFloat(obj.bucket_index).toFixed(1)}
                          </span>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  const GetHighBucketIndexTable = () => {
    return (
      <>
        {cluster4_bucket_index.length > 0 &&
        cluster3_bucket_index.length > 0 &&
        cluster2_bucket_index.length > 0 &&
        cluster1_bucket_index.length > 0 ? (
          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                id="stars3"
                className={classes.table}
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      fontWeight: 'bold',
                      width: '250px',
                      zIndex: 100,
                    }}
                  >
                    High Bucket Size Index <hr />
                    Days Out
                  </StyledTableCell>
                  {/* <StyledTableCell size="small">Stars</StyledTableCell> */}
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[3],
                        zIndex: 10,
                      }}
                    >
                      5 Star Bucket
                    </StyledTableCell>
                    {cluster4_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          size="small"
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.bucket_size_exception}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      size="small"
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[2],
                        zIndex: 10,
                      }}
                    >
                      4 Star Bucket
                    </StyledTableCell>
                    {cluster3_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          size="small"
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.bucket_size_exception}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[1],
                        zIndex: 10,
                      }}
                    >
                      3 Star Bucket
                    </StyledTableCell>
                    {cluster2_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          size="small"
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.bucket_size_exception}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[0],
                        zIndex: 10,
                      }}
                    >
                      2 Star Bucket
                    </StyledTableCell>
                    {cluster1_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          size="small"
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.bucket_size_exception}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  const GetIndexMovementsTable = () => {
    return (
      <>
        {cluster4_bucket_index.length > 0 &&
        cluster3_bucket_index.length > 0 &&
        cluster2_bucket_index.length > 0 &&
        cluster1_bucket_index.length > 0 ? (
          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                id="stars3"
                className={classes.table}
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      fontWeight: 'bold',
                      width: '250px',
                      zIndex: 100,
                    }}
                  >
                    Index Movement <hr />
                    Days Out
                  </StyledTableCell>
                  {/* <StyledTableCell size="small">Stars</StyledTableCell> */}
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[3],
                        zIndex: 10,
                      }}
                    >
                      5 Star Bucket
                    </StyledTableCell>
                    {cluster4_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          style={{
                            fontWeight: 'bold',
                          }}
                          size="small"
                          className={classes.rates + ' text-center'}
                        >
                          <span>
                            {obj.bucket_index == obj.comp_bucket_index ? (
                              <></>
                            ) : obj.bucket_index > obj.comp_bucket_index ? (
                              <sup
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sup>
                            ) : (
                              <sub
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sub>
                            )}
                            {parseFloat(
                              obj.bucket_index - obj.comp_bucket_index
                            ).toFixed(1)}
                          </span>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      size="small"
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[2],
                        zIndex: 10,
                      }}
                    >
                      4 Star Bucket
                    </StyledTableCell>
                    {cluster3_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          style={{
                            fontWeight: 'bold',
                          }}
                          size="small"
                          className={classes.rates + ' text-center'}
                        >
                          <span>
                            {obj.bucket_index == obj.comp_bucket_index ? (
                              <></>
                            ) : obj.bucket_index > obj.comp_bucket_index ? (
                              <sup
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sup>
                            ) : (
                              <sub
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sub>
                            )}
                            {parseFloat(
                              obj.bucket_index - obj.comp_bucket_index
                            ).toFixed(1)}
                          </span>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[1],
                        zIndex: 10,
                      }}
                    >
                      3 Star Bucket
                    </StyledTableCell>
                    {cluster2_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          style={{
                            fontWeight: 'bold',
                          }}
                          size="small"
                          className={classes.rates + ' text-center'}
                        >
                          <span>
                            {obj.bucket_index == obj.comp_bucket_index ? (
                              <></>
                            ) : obj.bucket_index > obj.comp_bucket_index ? (
                              <sup
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sup>
                            ) : (
                              <sub
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sub>
                            )}
                            {parseFloat(
                              obj.bucket_index - obj.comp_bucket_index
                            ).toFixed(1)}
                          </span>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[0],
                        zIndex: 10,
                      }}
                    >
                      2 Star Bucket
                    </StyledTableCell>
                    {cluster1_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          style={{
                            fontWeight: 'bold',
                          }}
                          size="small"
                          className={classes.rates + ' text-center'}
                        >
                          <span>
                            {obj.bucket_index == obj.comp_bucket_index ? (
                              <></>
                            ) : obj.bucket_index > obj.comp_bucket_index ? (
                              <sup
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sup>
                            ) : (
                              <sub
                                className={
                                  obj.bucket_index > obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-up text-success'
                                    : obj.bucket_index < obj.comp_bucket_index
                                    ? 'fa fa-long-arrow-down text-danger'
                                    : ''
                                }
                                aria-hidden="true"
                              >
                                &nbsp;
                              </sub>
                            )}
                            {parseFloat(
                              obj.bucket_index - obj.comp_bucket_index
                            ).toFixed(1)}
                          </span>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  const GetIndexMovementsStrengthTable = () => {
    return (
      <>
        {cluster4_bucket_index.length > 0 &&
        cluster3_bucket_index.length > 0 &&
        cluster2_bucket_index.length > 0 &&
        cluster1_bucket_index.length > 0 ? (
          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                id="stars3"
                className={classes.table}
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      fontWeight: 'bold',
                      width: '250px',
                      zIndex: 100,
                    }}
                  >
                    Index Movement Exception <hr />
                    Days Out
                  </StyledTableCell>
                  {/* <StyledTableCell size="small">Stars</StyledTableCell> */}
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[3],
                        zIndex: 10,
                      }}
                    >
                      5 Star Bucket
                    </StyledTableCell>
                    {cluster4_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          size="small"
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      size="small"
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[2],
                        zIndex: 10,
                      }}
                    >
                      4 Star Bucket
                    </StyledTableCell>
                    {cluster3_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          size="small"
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[1],
                        zIndex: 10,
                      }}
                    >
                      3 Star Bucket
                    </StyledTableCell>
                    {cluster2_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          size="small"
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[0],
                        zIndex: 10,
                      }}
                    >
                      2 Star Bucket
                    </StyledTableCell>
                    {cluster1_bucket_index.map((obj) => {
                      return (
                        <StyledTableCell
                          size="small"
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  const GetHotelCountTable = ({ cluster, cluster_idx }) => {
    const [avg_wd, setAvg_wd] = useState(-1);
    const [stdev_wd, setStdev_wd] = useState(-1);

    const [avg_we, setAvg_we] = useState(-1);
    const [stdev_we, setStdev_we] = useState(-1);

    const getHotelCountByDate = (cluster, dt) => {
      let show_hotels = [];
      if (hotels.length > 0) {
        hotels.map((_hotel) => {
          if (checkHotelAvailability(_hotel.hotelID, dt)) {
            if (_hotel.prices[dt]) {
              if (
                cluster ==
                getClusterByPrice(
                  _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
                  dt
                )
              ) {
                show_hotels.push({
                  hotelID: _hotel.hotelID,
                  hotelName: _hotel.hotelName,
                  prices: _hotel.prices[dt].price,
                  price:
                    _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
                });
              }
            }
          }
        });
      }

      return show_hotels.length;
    };

    const getHotelCountVariance = (cluster_idx, dt) => {
      let Variance = '';
      let day = moment(selectedDate)
        .add(dt, 'days')
        .format('dddd')
        .substring(0, 3);

      let show_hotels = getHotelCountByDate(cluster_idx, dt);

      if (avg_wd > -1 && stdev_wd > -1 && avg_we > -1 && stdev_we > -1) {
        if (day === 'Sat' || day === 'Fri') {
          if (show_hotels >= avg_we + stdev_we * 1.65) {
            Variance = 'Very High';
          } else if (show_hotels >= avg_we + stdev_we) {
            Variance = 'High';
          } else if (show_hotels <= avg_we - stdev_we * 1.65) {
            Variance = 'Very Low';
          } else if (show_hotels <= avg_we - stdev_we) {
            Variance = 'Low';
          }
        } else {
          if (show_hotels >= avg_wd + stdev_wd * 1.65) {
            Variance = 'Very High';
          } else if (show_hotels >= avg_wd + stdev_wd) {
            Variance = 'High';
          } else if (show_hotels <= avg_wd - stdev_wd * 1.65) {
            Variance = 'Very Low';
          } else if (show_hotels <= avg_wd - stdev_wd) {
            Variance = 'Low';
          }
        }
      }

      return Variance;
    };

    useEffect(() => {
      const GetHotelsCount = (cluster) => {
        set_Load(true);
        let show_hotels_wd = [];
        let show_hotels_we = [];
        if (hotels.length > 0) {
          [...Array(100).keys()].map((n, dt) => {
            let showing_hotels = [];

            let day = moment(selectedDate)
              .add(dt, 'days')
              .format('dddd')
              .substring(0, 3);

            hotels.map((_hotel) => {
              if (checkHotelAvailability(_hotel.hotelID, dt)) {
                if (_hotel.prices[dt]) {
                  if (
                    cluster ==
                    getClusterByPrice(
                      _hotel.prices[dt].price[
                        getPrice(_hotel.prices[dt].price)
                      ],
                      dt
                    )
                  ) {
                    showing_hotels.push({
                      hotelID: _hotel.hotelID,
                      hotelName: _hotel.hotelName,
                      prices: _hotel.prices[dt].price,
                      price:
                        _hotel.prices[dt].price[
                          getPrice(_hotel.prices[dt].price)
                        ],
                      nights: getPrice(_hotel.prices[dt].price),
                    });
                  }
                }
              }
            });

            if (day === 'Sat' || day === 'Fri') {
              show_hotels_we.push(showing_hotels.length);
            } else {
              show_hotels_wd.push(showing_hotels.length);
            }
          });
        }

        if (show_hotels_wd.length > 0) {
          const _avg_wd =
            show_hotels_wd.reduce((a, b) => a + b) / show_hotels_wd.length;
          const _stdev_wd = getStandardDeviation(show_hotels_wd);

          setAvg_wd(_avg_wd);
          setStdev_wd(_stdev_wd);
        }

        if (show_hotels_we.length > 0) {
          const _avg_we =
            show_hotels_we.reduce((a, b) => a + b) / show_hotels_we.length;
          const _stdev_we = getStandardDeviation(show_hotels_we);

          setAvg_we(_avg_we);
          setStdev_we(_stdev_we);
        }
        set_Load(false);
      };

      if (hotels.length > 0) {
        GetHotelsCount(cluster_idx);
      }
    }, [hotels]);

    return (
      <>
        {!_load ? (
          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                id="stars5"
                className={classes.table}
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      fontWeight: 'bold',
                      width: '250px',
                      zIndex: 100,
                    }}
                  >
                    {cluster_idx + 2} Star Hotels Count{' '}
                    <div class="dropdown-divider"></div>
                    Days Out
                  </StyledTableCell>
                  {/* <StyledTableCell size="small">Stars</StyledTableCell> */}
                  {cluster.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      size="small"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                      }}
                    >
                      Bucket Density
                    </StyledTableCell>
                    {cluster.length > 0 ? (
                      cluster.map((day, index) => (
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          key={index}
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          <span>
                            {' '}
                            {getHotelCountVariance(cluster_idx, index)}
                          </span>
                        </StyledTableCell>
                      ))
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                      }}
                    >
                      <p className="font-weight-bold">Hotels Showing Rates</p>
                      <div className="text-center">
                        <Divider />
                        5-stars <Divider /> 4-stars <Divider /> 3-stars
                        <Divider />
                        2-stars{' '}
                      </div>
                    </StyledTableCell>
                    {/* <StyledTableCell size="small"></StyledTableCell> */}
                    {cluster.length > 0 ? (
                      cluster.map((day, index) => (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          key={index}
                          style={{
                            fontSize: '14px',
                          }}
                          className={classes.rates + ' text-center'}
                        >
                          <p className="font-weight-bold">
                            {day
                              ? day.stars2.length +
                                day.stars3.length +
                                day.stars4.length +
                                day.stars5.length
                              : 0}
                          </p>
                          <Divider />
                          {day ? day.stars5.length : 0} <Divider />{' '}
                          {day ? day.stars4.length : 0} <Divider />{' '}
                          {day ? day.stars3.length : 0} <Divider />{' '}
                          {day ? day.stars2.length : 0}
                        </StyledTableCell>
                      ))
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getAvg = (arr) => {
    if (arr.length > 0) {
      return arr.reduce((a, b) => a + b) / arr.length;
    }
  };

  const getVarience = (value, avg, stdev) => {
    if (avg <= 0 || stdev <= 0 || value <= 0) {
      return '';
    } else if (value >= avg + 1.65 * stdev) {
      return 'Very High';
    } else if (value >= avg + stdev) {
      return 'High';
    }
    // else if (value <= avg - 1.65 * stdev) {
    //   return 'Very Low';
    // } else if (value <= avg - stdev) {
    //   return 'Low';
    // }
    else {
      return '';
    }
  };

  const getVarience_index_movement = (value, avg, stdev) => {
    if (avg <= 0 || stdev <= 0 || value <= 0) {
      return '';
    } else if (value >= avg + 1 * stdev) {
      return 'High';
    }
  };

  useEffect(() => {
    const calculateStdev = () => {
      setLoad(true);
      let stars5down_hotels = [];
      let stars4up_hotels = [];
      let stars4down_hotels = [];
      let stars3up_hotels = [];
      let stars3down_hotels = [];
      let stars2up_hotels = [];
      let cluster_1_index = [];
      let cluster_2_index = [];
      let cluster_3_index = [];
      let cluster_4_index = [];

      [...Array(report_len).keys()].map((e, index) => {
        let hotel_count_5_down = 0;
        let hotel_count_4_up = 0;
        let hotel_count_4_down = 0;
        let hotel_count_3_up = 0;
        let hotel_count_3_down = 0;
        let hotel_count_2_up = 0;

        let star_2_hotels = 0;
        let star_3_hotels = 0;
        let star_4_hotels = 0;
        let star_5_hotels = 0;

        let cluster_1_hotels = 0;
        let cluster_2_hotels = 0;
        let cluster_3_hotels = 0;
        let cluster_4_hotels = 0;

        let comp_star_2_hotels = 0;
        let comp_star_3_hotels = 0;
        let comp_star_4_hotels = 0;
        let comp_star_5_hotels = 0;

        let comp_cluster_1_hotels = 0;
        let comp_cluster_2_hotels = 0;
        let comp_cluster_3_hotels = 0;
        let comp_cluster_4_hotels = 0;

        if (cluster1.length > 0 && cluster1[index]) {
          hotel_count_5_down =
            hotel_count_5_down + cluster1[index].stars5.length;
          hotel_count_4_down =
            hotel_count_4_down + cluster1[index].stars4.length;

          hotel_count_3_down =
            hotel_count_3_down + cluster1[index].stars3.length;

          cluster_1_hotels = cluster1[index].unwanted.length;

          star_2_hotels = star_2_hotels + cluster1[index].stars2.length;

          star_3_hotels = star_3_hotels + cluster1[index].stars3.length;

          star_4_hotels = star_4_hotels + cluster1[index].stars4.length;

          star_5_hotels = star_5_hotels + cluster1[index].stars5.length;

          if (cluster1[index].comp_report) {
            comp_cluster_1_hotels = cluster1[index].comp_report.items;

            comp_star_2_hotels =
              comp_star_2_hotels + cluster1[index].comp_report.stars2.length;

            comp_star_3_hotels =
              comp_star_3_hotels + cluster1[index].comp_report.stars3.length;

            comp_star_4_hotels =
              comp_star_4_hotels + cluster1[index].comp_report.stars4.length;

            comp_star_5_hotels =
              comp_star_5_hotels + cluster1[index].comp_report.stars5.length;
          }
        }

        if (cluster2.length > 0 && cluster2[index]) {
          hotel_count_5_down =
            hotel_count_5_down + cluster2[index].stars5.length;

          hotel_count_4_down =
            hotel_count_4_down + cluster2[index].stars4.length;

          hotel_count_2_up = hotel_count_2_up + cluster2[index].stars2.length;

          cluster_2_hotels = cluster2[index].unwanted.length;

          star_2_hotels = star_2_hotels + cluster2[index].stars2.length;

          star_3_hotels = star_3_hotels + cluster2[index].stars3.length;

          star_4_hotels = star_4_hotels + cluster2[index].stars4.length;

          star_5_hotels = star_5_hotels + cluster2[index].stars5.length;

          if (cluster2[index].comp_report) {
            comp_cluster_2_hotels = cluster2[index].comp_report.items;

            comp_star_2_hotels =
              comp_star_2_hotels + cluster2[index].comp_report.stars2.length;

            comp_star_3_hotels =
              comp_star_3_hotels + cluster2[index].comp_report.stars3.length;

            comp_star_4_hotels =
              comp_star_4_hotels + cluster2[index].comp_report.stars4.length;

            comp_star_5_hotels =
              comp_star_5_hotels + cluster2[index].comp_report.stars5.length;
          }
        }

        if (cluster3.length > 0 && cluster3[index]) {
          hotel_count_5_down =
            hotel_count_5_down + cluster3[index].stars5.length;

          hotel_count_3_up = hotel_count_3_up + cluster3[index].stars3.length;

          hotel_count_2_up = hotel_count_2_up + cluster3[index].stars2.length;

          cluster_3_hotels = cluster3[index].unwanted.length;

          star_2_hotels = star_2_hotels + cluster3[index].stars2.length;

          star_3_hotels = star_3_hotels + cluster3[index].stars3.length;

          star_4_hotels = star_4_hotels + cluster3[index].stars4.length;

          star_5_hotels = star_5_hotels + cluster3[index].stars5.length;

          if (cluster3[index].comp_report) {
            comp_cluster_3_hotels = cluster3[index].comp_report.items;

            comp_star_2_hotels =
              comp_star_2_hotels + cluster3[index].comp_report.stars2.length;

            comp_star_3_hotels =
              comp_star_3_hotels + cluster3[index].comp_report.stars3.length;

            comp_star_4_hotels =
              comp_star_4_hotels + cluster3[index].comp_report.stars4.length;

            comp_star_5_hotels =
              comp_star_5_hotels + cluster3[index].comp_report.stars5.length;
          }
        }

        if (cluster4.length > 0 && cluster4[index]) {
          hotel_count_4_up = hotel_count_4_up + cluster4[index].stars4.length;

          hotel_count_3_up = hotel_count_3_up + cluster4[index].stars3.length;

          hotel_count_2_up = hotel_count_2_up + cluster4[index].stars2.length;

          cluster_4_hotels = cluster_4_hotels + cluster4[index].unwanted.length;

          star_2_hotels = star_2_hotels + cluster4[index].stars2.length;

          star_3_hotels = star_3_hotels + cluster4[index].stars3.length;

          star_4_hotels = star_4_hotels + cluster4[index].stars4.length;

          star_5_hotels = star_5_hotels + cluster4[index].stars5.length;

          if (cluster4[index].comp_report) {
            comp_cluster_4_hotels = cluster4[index].comp_report.items;

            comp_star_2_hotels =
              comp_star_2_hotels + cluster4[index].comp_report.stars2.length;

            comp_star_3_hotels =
              comp_star_3_hotels + cluster4[index].comp_report.stars3.length;

            comp_star_4_hotels =
              comp_star_4_hotels + cluster4[index].comp_report.stars4.length;

            comp_star_5_hotels =
              comp_star_5_hotels + cluster4[index].comp_report.stars5.length;
          }
        }

        stars5down_hotels.push(hotel_count_5_down);

        stars4up_hotels.push(hotel_count_4_up);
        stars4down_hotels.push(hotel_count_4_down);

        stars3up_hotels.push(hotel_count_3_up);
        stars3down_hotels.push(hotel_count_3_down);

        stars2up_hotels.push(hotel_count_2_up);

        cluster_1_index.push({
          bucket_index:
            star_2_hotels > 0
              ? Math.round(
                  (cluster_1_hotels /
                    (star_2_hotels +
                      star_3_hotels +
                      star_4_hotels +
                      star_5_hotels)) *
                    100
                )
              : 0,
          comp_bucket_index:
            comp_star_2_hotels > 0
              ? Math.round(
                  (comp_cluster_1_hotels /
                    (comp_star_2_hotels +
                      comp_star_3_hotels +
                      comp_star_4_hotels +
                      comp_star_5_hotels)) *
                    100
                )
              : 0,
        });

        cluster_2_index.push({
          bucket_index:
            star_3_hotels > 0
              ? Math.round(
                  (cluster_2_hotels /
                    (star_2_hotels +
                      star_3_hotels +
                      star_4_hotels +
                      star_5_hotels)) *
                    100
                )
              : 0,
          comp_bucket_index:
            comp_star_3_hotels > 0
              ? Math.round(
                  (comp_cluster_2_hotels /
                    (comp_star_2_hotels +
                      comp_star_3_hotels +
                      comp_star_4_hotels +
                      comp_star_5_hotels)) *
                    100
                )
              : 0,
        });

        cluster_3_index.push({
          bucket_index:
            star_4_hotels > 0
              ? Math.round(
                  (cluster_3_hotels /
                    (star_2_hotels +
                      star_3_hotels +
                      star_4_hotels +
                      star_5_hotels)) *
                    100
                )
              : 0,
          comp_bucket_index:
            comp_star_4_hotels > 0
              ? Math.round(
                  (comp_cluster_3_hotels /
                    (comp_star_2_hotels +
                      comp_star_3_hotels +
                      comp_star_4_hotels +
                      comp_star_5_hotels)) *
                    100
                )
              : 0,
        });

        cluster_4_index.push({
          bucket_index:
            star_5_hotels > 0
              ? Math.round(
                  (cluster_4_hotels /
                    (star_2_hotels +
                      star_3_hotels +
                      star_4_hotels +
                      star_5_hotels)) *
                    100
                )
              : 0,
          comp_bucket_index:
            comp_star_5_hotels > 0
              ? Math.round(
                  (comp_cluster_4_hotels /
                    (comp_star_2_hotels +
                      comp_star_3_hotels +
                      comp_star_4_hotels +
                      comp_star_5_hotels)) *
                    100
                )
              : 0,
        });
      });

      cluster_1_index.map((obj) => {
        obj.variance = getVarience_index_movement(
          obj.bucket_index - obj.comp_bucket_index,
          getAvg(
            cluster_1_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          ),
          getStandardDeviation(
            cluster_1_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          )
        );
        obj.bucket_size_exception = getVarience_index_movement(
          obj.bucket_index,
          getAvg(cluster_1_index.map((obj) => obj.bucket_index)),
          getStandardDeviation(cluster_1_index.map((obj) => obj.bucket_index))
        );
      });

      cluster_2_index.map((obj) => {
        obj.variance = getVarience_index_movement(
          obj.bucket_index - obj.comp_bucket_index,
          getAvg(
            cluster_2_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          ),
          getStandardDeviation(
            cluster_2_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          )
        );
        obj.bucket_size_exception = getVarience_index_movement(
          obj.bucket_index,
          getAvg(cluster_2_index.map((obj) => obj.bucket_index)),
          getStandardDeviation(cluster_2_index.map((obj) => obj.bucket_index))
        );
      });

      cluster_3_index.map((obj) => {
        obj.variance = getVarience_index_movement(
          obj.bucket_index - obj.comp_bucket_index,
          getAvg(
            cluster_3_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          ),
          getStandardDeviation(
            cluster_3_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          )
        );
        obj.bucket_size_exception = getVarience_index_movement(
          obj.bucket_index,
          getAvg(cluster_3_index.map((obj) => obj.bucket_index)),
          getStandardDeviation(cluster_3_index.map((obj) => obj.bucket_index))
        );
      });

      cluster_4_index.map((obj) => {
        obj.variance = getVarience_index_movement(
          obj.bucket_index - obj.comp_bucket_index,
          getAvg(
            cluster_4_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          ),
          getStandardDeviation(
            cluster_4_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          )
        );
        obj.bucket_size_exception = getVarience_index_movement(
          obj.bucket_index,
          getAvg(cluster_4_index.map((obj) => obj.bucket_index)),
          getStandardDeviation(cluster_4_index.map((obj) => obj.bucket_index))
        );
      });

      set_cluster1_bucket_index(cluster_1_index);
      set_cluster2_bucket_index(cluster_2_index);
      set_cluster3_bucket_index(cluster_3_index);
      set_cluster4_bucket_index(cluster_4_index);

      stars5down_hotels.map((e) => {
        stars5down.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars5down_hotels),
            getStandardDeviation(stars5down_hotels)
          ),
        });
      });

      stars4up_hotels.map((e) => {
        stars4up.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars4up_hotels),
            getStandardDeviation(stars4up_hotels)
          ),
        });
      });
      stars4down_hotels.map((e) => {
        stars4down.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars4down_hotels),
            getStandardDeviation(stars4down_hotels)
          ),
        });
      });

      stars3up_hotels.map((e) => {
        stars3up.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars3up_hotels),
            getStandardDeviation(stars3up_hotels)
          ),
        });
      });
      stars3down_hotels.map((e) => {
        stars3down.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars3down_hotels),
            getStandardDeviation(stars3down_hotels)
          ),
        });
      });

      stars2up_hotels.map((e) => {
        stars2up.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars2up_hotels),
            getStandardDeviation(stars2up_hotels)
          ),
        });
      });
      setLoad(false);
    };
    if (
      comp_report_state &&
      cluster1.length > 0 &&
      cluster2.length > 0 &&
      cluster3.length > 0 &&
      cluster4.length > 0
    ) {
      calculateStdev();
    }
  }, [cluster1, cluster2, cluster3, cluster4, comp_report_state]);

  useEffect(() => {
    const handdle_scroll = () => {
      if (!load && clusterData.length > 0 && hotels.length > 0) {
        if (tbl_ref == 1) {
          window.scrollTo(0, hotels_count_tbl_2.current.offsetTop - 250);
        } else if (tbl_ref == 2) {
          window.scrollTo(0, hotels_count_tbl_3.current.offsetTop - 250);
        } else if (tbl_ref == 3) {
          // alert(hotels_count_tbl_4.current);
          window.scrollTo(0, hotels_count_tbl_4.current.offsetTop - 250);
        } else if (tbl_ref == 4) {
          window.scrollTo(0, hotels_count_tbl_5.current.offsetTop - 250);
        } else if (tbl_ref == 5) {
          window.scrollTo(
            0,
            high_net_bucket_movements_tbl.current.offsetTop - 250
          );
        } else if (tbl_ref == 6) {
          window.scrollTo(
            0,
            high_bucket_size_index_tbl.current.offsetTop - 250
          );
        } else if (tbl_ref == 7) {
          window.scrollTo(
            0,
            index_movement_strength_tbl.current.offsetTop - 250
          );
        }
      }
    };
    handdle_scroll();
  });

  const BucketMovementsGrid = ({cluster, cluster_idx}) => {

    const [inBucket, setInBucket] = useState([]);
    const [pricingUps, setPricingUps] = useState([]);
    const [pricingDowns, setPricingDowns] = useState([]);
    const [outliers, setOutliers] = useState([]);
    const [noRates, setNoRates] = useState([]);

    useEffect(() => {
      const GetHotelsCount = (cluster) => {
        set_Load(true);

        let inBucket_ = [];
        let outliers_ = []
        let noRates_ = []
        let pricingUps_ = []
        let pricingDowns_ = []

        if (hotels.length > 0) {
          [...Array(report_len).keys()].map((n, dt) => {
            let _inBucket = [];
            let _outliers = []
            let _noRates = []
            let _pricingUps = []
            let _pricingDowns = []

            hotels.map((_hotel) => {
              if (_hotel.prices[dt] != null) {
                if (checkHotelAvailability(_hotel.hotelID, dt)) {
                  if(Math.floor(_hotel.stars) == cluster_idx + 2) {
                      if(cluster ==
                        getClusterByPrice(
                          _hotel.prices[dt].price[
                            getPrice(_hotel.prices[dt].price)
                          ],
                          dt
                        )) {
                          _inBucket.push({
                          hotelID: _hotel.hotelID,
                          hotelName: _hotel.hotelName
                        });
                      } else if (cluster <
                      getClusterByPrice(
                        _hotel.prices[dt].price[
                          getPrice(_hotel.prices[dt].price)
                        ],
                        dt
                      )) {
                        _pricingUps.push({
                          hotelID: _hotel.hotelID,
                          hotelName: _hotel.hotelName
                        });
                    }else if (cluster >
                      getClusterByPrice(
                        _hotel.prices[dt].price[
                          getPrice(_hotel.prices[dt].price)
                        ],
                        dt
                      )) {
                      _pricingDowns.push({
                        hotelID: _hotel.hotelID,
                        hotelName: _hotel.hotelName
                      });
                    }
                  }
                  
                }else {
                  if(Math.floor(_hotel.stars) == cluster_idx + 2){
                    _outliers.push({
                      hotelID: _hotel.hotelID,
                      hotelName: _hotel.hotelName,
                    });
                  }
                }
              }else {
                if(Math.floor(_hotel.stars) == cluster_idx + 2){
                  _noRates.push({
                    hotelID: _hotel.hotelID,
                    hotelName: _hotel.hotelName,
                  })
              }
              }
            });

            inBucket_.push(_inBucket.length)
            pricingUps_.push(_pricingUps.length)
            pricingDowns_.push(_pricingDowns.length)
            outliers_.push(_outliers.length)
            noRates_.push(_noRates.length)
          });
        }
        setInBucket(inBucket_)
        setPricingUps(pricingUps_)
        setPricingDowns(pricingDowns_)
        setOutliers(outliers_)
        setNoRates(noRates_)

        console.log(`inBucket => ${inBucket_}`)
        console.log(`pricingUps => ${pricingUps_}`)
        console.log(`pricingDowns => ${pricingDowns_}`)
        console.log(`outliers => ${outliers_}`)
        console.log(`noRates => ${noRates_}`)

        set_Load(false);
      };

      if (hotels.length > 0) {
        GetHotelsCount(cluster_idx);
      }
    }, [hotels]);

    return (
      <>
        {!_load ? (
          
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[cluster_idx],
                      }}
                    >
                      <p className="font-weight-bold">{cluster_idx + 2}-stars</p>
                      <div >
                        <Divider />
                        { cluster_idx != 3 ?  <> Pricing Up <Divider /> </>: <></> } In Bucket <Divider /> 
                        { cluster_idx != 0 ?  <> Pricing Down <Divider /> </>: <></> }  Total Showing
                        <Divider />
                        Outliers <Divider /> No Rate <Divider /> Total Hotels{' '}
                      </div>
                    </StyledTableCell>
                    {/* <StyledTableCell size="small"></StyledTableCell> */}
                    {
                      [...Array(report_len).keys()].map((day, index) => (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          key={index}
                          style={{
                            fontSize: '14px',
                          }}
                          className={classes.rates + ' text-center'}
                        >
                          <p className="font-weight-bold">
                            
                          </p>
                          <Divider />
                          {cluster_idx != 3 ? <>{pricingDowns[index] } <Divider />{' '}</> : <></>}  
                          {inBucket[index] }  <Divider />{' '}
                          {cluster_idx != 0 ? <>{pricingDowns[index] } <Divider />{' '}</> : <></>}  
                          { pricingDowns[index] + inBucket[index] } <Divider />{' '}
                          { outliers[index]}<Divider />{' '}
                          { noRates[index]}<Divider />{' '}
                          { pricingDowns[index] + inBucket[index] + pricingDowns[index]  + outliers[index] + noRates[index]}
                        </StyledTableCell>
                      ))
                    }
                  </StyledTableRow>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      {!loading && hotels.length > 0 && !load ? (
        <>
        <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                className={classes.table}
                size="medium"
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{ fontWeight: 'bold', width: '250px', zIndex: 100 }}
                  >
                    New Bucket Movements <hr />
                    Days Out
                  </StyledTableCell>
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                    <BucketMovementsGrid cluster={cluster4} cluster_idx={3}/>
                    <BucketMovementsGrid cluster={cluster3} cluster_idx={2}/>
                    <BucketMovementsGrid cluster={cluster2} cluster_idx={1}/>
                    <BucketMovementsGrid cluster={cluster1} cluster_idx={0}/>
                </TableBody>
                </Table>
                </Box>
                </TableContainer>

          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                className={classes.table}
                size="medium"
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{ fontWeight: 'bold', width: '250px', zIndex: 100 }}
                  >
                    Net Bucket Movements <hr />
                    Days Out
                  </StyledTableCell>
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[3],
                      }}
                    >
                      5 Star Pricing Down
                    </StyledTableCell>
                    {stars5down.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '14px' }}
                          className={classes.rates}
                        >
                          {obj.no_hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[2],
                      }}
                    >
                      4 Star Pricing Up
                    </StyledTableCell>
                    {stars4up.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '14px' }}
                          className={classes.rates}
                        >
                          {obj.no_hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[2],
                      }}
                    >
                      4 Star Pricing Down
                    </StyledTableCell>
                    {stars4down.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '14px' }}
                          className={classes.rates}
                        >
                          {obj.no_hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[1],
                      }}
                    >
                      3 Star Pricing Up
                    </StyledTableCell>
                    {stars3up.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '14px' }}
                          className={classes.rates}
                        >
                          {obj.no_hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[1],
                      }}
                    >
                      3 Star Pricing Down
                    </StyledTableCell>
                    {stars3down.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '14px' }}
                          className={classes.rates}
                        >
                          {obj.no_hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[0],
                      }}
                    >
                      2 Star Pricing Up
                    </StyledTableCell>
                    {stars2up.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '14px' }}
                          className={classes.rates}
                        >
                          {obj.no_hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>

          <TableContainer
            component={Paper}
            className="my-5"
            ref={high_net_bucket_movements_tbl}
          >
            <Box width={100}>
              <Table
                className={classes.table}
                size="medium"
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{ fontWeight: 'bold', width: '250px', zIndex: 100 }}
                  >
                    High Net Bucket Movements <hr />
                    Days Out
                  </StyledTableCell>
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[3],
                      }}
                    >
                      5 Star Pricing Down
                    </StyledTableCell>
                    {stars5down.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[2],
                      }}
                    >
                      4 Star Pricing Up
                    </StyledTableCell>
                    {stars4up.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[2],
                      }}
                    >
                      4 Star Pricing Down
                    </StyledTableCell>
                    {stars4down.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[1],
                      }}
                    >
                      3 Star Pricing Up
                    </StyledTableCell>
                    {stars3up.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[1],
                      }}
                    >
                      3 Star Pricing Down
                    </StyledTableCell>
                    {stars3down.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        backgroundColor: CLUSTER_BACKGROUND[0],
                      }}
                    >
                      2 Star Pricing Up
                    </StyledTableCell>
                    {stars2up.map((obj, idx) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={idx}
                          style={{ fontSize: '12px' }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>

          <div ref={hotels_count_tbl_5}>
            {cluster4.length > 0 ? (
              <GetHotelCountTable cluster={cluster4} cluster_idx={3} />
            ) : (
              <></>
            )}
          </div>

          <div ref={hotels_count_tbl_4}>
            {cluster3.length > 0 ? (
              <GetHotelCountTable cluster={cluster3} cluster_idx={2} />
            ) : (
              <></>
            )}
          </div>

          <div ref={hotels_count_tbl_3}>
            {cluster2.length > 0 ? (
              <GetHotelCountTable cluster={cluster2} cluster_idx={1} />
            ) : (
              <></>
            )}
          </div>

          <div ref={hotels_count_tbl_2}>
            {cluster1.length > 0 ? (
              <GetHotelCountTable cluster={cluster1} cluster_idx={0} />
            ) : (
              <></>
            )}
          </div>

          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                className={classes.table}
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{ fontWeight: 'bold', width: '250px', zIndex: 100 }}
                  >
                    Total Hotels Count &nbsp; ({hotels.length})<hr />
                    Days Out
                  </StyledTableCell>
                  {/* <StyledTableCell size="small">Stars</StyledTableCell> */}
                  {cluster1.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let day = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            day === 'Sat' || day === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{ fontSize: '12px' }}
                        >
                          <>
                            {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{day.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        width: '250px',
                        borderTop: '2px solid grey',
                      }}
                    >
                      <p className="font-weight-bold">Hotels Showing Rates</p>
                      <div className="text-center">
                        <Divider />
                        <div className="d-flex justify-content-around">
                          <span className="font-weight-bold">5-stars </span> (
                          {getTotalHotelsCount(5)})
                        </div>{' '}
                        <Divider />{' '}
                        <div className="d-flex justify-content-around">
                          <span className="font-weight-bold">4-stars </span>(
                          {getTotalHotelsCount(4)})
                        </div>
                        <Divider />{' '}
                        <div className="d-flex justify-content-around">
                          <span className="font-weight-bold">3-stars </span>(
                          {getTotalHotelsCount(3)})
                        </div>
                        <Divider />
                        <div className="d-flex justify-content-around">
                          <span className="font-weight-bold">2-stars </span>(
                          {getTotalHotelsCount(2)})
                        </div>
                        <Divider />{' '}
                        <span className="font-weight-bold">
                          Hotels with Outlier Rates
                        </span>
                        <Divider />{' '}
                        <span className="font-weight-bold">
                          Hotels not Showing Rates
                        </span>
                      </div>
                    </StyledTableCell>
                    {/* <StyledTableCell size="small"></StyledTableCell> */}

                    {[...Array(report_len).keys()].map((e, index) =>
                      (() => {
                        let hotel_count = 0;

                        hotel_count = 0;

                        if (cluster1.length > 0 && cluster1[index]) {
                          hotel_count += cluster1[index].unwanted.length;
                        }
                        if (cluster2.length > 0 && cluster2[index]) {
                          hotel_count += cluster2[index].unwanted.length;
                        }
                        if (cluster3.length > 0 && cluster3[index]) {
                          hotel_count += cluster3[index].unwanted.length;
                        }
                        if (cluster4.length > 0 && cluster4[index]) {
                          hotel_count += cluster4[index].unwanted.length;
                        }

                        let hotel_count_2 = 0;
                        if (cluster1.length > 0 && cluster1[index]) {
                          hotel_count_2 += cluster1[index].stars2.length;
                        }
                        if (cluster2.length > 0 && cluster2[index]) {
                          hotel_count_2 += cluster2[index].stars2.length;
                        }
                        if (cluster3.length > 0 && cluster3[index]) {
                          hotel_count_2 += cluster3[index].stars2.length;
                        }
                        if (cluster4.length > 0 && cluster4[index]) {
                          hotel_count_2 += cluster4[index].stars2.length;
                        }

                        let hotel_count_3 = 0;
                        if (cluster1.length > 0 && cluster1[index]) {
                          hotel_count_3 += cluster1[index].stars3.length;
                        }
                        if (cluster2.length > 0 && cluster2[index]) {
                          hotel_count_3 += cluster2[index].stars3.length;
                        }
                        if (cluster3.length > 0 && cluster3[index]) {
                          hotel_count_3 += cluster3[index].stars3.length;
                        }
                        if (cluster4.length > 0 && cluster4[index]) {
                          hotel_count_3 += cluster4[index].stars3.length;
                        }

                        let hotel_count_4 = 0;
                        if (cluster1.length > 0 && cluster1[index]) {
                          hotel_count_4 += cluster1[index].stars4.length;
                        }
                        if (cluster2.length > 0 && cluster2[index]) {
                          hotel_count_4 += cluster2[index].stars4.length;
                        }
                        if (cluster3.length > 0 && cluster3[index]) {
                          hotel_count_4 += cluster3[index].stars4.length;
                        }
                        if (cluster4.length > 0 && cluster4[index]) {
                          hotel_count_4 += cluster4[index].stars4.length;
                        }

                        let hotel_count_5 = 0;
                        if (cluster1.length > 0 && cluster1[index]) {
                          hotel_count_5 += cluster1[index].stars5.length;
                        }
                        if (cluster2.length > 0 && cluster2[index]) {
                          hotel_count_5 += cluster2[index].stars5.length;
                        }
                        if (cluster3.length > 0 && cluster3[index]) {
                          hotel_count_5 += cluster3[index].stars5.length;
                        }
                        if (cluster4.length > 0 && cluster4[index]) {
                          hotel_count_5 += cluster4[index].stars5.length;
                        }

                        let outliers = [];
                        let noRateHotels = [];

                        hotels.map((_hotel, id) => {
                          if (_hotel.prices[index] != null) {
                            // console.log(_hotel.prices[index]);
                            if (
                              !checkHotelAvailability(_hotel.hotelID, index)
                            ) {
                              outliers.push(_hotel);
                            }
                          } else {
                            noRateHotels.push(_hotel);
                          }
                        });

                        return (
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{
                              fontSize: '14px',
                              borderTop: '2px solid grey',
                            }}
                            className={classes.rates + ' text-center'}
                          >
                            <p className="font-weight-bold">{hotel_count}</p>
                            <>
                              <Divider />
                              {hotel_count_5}
                              <Divider /> {hotel_count_4}
                              <Divider /> {hotel_count_3} <Divider />{' '}
                              {hotel_count_2} <Divider /> {outliers.length}{' '}
                              <Divider /> {noRateHotels.length}
                            </>
                          </StyledTableCell>
                        );
                      })()
                    )}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </TableContainer>

          <GetBucketIndexTable />

          <div ref={high_bucket_size_index_tbl}>
            <GetHighBucketIndexTable />
          </div>

          <GetIndexMovementsTable />

          <div ref={index_movement_strength_tbl}>
            <GetIndexMovementsStrengthTable />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
