import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  makeStyles,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  withStyles,
  Button,
  FormGroup,
} from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';

import { CLUSTER_BACKGROUND, CLUSTER_LABELS, FONT_FAMILY } from '../utils/const';
import OverallMarketAnalysis from './OverallMarketAnalysis';
import ClusterBucket from './ClusterBucket';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LoadingOverlay } from './UI/LoadingOverlay';
import { useRef } from 'react';
import ClusterDataTable from './ClusterDataTable';
import { HotelsPlot } from './HotelsPlot';
import { LoadingOverlayHandler } from '../redux/actions/cluster.actions';
import { useDispatch } from 'react-redux';
import EventIcon from '@mui/icons-material/Event';
import DatePicker from 'react-multi-date-picker';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
//import ValueRank from './ValueRank';


const Excel = require('exceljs');

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: window.innerHeight - 275,
  },
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'flex',
    zIndex: 50,
  },
  rates: {
    fontFamily: FONT_FAMILY,
    textAlign: 'center',
  },
  formControl: { margin: theme.spacing(1), minWidth: 120 },
  tabularNavStyle: {
    backgroundColor: '#607D8B',
    fontFamily: FONT_FAMILY,
    fontWeight: 'bold',
  },
}));

export const Insigths = ({
  selectedDate,
  reqHotel,
  executeScroll,
  specailDates,
}) => {
  const classes = useStyles();
  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    clusterData,
    loading,
    err,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels,
    hotelList,
    markets,
    refreshDates,
    ratingCluster,
    comparison_report,
    report_len,
    date_format,
  } = getClusterDataSet;

  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [selectedCluster, setSelectedCluster] = useState([]);

  const [selectedCluster_idx, setSelectedCluster_idx] = useState(-1);

  const [freq_bucket_wd, set_freq_bucket_wd] = useState(-1);

  const [freq_bucket_we, set_freq_bucket_we] = useState(-1);

  const [load, setLoad] = useState(false);

  const [bestRatedHotels, setBestRatedHotels] = useState([]);

  const [tab, setTab] = useState(1);

  const _report_len = 180;

  const [avg_wd, setAvg_wd] = useState(-1);
  const [stdev_wd, setStdev_wd] = useState(-1);

  const [avg_we, setAvg_we] = useState(-1);
  const [stdev_we, setStdev_we] = useState(-1);

  const [stars5down, setStars5down] = useState([]);
  const [stars4up, setStars4up] = useState([]);
  const [stars4down, setStars4down] = useState([]);
  const [stars3up, setStars3up] = useState([]);
  const [stars3down, setStars3down] = useState([]);
  const [stars2up, setStars2up] = useState([]);

  const [cluster1_bucket_index, set_cluster1_bucket_index] = useState([]);
  const [cluster2_bucket_index, set_cluster2_bucket_index] = useState([]);
  const [cluster3_bucket_index, set_cluster3_bucket_index] = useState([]);
  const [cluster4_bucket_index, set_cluster4_bucket_index] = useState([]);

  const [comp_report_state, set_comp_report_state] = useState(false);

  const [net_bucket_data_state, set_net_bucket_data_state] = useState(false);

  const [quadrant_data_state, set_quadrant_data_state] = useState(false);

  const [days_arr, set_days_arr] = useState([]);

  const [lebr_hotels, set_lebr_hotels] = useState([]);

  const [lebr_avg, set_lebr_avg] = useState(-1);
  const [lebr_stdev, set_lebr_stdev] = useState(-1);

  const [zero_lebr_floor_prices, set_zero_lebr_floor_prices] = useState([]);

  const [events_date_picker_open, set_events_date_picker_open] =
    useState(false);

  const [selected_events_date_arr, set_selected_events_date_arr] = useState([]);

  const [all_dates_arr, set_all_dates_arr] = useState([]);

  const [reqHotelStrategyZone, setReqHotelStrategyZone] = useState(undefined);

  const [rateStrength, setRateStrength] = useState([]);

  const [rateVarianceStrength_avg, setRateVarianceStrength_avg] = useState(0);

  const [rateVarianceStrength_stdev, setRateVarianceStrength_stdev] =
    useState(0);

  const [dates_filter, set_dates_filter] = useState(1);

  const [AvgValueRank, setAvgValueRank] = useState(undefined);

  const reqHotelName = reqHotel.length > 0
  ? (reqHotel.find((el) => el.name != null) || {}).name
  : undefined;


  const reqHotelRatings = reqHotel.length > 0
  ? (reqHotel.find((el) => (el) => el.raings != null && el.raings != '-') || {}).raings
  : undefined;

  const reqHotelStars = reqHotel.length > 0
  ? (reqHotel.find((el) => el.stars != null && el.stars != '-') || {}).stars
  : undefined;

  function checkRatebucketMovement(a, b, c) {
    if ((a && b && c) || (a && b && !c) || (a && !b && c) || (!a && b && c)) {
      return true;
    } else {
      return false;
    }
  }

  const getDateByIndex = (index) => {
    if (cluster1.length > 0) {
      if (index >= 0) {
        return cluster1[index].date;
      }
    }
  };

  const getReportName = () => {
    let name = null;
    if (reqHotel.length > 0) {
      reqHotel.map((e, index) => {
        if (e.name !== null) {
          name = e.name;
          return;
        }
      });
    }

    return `${name}-Insights-${moment(selectedDate).format('YYYY-MM-DD')}.xlsx`;
  };

  const handdle_events_date_picker = (dates) => {
    if (dates.length > 0) {
      set_selected_events_date_arr(
        dates.map((date) => moment(date).format('YYYY-MM-DD'))
      );
    }

    set_events_date_picker_open(false);
  };

  const handleExport = () => {
    // create a new workbook object
    const workbook = new Excel.Workbook();

    // create a new worksheet
    const worksheet = workbook.addWorksheet('Insights');

    // get the data from the HTML table
    const table = document.getElementById('insights-tbl');
    const headers = [[], [], [], []];
    const data = [];
    for (let i = 0; i < table.rows.length; i++) {
      const row = [];
      for (let j = 0; j < table.rows[i].cells.length; j++) {
        let content = table.rows[i].cells[j].textContent;
        if (i == 0) {
          if (j == 0) {
            let _h = ['Your Property', '4-Stars', '8.8 Rating', 'Days Out'];

            _h.map((e, ix) => {
              headers[ix].push(e);
            });
          } else {
            let _rows = content.split(' ');

            if (_rows.length > 0) {
              _rows.map((e, ix) => {
                if (ix < 4 && e.length > 0) headers[ix].push(e);
              });
            }
          }
        } else {
          row.push(content);
        }
      }
      data.push(row);
    }

    // add the data to the worksheet
    worksheet.addRows([...headers, ...data]);

    // generate a binary string representation of the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      // convert the binary string to a Blob object
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // create a URL that can be used to download the Blob
      const url = URL.createObjectURL(blob);

      // force the browser to download the file
      const link = document.createElement('a');
      link.href = url;
      link.download = getReportName();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  useEffect(() => {
    const SelectedPropertyData = () => {
      setLoad(true);
      let freq_bucket = -1;

      const req_hotel_data = hotels.find((e) => e.hotelName == reqHotelName);

      if (req_hotel_data != undefined) {
        let all_cluster_arr = [];
        let cluster_arr_wd = [];
        let cluster_arr_we = [];

        let _days_arr = [];

        let _all_dates = [];

        req_hotel_data.prices.map((dt, ix) => {
          if (dt !== null) {
            const day = moment(dt.date).format('dddd').substring(0, 3);
            _all_dates.push(moment(dt.date).format('YYYY-MM-DD'));
            _days_arr.push(day);

            all_cluster_arr.push(
              getClusterByPrice(dt.price[getPrice(dt.price)], ix) + 2
            );
            if (day === 'Sat' || day === 'Fri') {
              cluster_arr_we.push(
                getClusterByPrice(dt.price[getPrice(dt.price)], ix) + 2
              );
            } else {
              cluster_arr_wd.push(
                getClusterByPrice(dt.price[getPrice(dt.price)], ix) + 2
              );
            }
          }
        });

        freq_bucket = mode(all_cluster_arr);

        set_freq_bucket_wd(mode(cluster_arr_wd));
        set_freq_bucket_we(mode(cluster_arr_we));

        set_all_dates_arr(_all_dates);
        set_days_arr(_days_arr);

        if (freq_bucket == 2) {
          setSelectedCluster(cluster1);
          setSelectedCluster_idx(0);
        } else if (freq_bucket == 3) {
          setSelectedCluster(cluster2);
          setSelectedCluster_idx(1);
        } else if (freq_bucket == 4) {
          setSelectedCluster(cluster3);
          setSelectedCluster_idx(2);
        } else if (freq_bucket == 5) {
          setSelectedCluster(cluster4);
          setSelectedCluster_idx(3);
        }
      }

      setLoad(false);
    };

    if (clusterData.length > 0 && reqHotel.length > 0) {
      SelectedPropertyData();
    }
  }, [clusterData, reqHotel]);

  useEffect(() => {
    const CompareReport = async () => {
      cluster1.map((e) => {
        if (e && e != undefined) {
          let dateMatch = comparison_report.cluster1.find((obj) => {
            try {
              if (obj && obj != undefined && obj != undefined) {
                if (
                  moment(e.date).format('YYYY-MM-DD') ===
                  moment(obj.date).format('YYYY-MM-DD')
                ) {
                  return obj;
                }
              }
            } catch (e) {}
          });

          if (dateMatch) {
            delete dateMatch.cluster;
            delete dateMatch.unwanted;

            e.comp_report = dateMatch;
          }
        }
      });

      cluster2.map((e) => {
        if (e && e != undefined) {
          let dateMatch = comparison_report.cluster2.find((obj) => {
            try {
              if (obj && obj != undefined && obj != undefined) {
                if (
                  moment(e.date).format('YYYY-MM-DD') ===
                  moment(obj.date).format('YYYY-MM-DD')
                ) {
                  return obj;
                }
              }
            } catch (e) {}
          });

          if (dateMatch) {
            delete dateMatch.cluster;
            delete dateMatch.unwanted;

            e.comp_report = dateMatch;
          }
        }
      });

      cluster3.map((e) => {
        if (e && e != undefined) {
          let dateMatch = comparison_report.cluster3.find((obj) => {
            try {
              if (obj && obj != undefined && obj != undefined) {
                if (
                  moment(e.date).format('YYYY-MM-DD') ===
                  moment(obj.date).format('YYYY-MM-DD')
                ) {
                  return obj;
                }
              }
            } catch (e) {}
          });

          if (dateMatch) {
            delete dateMatch.cluster;
            delete dateMatch.unwanted;

            e.comp_report = dateMatch;
          }
        }
      });

      cluster4.map((e) => {
        if (e && e != undefined) {
          let dateMatch = comparison_report.cluster4.find((obj) => {
            try {
              if (obj && obj != undefined && obj != undefined) {
                if (
                  moment(e.date).format('YYYY-MM-DD') ===
                  moment(obj.date).format('YYYY-MM-DD')
                ) {
                  return obj;
                }
              }
            } catch (e) {}
          });

          if (dateMatch) {
            delete dateMatch.cluster;
            delete dateMatch.unwanted;

            e.comp_report = dateMatch;
          }
        }
      });
      set_comp_report_state(true);
    };

    const build_quadrent_dataset = () => {
      let lebr_hotels = [];
      let lebr_floor_prices_arr = [];

      let reqHotelData = reqHotelName 
      ? hotels.find((o) => reqHotelName.localeCompare(o.hotelName) === 0)
      : {};

      [...Array(report_len).keys()].map((obj, index) => {
        let lebr = [];

        let all_hotels = [];

        let lebr_floor_price =
          reqHotelData.prices[index] != null &&
          reqHotelData.prices[index] != undefined
            ? reqHotelData.prices[index].price[
                getPrice(reqHotelData.prices[index].price)
              ]
            : 'N/A';

        hotels.map((_hotel) => {
          if (
            _hotel.prices[index] != null &&
            _hotel.prices[index] !== undefined &&
            reqHotelData.prices[index] != null &&
            reqHotelData.prices[index] != undefined
          ) {
            let cluster_dt =
              getClusterByPrice(
                _hotel.prices[index].price[
                  getPrice(_hotel.prices[index].price)
                ],
                index
              ) + 2;

            let reqHotelCluster =
              getClusterByPrice(
                reqHotelData.prices[index].price[
                  getPrice(reqHotelData.prices[index].price)
                ],
                index
              ) + 2;

            const reqHotelFrqBucketMinMax = getFreqBucketMinMaxRatings(
              reqHotelCluster - 2,
              index
            );

            const FrqBucketMinMax = getFreqBucketMinMaxRatings(
              cluster_dt - 2,
              index
            );

            if (FrqBucketMinMax.min > 0 && FrqBucketMinMax.max > 0) {
              let frq_rating = Number(
                Math.abs(_hotel.ratings - cluster_dt) /
                  (FrqBucketMinMax.max - FrqBucketMinMax.min) +
                  cluster_dt
              ).toFixed(2);

              let reqHotel_frq_rating = Number(
                Math.abs(reqHotelData.ratings - reqHotelCluster) /
                  (reqHotelFrqBucketMinMax.max - reqHotelFrqBucketMinMax.min) +
                  reqHotelCluster
              ).toFixed(2);

              let x = (frq_rating - reqHotel_frq_rating).toFixed(2);
              let y =
                _hotel.prices[index].price[
                  getPrice(_hotel.prices[index].price)
                ] -
                reqHotelData.prices[index].price[
                  getPrice(reqHotelData.prices[index].price)
                ];

              all_hotels.push({ x: x, y: y });

              if (x == 0 && y == 0) {
              } else if (x < 0 && y < 0) {
              } else if (x <= 0 && y >= 0) {
              } else if (x > 0 && y > 0) {
              } else if (x >= 0 && y <= 0) {
                lebr.push(_hotel.hotelName);
              }
            }
          }
        });

        if (
          reqHotelData.prices[index] != null &&
          reqHotelData.prices[index] != undefined
        ) {
          const max_price_difference_hotels = all_hotels
            .filter((item) => item.x >= 0 && item.y <= 0)
            .sort((a, b) => b.x - a.x || a.y - b.y);

          if (max_price_difference_hotels.length > 0) {
            lebr_floor_price =
              reqHotelData.prices[index].price[
                getPrice(reqHotelData.prices[index].price)
              ] - Math.abs(max_price_difference_hotels[0].y);
          }
        }

        lebr_floor_prices_arr.push(lebr_floor_price);
        lebr_hotels.push(lebr.length);
      });

      set_zero_lebr_floor_prices(lebr_floor_prices_arr);

      set_lebr_avg(getAvg(lebr_hotels.map((obj) => obj)));
      set_lebr_stdev(getStandardDeviation(lebr_hotels.map((obj) => obj)));

      set_lebr_hotels(lebr_hotels);

      set_quadrant_data_state(true);
    };

    const NetBucketMovements = () => {
      let stars5down_hotels = [];
      let stars4up_hotels = [];
      let stars4down_hotels = [];
      let stars3up_hotels = [];
      let stars3down_hotels = [];
      let stars2up_hotels = [];
      let cluster_1_index = [];
      let cluster_2_index = [];
      let cluster_3_index = [];
      let cluster_4_index = [];

      [...Array(report_len).keys()].map((e, index) => {
        let hotel_count_5_down = 0;
        let hotel_count_4_up = 0;
        let hotel_count_4_down = 0;
        let hotel_count_3_up = 0;
        let hotel_count_3_down = 0;
        let hotel_count_2_up = 0;

        let star_2_hotels = 0;
        let star_3_hotels = 0;
        let star_4_hotels = 0;
        let star_5_hotels = 0;

        let cluster_1_hotels = 0;
        let cluster_2_hotels = 0;
        let cluster_3_hotels = 0;
        let cluster_4_hotels = 0;

        let comp_star_2_hotels = 0;
        let comp_star_3_hotels = 0;
        let comp_star_4_hotels = 0;
        let comp_star_5_hotels = 0;

        let comp_cluster_1_hotels = 0;
        let comp_cluster_2_hotels = 0;
        let comp_cluster_3_hotels = 0;
        let comp_cluster_4_hotels = 0;

        try {
          if (cluster1.length > 0 && cluster1[index]) {
            hotel_count_5_down =
              hotel_count_5_down + cluster1[index].stars5.length;
            hotel_count_4_down =
              hotel_count_4_down + cluster1[index].stars4.length;

            hotel_count_3_down =
              hotel_count_3_down + cluster1[index].stars3.length;

            cluster_1_hotels = cluster1[index].unwanted.length;

            star_2_hotels = star_2_hotels + cluster1[index].stars2.length;

            star_3_hotels = star_3_hotels + cluster1[index].stars3.length;

            star_4_hotels = star_4_hotels + cluster1[index].stars4.length;

            star_5_hotels = star_5_hotels + cluster1[index].stars5.length;

            if (cluster1[index].comp_report) {
              comp_cluster_1_hotels = cluster1[index].comp_report.items;

              comp_star_2_hotels =
                comp_star_2_hotels + cluster1[index].comp_report.stars2.length;

              comp_star_3_hotels =
                comp_star_3_hotels + cluster1[index].comp_report.stars3.length;

              comp_star_4_hotels =
                comp_star_4_hotels + cluster1[index].comp_report.stars4.length;

              comp_star_5_hotels =
                comp_star_5_hotels + cluster1[index].comp_report.stars5.length;
            }
          }
        } catch (e) {}

        try {
          if (cluster2.length > 0 && cluster2[index]) {
            hotel_count_5_down =
              hotel_count_5_down + cluster2[index].stars5.length;

            hotel_count_4_down =
              hotel_count_4_down + cluster2[index].stars4.length;

            hotel_count_2_up = hotel_count_2_up + cluster2[index].stars2.length;

            cluster_2_hotels = cluster2[index].unwanted.length;

            star_2_hotels = star_2_hotels + cluster2[index].stars2.length;

            star_3_hotels = star_3_hotels + cluster2[index].stars3.length;

            star_4_hotels = star_4_hotels + cluster2[index].stars4.length;

            star_5_hotels = star_5_hotels + cluster2[index].stars5.length;

            if (cluster2[index].comp_report) {
              comp_cluster_2_hotels = cluster2[index].comp_report.items;

              comp_star_2_hotels =
                comp_star_2_hotels + cluster2[index].comp_report.stars2.length;

              comp_star_3_hotels =
                comp_star_3_hotels + cluster2[index].comp_report.stars3.length;

              comp_star_4_hotels =
                comp_star_4_hotels + cluster2[index].comp_report.stars4.length;

              comp_star_5_hotels =
                comp_star_5_hotels + cluster2[index].comp_report.stars5.length;
            }
          }
        } catch (e) {}

        try {
          if (cluster3.length > 0 && cluster3[index]) {
            hotel_count_5_down =
              hotel_count_5_down + cluster3[index].stars5.length;

            hotel_count_3_up = hotel_count_3_up + cluster3[index].stars3.length;

            hotel_count_2_up = hotel_count_2_up + cluster3[index].stars2.length;

            cluster_3_hotels = cluster3[index].unwanted.length;

            star_2_hotels = star_2_hotels + cluster3[index].stars2.length;

            star_3_hotels = star_3_hotels + cluster3[index].stars3.length;

            star_4_hotels = star_4_hotels + cluster3[index].stars4.length;

            star_5_hotels = star_5_hotels + cluster3[index].stars5.length;

            if (cluster3[index].comp_report) {
              comp_cluster_3_hotels = cluster3[index].comp_report.items;

              comp_star_2_hotels =
                comp_star_2_hotels + cluster3[index].comp_report.stars2.length;

              comp_star_3_hotels =
                comp_star_3_hotels + cluster3[index].comp_report.stars3.length;

              comp_star_4_hotels =
                comp_star_4_hotels + cluster3[index].comp_report.stars4.length;

              comp_star_5_hotels =
                comp_star_5_hotels + cluster3[index].comp_report.stars5.length;
            }
          }
        } catch (e) {}

        try {
          if (cluster4.length > 0 && cluster4[index]) {
            hotel_count_4_up = hotel_count_4_up + cluster4[index].stars4.length;

            hotel_count_3_up = hotel_count_3_up + cluster4[index].stars3.length;

            hotel_count_2_up = hotel_count_2_up + cluster4[index].stars2.length;

            cluster_4_hotels =
              cluster_4_hotels + cluster4[index].unwanted.length;

            star_2_hotels = star_2_hotels + cluster4[index].stars2.length;

            star_3_hotels = star_3_hotels + cluster4[index].stars3.length;

            star_4_hotels = star_4_hotels + cluster4[index].stars4.length;

            star_5_hotels = star_5_hotels + cluster4[index].stars5.length;

            if (cluster4[index].comp_report) {
              comp_cluster_4_hotels = cluster4[index].comp_report.items;

              comp_star_2_hotels =
                comp_star_2_hotels + cluster4[index].comp_report.stars2.length;

              comp_star_3_hotels =
                comp_star_3_hotels + cluster4[index].comp_report.stars3.length;

              comp_star_4_hotels =
                comp_star_4_hotels + cluster4[index].comp_report.stars4.length;

              comp_star_5_hotels =
                comp_star_5_hotels + cluster4[index].comp_report.stars5.length;
            }
          }
        } catch (e) {}

        stars5down_hotels.push(hotel_count_5_down);

        stars4up_hotels.push(hotel_count_4_up);
        stars4down_hotels.push(hotel_count_4_down);

        stars3up_hotels.push(hotel_count_3_up);
        stars3down_hotels.push(hotel_count_3_down);

        stars2up_hotels.push(hotel_count_2_up);

        cluster_1_index.push({
          bucket_index:
            star_2_hotels > 0
              ? Math.round(
                  (cluster_1_hotels /
                    (star_2_hotels +
                      star_3_hotels +
                      star_4_hotels +
                      star_5_hotels)) *
                    100
                )
              : 0,
          comp_bucket_index:
            comp_star_2_hotels > 0
              ? Math.round(
                  (comp_cluster_1_hotels /
                    (comp_star_2_hotels +
                      comp_star_3_hotels +
                      comp_star_4_hotels +
                      comp_star_5_hotels)) *
                    100
                )
              : 0,
        });

        cluster_2_index.push({
          bucket_index:
            star_3_hotels > 0
              ? Math.round(
                  (cluster_2_hotels /
                    (star_2_hotels +
                      star_3_hotels +
                      star_4_hotels +
                      star_5_hotels)) *
                    100
                )
              : 0,
          comp_bucket_index:
            comp_star_3_hotels > 0
              ? Math.round(
                  (comp_cluster_2_hotels /
                    (comp_star_2_hotels +
                      comp_star_3_hotels +
                      comp_star_4_hotels +
                      comp_star_5_hotels)) *
                    100
                )
              : 0,
        });

        cluster_3_index.push({
          bucket_index:
            star_4_hotels > 0
              ? Math.round(
                  (cluster_3_hotels /
                    (star_2_hotels +
                      star_3_hotels +
                      star_4_hotels +
                      star_5_hotels)) *
                    100
                )
              : 0,
          comp_bucket_index:
            comp_star_4_hotels > 0
              ? Math.round(
                  (comp_cluster_3_hotels /
                    (comp_star_2_hotels +
                      comp_star_3_hotels +
                      comp_star_4_hotels +
                      comp_star_5_hotels)) *
                    100
                )
              : 0,
        });

        cluster_4_index.push({
          bucket_index:
            star_5_hotels > 0
              ? Math.round(
                  (cluster_4_hotels /
                    (star_2_hotels +
                      star_3_hotels +
                      star_4_hotels +
                      star_5_hotels)) *
                    100
                )
              : 0,
          comp_bucket_index:
            comp_star_5_hotels > 0
              ? Math.round(
                  (comp_cluster_4_hotels /
                    (comp_star_2_hotels +
                      comp_star_3_hotels +
                      comp_star_4_hotels +
                      comp_star_5_hotels)) *
                    100
                )
              : 0,
        });
      });

      cluster_1_index.map((obj) => {
        obj.variance = getVarience_index_movement(
          obj.bucket_index - obj.comp_bucket_index,
          getAvg(
            cluster_1_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          ),
          getStandardDeviation(
            cluster_1_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          )
        );
        obj.bucket_size_exception = getVarience_index_movement(
          obj.bucket_index,
          getAvg(cluster_1_index.map((obj) => obj.bucket_index)),
          getStandardDeviation(cluster_1_index.map((obj) => obj.bucket_index))
        );
      });

      cluster_2_index.map((obj) => {
        obj.variance = getVarience_index_movement(
          obj.bucket_index - obj.comp_bucket_index,
          getAvg(
            cluster_2_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          ),
          getStandardDeviation(
            cluster_2_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          )
        );
        obj.bucket_size_exception = getVarience_index_movement(
          obj.bucket_index,
          getAvg(cluster_2_index.map((obj) => obj.bucket_index)),
          getStandardDeviation(cluster_2_index.map((obj) => obj.bucket_index))
        );
      });

      cluster_3_index.map((obj) => {
        obj.variance = getVarience_index_movement(
          obj.bucket_index - obj.comp_bucket_index,
          getAvg(
            cluster_3_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          ),
          getStandardDeviation(
            cluster_3_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          )
        );
        obj.bucket_size_exception = getVarience_index_movement(
          obj.bucket_index,
          getAvg(cluster_3_index.map((obj) => obj.bucket_index)),
          getStandardDeviation(cluster_3_index.map((obj) => obj.bucket_index))
        );
      });

      cluster_4_index.map((obj) => {
        obj.variance = getVarience_index_movement(
          obj.bucket_index - obj.comp_bucket_index,
          getAvg(
            cluster_4_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          ),
          getStandardDeviation(
            cluster_4_index.map(
              (obj) => obj.bucket_index - obj.comp_bucket_index
            )
          )
        );
        obj.bucket_size_exception = getVarience_index_movement(
          obj.bucket_index,
          getAvg(cluster_4_index.map((obj) => obj.bucket_index)),
          getStandardDeviation(cluster_4_index.map((obj) => obj.bucket_index))
        );
      });

      set_cluster1_bucket_index(cluster_1_index);
      set_cluster2_bucket_index(cluster_2_index);
      set_cluster3_bucket_index(cluster_3_index);
      set_cluster4_bucket_index(cluster_4_index);

      stars5down_hotels.map((e) => {
        stars5down.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars5down_hotels),
            getStandardDeviation(stars5down_hotels)
          ),
        });
      });

      stars4up_hotels.map((e) => {
        stars4up.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars4up_hotels),
            getStandardDeviation(stars4up_hotels)
          ),
        });
      });
      stars4down_hotels.map((e) => {
        stars4down.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars4down_hotels),
            getStandardDeviation(stars4down_hotels)
          ),
        });
      });

      stars3up_hotels.map((e) => {
        stars3up.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars3up_hotels),
            getStandardDeviation(stars3up_hotels)
          ),
        });
      });
      stars3down_hotels.map((e) => {
        stars3down.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars3down_hotels),
            getStandardDeviation(stars3down_hotels)
          ),
        });
      });

      stars2up_hotels.map((e) => {
        stars2up.push({
          no_hotels: e,
          variance: getVarience(
            e,
            getAvg(stars2up_hotels),
            getStandardDeviation(stars2up_hotels)
          ),
        });
      });

      set_net_bucket_data_state(true);
    };

    const build_insights = async () => {
      if (comparison_report && clusterData.length > 0 && hotels.length > 0) {
        setLoad(true);

        CompareReport();

        if (comp_report_state) {
          await build_quadrent_dataset();
          await NetBucketMovements();
        }
        dispatch(LoadingOverlay(false));
        setLoad(false);
      }
    };

    build_insights();
  }, [comparison_report, clusterData, comp_report_state, hotels]);

  useEffect(() => {
    const filterBestRatedHotels = () => {
      let filtered_hotels = [];
      if (hotels.length > 0) {
        hotels.map((_hotel) => {
          if (_hotel.ratings >= ratingCluster.min_rating) {
            filtered_hotels.push(_hotel);
          }
        });
      }

      setBestRatedHotels(filtered_hotels);
    };

    if (hotels.length > 0 && ratingCluster) {
      filterBestRatedHotels();
    }
  }, [hotels, ratingCluster]);

  useEffect(() => {
    const rateStrengthHandler = () => {
      setLoad(true);
      let midAvgArr_wd = [];
      let midAvgArr_we = [];
      let mid_avg_rates = [];

      selectedCluster.map((e, i) => {
        if (e.comp_report) {
          if (i <= 90) {
            if (e.comp_report.midAVG != 'NaN') {
              mid_avg_rates.push(
                Math.abs(
                  ((e.midAVG - e.comp_report.midAVG) / e.comp_report.midAVG) *
                    100
                )
              );
            }
          }
          e.rate_movements = checkRatebucketMovement(
            ((e.highAVG - e.comp_report.highAVG) / e.comp_report.highAVG) *
              100 >
              0,
            ((e.midAVG - e.comp_report.midAVG) / e.comp_report.midAVG) * 100 >
              0,
            ((e.lowAVG - e.comp_report.lowAVG) / e.comp_report.lowAVG) * 100 > 0
          );
        }

        if (e.midAVG != 'NaN') {
          let _date = moment(selectedDate, 'YYYY-MM-DD').add(i, 'days');
          let day = _date.format('dddd').substring(0, 3);

          if (day == 'Sat' || day == 'Fri') {
            midAvgArr_we.push(Math.round(e.midAVG));
          } else {
            midAvgArr_wd.push(Math.round(e.midAVG));
          }
        }
      });

      setRateVarianceStrength_stdev(getStandardDeviation(mid_avg_rates));
      setRateVarianceStrength_avg(getAvg(mid_avg_rates));

      const sd_wd = getStandardDeviation(midAvgArr_wd);
      const sd_we = getStandardDeviation(midAvgArr_we);

      const avg_wd = midAvgArr_wd.reduce((a, b) => a + b) / midAvgArr_wd.length;
      const avg_we = midAvgArr_we.reduce((a, b) => a + b) / midAvgArr_we.length;

      let _rateStrength = [];

      selectedCluster.map((e, index) => {
        let _date = moment(selectedDate, 'YYYY-MM-DD').add(index, 'days');
        let day = _date.format('dddd').substring(0, 3);

        if (day == 'Sat' || day == 'Fri') {
          if (e.midAVG >= avg_we + 1.65 * sd_we) {
            e.rateStrength = 'Very High';
            _rateStrength.push('Very High');
          }
          if (
            e.midAVG >= avg_we + 1 * sd_we &&
            e.midAVG < avg_we + 1.65 * sd_we
          ) {
            e.rateStrength = 'High';
            _rateStrength.push('High');
          }
          if (e.midAVG >= avg_we - 1 * sd_we && e.midAVG < avg_we + 1 * sd_we) {
            e.rateStrength = '';
            _rateStrength.push('');
          }
          if (
            e.midAVG >= avg_we - 1.65 * sd_we &&
            e.midAVG < avg_we - 1 * sd_we
          ) {
            e.rateStrength = 'Low';
            _rateStrength.push('Low');
          }
          if (e.midAVG <= avg_we - 1.65 * sd_we) {
            e.rateStrength = 'Very Low';
            _rateStrength.push('Very Low');
          }
        } else {
          if (e.midAVG >= avg_wd + 1.65 * sd_wd) {
            e.rateStrength = 'Very High';
            _rateStrength.push('Very High');
          }
          if (
            e.midAVG >= avg_wd + 1 * sd_wd &&
            e.midAVG < avg_wd + 1.65 * sd_wd
          ) {
            e.rateStrength = 'High';
            _rateStrength.push('High');
          }
          if (e.midAVG >= avg_wd - 1 * sd_wd && e.midAVG < avg_wd + 1 * sd_wd) {
            e.rateStrength = '';
            _rateStrength.push('');
          }
          if (
            e.midAVG >= avg_wd - 1.65 * sd_wd &&
            e.midAVG < avg_wd - 1 * sd_wd
          ) {
            e.rateStrength = 'Low';
            _rateStrength.push('Low');
          }
          if (e.midAVG <= avg_wd - 1.65 * sd_wd) {
            e.rateStrength = 'Very Low';
            _rateStrength.push('Very Low');
          }
        }
      });

      setRateStrength(_rateStrength);

      setLoad(false);
    };

    const GetHotelsCount = (cluster) => {
      setLoad(true);
      let show_hotels_wd = [];
      let show_hotels_we = [];
      if (hotels.length > 0) {
        [...Array(100).keys()].map((n, dt) => {
          let showing_hotels = [];

          let day = moment(selectedDate)
            .add(dt, 'days')
            .format('dddd')
            .substring(0, 3);

          hotels.map((_hotel) => {
            if (checkHotelAvailability(_hotel.hotelID, dt)) {
              if (_hotel.prices[dt]) {
                if (
                  cluster ==
                  getClusterByPrice(
                    _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
                    dt
                  )
                ) {
                  showing_hotels.push({
                    hotelID: _hotel.hotelID,
                    hotelName: _hotel.hotelName,
                    prices: _hotel.prices[dt].price,
                    price:
                      _hotel.prices[dt].price[
                        getPrice(_hotel.prices[dt].price)
                      ],
                    nights: getPrice(_hotel.prices[dt].price),
                  });
                }
              }
            }
          });

          if (day === 'Sat' || day === 'Fri') {
            show_hotels_we.push(showing_hotels.length);
          } else {
            show_hotels_wd.push(showing_hotels.length);
          }
        });
      }

      if (show_hotels_wd.length > 0) {
        const _avg_wd =
          show_hotels_wd.reduce((a, b) => a + b) / show_hotels_wd.length;
        const _stdev_wd = getStandardDeviation(show_hotels_wd);

        setAvg_wd(_avg_wd);
        setStdev_wd(_stdev_wd);
      }

      if (show_hotels_we.length > 0) {
        const _avg_we =
          show_hotels_we.reduce((a, b) => a + b) / show_hotels_we.length;
        const _stdev_we = getStandardDeviation(show_hotels_we);

        setAvg_we(_avg_we);
        setStdev_we(_stdev_we);
      }
      setLoad(false);
    };

    if (
      selectedCluster.length > 0 &&
      reqHotel.length > 0 &&
      comp_report_state &&
      selectedCluster_idx >= 0
    ) {
      rateStrengthHandler();
      GetHotelsCount(selectedCluster_idx);
    }
  }, [selectedCluster]);

  const getPriceByDayRank = (day, rank) => {
    let hotels_list = [];

    try {
      if (cluster1[day]) {
        hotels_list = hotels_list.concat(cluster1[day].unwanted);
      }
    } catch (e) {}
    try {
      if (cluster2[day]) {
        hotels_list = hotels_list.concat(cluster2[day].unwanted);
      }
    } catch (e) {}
    try {
      if (cluster3[day]) {
        hotels_list = hotels_list.concat(cluster3[day].unwanted);
      }
    } catch (e) {}
    try {
      if (cluster4[day]) {
        hotels_list = hotels_list.concat(cluster4[day].unwanted);
      }
    } catch (e) {}

    hotels_list = hotels_list.sort(
      (a, b) => b.rate - a.rate || b.raings - a.raings
    );

    const rank_hotel = hotels_list[rank - 1];

    if (rank_hotel) {
      return rank_hotel.rate;
    } else {
      return '';
    }
  };

  useEffect(() => {
    // console.log(`selectedDate: ${selectedDate}`);

    const CalculateHotelRanks = async () => {
      setLoad(true);
      let hotelList = [...hotels]
      let ranked_hotels_list = [];
      if (hotelList.length > 0) {
         [...Array(report_len).keys()].map((e, index) => {
          let hotel_rates_by_day = [];
          hotelList.map((_hotel) => {
            let dt = _hotel.prices[index];

            try {
              if (
                dt !== null &&
                checkHotelAvailability(_hotel.hotelID, index)
              ) {
                hotel_rates_by_day.push({
                  hotel_id: _hotel.hotelID,
                  rate: dt.price[getPrice(dt.price)],
                  date: dt.date,
                  ratings: _hotel.ratings,
                });
              }
            } catch (error) {}
          });

          const ranked_hotels = getRankedHotels(hotel_rates_by_day);

          ranked_hotels_list.push(ranked_hotels);

          hotelList.map((_hotel) => {
            let dt = _hotel.prices[index];

            try {
              if (
                dt !== null &&
                checkHotelAvailability(_hotel.hotelID, index)
              ) {
                let obj =
                  ranked_hotels.findIndex((e) => e.hotel_id == _hotel.hotelID) +
                  1;
                if (obj > 0) {
                  dt.day_rank = obj;
                  dt.total = ranked_hotels.length;
                }
              }
            } catch (error) {}
          });
        });

        const req_hotel_data = hotelList.find((e) => e.hotelName == reqHotelName);

        if (req_hotel_data != undefined) {
          
          let ranks_arr_wd = [];
          let ranks_arr_we = [];
          let ranks_arr_w = [];
          req_hotel_data.prices.map((dt, ix) => {

            if (dt !== null) {
              if (checkHotelAvailability(req_hotel_data.hotelID, ix)) {
                ranks_arr_w.push(dt.day_rank);
                if (moment(dt.date).day() == 5 || moment(dt.date).day() == 6) {
                  ranks_arr_we.push(dt.day_rank);
                } else {
                  ranks_arr_wd.push(dt.day_rank);
                }
              }
            }
          });

          let avg_rank_wd = getAvg(ranks_arr_wd);
          let avg_rank_we = getAvg(ranks_arr_we);
          let avg_rank = getAvg(ranks_arr_w);
          let stdev_wd = getStandardDeviation(ranks_arr_wd);
          let stdev_we = getStandardDeviation(ranks_arr_we);

          req_hotel_data.avg_rank_wd = avg_rank_wd;
          req_hotel_data.avg_rank_we = avg_rank_we;
          req_hotel_data.avg_rank = avg_rank;
          req_hotel_data.stdev_wd = stdev_wd;
          req_hotel_data.stdev_we = stdev_we;

          req_hotel_data.upper_bound_wd = Math.ceil(avg_rank_wd - 2 * stdev_wd);
          req_hotel_data.lower_bound_wd = Math.ceil(avg_rank_wd + 2 * stdev_wd);
          req_hotel_data.upper_start_wd = Math.ceil(avg_rank_wd - 1 * stdev_wd);
          req_hotel_data.lower_start_wd = Math.ceil(avg_rank_wd + 1 * stdev_wd);

          req_hotel_data.upper_bound_we = Math.ceil(avg_rank_we - 2 * stdev_we);
          req_hotel_data.lower_bound_we = Math.ceil(avg_rank_we + 2 * stdev_we);
          req_hotel_data.upper_start_we = Math.ceil(avg_rank_we - 1 * stdev_we);
          req_hotel_data.lower_start_we = Math.ceil(avg_rank_we + 1 * stdev_we);
        

          req_hotel_data.prices.map((dt, ix) => {
            if (dt !== null) {
              let day = moment(dt.date).format('dddd').substring(0, 3);

              if (
                Object.prototype.toString.call(ranked_hotels_list[ix]) ===
                '[object Array]'
              ) {
                if (day === 'Sat' || day === 'Fri') {
                  dt.upper_bound_rate = ranked_hotels_list[ix].find(
                    (obj, i) => obj.day_rank == req_hotel_data.upper_bound_we
                  );

                  dt.upper_start_rate = ranked_hotels_list[ix].find(
                    (obj, i) => obj.day_rank == req_hotel_data.upper_start_we
                  );

                  dt.lower_bound_rate = ranked_hotels_list[ix].find(
                    (obj, i) => obj.day_rank == req_hotel_data.lower_bound_we
                  );

                  dt.lower_start_rate = ranked_hotels_list[ix].find(
                    (obj, i) => obj.day_rank == req_hotel_data.lower_start_we
                  );

                  if (dt.upper_bound_rate == undefined) {
                    dt.upper_bound_rate = ranked_hotels_list[ix][0];
                  }

                  if (dt.lower_bound_rate == undefined) {
                    dt.lower_bound_rate =
                      ranked_hotels_list[ix][ranked_hotels_list[ix].length - 1];
                  }
                } else {
                  dt.upper_bound_rate = ranked_hotels_list[ix].find(
                    (obj, i) => obj.day_rank == req_hotel_data.upper_bound_wd
                  );
                  dt.upper_start_rate = ranked_hotels_list[ix].find(
                    (obj, i) => obj.day_rank == req_hotel_data.upper_start_wd
                  );
                  dt.lower_bound_rate = ranked_hotels_list[ix].find(
                    (obj, i) => obj.day_rank == req_hotel_data.lower_bound_wd
                  );
                  dt.lower_start_rate = ranked_hotels_list[ix].find(
                    (obj, i) => obj.day_rank == req_hotel_data.lower_start_wd
                  );

                  if (dt.upper_bound_rate == undefined) {
                    dt.upper_bound_rate = ranked_hotels_list[ix][0];
                  }

                  if (dt.lower_bound_rate == undefined) {
                    dt.lower_bound_rate =
                      ranked_hotels_list[ix][ranked_hotels_list[ix].length - 1];
                  }
                }
              }

              if (
                dt.lower_bound_rate != undefined &&
                dt.upper_bound_rate != undefined &&
                dt.upper_start_rate &&
                dt.lower_start_rate
              ) {
                if (
                  dt.price[getPrice(dt.price)] >= dt.lower_bound_rate.rate &&
                  dt.price[getPrice(dt.price)] < dt.lower_start_rate.rate
                ) {
                  dt.qr = 'LOW';
                } else if (
                  dt.price[getPrice(dt.price)] >= dt.lower_start_rate.rate &&
                  dt.price[getPrice(dt.price)] < dt.upper_start_rate.rate
                ) {
                  dt.qr = 'MID';
                } else if (
                  dt.price[getPrice(dt.price)] >= dt.upper_start_rate.rate &&
                  dt.price[getPrice(dt.price)] <= dt.upper_bound_rate.rate
                ) {
                  dt.qr = 'HIGH';
                } else if (
                  dt.price[getPrice(dt.price)] < dt.lower_bound_rate.rate
                ) {
                  dt.qr = 'BELOW';
                } else if (
                  dt.price[getPrice(dt.price)] > dt.upper_bound_rate.rate
                ) {
                  dt.qr = 'OVER';
                }
              }
            }
          });
          
          
          let cluster_arr_wd = [];
          let cluster_arr_we = [];
          let day_ranks_wd = [];
          let day_ranks_we = [];
          let qr_wd = [];
          let qr_we = [];

          req_hotel_data.prices.map((dt, ix) => {
            if (dt !== null) {
              if (moment(dt.date).day() == 5 || moment(dt.date).day() == 6) {
                qr_we.push(dt.qr);
                cluster_arr_we.push(
                  getClusterByPrice(dt.price[getPrice(dt.price)], ix) + 2
                );
                day_ranks_we.push(dt.day_rank);
              } else {
                qr_wd.push(dt.qr);
                cluster_arr_wd.push(
                  getClusterByPrice(dt.price[getPrice(dt.price)], ix) + 2
                );
                day_ranks_wd.push(dt.day_rank);
              }
            }
          });

          req_hotel_data.freq_bucket_wd = mode(cluster_arr_wd);
          req_hotel_data.freq_bucket_we = mode(cluster_arr_we);
          const avg_day_rank_wd = getAvg(day_ranks_wd);
          req_hotel_data.avg_day_rank_wd = Math.round(avg_day_rank_wd);

          const avg_day_rank_we = getAvg(day_ranks_we);
          req_hotel_data.avg_day_rank_we = Math.round(avg_day_rank_we);

          req_hotel_data.freq_qr_wd = mode(qr_wd);
          req_hotel_data.freq_qr_we = mode(qr_we);

          req_hotel_data.prices.map((dt, ix) => {
            if (dt !== null) {
              if (moment(dt.date).day() == 5 || moment(dt.date).day() == 6) {
                dt.avarage_rate = getPriceByDayRank(
                  ix,
                  Math.round(avg_day_rank_we)
                );
              } else {
                dt.avarage_rate = getPriceByDayRank(
                  ix,
                  Math.round(avg_day_rank_wd)
                );
              }
            }
          });
        }

        setReqHotelStrategyZone(req_hotel_data);
        // console.log(req_hotel_data);
      }
      setLoad(false);
    };

    CalculateHotelRanks();
  }, [hotels]);

  const getClusterByPrice = (rate, ix) => {
    let clustered = [];
    let res = 4;

    if (cluster1.length > 0 && cluster1[ix]) {
      clustered.push(cluster1[ix]);
    }
    if (cluster2.length > 0 && cluster2[ix]) {
      clustered.push(cluster2[ix]);
    }
    if (cluster3.length > 0 && cluster3[ix]) {
      clustered.push(cluster3[ix]);
    }
    if (cluster4.length > 0 && cluster4[ix]) {
      clustered.push(cluster4[ix]);
    }

    clustered.sort((a, b) => a.mean - b.mean);

    // console.log(clustered);

    try {
      clustered.map((cl, id) => {
        if (rate >= cl.min && rate <= cl.max) {
          res = id;
          return;
        }
      });
    } catch (e) {}

    return res;
  };

  const getPrice = (arr) => {
    const price = arr.findIndex((e) => e > 0);
    return price;
  };

  const checkHotelAvailability = (id, day) => {
    let clustered = [];

    if (cluster1.length > 0 && cluster1[day]) {
      clustered.push(cluster1[day].unwanted);
    }
    if (cluster2.length > 0 && cluster2[day]) {
      clustered.push(cluster2[day].unwanted);
    }
    if (cluster3.length > 0 && cluster3[day]) {
      clustered.push(cluster3[day].unwanted);
    }
    if (cluster4.length > 0 && cluster4[day]) {
      clustered.push(cluster4[day].unwanted);
    }
    let hotels_arr = [];

    for (var i = 0; i < clustered.length; i++) {
      hotels_arr = hotels_arr.concat(clustered[i]);
    }

    const exists = hotels_arr.some((obj) => obj.id == id);

    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  const getStandardDeviation = (array) => {
    const n = array.length;
    if (n > 0) {
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      );
    } else {
      return -1;
    }
  };

  const getRankedHotels = (arr) => {
    var sorted = arr
      .filter((e) => e.rate != 'NaN')
      .sort((a, b) => b.rate - a.rate || b.ratings - a.ratings);

    var rank = 1;
    for (var i = 0; i < sorted.length; i++) {
      sorted[i].day_rank = i + 1;
    }

    // console.log(sorted);

    return sorted;
  };

  function mode(array) {
    if (array.length == 0) return null;
    var modeMap = {};
    var maxEl = array[0],
      maxCount = 1;
    for (var i = 0; i < array.length; i++) {
      var el = array[i];
      if (modeMap[el] == null) modeMap[el] = 1;
      else modeMap[el]++;
      if (modeMap[el] > maxCount) {
        maxEl = el;
        maxCount = modeMap[el];
      }
    }
    return maxEl;
  }

  const getAvg = (arr) => {
    let array = arr.filter((n) => n == 0 || n);
    if (array.length > 0) {
      let avg = array.reduce((a, b) => a + b) / array.length;
      return avg;
    } else {
      return -1;
    }
  };

  const getReqHotelName = () => {
    let name = null;
    if (reqHotel.length > 0) {
      reqHotel.map((e, index) => {
        if (e.name !== null) {
          name = e.name;
        }
      });
    }

    return name;
  };

  const getVarience = (value, avg, stdev) => {
    if (avg <= 0 || stdev <= 0 || value <= 0) {
      return '';
    } else if (value >= avg + stdev) {
      return 'High';
    } else {
      return '';
    }
  };

  const getFreqBucketMinMaxRatings = (bucket, dt) => {
    // console.log(`dt: ${dt}, bucket: ${bucket}`);
    let min = -1;
    let max = -1;

    let hotelsArr = [];

    if (clusterData.length > 0) {
      if (clusterData[dt][bucket]) {
        if (clusterData[dt][bucket].unwanted.length > 0) {
          hotelsArr = [...hotelsArr, ...clusterData[dt][bucket].unwanted];
        }
      }
    }

    min = Math.min.apply(
      null,
      hotelsArr.map((item) => item.raings)
    );
    max = Math.max.apply(
      null,
      hotelsArr.map((item) => item.raings)
    );
    return { min: min, max: max };
  };

  const getHotelCountByNights = (cluster, dt, nights) => {
    let nights_hotels = [];
    if (hotels.length > 0) {
      hotels.map((_hotel) => {
        if (checkHotelAvailability(_hotel.hotelID, dt)) {
          if (_hotel.prices[dt]) {
            if (
              cluster ==
              getClusterByPrice(
                _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
                dt
              ) +
                2
            ) {
              if (getPrice(_hotel.prices[dt].price) == nights) {
                nights_hotels.push({
                  hotelID: _hotel.hotelID,
                  hotelName: _hotel.hotelName,
                  prices: _hotel.prices[dt].price,
                  price:
                    _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
                  nights: getPrice(_hotel.prices[dt].price),
                  cluster:
                    getClusterByPrice(
                      _hotel.prices[dt].price[
                        getPrice(_hotel.prices[dt].price)
                      ],
                      dt
                    ) + 2,
                });
              }
            }
          }
        }
      });
    }

    return nights_hotels.length;
  };

  const GetMLOSUsage = ({ cluster, cluster_idx, sub_tab }) => {
    const [avg_wd, setAvg_wd] = useState(-1);
    const [stdev_wd, setStdev_wd] = useState(-1);

    const [avg_we, setAvg_we] = useState(-1);
    const [stdev_we, setStdev_we] = useState(-1);

    const [load, setLoad] = useState(true);

    const getNightVarience = (cluster_idx, dt) => {
      let varience = '';
      let day = moment(selectedDate)
        .add(dt, 'days')
        .format('dddd')
        .substring(0, 3);

      let nights_count_2 = getHotelCountByNights(cluster_idx, dt, 1);
      let nights_count_3 = getHotelCountByNights(cluster_idx, dt, 2);

      let perc = nights_count_2 + nights_count_3;

      if (perc > 0) {
        if (avg_wd > -1 && stdev_wd > -1 && avg_we > -1 && stdev_we > -1) {
          if (day === 'Sat' || day === 'Fri') {
            if (perc >= avg_we + stdev_we * 1.5) {
              varience = 'Very High';
            } else if (perc >= avg_we + stdev_we) {
              varience = 'High';
            }
          } else {
            if (perc >= avg_wd + stdev_wd * 1.5) {
              varience = 'Very High';
            } else if (perc >= avg_wd + stdev_wd) {
              varience = 'High';
            }
          }
        }
      }

      return varience;
    };

    useEffect(() => {
      const MinimumNightsVarience = (cluster) => {
        setLoad(true);
        let los_percentages_wd = [];
        let los_percentages_we = [];
        if (hotels.length > 0) {
          [...Array(100).keys()].map((n, dt) => {
            let nights_hotels = [];
            let showing_hotels = [];

            let day = moment(selectedDate)
              .add(dt, 'days')
              .format('dddd')
              .substring(0, 3);

            hotels.map((_hotel) => {
              if (checkHotelAvailability(_hotel.hotelID, dt)) {
                if (_hotel.prices[dt]) {
                  if (
                    cluster ==
                    getClusterByPrice(
                      _hotel.prices[dt].price[
                        getPrice(_hotel.prices[dt].price)
                      ],
                      dt
                    ) +
                      2
                  ) {
                    showing_hotels.push({
                      hotelID: _hotel.hotelID,
                      hotelName: _hotel.hotelName,
                      prices: _hotel.prices[dt].price,
                      price:
                        _hotel.prices[dt].price[
                          getPrice(_hotel.prices[dt].price)
                        ],
                      nights: getPrice(_hotel.prices[dt].price),
                    });
                    if (
                      getPrice(_hotel.prices[dt].price) == 1 ||
                      getPrice(_hotel.prices[dt].price) == 2
                    ) {
                      nights_hotels.push({
                        hotelID: _hotel.hotelID,
                        hotelName: _hotel.hotelName,
                        prices: _hotel.prices[dt].price,
                        price:
                          _hotel.prices[dt].price[
                            getPrice(_hotel.prices[dt].price)
                          ],
                        nights: getPrice(_hotel.prices[dt].price),
                      });
                    }
                  }
                }
              }
            });

            if (day === 'Sat' || day === 'Fri') {
              los_percentages_we.push(nights_hotels.length);
            } else {
              los_percentages_wd.push(nights_hotels.length);
            }
          });
        }

        if (los_percentages_wd.length > 0) {
          const _avg_wd =
            los_percentages_wd.reduce((a, b) => a + b) /
            los_percentages_wd.length;
          const _stdev_wd = getStandardDeviation(los_percentages_wd);

          setAvg_wd(_avg_wd);
          setStdev_wd(_stdev_wd);
        }

        if (los_percentages_we.length > 0) {
          const _avg_we =
            los_percentages_we.reduce((a, b) => a + b) /
            los_percentages_we.length;
          const _stdev_we = getStandardDeviation(los_percentages_we);

          setAvg_we(_avg_we);
          setStdev_we(_stdev_we);
        }
        setLoad(false);
      };

      MinimumNightsVarience(cluster_idx);
    }, [hotels]);
    return (
      <>
        {!load ? (
          <StyledTableRow>
            <StyledTableCell
              component="th"
              scope="row"
              size="small"
              className={classes.sticky}
              style={{
                fontWeight: 'bold',
                width: '300px',
              }}
            >
              MLOS Usage &nbsp;{' '}
              <i
                class="fa fa-external-link"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  executeScroll(8, selectedCluster_idx + 1, sub_tab)
                }
              ></i>
            </StyledTableCell>
            {cluster.length > 0 ? (
              cluster.map((day, index) =>
                (() => {
                  if (dates_filter == 1) {
                    return (
                      <StyledTableCell
                        size="small"
                        scope="row"
                        key={index}
                        className={classes.rates}
                        style={{
                          fontSize: '12px',
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        <span> {getNightVarience(cluster_idx, index)}</span>
                      </StyledTableCell>
                    );
                  } else if (dates_filter == 2) {
                    const found = specailDates.filter(
                      (obj) =>
                        moment(obj.date).format('YYYY-MM-DD') ==
                        moment(getDateByIndex(index)).format('YYYY-MM-DD')
                    );
                    if (found.length > 0) {
                      return (
                        <StyledTableCell
                          size="small"
                          scope="row"
                          key={index}
                          className={classes.rates}
                          style={{
                            fontSize: '12px',
                            borderRight:
                              index == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                        >
                          <span> {getNightVarience(cluster_idx, index)}</span>
                        </StyledTableCell>
                      );
                    }
                  }
                })()
              )
            ) : (
              <></>
            )}
          </StyledTableRow>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getVarience_index_movement = (value, avg, stdev) => {
    if (avg <= 0 || stdev <= 0 || value <= 0) {
      return '';
    } else if (value >= avg + 1 * stdev) {
      return 'High';
    }
  };

  const daily_fetch_len = selectedDate
    ? moment(moment(selectedDate).add(180, 'days'))
        .endOf('month')
        .day('sunday')
        .diff(selectedDate, 'days')
    : 0;

  const getHotelCountByDate = (cluster, dt) => {
    let show_hotels = [];
    if (hotels.length > 0) {
      hotels.map((_hotel) => {
        if (checkHotelAvailability(_hotel.hotelID, dt)) {
          if (_hotel.prices[dt]) {
            if (
              cluster ==
              getClusterByPrice(
                _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
                dt
              )
            ) {
              show_hotels.push({
                hotelID: _hotel.hotelID,
                hotelName: _hotel.hotelName,
                prices: _hotel.prices[dt].price,
                price:
                  _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
              });
            }
          }
        }
      });
    }

    return show_hotels.length;
  };

  const getHotelCountVariance = (cluster_idx, dt) => {
    let Variance = '';
    let day = moment(selectedDate)
      .add(dt, 'days')
      .format('dddd')
      .substring(0, 3);

    let show_hotels = getHotelCountByDate(cluster_idx, dt);

    if (avg_wd > -1 && stdev_wd > -1 && avg_we > -1 && stdev_we > -1) {
      if (day === 'Sat' || day === 'Fri') {
        if (show_hotels >= avg_we + stdev_we * 1.65) {
          Variance = 'Very High';
        } else if (show_hotels >= avg_we + stdev_we) {
          Variance = 'High';
        } else if (show_hotels <= avg_we - stdev_we * 1.65) {
          Variance = 'Very Low';
        } else if (show_hotels <= avg_we - stdev_we) {
          Variance = 'Low';
        }
      } else {
        if (show_hotels >= avg_wd + stdev_wd * 1.65) {
          Variance = 'Very High';
        } else if (show_hotels >= avg_wd + stdev_wd) {
          Variance = 'High';
        } else if (show_hotels <= avg_wd - stdev_wd * 1.65) {
          Variance = 'Very Low';
        } else if (show_hotels <= avg_wd - stdev_wd) {
          Variance = 'Low';
        }
      }
    }

    return Variance;
  };

  const GetEventsDatePicker = () => {
    return (
      <>
        {hotels.length > 0 && clusterData.length > 0 && !load ? (
          <Grid container direction="row-reverse" className="mb-1">
            <Button
              variant="outlined"
              size="small"
              endIcon={<EventIcon />}
              onClick={() =>
                set_events_date_picker_open(!events_date_picker_open)
              }
            >
              Select Dates
            </Button>

            {/* <MultipleDatesPicker
              open={events_date_picker_open}
              // selectedDates={all_dates_arr.map((date) =>
              //   moment(date).format('LL')
              // )}
              onCancel={() => set_events_date_picker_open(false)}
              onSubmit={(dates) => handdle_events_date_picker(dates)}
            /> */}
            <DatePicker onChange={handdle_events_date_picker} />
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleDatesFilter = (event) => {
    set_dates_filter(event.target.value);
  };

  const GetInsightsTable = ({
    _clusterData,
    _cluster1,
    _cluster2,
    _cluster3,
    _cluster4,
    _hotels,
    _reqHotel,
    sub_tab,
  }) => {
    return (
      <>
        {hotels.length > 0 && clusterData.length > 0 && !load ? (
          <>
            {/*  */}

            <div className="my-5">
              <Grid container justify="flex-end" className="mb-3">
                <Grid container justify="space-evenly">
                  <p className="font-weight-bold">
                    Weekday: Most Freq Bucket:{' '}
                    {reqHotelStrategyZone != undefined
                      ? reqHotelStrategyZone.freq_bucket_wd
                      : ''}
                    ,&nbsp; Freq. Rate Position:{' '}
                    {reqHotelStrategyZone != undefined
                      ? reqHotelStrategyZone.freq_qr_wd
                      : ''}
                    ,&nbsp; Avg Total Market Rank:{' '}
                    {reqHotelStrategyZone != undefined
                      ? Math.round(reqHotelStrategyZone.avg_day_rank_wd)
                      : ''}
                  </p>
                  <p className="font-weight-bold">
                    Weekend: Most Freq Bucket:{' '}
                    {reqHotelStrategyZone != undefined
                      ? reqHotelStrategyZone.freq_bucket_we
                      : ''}
                    ,&nbsp; Freq. Rate Position:{' '}
                    {reqHotelStrategyZone != undefined
                      ? reqHotelStrategyZone.freq_qr_we
                      : ''}
                    ,&nbsp; Avg Total Market Rank:{' '}
                    {reqHotelStrategyZone != undefined
                      ? Math.round(reqHotelStrategyZone.avg_day_rank_we)
                      : ''}
                  </p>
                </Grid>
                <FormControl className={classes.formControl + ' mx-5 my-1'}>
                  <InputLabel htmlFor="grouped-native-select">
                    Dates Filter
                  </InputLabel>
                  <Select
                    native
                    id="grouped-native-select"
                    onChange={handleDatesFilter}
                    value={dates_filter}
                    style={{
                      backgroundColor: 'white',
                      fontFamily: FONT_FAMILY,
                    }}
                  >
                    <option value={1}>All Dates</option>
                    {specailDates.length > 0 ? (
                      <option value={2}>Special Dates</option>
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  className={classes.formControl}
                  onClick={handleExport}
                >
                  <Button
                    className="text-light my-1"
                    variant="contained"
                    endIcon={<SaveAltIcon />}
                    style={{ backgroundColor: '#4F4F4F' }}
                  >
                    Export
                  </Button>
                </FormControl>
              </Grid>

              <TableContainer component={Paper} className={classes.container}>
                <Box width={100}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="customized table"
                    stickyHeader
                    bodystyle={{ overflow: 'visible' }}
                    ref={tableRef}
                    id="insights-tbl"
                  >
                    <TableHead style={{ position: 'sticky', zIndex: 100 }}>
                      <StyledTableRow>
                        <StyledTableCell
                          style={{
                            width: '300px',
                            fontFamily: FONT_FAMILY,
                            zIndex: 100,
                          }}
                        >
                          <TableSortLabel disabled>
                            Your Property <br />
                            {reqHotelStars}
                            -Stars | {reqHotelRatings} Rating
                          </TableSortLabel>{' '}
                          <hr />
                          <TableSortLabel disabled>Days Out</TableSortLabel>
                        </StyledTableCell>
                        {_reqHotel.map((e, index) =>
                          (() => {
                            let _date = moment(e.checkIn);
                            let daysOut = _date.diff(selectedDate, 'days');
                            let day = _date.format('dddd').substring(0, 3);

                            const found = specailDates.filter(
                              (obj) =>
                                moment(obj.date).format('YYYY-MM-DD') ==
                                moment(_date).format('YYYY-MM-DD')
                            );

                            if (dates_filter == 1) {
                              return (
                                <StyledTableCell
                                  size="small"
                                  key={index}
                                  className={
                                    day === 'Sat' || day === 'Fri'
                                      ? 'bg-secondary text-light text-center'
                                      : 'text-center'
                                  }
                                  style={{
                                    fontSize: '12px',
                                    borderRight:
                                      index == daily_fetch_len
                                        ? '5px solid rgba(66, 66, 66, 1)'
                                        : '',
                                  }}
                                >
                                  <span>
                                    {day === 'Sat' || day === 'Fri'
                                      ? 'WEND'
                                      : 'WDAY'}{' '}
                                    &nbsp;
                                    {found.length > 0 ? (
                                      <sup>
                                        <OverlayTrigger
                                          key="top"
                                          placement="top"
                                          overlay={
                                            <Tooltip id={`tooltip-${index}`}>
                                              {found
                                                .map((l) => l.label)
                                                .join(' | ')}
                                            </Tooltip>
                                          }
                                        >
                                          <i
                                            class="fa fa-info-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </OverlayTrigger>
                                      </sup>
                                    ) : (
                                      <></>
                                    )}
                                  </span>
                                  <br />
                                  <>{day.toUpperCase()}</> <br />{' '}
                                  <>{moment(e.checkIn).format(date_format)}</>{' '}
                                  <div class="dropdown-divider"></div> {daysOut}
                                </StyledTableCell>
                              );
                            } else if (dates_filter == 2) {
                              if (found.length > 0) {
                                return (
                                  <StyledTableCell
                                    size="small"
                                    key={index}
                                    className={
                                      day === 'Sat' || day === 'Fri'
                                        ? 'bg-secondary text-light text-center '
                                        : 'text-center '
                                    }
                                    style={{ fontSize: '12px' }}
                                  >
                                    <span>
                                      {day === 'Sat' || day === 'Fri'
                                        ? 'WEND'
                                        : 'WDAY'}{' '}
                                      &nbsp;
                                      {found.length > 0 ? (
                                        <sup>
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip id={`tooltip-${index}`}>
                                                {found
                                                  .map((l) => l.label)
                                                  .join(' | ')}
                                              </Tooltip>
                                            }
                                          >
                                            <i
                                              class="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </OverlayTrigger>
                                        </sup>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                    <br />
                                    <>{day.toUpperCase()}</> <br />
                                    <>
                                      {moment(e.checkIn).format(date_format)}
                                    </>{' '}
                                    <div class="dropdown-divider"></div>
                                    {daysOut}
                                  </StyledTableCell>
                                );
                              }
                            }
                          })()
                        )}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        <StyledTableRow>
                          <StyledTableCell
                            size="small"
                            component="th"
                            scope="col"
                            className={classes.sticky}
                            style={{ fontWeight: 'bold', width: '300px' }}
                          >
                            Current Rate &nbsp;{' '}
                            <i
                              class="fa fa-external-link"
                              aria-hidden="true"
                              style={{ cursor: 'pointer' }}
                              onClick={() => executeScroll(7, 1, sub_tab)}
                            ></i>
                          </StyledTableCell>
                          {_reqHotel.map((e, index) =>
                            (() => {
                              if (dates_filter == 1) {
                                return (
                                  <StyledTableCell
                                    size="small"
                                    key={index}
                                    style={{
                                      backgroundColor:
                                      e.rate == '-' ? '': e.cluster <= 5 ? CLUSTER_BACKGROUND[e.cluster - 2]: CLUSTER_BACKGROUND[4],
                                      borderRight:
                                        index == daily_fetch_len
                                          ? '5px solid rgba(66, 66, 66, 1)'
                                          : '',
                                    }}
                                    className={classes.rates}
                                  >
                                    {e.rate}
                                  </StyledTableCell>
                                );
                              } else if (dates_filter == 2) {
                                const found = specailDates.filter(
                                  (obj) =>
                                    moment(obj.date).format('YYYY-MM-DD') ==
                                    moment(getDateByIndex(index)).format(
                                      'YYYY-MM-DD'
                                    )
                                );
                                if (found.length > 0) {
                                  return (
                                    <StyledTableCell
                                      size="small"
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          CLUSTER_BACKGROUND[e.cluster - 2],
                                        borderRight:
                                          index == daily_fetch_len
                                            ? '5px solid rgba(66, 66, 66, 1)'
                                            : '',
                                      }}
                                      className={classes.rates}
                                    >
                                      {e.rate}
                                    </StyledTableCell>
                                  );
                                }
                              }
                            })()
                          )}
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            size="small"
                            component="th"
                            scope="row"
                            className={classes.sticky}
                            style={{ fontWeight: 'bold', width: '300px' }}
                          >
                            Current Rate Bucket &nbsp;{' '}
                            <i
                              class="fa fa-external-link"
                              aria-hidden="true"
                              style={{ cursor: 'pointer' }}
                              onClick={() => executeScroll(7, 1, sub_tab)}
                            ></i>
                          </StyledTableCell>

                          {_reqHotel.map((e, index) =>
                            (() => {
                              if (dates_filter == 1) {
                                return (
                                  <StyledTableCell
                                    size="small"
                                    key={index}
                                    className={classes.rates}
                                    style={{
                                      borderRight:
                                        index == daily_fetch_len
                                          ? '5px solid rgba(66, 66, 66, 1)'
                                          : '',
                                    }}
                                  >
                                   <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                      {e.cluster != '-' ? (
                                        CLUSTER_LABELS[e.cluster - 2]
                                      ) : (CLUSTER_LABELS[4])}
                                    </span>
                                  </StyledTableCell>
                                );
                              } else if (dates_filter == 2) {
                                const found = specailDates.filter(
                                  (obj) =>
                                    moment(obj.date).format('YYYY-MM-DD') ==
                                    moment(getDateByIndex(index)).format(
                                      'YYYY-MM-DD'
                                    )
                                );
                                if (found.length > 0) {
                                  return (
                                    <StyledTableCell
                                      size="small"
                                      key={index}
                                      className={classes.rates}
                                      style={{
                                        borderRight:
                                          index == daily_fetch_len
                                            ? '5px solid rgba(66, 66, 66, 1)'
                                            : '',
                                      }}
                                    >
                                      {e.cluster != 'N/A' ? (
                                        e.cluster
                                      ) : (
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                          }}
                                        >
                                          OUT
                                        </span>
                                      )}
                                    </StyledTableCell>
                                  );
                                }
                              }
                            })()
                          )}
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell
                            size="small"
                            component="th"
                            scope="row"
                            className={classes.sticky}
                            style={{ fontWeight: 'bold', width: '300px' }}
                          >
                            Rate Bucket Position &nbsp;{' '}
                            <i
                              class="fa fa-external-link"
                              aria-hidden="true"
                              style={{ cursor: 'pointer' }}
                              onClick={() => executeScroll(7, 1, sub_tab)}
                            ></i>
                          </StyledTableCell>

                          {_reqHotel.map((e, index) =>
                            (() => {
                              if (dates_filter == 1) {
                                return (
                                  <StyledTableCell
                                    size="small"
                                    key={index}
                                    className={classes.rates}
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: '12px',
                                      borderRight:
                                        index == daily_fetch_len
                                          ? '5px solid rgba(66, 66, 66, 1)'
                                          : '',
                                    }}
                                  >
                                    {e.pos}
                                  </StyledTableCell>
                                );
                              } else if (dates_filter == 2) {
                                const found = specailDates.filter(
                                  (obj) =>
                                    moment(obj.date).format('YYYY-MM-DD') ==
                                    moment(getDateByIndex(index)).format(
                                      'YYYY-MM-DD'
                                    )
                                );
                                if (found.length > 0) {
                                  return (
                                    <StyledTableCell
                                      size="small"
                                      key={index}
                                      className={classes.rates}
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        borderRight:
                                          index == daily_fetch_len
                                            ? '5px solid rgba(66, 66, 66, 1)'
                                            : '',
                                      }}
                                    >
                                      {e.pos}
                                    </StyledTableCell>
                                  );
                                }
                              }
                            })()
                          )}
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell
                            size="small"
                            component="th"
                            scope="row"
                            className={classes.sticky}
                            style={{
                              fontWeight: 'bold',
                              width: '300px',
                            }}
                          >
                            Rate Bucket Rank (Highest to Lowest) &nbsp;{' '}
                            <i
                              class="fa fa-external-link"
                              aria-hidden="true"
                              style={{ cursor: 'pointer' }}
                              onClick={() => executeScroll(7, 1, sub_tab)}
                            ></i>
                          </StyledTableCell>
                          {_reqHotel.map((e, index) =>
                            (() => {
                              if (dates_filter == 1) {
                                return (
                                  <StyledTableCell
                                    size="small"
                                    key={index}
                                    style={{
                                      fontSize: '14px',
                                      borderRight:
                                        index == daily_fetch_len
                                          ? '5px solid rgba(66, 66, 66, 1)'
                                          : '',
                                    }}
                                    className={classes.rates}
                                  >
                                    <sup>{e.rank.split('/')[0]}</sup>&frasl;
                                    <sub>{e.rank.split('/')[1]}</sub>
                                  </StyledTableCell>
                                );
                              } else if (dates_filter == 2) {
                                const found = specailDates.filter(
                                  (obj) =>
                                    moment(obj.date).format('YYYY-MM-DD') ==
                                    moment(getDateByIndex(index)).format(
                                      'YYYY-MM-DD'
                                    )
                                );
                                if (found.length > 0) {
                                  return (
                                    <StyledTableCell
                                      size="small"
                                      key={index}
                                      style={{
                                        fontSize: '14px',
                                        borderRight:
                                          index == daily_fetch_len
                                            ? '5px solid rgba(66, 66, 66, 1)'
                                            : '',
                                      }}
                                      className={classes.rates}
                                    >
                                      <sup>{e.rank.split('/')[0]}</sup>&frasl;
                                      <sub>{e.rank.split('/')[1]}</sub>
                                    </StyledTableCell>
                                  );
                                }
                              }
                            })()
                          )}
                        </StyledTableRow>
                      </>

                      <OverallMarketAnalysis
                        selectedDate={selectedDate}
                        rows_only={true}
                        executeScroll={executeScroll}
                        sub_tab={sub_tab}
                        specailDates={specailDates}
                        dates_filter={dates_filter}
                      />

                      <>
                        {reqHotelStrategyZone != undefined ? (
                          <>
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="col"
                                className={classes.sticky}
                                style={{ fontWeight: 'bold', width: '300px' }}
                              >
                                Total Market Rank (Highest to Lowest) &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => executeScroll(7, 1, sub_tab)}
                                ></i>
                              </StyledTableCell>

                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    if (
                                      reqHotelStrategyZone.prices[index] != null
                                    ) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          <sup>
                                            {
                                              reqHotelStrategyZone.prices[index]
                                                .day_rank
                                            }
                                          </sup>
                                          &frasl;
                                          <sub>
                                            {
                                              reqHotelStrategyZone.prices[index]
                                                .total
                                            }
                                          </sub>
                                        </StyledTableCell>
                                      );
                                    } else {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          --
                                        </StyledTableCell>
                                      );
                                    }
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(index)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );

                                    if (found.length > 0) {
                                      if (
                                        reqHotelStrategyZone.prices[index] !=
                                        null
                                      ) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            <sup>
                                              {
                                                reqHotelStrategyZone.prices[
                                                  index
                                                ].day_rank
                                              }
                                            </sup>
                                            &frasl;
                                            <sub>
                                              {
                                                reqHotelStrategyZone.prices[
                                                  index
                                                ].total
                                              }
                                            </sub>
                                          </StyledTableCell>
                                        );
                                      } else {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            --
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  }
                                })()
                              )}
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="col"
                                className={classes.sticky}
                                style={{ fontWeight: 'bold', width: '300px' }}
                              >
                                Zone Position &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => executeScroll(7, 2, sub_tab)}
                                ></i>
                              </StyledTableCell>

                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    if (
                                      reqHotelStrategyZone.prices[index] != null
                                    ) {
                                      if (
                                        reqHotelStrategyZone.prices[index].qr
                                      ) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              fontWeight: 'bold',
                                              fontSize: '12px',
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            {reqHotelStrategyZone.prices[index]
                                              .qr == 'MID'
                                              ? ''
                                              : reqHotelStrategyZone.prices[
                                                  index
                                                ].qr}
                                          </StyledTableCell>
                                        );
                                      } else {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            --
                                          </StyledTableCell>
                                        );
                                      }
                                    } else {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          --
                                        </StyledTableCell>
                                      );
                                    }
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(index)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );

                                    if (found.length > 0) {
                                      if (
                                        reqHotelStrategyZone.prices[index] !=
                                        null
                                      ) {
                                        if (
                                          reqHotelStrategyZone.prices[index].qr
                                        ) {
                                          return (
                                            <StyledTableCell
                                              size="small"
                                              key={index}
                                              style={{
                                                fontWeight: 'bold',
                                                fontSize: '12px',
                                                borderRight:
                                                  index == daily_fetch_len
                                                    ? '5px solid rgba(66, 66, 66, 1)'
                                                    : '',
                                              }}
                                              className={classes.rates}
                                            >
                                              {reqHotelStrategyZone.prices[
                                                index
                                              ].qr == 'MID'
                                                ? ''
                                                : reqHotelStrategyZone.prices[
                                                    index
                                                  ].qr}
                                            </StyledTableCell>
                                          );
                                        } else {
                                          return (
                                            <StyledTableCell
                                              size="small"
                                              key={index}
                                              style={{
                                                borderRight:
                                                  index == daily_fetch_len
                                                    ? '5px solid rgba(66, 66, 66, 1)'
                                                    : '',
                                              }}
                                              className={classes.rates}
                                            >
                                              --
                                            </StyledTableCell>
                                          );
                                        }
                                      } else {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            --
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  }
                                })()
                              )}
                            </StyledTableRow>

                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="col"
                                className={classes.sticky}
                                style={{ fontWeight: 'bold', width: '300px' }}
                              >
                                Zone Freq Rate Range - High &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => executeScroll(7, 2, sub_tab)}
                                ></i>
                              </StyledTableCell>

                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    if (
                                      reqHotelStrategyZone.prices[index] != null
                                    ) {
                                      if (
                                        reqHotelStrategyZone.prices[index]
                                          .upper_start_rate
                                      ) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              backgroundColor:
                                                CLUSTER_BACKGROUND[
                                                  getClusterByPrice(
                                                    reqHotelStrategyZone.prices[
                                                      index
                                                    ].upper_start_rate.rate,
                                                    index
                                                  )
                                                ],
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            {parseFloat(
                                              reqHotelStrategyZone.prices[index]
                                                .upper_start_rate.rate
                                            ).toFixed(0)}
                                          </StyledTableCell>
                                        );
                                      } else {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            --
                                          </StyledTableCell>
                                        );
                                      }
                                    } else {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          --
                                        </StyledTableCell>
                                      );
                                    }
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(index)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );

                                    if (found.length > 0) {
                                      if (
                                        reqHotelStrategyZone.prices[index] !=
                                        null
                                      ) {
                                        if (
                                          reqHotelStrategyZone.prices[index]
                                            .upper_start_rate
                                        ) {
                                          return (
                                            <StyledTableCell
                                              size="small"
                                              key={index}
                                              style={{
                                                backgroundColor:
                                                  CLUSTER_BACKGROUND[
                                                    getClusterByPrice(
                                                      reqHotelStrategyZone
                                                        .prices[index]
                                                        .upper_start_rate.rate,
                                                      index
                                                    )
                                                  ],
                                                borderRight:
                                                  index == daily_fetch_len
                                                    ? '5px solid rgba(66, 66, 66, 1)'
                                                    : '',
                                              }}
                                              className={classes.rates}
                                            >
                                              {parseFloat(
                                                reqHotelStrategyZone.prices[
                                                  index
                                                ].upper_start_rate.rate
                                              ).toFixed(0)}
                                            </StyledTableCell>
                                          );
                                        } else {
                                          return (
                                            <StyledTableCell
                                              size="small"
                                              key={index}
                                              style={{
                                                borderRight:
                                                  index == daily_fetch_len
                                                    ? '5px solid rgba(66, 66, 66, 1)'
                                                    : '',
                                              }}
                                              className={classes.rates}
                                            >
                                              --
                                            </StyledTableCell>
                                          );
                                        }
                                      } else {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            --
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  }
                                })()
                              )}
                            </StyledTableRow>

                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{
                                  fontWeight: 'bold',
                                  width: '300px',
                                  borderTop: '2px solid grey',
                                  borderBottom: '2px solid grey',
                                }}
                              >
                                Zone Average Rank Rate &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => executeScroll(7, 2, sub_tab)}
                                ></i>
                              </StyledTableCell>

                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    if (
                                      reqHotelStrategyZone.prices[index] != null
                                    ) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            backgroundColor:
                                              CLUSTER_BACKGROUND[
                                                getClusterByPrice(
                                                  reqHotelStrategyZone.prices[
                                                    index
                                                  ].avarage_rate,
                                                  index
                                                )
                                              ],
                                            borderTop: '2px solid grey',
                                            borderBottom: '2px solid grey',
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {
                                            reqHotelStrategyZone.prices[index]
                                              .avarage_rate
                                          }
                                        </StyledTableCell>
                                      );
                                    } else {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          --
                                        </StyledTableCell>
                                      );
                                    }
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(index)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );

                                    if (found.length > 0) {
                                      if (
                                        reqHotelStrategyZone.prices[index] !=
                                        null
                                      ) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              backgroundColor:
                                                CLUSTER_BACKGROUND[
                                                  getClusterByPrice(
                                                    reqHotelStrategyZone.prices[
                                                      index
                                                    ].avarage_rate,
                                                    index
                                                  )
                                                ],
                                              borderTop: '2px solid grey',
                                              borderBottom: '2px solid grey',
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            {
                                              reqHotelStrategyZone.prices[index]
                                                .avarage_rate
                                            }
                                          </StyledTableCell>
                                        );
                                      } else {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            --
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  }
                                })()
                              )}
                            </StyledTableRow>

                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{
                                  fontWeight: 'bold',
                                  width: '300px',
                                }}
                              >
                                Zone Freq Rate Range - Low &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => executeScroll(7, 2, sub_tab)}
                                ></i>
                              </StyledTableCell>

                              {[...Array(report_len).keys()].map((e, index) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    if (
                                      reqHotelStrategyZone.prices[index] != null
                                    ) {
                                      if (
                                        reqHotelStrategyZone.prices[index]
                                          .lower_start_rate
                                      ) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              backgroundColor:
                                                CLUSTER_BACKGROUND[
                                                  getClusterByPrice(
                                                    reqHotelStrategyZone.prices[
                                                      index
                                                    ].lower_start_rate.rate,
                                                    index
                                                  )
                                                ],
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            {parseFloat(
                                              reqHotelStrategyZone.prices[index]
                                                .lower_start_rate.rate
                                            ).toFixed(0)}
                                          </StyledTableCell>
                                        );
                                      } else {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            --
                                          </StyledTableCell>
                                        );
                                      }
                                    } else {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          --
                                        </StyledTableCell>
                                      );
                                    }
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(index)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );

                                    if (found.length > 0) {
                                      if (
                                        reqHotelStrategyZone.prices[index] !=
                                        null
                                      ) {
                                        if (
                                          reqHotelStrategyZone.prices[index]
                                            .lower_start_rate
                                        ) {
                                          return (
                                            <StyledTableCell
                                              size="small"
                                              key={index}
                                              style={{
                                                backgroundColor:
                                                  CLUSTER_BACKGROUND[
                                                    getClusterByPrice(
                                                      reqHotelStrategyZone
                                                        .prices[index]
                                                        .lower_start_rate.rate,
                                                      index
                                                    )
                                                  ],
                                                borderRight:
                                                  index == daily_fetch_len
                                                    ? '5px solid rgba(66, 66, 66, 1)'
                                                    : '',
                                              }}
                                              className={classes.rates}
                                            >
                                              {parseFloat(
                                                reqHotelStrategyZone.prices[
                                                  index
                                                ].lower_start_rate.rate
                                              ).toFixed(0)}
                                            </StyledTableCell>
                                          );
                                        } else {
                                          return (
                                            <StyledTableCell
                                              size="small"
                                              key={index}
                                              style={{
                                                borderRight:
                                                  index == daily_fetch_len
                                                    ? '5px solid rgba(66, 66, 66, 1)'
                                                    : '',
                                              }}
                                              className={classes.rates}
                                            >
                                              --
                                            </StyledTableCell>
                                          );
                                        }
                                      } else {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={classes.rates}
                                          >
                                            --
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  }
                                })()
                              )}
                            </StyledTableRow>
                          </>
                        ) : (
                          <></>
                        )}
                      </>

                      <>
                        {quadrant_data_state && lebr_hotels.length > 0 ? (
                          <>
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="col"
                                className={classes.sticky}
                                style={{ fontWeight: 'bold', width: '300px' }}
                              >
                                Less Expensive Better Rated - Count &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => executeScroll(2, 1, sub_tab)}
                                ></i>
                              </StyledTableCell>
                              {lebr_hotels.map((obj, dt) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={dt}
                                        className={classes.rates}
                                        style={{
                                          borderRight:
                                            dt == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                      >
                                        {obj}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(dt)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={dt}
                                          className={classes.rates}
                                          style={{
                                            borderRight:
                                              dt == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                        >
                                          {obj}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )}
                            </StyledTableRow>

                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="col"
                                className={classes.sticky}
                                style={{ fontWeight: 'bold', width: '300px' }}
                              >
                                Less Expensive Better Rated - High Up Count
                                &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => executeScroll(2, 2, sub_tab)}
                                ></i>
                              </StyledTableCell>
                              {lebr_hotels.map((obj, dt) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={dt}
                                        className={classes.rates}
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            dt == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                      >
                                        {getVarience(obj, lebr_avg, lebr_stdev)}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(dt)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={dt}
                                          className={classes.rates}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              dt == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                        >
                                          {getVarience(
                                            obj,
                                            lebr_avg,
                                            lebr_stdev
                                          )}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )}
                            </StyledTableRow>

                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="col"
                                className={classes.sticky}
                                style={{ fontWeight: 'bold', width: '300px' }}
                              >
                                Zero Less Expensive Better Rated Floor &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => executeScroll(2, 1, sub_tab)}
                                ></i>
                              </StyledTableCell>
                              {zero_lebr_floor_prices.map((obj, dt) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={dt}
                                        className={classes.rates}
                                        style={{
                                          backgroundColor:
                                            obj != 'N/A'
                                              ? CLUSTER_BACKGROUND[
                                                  getClusterByPrice(obj, dt)
                                                ]
                                              : '',
                                          borderRight:
                                            dt == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                      >
                                        {obj}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(dt)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={dt}
                                          className={classes.rates}
                                          style={{
                                            backgroundColor:
                                              obj != 'N/A'
                                                ? CLUSTER_BACKGROUND[
                                                    getClusterByPrice(obj, dt)
                                                  ]
                                                : '',
                                            borderRight:
                                              dt == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                        >
                                          {obj}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )}
                            </StyledTableRow>
                          </>
                        ) : (
                          <></>
                        )}
                      </>

                     
                      {/* <ValueRank
                        selectedDate={selectedDate}
                        rows_only={true}
                        executeScroll={executeScroll}
                        sub_tab={sub_tab}
                        specailDates={specailDates}
                        dates_filter={dates_filter}
                        setAvgValueRank={setAvgValueRank}
                      /> */}

                      <>
                        {days_arr.length > 0 &&
                        freq_bucket_wd >= 0 &&
                        freq_bucket_we >= 0 ? (
                          <StyledTableRow>
                            <StyledTableCell
                              size="small"
                              component="th"
                              scope="row"
                              className={classes.sticky}
                              style={{ fontWeight: 'bold', width: '300px' }}
                            >
                              Average of Middle Rates &nbsp;{' '}
                              <i
                                class="fa fa-external-link"
                                aria-hidden="true"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  executeScroll(
                                    0,
                                    selectedCluster_idx + 1,
                                    sub_tab
                                  )
                                }
                              ></i>
                            </StyledTableCell>

                            {days_arr.map((day, index) =>
                              (() => {
                                if (day === 'Sat' || day === 'Fri') {
                                  const e =
                                    clusterData[index][freq_bucket_we - 2];
                                  if (dates_filter == 1) {
                                    if (e) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          className={classes.rates}
                                          style={{
                                            backgroundColor:
                                              CLUSTER_BACKGROUND[
                                                freq_bucket_we - 2
                                              ],
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                        >
                                          {e.midAVG !== 'NaN' && e.items > 0 ? (
                                            <span>{Math.round(e.midAVG)}</span>
                                          ) : e.midAVG !== 'NaN' &&
                                            e.items < 0 ? (
                                            'NED'
                                          ) : (
                                            '--'
                                          )}
                                        </StyledTableCell>
                                      );
                                    }
                                  } else if (dates_filter == 2) {
                                    if (e) {
                                      const found = specailDates.filter(
                                        (obj) =>
                                          moment(obj.date).format(
                                            'YYYY-MM-DD'
                                          ) ==
                                          moment(getDateByIndex(index)).format(
                                            'YYYY-MM-DD'
                                          )
                                      );
                                      if (found.length > 0) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            className={classes.rates}
                                            style={{
                                              backgroundColor:
                                                CLUSTER_BACKGROUND[
                                                  freq_bucket_we - 2
                                                ],
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                          >
                                            {e.midAVG !== 'NaN' &&
                                            e.items > 0 ? (
                                              <span>
                                                {Math.round(e.midAVG)}
                                              </span>
                                            ) : e.midAVG !== 'NaN' &&
                                              e.items < 0 ? (
                                              'NED'
                                            ) : (
                                              '--'
                                            )}
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  }
                                } else {
                                  const e =
                                    clusterData[index][freq_bucket_wd - 2];

                                  if (dates_filter == 1) {
                                    if (e) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          className={classes.rates}
                                          style={{
                                            backgroundColor:
                                              CLUSTER_BACKGROUND[
                                                freq_bucket_wd - 2
                                              ],
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                        >
                                          {e.midAVG !== 'NaN' && e.items > 0 ? (
                                            <span>{Math.round(e.midAVG)}</span>
                                          ) : e.midAVG !== 'NaN' &&
                                            e.items < 0 ? (
                                            'NED'
                                          ) : (
                                            '--'
                                          )}
                                        </StyledTableCell>
                                      );
                                    }
                                  } else if (dates_filter == 2) {
                                    if (e) {
                                      const found = specailDates.filter(
                                        (obj) =>
                                          moment(obj.date).format(
                                            'YYYY-MM-DD'
                                          ) ==
                                          moment(getDateByIndex(index)).format(
                                            'YYYY-MM-DD'
                                          )
                                      );
                                      if (found.length > 0) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            className={classes.rates}
                                            style={{
                                              backgroundColor:
                                                CLUSTER_BACKGROUND[
                                                  freq_bucket_wd - 2
                                                ],
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                          >
                                            {e.midAVG !== 'NaN' &&
                                            e.items > 0 ? (
                                              <span>
                                                {Math.round(e.midAVG)}
                                              </span>
                                            ) : e.midAVG !== 'NaN' &&
                                              e.items < 0 ? (
                                              'NED'
                                            ) : (
                                              '--'
                                            )}
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  }
                                }
                              })()
                            )}
                          </StyledTableRow>
                        ) : (
                          <></>
                        )}
                      </>

                      <>
                        {selectedCluster.length > 0 &&
                        selectedCluster_idx >= 0 ? (
                          // <ClusterDataTable
                          //   cluster={selectedCluster}
                          //   stars={selectedCluster_idx + 2}
                          //   type="cluster"
                          //   selectedDate={selectedDate}
                          //   comparison_report={
                          //     comparison_report
                          //       ? comparison_report.clusterData.length > 0
                          //         ? comparison_report.clusterData
                          //         : []
                          //       : []
                          //   }
                          //   insight_data={true}
                          //   executeScroll={executeScroll}
                          //   set_cluster_load={() => {}}
                          //   sub_tab={sub_tab}
                          // />
                          <>
                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{
                                  fontWeight: 'bold',
                                  width: '300px',
                                }}
                              >
                                {selectedCluster_idx + 2} Star Bucket Rate
                                Strength &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    executeScroll(
                                      0,
                                      selectedCluster_idx + 1,
                                      sub_tab
                                    )
                                  }
                                ></i>
                              </StyledTableCell>
                              {rateStrength.map((e, index) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={index}
                                        className={classes.rates}
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                      >
                                        {e ? <span className="">{e}</span> : ''}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(index)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          className={classes.rates}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                        >
                                          {e ? (
                                            <span className="">{e}</span>
                                          ) : (
                                            ''
                                          )}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )}
                            </StyledTableRow>

                            {selectedCluster.length > 0 ? (
                              <StyledTableRow>
                                <StyledTableCell
                                  size="small"
                                  component="th"
                                  scope="row"
                                  className={classes.sticky}
                                  style={{
                                    fontWeight: 'bold',
                                    width: '300px',
                                  }}
                                >
                                  {selectedCluster_idx + 2} Star Bucket Rate
                                  Movement &nbsp;{' '}
                                  <i
                                    class="fa fa-external-link"
                                    aria-hidden="true"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      executeScroll(
                                        0,
                                        selectedCluster_idx + 1,
                                        sub_tab
                                      )
                                    }
                                  ></i>
                                </StyledTableCell>

                                {selectedCluster.map((e, index) =>
                                  (() => {
                                    if (dates_filter == 1) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          className={classes.rates}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                        >
                                          {e.comp_report && e.items > 0 ? (
                                            <span
                                              className={
                                                e.rate_movements
                                                  ? 'text-success'
                                                  : 'text-danger'
                                              }
                                            >
                                              {e.rate_movements ? 'Up' : 'Down'}
                                            </span>
                                          ) : (
                                            ''
                                          )}
                                        </StyledTableCell>
                                      );
                                    } else if (dates_filter == 2) {
                                      const found = specailDates.filter(
                                        (obj) =>
                                          moment(obj.date).format(
                                            'YYYY-MM-DD'
                                          ) ==
                                          moment(getDateByIndex(index)).format(
                                            'YYYY-MM-DD'
                                          )
                                      );
                                      if (found.length > 0) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            className={classes.rates}
                                            style={{
                                              fontSize: '12px',
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                          >
                                            {e.comp_report && e.items > 0 ? (
                                              <span
                                                className={
                                                  e.rate_movements
                                                    ? 'text-success'
                                                    : 'text-danger'
                                                }
                                              >
                                                {e.rate_movements
                                                  ? 'Up'
                                                  : 'Down'}
                                              </span>
                                            ) : (
                                              ''
                                            )}
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  })()
                                )}
                              </StyledTableRow>
                            ) : (
                              <></>
                            )}

                            {selectedCluster.length > 0 ? (
                              <StyledTableRow>
                                <StyledTableCell
                                  size="small"
                                  component="th"
                                  scope="row"
                                  className={classes.sticky}
                                  style={{
                                    fontWeight: 'bold',
                                    width: '300px',
                                  }}
                                >
                                  {selectedCluster_idx + 2} Star Bucket Movement
                                  Strength &nbsp;{' '}
                                  <i
                                    class="fa fa-external-link"
                                    aria-hidden="true"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      executeScroll(
                                        0,
                                        selectedCluster_idx + 1,
                                        sub_tab
                                      )
                                    }
                                  ></i>
                                </StyledTableCell>
                                {selectedCluster.map((e, index) =>
                                  (() => {
                                    if (dates_filter == 1) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={index}
                                          className={classes.rates}
                                          style={{
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                            fontSize: '12px',
                                          }}
                                        >
                                          {e.midAVG !== 'NaN' &&
                                          e.comp_report &&
                                          e.items > 0
                                            ? getVarience(
                                                Math.abs(
                                                  ((e.midAVG -
                                                    e.comp_report.midAVG) /
                                                    e.comp_report.midAVG) *
                                                    100
                                                ),
                                                rateVarianceStrength_avg,
                                                rateVarianceStrength_stdev
                                              )
                                            : ''}
                                        </StyledTableCell>
                                      );
                                    } else if (dates_filter == 2) {
                                      const found = specailDates.filter(
                                        (obj) =>
                                          moment(obj.date).format(
                                            'YYYY-MM-DD'
                                          ) ==
                                          moment(getDateByIndex(index)).format(
                                            'YYYY-MM-DD'
                                          )
                                      );
                                      if (found.length > 0) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            key={index}
                                            className={classes.rates}
                                            style={{
                                              borderRight:
                                                index == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                              fontSize: '12px',
                                            }}
                                          >
                                            {e.midAVG !== 'NaN' &&
                                            e.comp_report &&
                                            e.items > 0
                                              ? getVarience(
                                                  Math.abs(
                                                    ((e.midAVG -
                                                      e.comp_report.midAVG) /
                                                      e.comp_report.midAVG) *
                                                      100
                                                  ),
                                                  rateVarianceStrength_avg,
                                                  rateVarianceStrength_stdev
                                                )
                                              : ''}
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  })()
                                )}
                              </StyledTableRow>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>

                      <>
                        {selectedCluster.length > 0 &&
                        selectedCluster_idx >= 0 &&
                        net_bucket_data_state ? (
                          <>
                            {/* <StyledTableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              size="small"
                              className={classes.sticky}
                              style={{
                                fontWeight: 'bold',
                                width: '300px',
                              }}
                            >
                              {selectedCluster_idx + 2} Star Bucket Density
                              &nbsp;{' '}
                              <i
                                class="fa fa-external-link"
                                aria-hidden="true"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  executeScroll(
                                    1,
                                    selectedCluster_idx + 1,
                                    sub_tab
                                  )
                                }
                              ></i>
                            </StyledTableCell>
                            {selectedCluster.length > 0 ? (
                              selectedCluster.map((day, index) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        component="th"
                                        scope="row"
                                        key={index}
                                        className={classes.rates}
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            index == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                      >
                                        <span>
                                          {getHotelCountVariance(
                                            selectedCluster_idx,
                                            index
                                          )}
                                        </span>
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(index)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          component="th"
                                          scope="row"
                                          key={index}
                                          className={classes.rates}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              index == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                        >
                                          <span>
                                            {' '}
                                            {getHotelCountVariance(
                                              selectedCluster_idx,
                                              index
                                            )}
                                          </span>
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : (
                              <></>
                            )}
                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell
                              size="small"
                              component="th"
                              scope="row"
                              className={classes.sticky}
                              style={{
                                fontWeight: 'bold',
                                width: '300px',
                              }}
                            >
                              {selectedCluster_idx + 2} Star Pricing Up Strength
                              &nbsp;{' '}
                              <i
                                class="fa fa-external-link"
                                aria-hidden="true"
                                style={{ cursor: 'pointer' }}
                                onClick={() => executeScroll(1, 5, sub_tab)}
                              ></i>
                            </StyledTableCell>
                            {selectedCluster_idx == 0 ? (
                              stars2up.map((obj, idx) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={idx}
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            idx == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {obj.variance}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(idx)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={idx}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {obj.variance}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : selectedCluster_idx == 1 ? (
                              stars3up.map((obj, idx) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={idx}
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            idx == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {obj.variance}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(idx)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={idx}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {obj.variance}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : selectedCluster_idx == 2 ? (
                              stars4up.map((obj, idx) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={idx}
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            idx == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {obj.variance}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(idx)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={idx}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {obj.variance}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : (
                              <></>
                            )}
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell
                              size="small"
                              component="th"
                              scope="row"
                              className={classes.sticky}
                              style={{
                                fontWeight: 'bold',
                                width: '300px',
                              }}
                            >
                              {selectedCluster_idx + 2} Star Pricing Down
                              Strength &nbsp;{' '}
                              <i
                                class="fa fa-external-link"
                                aria-hidden="true"
                                style={{ cursor: 'pointer' }}
                                onClick={() => executeScroll(1, 5, sub_tab)}
                              ></i>
                            </StyledTableCell>
                            {selectedCluster_idx == 1 ? (
                              stars3down.map((obj, idx) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={idx}
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            idx == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {obj.variance}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(idx)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={idx}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {obj.variance}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : selectedCluster_idx == 2 ? (
                              stars4down.map((obj, idx) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={idx}
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            idx == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {obj.variance}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(idx)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={idx}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {obj.variance}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : selectedCluster_idx == 3 ? (
                              stars5down.map((obj, idx) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        key={idx}
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            idx == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {obj.variance}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(idx)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          key={idx}
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {obj.variance}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : (
                              <></>
                            )}
                          </StyledTableRow> */}

                            <StyledTableRow>
                              <StyledTableCell
                                size="small"
                                component="th"
                                scope="row"
                                className={classes.sticky}
                                style={{
                                  fontWeight: 'bold',
                                  width: '300px',
                                }}
                              >
                                {selectedCluster_idx + 2} Star High Bucket Size
                                Index &nbsp;{' '}
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => executeScroll(1, 6, sub_tab)}
                                ></i>
                              </StyledTableCell>
                              {selectedCluster_idx == 0 ? (
                                cluster1_bucket_index.map((obj, idx) =>
                                  (() => {
                                    if (dates_filter == 1) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={
                                            classes.rates + ' text-center'
                                          }
                                        >
                                          {obj.bucket_size_exception}
                                        </StyledTableCell>
                                      );
                                    } else if (dates_filter == 2) {
                                      const found = specailDates.filter(
                                        (obj) =>
                                          moment(obj.date).format(
                                            'YYYY-MM-DD'
                                          ) ==
                                          moment(getDateByIndex(idx)).format(
                                            'YYYY-MM-DD'
                                          )
                                      );
                                      if (found.length > 0) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            style={{
                                              fontSize: '12px',
                                              borderRight:
                                                idx == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={
                                              classes.rates + ' text-center'
                                            }
                                          >
                                            {obj.bucket_size_exception}
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  })()
                                )
                              ) : selectedCluster_idx == 1 ? (
                                cluster2_bucket_index.map((obj, idx) =>
                                  (() => {
                                    if (dates_filter == 1) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={
                                            classes.rates + ' text-center'
                                          }
                                        >
                                          {obj.bucket_size_exception}
                                        </StyledTableCell>
                                      );
                                    } else if (dates_filter == 2) {
                                      const found = specailDates.filter(
                                        (obj) =>
                                          moment(obj.date).format(
                                            'YYYY-MM-DD'
                                          ) ==
                                          moment(getDateByIndex(idx)).format(
                                            'YYYY-MM-DD'
                                          )
                                      );
                                      if (found.length > 0) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            style={{
                                              fontSize: '12px',
                                              borderRight:
                                                idx == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={
                                              classes.rates + ' text-center'
                                            }
                                          >
                                            {obj.bucket_size_exception}
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  })()
                                )
                              ) : selectedCluster_idx == 2 ? (
                                cluster3_bucket_index.map((obj, idx) =>
                                  (() => {
                                    if (dates_filter == 1) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={
                                            classes.rates + ' text-center'
                                          }
                                        >
                                          {obj.bucket_size_exception}
                                        </StyledTableCell>
                                      );
                                    } else if (dates_filter == 2) {
                                      const found = specailDates.filter(
                                        (obj) =>
                                          moment(obj.date).format(
                                            'YYYY-MM-DD'
                                          ) ==
                                          moment(getDateByIndex(idx)).format(
                                            'YYYY-MM-DD'
                                          )
                                      );
                                      if (found.length > 0) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            style={{
                                              fontSize: '12px',
                                              borderRight:
                                                idx == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={
                                              classes.rates + ' text-center'
                                            }
                                          >
                                            {obj.bucket_size_exception}
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  })()
                                )
                              ) : selectedCluster_idx == 3 ? (
                                cluster4_bucket_index.map((obj, idx) =>
                                  (() => {
                                    if (dates_filter == 1) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={
                                            classes.rates + ' text-center'
                                          }
                                        >
                                          {obj.bucket_size_exception}
                                        </StyledTableCell>
                                      );
                                    } else if (dates_filter == 2) {
                                      const found = specailDates.filter(
                                        (obj) =>
                                          moment(obj.date).format(
                                            'YYYY-MM-DD'
                                          ) ==
                                          moment(getDateByIndex(idx)).format(
                                            'YYYY-MM-DD'
                                          )
                                      );
                                      if (found.length > 0) {
                                        return (
                                          <StyledTableCell
                                            size="small"
                                            style={{
                                              fontSize: '12px',
                                              borderRight:
                                                idx == daily_fetch_len
                                                  ? '5px solid rgba(66, 66, 66, 1)'
                                                  : '',
                                            }}
                                            className={
                                              classes.rates + ' text-center'
                                            }
                                          >
                                            {obj.bucket_size_exception}
                                          </StyledTableCell>
                                        );
                                      }
                                    }
                                  })()
                                )
                              ) : (
                                <></>
                              )}
                            </StyledTableRow>

                            {/* <StyledTableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              size="small"
                              className={classes.sticky}
                              style={{
                                fontWeight: 'bold',
                                width: '300px',
                                zIndex: 10,
                              }}
                            >
                              {selectedCluster_idx + 2} Star Index Movement
                              Strength &nbsp;{' '}
                              <i
                                class="fa fa-external-link"
                                aria-hidden="true"
                                style={{ cursor: 'pointer' }}
                                onClick={() => executeScroll(1, 7, sub_tab)}
                              ></i>
                            </StyledTableCell>
                            {selectedCluster_idx == 0 ? (
                              cluster1_bucket_index.map((obj, idx) => {
                                return (
                                  <StyledTableCell
                                    size="small"
                                    style={{
                                      fontSize: '12px',
                                      borderRight:
                                        idx == daily_fetch_len
                                          ? '5px solid rgba(66, 66, 66, 1)'
                                          : '',
                                    }}
                                    className={classes.rates}
                                  >
                                    {obj.bucket_size_exception}
                                  </StyledTableCell>
                                );
                              })
                            ) : selectedCluster_idx == 1 ? (
                              cluster2_bucket_index.map((obj, idx) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            idx == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {obj.bucket_size_exception}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(idx)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {obj.bucket_size_exception}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : selectedCluster_idx == 2 ? (
                              cluster3_bucket_index.map((obj, idx) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            idx == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {obj.bucket_size_exception}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(idx)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {obj.bucket_size_exception}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : selectedCluster_idx == 3 ? (
                              cluster4_bucket_index.map((obj, idx) =>
                                (() => {
                                  if (dates_filter == 1) {
                                    return (
                                      <StyledTableCell
                                        size="small"
                                        style={{
                                          fontSize: '12px',
                                          borderRight:
                                            idx == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                        }}
                                        className={classes.rates}
                                      >
                                        {obj.bucket_size_exception}
                                      </StyledTableCell>
                                    );
                                  } else if (dates_filter == 2) {
                                    const found = specailDates.filter(
                                      (obj) =>
                                        moment(obj.date).format('YYYY-MM-DD') ==
                                        moment(getDateByIndex(idx)).format(
                                          'YYYY-MM-DD'
                                        )
                                    );
                                    if (found.length > 0) {
                                      return (
                                        <StyledTableCell
                                          size="small"
                                          style={{
                                            fontSize: '12px',
                                            borderRight:
                                              idx == daily_fetch_len
                                                ? '5px solid rgba(66, 66, 66, 1)'
                                                : '',
                                          }}
                                          className={classes.rates}
                                        >
                                          {obj.bucket_size_exception}
                                        </StyledTableCell>
                                      );
                                    }
                                  }
                                })()
                              )
                            ) : (
                              <></>
                            )}
                          </StyledTableRow> */}
                          </>
                        ) : (
                          <></>
                        )}
                      </>

                      <>
                        {selectedCluster.length > 0 &&
                        selectedCluster_idx >= 0 ? (
                          <GetMLOSUsage
                            cluster={selectedCluster}
                            cluster_idx={selectedCluster_idx + 2}
                            sub_tab={sub_tab}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    </TableBody>
                  </Table>
                  <br />
                </Box>
              </TableContainer>
            </div>
          </>
        ) : load ? (
          <LoadingOverlay show={load} />
        ) : (
          <></>
        )}
      </>
    );
  };

  const TabularNav = () => {
    const [tabularNavCls] = useState(
      'text-light border-bottom-0 border-secondary  ' + classes.tabularNavStyle
    );
    return (
      <div style={{ position: 'sticky', top: 185, zIndex: 300 }}>
        <Nav variant="tabs" justify="space-around">
          <Nav.Item>
            <Nav.Link
              className={
                tab === 1
                  ? tabularNavCls
                  : 'text-dark font-weight-bold bg-light  shadow '
              }
              eventKey="link-1"
              onClick={() => setTab(1)}
            >
              All Hotels
            </Nav.Link>
          </Nav.Item>{' '}
          {/* {ratingCluster.min_rating && reqHotel.length > 0 ? (
            reqHotelRatings >= ratingCluster.min_rating &&
            ratingCluster.clusterData.length > 0 ? (
              <Nav.Item>
                <Nav.Link
                  className={
                    tab === 2
                      ? tabularNavCls
                      : 'text-dark font-weight-bold bg-light shadow '
                  }
                  eventKey="link-1"
                  onClick={() => setTab(2)}
                >
                  Best Rated Hotels
                </Nav.Link>
              </Nav.Item>
            ) : (
              <></>
            )
          ) : (
            ''
          )} */}
        </Nav>
      </div>
    );
  };

  return (
    <>
      {comp_report_state && net_bucket_data_state ? (
        <>
          {/* <TabularNav /> */}

          {hotels.length > 0 && tab === 1 && clusterData.length > 0 ? (
            <GetInsightsTable
              className="my-5"
              _clusterData={clusterData}
              _cluster1={cluster1}
              _cluster2={cluster2}
              _cluster3={cluster3}
              _cluster4={cluster4}
              _hotels={hotels}
              _reqHotel={reqHotel}
              sub_tab={1}
            />
          ) : (
            <></>
          )}

          {/* {hotels.length > 0 &&
          tab === 2 &&
          ratingCluster.clusterData.length > 0 ? (
            <GetInsightsTable
              className="my-5"
              _clusterData={ratingCluster.clusterData}
              _cluster1={ratingCluster.cluster1}
              _cluster2={ratingCluster.cluster2}
              _cluster3={ratingCluster.cluster3}
              _cluster4={ratingCluster.cluster4}
              _hotels={bestRatedHotels}
              _reqHotel={ratingCluster.reqHotel}
              sub_tab={2}
            />
          ) : (
            <></>
          )} */}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
