import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { CLUSTER_BACKGROUND, CLUSTER_LABELS, FONT_FAMILY } from '../utils/const';

import ClusterDataTable from './ClusterDataTable';
import OverallMarketAnalysis from './OverallMarketAnalysis';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import moment from 'moment/moment';
import { ClusterVarienceDataTable } from './ClusterVarienceDataTable';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
    cursor: 'pointer',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);
const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'block',
  },
  rates: {
    fontFamily: FONT_FAMILY,
    textAlign: 'center',
  },
  formControl: {
    minWidth: 120,
  },
  tabularNavStyle: {
    backgroundColor: '#607D8B',
    fontFamily: FONT_FAMILY,
    fontWeight: 'bold',
  },
});

export const Ratebuckets = ({ selectedDate, tbl_ref, sub_tab }) => {
  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    clusterData,
    loading,
    err,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels,
    reqHotel,
    hotelList,
    markets,
    refreshDates,
    ratingCluster,
    comparison_report,
    date_format,
  } = getClusterDataSet;

  const classes = useStyles();
  const [tab, setTab] = useState(sub_tab);

  const cluster_bucket_tbl_2 = useRef(null);
  const cluster_bucket_tbl_3 = useRef(null);
  const cluster_bucket_tbl_4 = useRef(null);
  const cluster_bucket_tbl_5 = useRef(null);

  const [cluster_1_tbl_load, set_cluster_1_tbl_load] = useState(false);
  const [cluster_2_tbl_load, set_cluster_2_tbl_load] = useState(false);
  const [cluster_3_tbl_load, set_cluster_3_tbl_load] = useState(false);
  const [cluster_4_tbl_load, set_cluster_4_tbl_load] = useState(false);

  const daily_fetch_len = selectedDate
    ? moment(moment(selectedDate).add(180, 'days'))
        .endOf('month')
        .day('sunday')
        .diff(selectedDate, 'days')
    : 0;

  const TabularNav = () => {
    const [tabularNavCls] = useState(
      'text-light border-bottom-0 border-secondary  ' + classes.tabularNavStyle
    );
    return (
      <div style={{ position: 'sticky', top: 185, zIndex: 200 }}>
        <Nav variant="tabs" justify="space-around">
          <Nav.Item>
            <Nav.Link
              className={
                tab === 1
                  ? tabularNavCls
                  : 'text-dark font-weight-bold bg-light  shadow '
              }
              eventKey="link-1"
              onClick={() => setTab(1)}
            >
              All Hotels
            </Nav.Link>
          </Nav.Item>{' '}
          {/* {ratingCluster.clusterData.length > 0 ? (
            <Nav.Item>
              <Nav.Link
                className={
                  tab === 2
                    ? tabularNavCls
                    : 'text-dark font-weight-bold bg-light shadow '
                }
                eventKey="link-1"
                onClick={() => setTab(2)}
              >
                Best Rated Hotels
              </Nav.Link>
            </Nav.Item>
          ) : (
            <></>
          )} */}
        </Nav>
      </div>
    );
  };

  useEffect(() => {
    const handdle_scroll = () => {
      if (tbl_ref == 1 && cluster_1_tbl_load) {
        window.scrollTo(0, cluster_bucket_tbl_2.current.offsetTop - 250);
      } else if (tbl_ref == 2 && cluster_2_tbl_load) {
        window.scrollTo(0, cluster_bucket_tbl_3.current.offsetTop - 250);
      } else if (tbl_ref == 3 && cluster_3_tbl_load) {
        window.scrollTo(0, cluster_bucket_tbl_4.current.offsetTop - 250);
      } else if (tbl_ref == 4 && cluster_4_tbl_load) {
        window.scrollTo(0, cluster_bucket_tbl_5.current.offsetTop - 250);
      }
    };
    handdle_scroll();
  }, [
    cluster_1_tbl_load,
    cluster_2_tbl_load,
    cluster_3_tbl_load,
    cluster_4_tbl_load,
  ]);

  const GetYourPropertyTbl = ({ req_hotel }) => {
    return (
      <>
        {req_hotel.length > 0 ? (
          <>
            <TableContainer component={Paper} className="my-5">
              <Box width={100}>
                <Table
                  className={classes.table}
                  size="medium"
                  aria-label="customized table"
                  bodyStyle={{ overflow: 'visible' }}
                  stickyHeader
                >
                  <TableHead>
                    <StyledTableCell
                      style={{
                        width: '250px',
                        zIndex: 100,
                        fontFamily: FONT_FAMILY,
                      }}
                    >
                      <TableSortLabel disabled>
                        Your Property <br />
                        {(() => {
                          let stars = null;
                          req_hotel.map((e, index) => {
                            if (e.stars !== null && e.stars != '-') {
                              stars = e.stars;
                            }
                          });

                          return stars;
                        })()}
                        -Stars | {req_hotel[0].raings} Rating
                      </TableSortLabel>{' '}
                      <hr />
                      <TableSortLabel disabled>Days Out</TableSortLabel>
                    </StyledTableCell>
                    {req_hotel.map((e, index) =>
                      (() => {
                        let _date = moment(e.checkIn);
                        let daysOut = _date.diff(selectedDate, 'days');
                        let date = _date.format('dddd').substring(0, 3);
                        return (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={
                              date === 'Sat' || date === 'Fri'
                                ? 'bg-secondary text-light text-center'
                                : 'text-center'
                            }
                            style={{
                              fontSize: '12px',
                              borderRight:
                                index + 1 == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            <>
                              {date === 'Sat' || date === 'Fri'
                                ? 'WEND'
                                : 'WDAY'}
                            </>
                            <br />
                            <>{date.toUpperCase()}</>
                            <br />
                            <>{moment(e.checkIn).format(date_format)}</>{' '}
                            <div class="dropdown-divider"></div>
                            {daysOut}
                          </StyledTableCell>
                        );
                      })()
                    )}
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        size="medium"
                        component="th"
                        scope="col"
                        className={classes.sticky}
                        style={{ fontWeight: 'bold', width: '250px' }}
                      >
                        Current Rate
                      </StyledTableCell>
                      {req_hotel.map((e, index) =>
                        e.message ? (
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                {e.message}
                              </Tooltip>
                            }
                          >
                            <StyledTableCell
                              size="small"
                              key={index}
                              style={{
                                backgroundColor:
                                e.rate == '-' ? '': e.cluster <= 5 ? CLUSTER_BACKGROUND[e.cluster - 2]: CLUSTER_BACKGROUND[4],
                                borderRight:
                                  index + 1 == daily_fetch_len
                                    ? '5px solid rgba(66, 66, 66, 1)'
                                    : '',
                              }}
                              className={classes.rates}
                            >
                              {e.rate}
                            </StyledTableCell>
                          </OverlayTrigger>
                        ) : (
                          <StyledTableCell
                            size="small"
                            key={index}
                            style={{
                              backgroundColor:
                                e.rate == '-' ? '': e.cluster <= 5 ? CLUSTER_BACKGROUND[e.cluster - 2]: CLUSTER_BACKGROUND[4],
                              borderRight:
                                index + 1 == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                            className={classes.rates}
                          >
                            {e.rate}
                          </StyledTableCell>
                        )
                      )}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{ fontWeight: 'bold', width: '250px' }}
                      >
                        Current Rate Bucket
                      </StyledTableCell>

                      {req_hotel.map((e, index) => (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={classes.rates}
                          style={{
                            borderRight:
                              index + 1 == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                        >
                           <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            {e.cluster != '-' ? (
                              CLUSTER_LABELS[e.cluster - 2]
                            ) : (CLUSTER_LABELS[4])}
                          </span>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{ fontWeight: 'bold', width: '250px' }}
                      >
                        Rate Bucket Position
                      </StyledTableCell>

                      {req_hotel.map((e, index) => (
                        <StyledTableCell
                          size="small"
                          key={index}
                          style={{
                            fontWeight: 'bold',
                            fontSize: '12px',
                            borderRight:
                              index + 1 == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                          className={classes.rates}
                        >
                          {e.pos}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          zIndex: 100,
                        }}
                      >
                        Rate Bucket Rank (Highest to Lowest)
                      </StyledTableCell>

                      {req_hotel.map((e, index) => (
                        <StyledTableCell
                          size="small"
                          key={index}
                          style={{
                            fontSize: '14px',
                            borderRight:
                              index + 1 == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                          className={classes.rates}
                        >
                          <sup>{e.rank.split('/')[0]}</sup>&frasl;
                          <sub>{e.rank.split('/')[1]}</sub>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableBody>
                </Table>
                <br />
              </Box>
            </TableContainer>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      {/* <TabularNav /> */}
      {hotels.length > 0 ? (
        <>
          <GetYourPropertyTbl
            req_hotel={
              tab == 1
                ? reqHotel
                : tab == 2
                ? ratingCluster.reqHotel.length > 0
                  ? ratingCluster.reqHotel
                  : []
                : []
            }
          />

          <OverallMarketAnalysis
            selectedDate={selectedDate}
            rows_only={false}
            className="my-5"
          />

          {tab === 1 && clusterData.length > 0 ? (
            <>
              <>
                <div id="stars5" className="my-5" ref={cluster_bucket_tbl_5}>
                  <ClusterDataTable
                    cluster={cluster4}
                    stars={5}
                    type="cluster"
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.clusterData.length > 0
                          ? comparison_report.clusterData
                          : []
                        : []
                    }
                    set_cluster_load={set_cluster_4_tbl_load}
                  />
                </div>
                <div id="stars4" className="my-5" ref={cluster_bucket_tbl_4}>
                  <ClusterDataTable
                    cluster={cluster3}
                    stars={4}
                    type="cluster"
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.clusterData.length > 0
                          ? comparison_report.clusterData
                          : []
                        : []
                    }
                    set_cluster_load={set_cluster_3_tbl_load}
                  />
                </div>
                <div id="stars3" className="my-5" ref={cluster_bucket_tbl_3}>
                  <ClusterDataTable
                    cluster={cluster2}
                    stars={3}
                    type="cluster"
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.clusterData.length > 0
                          ? comparison_report.clusterData
                          : []
                        : []
                    }
                    set_cluster_load={set_cluster_2_tbl_load}
                  />
                </div>
                <div id="stars2" className="my-5" ref={cluster_bucket_tbl_2}>
                  <ClusterDataTable
                    cluster={cluster1}
                    stars={2}
                    type="cluster"
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.clusterData.length > 0
                          ? comparison_report.clusterData
                          : []
                        : []
                    }
                    set_cluster_load={set_cluster_1_tbl_load}
                  />
                </div>
              </>

              {/* <>
                <div id="stars5" className="my-5" ref={cluster_bucket_tbl_5}>
                  <ClusterVarienceDataTable
                    cluster={cluster4}
                    stars={5}
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.clusterData.length > 0
                          ? comparison_report.clusterData
                          : []
                        : []
                    }
                  />
                </div>
                <div id="stars4" className="my-5" ref={cluster_bucket_tbl_4}>
                  <ClusterVarienceDataTable
                    cluster={cluster3}
                    stars={4}
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.clusterData.length > 0
                          ? comparison_report.clusterData
                          : []
                        : []
                    }
                  />
                </div>
                <div id="stars3" className="my-5" ref={cluster_bucket_tbl_3}>
                  <ClusterVarienceDataTable
                    cluster={cluster2}
                    stars={3}
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.clusterData.length > 0
                          ? comparison_report.clusterData
                          : []
                        : []
                    }
                  />
                </div>
                <div id="stars2" className="my-5" ref={cluster_bucket_tbl_2}>
                  <ClusterVarienceDataTable
                    cluster={cluster1}
                    stars={2}
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.clusterData.length > 0
                          ? comparison_report.clusterData
                          : []
                        : []
                    }
                  />
                </div>
              </> */}
            </>
          ) : tab === 2 && ratingCluster.clusterData.length > 0 ? (
            <>
              {/* <>
                <div id="stars5" className="my-5" ref={cluster_bucket_tbl_5}>
                  <ClusterDataTable
                    cluster={ratingCluster.cluster4}
                    stars={5}
                    type="ratingCluster"
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.rating_cluster_report.length > 0
                          ? comparison_report.rating_cluster_report[0].clusters
                          : []
                        : []
                    }
                    set_cluster_load={set_cluster_4_tbl_load}
                  />
                </div>
                <div id="stars4" className="my-5" ref={cluster_bucket_tbl_4}>
                  <ClusterDataTable
                    cluster={ratingCluster.cluster3}
                    stars={4}
                    type="ratingCluster"
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.rating_cluster_report.length > 0
                          ? comparison_report.rating_cluster_report[0].clusters
                          : []
                        : []
                    }
                    set_cluster_load={set_cluster_3_tbl_load}
                  />
                </div>
                <div id="stars3" className="my-5" ref={cluster_bucket_tbl_3}>
                  <ClusterDataTable
                    cluster={ratingCluster.cluster2}
                    stars={3}
                    type="ratingCluster"
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.rating_cluster_report.length > 0
                          ? comparison_report.rating_cluster_report[0].clusters
                          : []
                        : []
                    }
                    set_cluster_load={set_cluster_2_tbl_load}
                  />
                </div>
                <div id="stars2" className="my-5" ref={cluster_bucket_tbl_2}>
                  <ClusterDataTable
                    cluster={ratingCluster.cluster1}
                    stars={2}
                    type="ratingCluster"
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.rating_cluster_report.length > 0
                          ? comparison_report.rating_cluster_report[0].clusters
                          : []
                        : []
                    }
                    set_cluster_load={set_cluster_1_tbl_load}
                  />
                </div>
              </>
              <>
                <div id="stars5" className="my-5" ref={cluster_bucket_tbl_5}>
                  <ClusterVarienceDataTable
                    cluster={ratingCluster.cluster4}
                    stars={5}
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.rating_cluster_report.length > 0
                          ? comparison_report.rating_cluster_report[0].clusters
                          : []
                        : []
                    }
                  />
                </div>
                <div id="stars4" className="my-5" ref={cluster_bucket_tbl_4}>
                  <ClusterVarienceDataTable
                    cluster={ratingCluster.cluster3}
                    stars={4}
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.rating_cluster_report.length > 0
                          ? comparison_report.rating_cluster_report[0].clusters
                          : []
                        : []
                    }
                  />
                </div>
                <div id="stars3" className="my-5" ref={cluster_bucket_tbl_3}>
                  <ClusterVarienceDataTable
                    cluster={ratingCluster.cluster2}
                    stars={3}
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.rating_cluster_report.length > 0
                          ? comparison_report.rating_cluster_report[0].clusters
                          : []
                        : []
                    }
                  />
                </div>
                <div id="stars2" className="my-5" ref={cluster_bucket_tbl_2}>
                  <ClusterVarienceDataTable
                    cluster={ratingCluster.cluster1}
                    stars={2}
                    selectedDate={selectedDate}
                    comparison_report={
                      comparison_report
                        ? comparison_report.rating_cluster_report.length > 0
                          ? comparison_report.rating_cluster_report[0].clusters
                          : []
                        : []
                    }
                  />
                </div>
              </> */}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
