import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { CLUSTER_BACKGROUND, CLUSTER_LABELS, FONT_FAMILY } from '../utils/const';
import SearchBar from 'material-ui-search-bar';
import { Col, Nav, Row } from 'react-bootstrap';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: window.innerHeight - 275,
  },
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  rates: {
    fontFamily: FONT_FAMILY,
    textAlign: 'center',
  },
}));

export default function SimilarityByDate({ selectedDate }) {
  const classes = useStyles();
  const [dates, setDates] = useState([]);
  const [sortDir, setSortDir] = useState('desc');

  const [sortBy, setSortBy] = useState(1);

  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    loading,
    err,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels,
    reqHotel,
    ratingCluster,
    date_format,
  } = getClusterDataSet;

  const daily_fetch_len = selectedDate
    ? moment(moment(selectedDate).add(180, 'days'))
        .endOf('month')
        .day('sunday')
        .diff(selectedDate, 'days')
    : 0;

  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const [originalRows, setOriginalRows] = useState([]);

  const [nights, setNights] = useState(0);

  const [binding, setBinding] = useState(true);

  const [searched, setSearched] = useState('');

  const report_len = 90;

  const [hotelsList, setHotelsList] = useState([]);
  
  const [dateSelection, setDateSelection] = useState(0);

  const requestSearch = (searchedVal) => {
    // setSearched(searchedVal);
    const filteredRows = originalRows.filter((row) => {
      return row.hotelName.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setHotelsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  useEffect(() => {
    requestSearch(searched);
  }, [searched]);

  const calculateDistance = (p1, p2) => {
    var a = ((p2.stars - p1.stars) / p1.stars) * 100;
    var b = ((p2.cluster - p1.cluster) / p1.cluster) * 100;
    var c = ((p2.ratings - p1.ratings) / p1.ratings) * 100;
    var d = ((p2.rate - p1.rate) / p1.rate) * 100;

    return Math.abs(Math.hypot(c, d));
  };

  const getSimilarityRank = (arr) => {
    var sorted = arr
      .filter((e) => e.similiarity_score != 'NaN')
      .sort((a, b) => a.similiarity_score - b.similiarity_score);

    var rank = 1;
    for (var i = 0; i < sorted.length; i++) {
      if (
        i > 0 &&
        sorted[i].similiarity_score > sorted[i - 1].similiarity_score
      ) {
        rank++;
      }
      sorted[i].similarityRank = rank;
    }

    // console.log(sorted);
    return sorted;
  };

  const getReqHotelData = () => {
    let name = null;
    if (reqHotel.length > 0) {
      reqHotel.map((e, index) => {
        if (e.name !== null) {
          name = e.name;
        }
      });
    }

    return name;
  };


  useEffect(() => {
    const handleSimilarityByDate = () => {
      setBinding(true);
      if(hotels.length > 0){

        const score_arr = []

          hotels.map((_hotel, id) => {
            if (_hotel.prices[dateSelection] != null) {
              let p1 = {
                stars: reqHotel[dateSelection].stars,
                cluster: getClusterByPrice(reqHotel[dateSelection].rate, dateSelection),
                rate: reqHotel[dateSelection].rate,
                ratings: reqHotel[dateSelection].raings,
              };
  
              let p2 = {
                stars: _hotel.stars,
                cluster: getClusterByPrice(
                  _hotel.prices[dateSelection].price[getPrice(_hotel.prices[dateSelection].price)],
                  dateSelection
                ),
                rate: _hotel.prices[dateSelection].price[getPrice(_hotel.prices[dateSelection].price)],
                ratings: _hotel.ratings,
              };
  
              const score = parseFloat(
                calculateDistance(p2, p1).toFixed(2)
              );

              _hotel.prices[dateSelection].score = score

              if(score != 'NaN'){
                let n_hotel = {
                  checkIn: _hotel.checkIn,
                  hotelID: _hotel.hotelID,
                  hotelName: _hotel.hotelName,
                  stars: _hotel.stars,
                  price: _hotel.prices[dateSelection].price[getPrice(_hotel.prices[dateSelection].price)],
                  cluster: getClusterByPrice(
                    _hotel.prices[dateSelection].price[getPrice(_hotel.prices[dateSelection].price)],
                    dateSelection
                  ) + 2,
                  rank: _hotel.prices[dateSelection].rank,
                  similiarity_score: _hotel.prices[dateSelection].score,
                  avg_diff: ((_hotel.prices[dateSelection].price[getPrice(_hotel.prices[dateSelection].price)] - reqHotel[dateSelection].rate) /
                        reqHotel[dateSelection].rate) *
                      100,
                  ratings: _hotel.ratings,
                  diff: _hotel.prices[dateSelection].price[getPrice(_hotel.prices[dateSelection].price)] - reqHotel[dateSelection].rate
                };
                score_arr.push(n_hotel);
              }
            }
          });

          const ranks_arr = getSimilarityRank(score_arr);

          const ranked_hotels = ranks_arr.sort((a, b) => a.similarityRank - b.similarityRank).slice(0, 25)

          setOriginalRows(ranked_hotels);
          setHotelsList(ranked_hotels);

      }

      setBinding(false);
    }

    handleSimilarityByDate()
  }, [dateSelection])
  

  const getClusterByPrice = (rate, ix) => {
    let clustered = [];
    let res = 4;

    if (cluster1.length > 0 && cluster1[ix]) {
      clustered.push(cluster1[ix]);
    }
    if (cluster2.length > 0 && cluster2[ix]) {
      clustered.push(cluster2[ix]);
    }
    if (cluster3.length > 0 && cluster3[ix]) {
      clustered.push(cluster3[ix]);
    }
    if (cluster4.length > 0 && cluster4[ix]) {
      clustered.push(cluster4[ix]);
    }

    clustered.sort((a, b) => a.mean - b.mean);

    // console.log(clustered);

    try {
      clustered.map((cl, id) => {
        if (rate >= cl.min && rate <= cl.max) {
          res = id;
          return;
        }
      });
    } catch (e) {}

    return res;
  };

  // const handleHotelsFilter = async (event) => {
  //   if (event.target.value == 0) {
  //     const selectedHotels = [hotels[0]];
  //     user.application.candidate_properties.map((_filterHotel) =>
  //       hotels.some((hotel) => {
  //         if (hotel.hotelID === _filterHotel.id) {
  //           selectedHotels.push(hotel);
  //         }
  //       })
  //     );
  //     setHotelsList(selectedHotels);
  //   } else {
  //     setHotelsList(hotels);
  //   }
  // };

  const handleNightsFilter = async (event) => {
    setNights(event.target.value);
  };

  const getPrice = (arr) => {
    const price = arr.findIndex((e) => e > 0);
    return price;
  };

  const mode = (arr) => {
    return arr
      .sort(
        (a, b) =>
          arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
      )
      .pop();
  };

  const checkHotelAvailability = (id, day) => {
    let clustered = [];

    if (cluster1.length > 0 && cluster1[day]) {
      clustered.push(cluster1[day].unwanted);
    }
    if (cluster2.length > 0 && cluster2[day]) {
      clustered.push(cluster2[day].unwanted);
    }
    if (cluster3.length > 0 && cluster3[day]) {
      clustered.push(cluster3[day].unwanted);
    }
    if (cluster4.length > 0 && cluster4[day]) {
      clustered.push(cluster4[day].unwanted);
    }

    let hotels_arr = [];

    for (var i = 0; i < clustered.length; i++) {
      hotels_arr = hotels_arr.concat(clustered[i]);
    }

    const exists = hotels_arr.some((obj) => obj.id == id);

    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  const handleHotelsFilter = async (event) => {
    if (event.target.value == 0) {
      const selectedHotels = [hotels[0]];
      user.application.candidate_properties.map((_filterHotel) =>
        originalRows.some((hotel) => {
          if (hotel.hotelID === _filterHotel.id) {
            selectedHotels.push(hotel);
          }
        })
      );
      setHotelsList(selectedHotels);
    } else if (event.target.value == 2) {
      if (ratingCluster.min_rating) {
        if (hotelsList.length > 0) {
          setHotelsList(
            hotelsList.filter((h) => h.ratings >= ratingCluster.min_rating)
          );
        }
      }
    } else {
      setHotelsList(originalRows);
    }
  };

  const handleDatePicker = (e) => {
    setDateSelection(e);
  };

  return (
    <>
      {hotels.length > 0 &&
      originalRows.length > 0 &&
      !binding ? (
        <>
          <Grid container justify="space-evenly" className="my-3">
            <FormGroup className={classes.formControl}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => setSearched(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
            </FormGroup>
            <FormGroup className={classes.formControl}>
              <InputLabel
                htmlFor="grouped-native-select"
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                Hotels Filter
              </InputLabel>
              <Select
                native
                id="grouped-native-select"
                onChange={handleHotelsFilter}
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                <option value={1}>All Hotels</option>
                <option value={2}>Best Rated Hotels</option>
                <option value={0}>Analysis Set</option>
              </Select>
            </FormGroup>
            <FormGroup>
            <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-native-select">Select Date</InputLabel>
            <Select
              native={true}
              onChange={(e) => handleDatePicker(e.target.value)}
              id="grouped-native-select"
              value={dateSelection}
            >
              {reqHotel.map((e, i) => (
                <option value={i}>
                  {moment(e.checkIn).format(date_format)}
                </option>
              ))}
            </Select>
          </FormControl>
            </FormGroup>
          </Grid>

          <Row>
              <TableContainer
                component={Paper}
                className={classes.container + ' mt-3'}
              >
                <Box>
                  <Table
                    className={classes.table}
                    size="medium"
                    aria-label="customized table"
                    stickyHeader
                    bodystyle={{ overflow: 'visible' }}
                  >
                    <TableHead>
                      <StyledTableRow className="text-center">
                        <StyledTableCell size="small">#</StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontWeight: 'bold',
                            zIndex: 100,
                            fontFamily: FONT_FAMILY,
                          }}
                        >
                          Hotel Name
                        </StyledTableCell>
                        <StyledTableCell size="small">Stars</StyledTableCell>
                        <StyledTableCell size="small">Ratings</StyledTableCell>
                        <StyledTableCell size="small">
                          Bucket - Position
                        </StyledTableCell>
                        <StyledTableCell size="small">
                          Rate Difference
                        </StyledTableCell>
                        <StyledTableCell size="small">
                          Rate % Difference
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      {hotelsList.map((_hotel, index) => (
                        <StyledTableRow>
                          <StyledTableCell size="small">
                            {index}
                          </StyledTableCell>
                          <StyledTableCell
                            size="medium"
                            component="th"
                            scope="col"
                            className={classes.sticky}
                            style={{ fontWeight: 'bold' }}
                          >
                            {_hotel.hotelName}
                          </StyledTableCell>
                          <StyledTableCell
                            size="small"
                            className={classes.rates}
                          >
                            {_hotel.stars}
                          </StyledTableCell>
                          <StyledTableCell
                            size="small"
                            className={classes.rates}
                          >
                            {_hotel.ratings}
                          </StyledTableCell>
                          <StyledTableCell
                                size="small"
                                className={classes.rates}
                                style={{
                                  backgroundColor:
                                  _hotel.price && _hotel.cluster <= 5 ? CLUSTER_BACKGROUND[_hotel.cluster - 2]: CLUSTER_BACKGROUND[4],
                                  borderRight:
                                    index + 1 == daily_fetch_len
                                      ? '5px solid rgba(66, 66, 66, 1)'
                                      : '',
                                }}
                            >
                            {_hotel.price && _hotel.cluster <= 5 ?  CLUSTER_LABELS[_hotel.cluster - 2] : CLUSTER_LABELS[4]} - {_hotel.rank}
                            </StyledTableCell>
                          <StyledTableCell
                            size="small"
                            className={classes.rates}
                          >
                            {Math.round(_hotel.diff)}
                          </StyledTableCell>
                          <StyledTableCell
                            size="small"
                            className={classes.rates}
                          >
                            {parseFloat(_hotel.avg_diff).toFixed(2)}%
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <br />
                </Box>
              </TableContainer>
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
