import {
  Box,
  makeStyles,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { CLUSTER_BACKGROUND, CLUSTER_LABELS, FONT_FAMILY } from '../utils/const';
import { LoadingOverlay } from './UI/LoadingOverlay';
import { useSelector } from 'react-redux';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import $ from 'jquery';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 14,
    minWidth: 100,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'block',
    zIndex: 100,
  },
  rates: {
    fontFamily: FONT_FAMILY,
    textAlign: 'center',
  },
});

ReactHTMLTableToExcel.format = (s, c) => {
  // console.log(`c: ${JSON.stringify(c)}\n s: ${s}`);
  if (c && c['table']) {
    const tables_html = document.getElementsByTagName('table');
    const html = c.table;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const rows = doc.querySelectorAll('tr');

    console.log(`doc: ${new XMLSerializer().serializeToString(tables_html)}`);

    // for (const row of rows) row.removeChild(row.firstChild);

    // c.table = doc.querySelector('table').outerHTML;
  }

  return s.replace(/{(\w+)}/g, (m, p) => c[p]);
};

function checkRatebucketMovement(a, b, c) {
  if ((a && b && c) || (a && b && !c) || (a && !b && c) || (!a && b && c)) {
    return true;
  } else {
    return false;
  }
}

export default function ClusterDataTable({
  cluster,
  stars,
  selectedDate,
  type,
  comparison_report,
  insight_data,
  executeScroll,
  set_cluster_load,
  sub_tab,
}) {
  const classes = useStyles();

  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const { loading, reqHotel, date_format } = getClusterDataSet;

  const [load, setLoad] = useState(true);

  const [rateStrength, setRateStrength] = useState([]);

  const [rateVarianceStrength_avg, setRateVarianceStrength_avg] = useState(0);

  const [rateVarianceStrength_stdev, setRateVarianceStrength_stdev] =
    useState(0);

  const varianceTableState = comparison_report.length > 0 ? true : false;

  const daily_fetch_len = selectedDate
    ? moment(moment(selectedDate).add(180, 'days'))
        .endOf('month')
        .day('sunday')
        .diff(selectedDate, 'days')
    : 0;

  const getAvg = (arr) => {
    if (arr.length > 0) {
      return arr.reduce((a, b) => a + b) / arr.length;
    }
  };

  function getStandardDeviation(array) {
    if (array.length === 0) {
      return 0;
    }
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );
  }

  const getVarience = (value, avg, stdev) => {
    if (value >= avg + 1.65 * stdev) {
      return 'V Strong';
    } else if (value >= avg + stdev) {
      return 'Strong';
    } else {
      return '';
    }
  };


  useEffect(() => {
    const rateStrengthHandler = () => {
      setLoad(true);
      let midAvgArr_wd = [];
      let midAvgArr_we = [];
      let mid_avg_rates = []

      cluster.map((e, i) => {
        if (e && e != undefined) {
          let dateMatch = comparison_report.find((obj) => {
            try {
              if (obj && obj != undefined && obj[stars - 2] != undefined) {
                if (
                  moment(e.date).format('YYYY-MM-DD') ===
                  moment(obj[stars - 2].date).format('YYYY-MM-DD')
                ) {
                  return obj;
                }
              }
            } catch (e) {}
          });

          if (dateMatch) {
            let comp_report = dateMatch[stars - 2];
            delete comp_report.cluster;
            delete comp_report.unwanted;

            e.comp_report = comp_report;

            e.rate_movements = checkRatebucketMovement(
              ((e.highAVG - comp_report.highAVG) / comp_report.highAVG) * 100 >
                0,
              ((e.midAVG - comp_report.midAVG) / comp_report.midAVG) * 100 > 0,
              ((e.lowAVG - comp_report.lowAVG) / comp_report.lowAVG) * 100 > 0
            );

            if (i <= 90) {
              if (e.midAVG != 'NaN' && comp_report.midAVG != 'NaN') {
                mid_avg_rates.push(
                  Math.abs(
                    ((e.midAVG - comp_report.midAVG) / comp_report.midAVG) * 100
                  )
                );
              }
            }
          }
        }

        if (e.midAVG != 'NaN') {
          let _date = moment(selectedDate, 'YYYY-MM-DD').add(i, 'days');
          let day = _date.format('dddd').substring(0, 3);

          if (day == 'Sat' || day == 'Fri') {
            midAvgArr_we.push(Math.round(e.midAVG));
          } else {
            midAvgArr_wd.push(Math.round(e.midAVG));
          }
        }
      });

      setRateVarianceStrength_stdev(getStandardDeviation(mid_avg_rates));
      setRateVarianceStrength_avg(getAvg(mid_avg_rates));

      const sd_wd = getStandardDeviation(midAvgArr_wd);
      const sd_we = getStandardDeviation(midAvgArr_we);

      const avg_wd = midAvgArr_wd.reduce((a, b) => a + b) / midAvgArr_wd.length;
      const avg_we = midAvgArr_we.reduce((a, b) => a + b) / midAvgArr_we.length;

      let _rateStrength = [];

      cluster.map((e, index) => {
        let _date = moment(selectedDate, 'YYYY-MM-DD').add(index, 'days');
        let day = _date.format('dddd').substring(0, 3);

        if (day == 'Sat' || day == 'Fri') {
          if (e.midAVG >= avg_we + 1.65 * sd_we) {
            e.rateStrength = 'Very High';
            _rateStrength.push('Very High');
          }
          if (
            e.midAVG >= avg_we + 1 * sd_we &&
            e.midAVG < avg_we + 1.65 * sd_we
          ) {
            e.rateStrength = 'High';
            _rateStrength.push('High');
          }
          if (e.midAVG >= avg_we - 1 * sd_we && e.midAVG < avg_we + 1 * sd_we) {
            e.rateStrength = '';
            _rateStrength.push('');
          }
          if (
            e.midAVG >= avg_we - 1.65 * sd_we &&
            e.midAVG < avg_we - 1 * sd_we
          ) {
            e.rateStrength = 'Low';
            _rateStrength.push('Low');
          }
          if (e.midAVG <= avg_we - 1.65 * sd_we) {
            e.rateStrength = 'Very Low';
            _rateStrength.push('Very Low');
          }
        } else {
          if (e.midAVG >= avg_wd + 1.65 * sd_wd) {
            e.rateStrength = 'Very High';
            _rateStrength.push('Very High');
          }
          if (
            e.midAVG >= avg_wd + 1 * sd_wd &&
            e.midAVG < avg_wd + 1.65 * sd_wd
          ) {
            e.rateStrength = 'High';
            _rateStrength.push('High');
          }
          if (e.midAVG >= avg_wd - 1 * sd_wd && e.midAVG < avg_wd + 1 * sd_wd) {
            e.rateStrength = '';
            _rateStrength.push('');
          }
          if (
            e.midAVG >= avg_wd - 1.65 * sd_wd &&
            e.midAVG < avg_wd - 1 * sd_wd
          ) {
            e.rateStrength = 'Low';
            _rateStrength.push('Low');
          }
          if (e.midAVG <= avg_wd - 1.65 * sd_wd) {
            e.rateStrength = 'Very Low';
            _rateStrength.push('Very Low');
          }
        }
      });

      setRateStrength(_rateStrength);

      setLoad(false);
    };

    if (cluster.length > 0 && reqHotel.length > 0) {
      rateStrengthHandler();
    }
  }, [cluster]);

  const getReportName = () => {
    let name = null;
    if (reqHotel.length > 0) {
      reqHotel.map((e, index) => {
        if (e.name !== null) {
          name = e.name;
          return;
        }
      });
    }

    return `${name}-Rate_Buckets-Cluster-${stars}-${moment(selectedDate).format(
      'YYYY-MM-DD'
    )}`;
  };

  const tbOptions = {
    headers: true,
    formats: ['xlsx'], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
    bootstrap: true, // (Boolean), style buttons using bootstrap, (default: true)
    exportButtons: false, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
    position: 'bottom', // (top, bottom), position of the caption element relative to table, (default: 'bottom')
  };


  const GetInsightsTableData = () => {
    return (
      <>
        <StyledTableRow>
          <StyledTableCell
            size="small"
            component="th"
            scope="row"
            className={classes.sticky}
            style={{
              fontWeight: 'bold',
              width: '300px',
            }}
          >
            {stars} Star Bucket Rate Strength &nbsp;{' '}
            <i
              class="fa fa-external-link"
              aria-hidden="true"
              style={{ cursor: 'pointer' }}
              onClick={() => executeScroll(0, stars - 1, sub_tab)}
            ></i>
          </StyledTableCell>
          {rateStrength.map((e, index) => (
            <StyledTableCell
              size="small"
              key={index}
              className={classes.rates}
              style={{
                fontSize: '12px',
                borderRight:
                  index == daily_fetch_len
                    ? '5px solid rgba(66, 66, 66, 1)'
                    : '',
              }}
            >
              {e ? <span className="">{e}</span> : ''}
            </StyledTableCell>
          ))}
        </StyledTableRow>

        {cluster.length > 0 ? (
          <StyledTableRow>
            <StyledTableCell
              size="small"
              component="th"
              scope="row"
              className={classes.sticky}
              style={{
                fontWeight: 'bold',
                width: '300px',
              }}
            >
              {stars} Star Bucket Rate Movement &nbsp;{' '}
              <i
                class="fa fa-external-link"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
                onClick={() => executeScroll(0, stars - 1, sub_tab)}
              ></i>
            </StyledTableCell>

            {cluster.map((e, index) => (
              <StyledTableCell
                size="small"
                key={index}
                className={classes.rates}
                style={{
                  fontSize: '12px',
                  borderRight:
                    index == daily_fetch_len
                      ? '5px solid rgba(66, 66, 66, 1)'
                      : '',
                }}
              >
                {e.comp_report && e.items > 0 ? (
                  <span
                    className={
                      e.rate_movements ? 'text-success' : 'text-danger'
                    }
                  >
                    {e.rate_movements ? 'Up' : 'Down'}
                  </span>
                ) : (
                  ''
                )}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ) : (
          <></>
        )}

        {cluster.length > 0 ? (
          <StyledTableRow>
            <StyledTableCell
              size="small"
              component="th"
              scope="row"
              className={classes.sticky}
              style={{
                fontWeight: 'bold',
                width: '300px',
              }}
            >
              {stars} Star Bucket Movement Strength &nbsp;{' '}
              <i
                class="fa fa-external-link"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
                onClick={() => executeScroll(0, stars - 1, sub_tab)}
              ></i>
            </StyledTableCell>
            {cluster.map((e, index) => (
              <StyledTableCell
                size="small"
                key={index}
                className={classes.rates}
                style={{
                  borderRight:
                    index == daily_fetch_len
                      ? '5px solid rgba(66, 66, 66, 1)'
                      : '',
                  fontSize: '12px',
                }}
              >
                {e.midAVG !== 'NaN' && e.comp_report && e.items > 0
                  ? getVarience(
                      Math.abs(
                        ((e.midAVG - e.comp_report.midAVG) /
                          e.comp_report.midAVG) *
                          100
                      ),
                      rateVarianceStrength_avg,
                      rateVarianceStrength_stdev
                    )
                  : ''}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ) : (
          <></>
        )}
      </>
    );
  };

  const GetClusterVarience = ({}) => {
    return (
      <>
        {!load && varianceTableState > 0 ? (
          <TableContainer component={Paper} className="my-5">
            <Box width={100}>
              <Table
                id={`clusters-${stars}-to-xls`}
                className={classes.table}
                size="medium"
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      backgroundColor: CLUSTER_BACKGROUND[stars - 2],
                      width: '250px',
                      zIndex: 100,
                      fontFamily: FONT_FAMILY,
                    }}
                  >
                    <TableSortLabel disabled>
                      {' '}
                      {`${stars} Star Bucket % Variance`}
                    </TableSortLabel>{' '}
                    <hr style={{ 'background-color': '#f4f4f4' }} />
                    <TableSortLabel disabled>Days Out</TableSortLabel>
                  </StyledTableCell>
                  {cluster.map((e, index) =>
                    (() => {
                      let _date = moment(e.date, 'YYYY-MM-DD');
                      let daysOut = _date.diff(selectedDate, 'days');
                      let date = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            date === 'Sat' || date === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{
                            fontSize: '12px',
                            borderRight:
                              index == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                        >
                          <>
                            {date === 'Sat' || date === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{date.toUpperCase()}</>
                          <br />
                          <>{moment(_date).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                      }}
                    >
                      Rate Movement
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          fontSize: '12px',
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e.comp_report && e.items > 0 ? (
                          <span
                            className={
                              e.rate_movements ? 'text-success' : 'text-danger'
                            }
                          >
                            {e.rate_movements ? 'Up' : 'Down'}
                          </span>
                        ) : (
                          ''
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>

                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                      }}
                    >
                      Movement Strength
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        style={{
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                          fontSize: '12px',
                        }}
                      >
                        {e.midAVG !== 'NaN' && e.comp_report && e.items > 0
                          ? getVarience(
                              Math.abs(
                                ((e.midAVG - e.comp_report.midAVG) /
                                  e.comp_report.midAVG) *
                                  100
                              ),
                              rateVarianceStrength_avg,
                              rateVarianceStrength_stdev
                            )
                          : ''}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>

                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      Average Rate
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e.mean !== 'NaN' && e.comp_report && e.items > 0 ? (
                          <span>
                            {e.comp_report ? (
                              e.mean > e.comp_report.mean ? (
                                <sup
                                  className={
                                    e.comp_report
                                      ? e.mean > e.comp_report.mean
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.mean < e.comp_report.mean
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sup>
                              ) : (
                                <sub
                                  className={
                                    e.comp_report
                                      ? e.mean > e.comp_report.mean
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.mean < e.comp_report.mean
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sub>
                              )
                            ) : (
                              <></>
                            )}
                            {parseFloat(
                              ((e.mean - e.comp_report.mean) /
                                e.comp_report.mean) *
                                100
                            ).toFixed(1)}
                          </span>
                        ) : e.mean !== 'NaN' && e.items < 0 ? (
                          'NED'
                        ) : (
                          '--'
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>

                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      Most Repeated rate
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e.mod !== 'NaN' && e.comp_report && e.items > 0 ? (
                          <span className="text-wrap">
                            {e.comp_report ? (
                              e.mod > e.comp_report.mod ? (
                                <sup
                                  className={
                                    e.comp_report
                                      ? e.mod > e.comp_report.mod
                                        ? 'fa fa-long-arrow-up  text-success'
                                        : e.mod < e.comp_report.mod
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sup>
                              ) : (
                                <sub
                                  className={
                                    e.comp_report
                                      ? e.mod > e.comp_report.mod
                                        ? 'fa fa-long-arrow-up  text-success'
                                        : e.mod < e.comp_report.mod
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sub>
                              )
                            ) : (
                              <></>
                            )}
                            {parseFloat(
                              ((e.mod - e.comp_report.mod) /
                                e.comp_report.mod) *
                                100
                            ).toFixed(1)}
                          </span>
                        ) : e.mod !== 'NaN' && e.items < 0 ? (
                          'NED'
                        ) : (
                          '--'
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>

                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      Middle Rate
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e.median !== 'NaN' && e.comp_report && e.items > 0 ? (
                          <span>
                            {e.comp_report ? (
                              e.median > e.comp_report.median ? (
                                <sup
                                  className={
                                    e.comp_report
                                      ? e.median > e.comp_report.median
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.median < e.comp_report.median
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sup>
                              ) : (
                                <sub
                                  className={
                                    e.comp_report
                                      ? e.median > e.comp_report.median
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.median < e.comp_report.median
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sub>
                              )
                            ) : (
                              <></>
                            )}
                            {parseFloat(
                              ((e.median - e.comp_report.median) /
                                e.comp_report.median) *
                                100
                            ).toFixed(1)}
                          </span>
                        ) : e.median !== 'NaN' && e.items < 0 ? (
                          'NED'
                        ) : (
                          '--'
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      &emsp;Highest Rate
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e.max !== 'NaN' && e.comp_report && e.items > 0 ? (
                          <span>
                            {e.comp_report ? (
                              e.max > e.comp_report.max ? (
                                <sup
                                  className={
                                    e.comp_report
                                      ? e.max > e.comp_report.max
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.max < e.comp_report.max
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sup>
                              ) : (
                                <sub
                                  className={
                                    e.comp_report
                                      ? e.max > e.comp_report.max
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.max < e.comp_report.max
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sub>
                              )
                            ) : (
                              <></>
                            )}
                            {parseFloat(
                              ((e.max - e.comp_report.max) /
                                e.comp_report.max) *
                                100
                            ).toFixed(1)}
                          </span>
                        ) : e.max !== 'NaN' && e.items < 0 ? (
                          'NED'
                        ) : (
                          '--'
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        borderTop: '2px solid grey',
                      }}
                    >
                      &emsp;&emsp;Average of Highest Rates
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          borderTop: '3px solid grey',
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e.highAVG !== 'NaN' && e.comp_report && e.items > 0 ? (
                          <span>
                            {e.comp_report ? (
                              e.highAVG > e.comp_report.highAVG ? (
                                <sup
                                  className={
                                    e.comp_report
                                      ? e.highAVG > e.comp_report.highAVG
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.highAVG < e.comp_report.highAVG
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sup>
                              ) : (
                                <sub
                                  className={
                                    e.comp_report
                                      ? e.highAVG > e.comp_report.highAVG
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.highAVG < e.comp_report.highAVG
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sub>
                              )
                            ) : (
                              <></>
                            )}
                            {parseFloat(
                              ((e.highAVG - e.comp_report.highAVG) /
                                e.comp_report.highAVG) *
                                100
                            ).toFixed(1)}
                          </span>
                        ) : e.highAVG !== 'NaN' && e.items < 0 ? (
                          'NED'
                        ) : (
                          '--'
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      &emsp;&emsp;Average of Middle Rates
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e.midAVG !== 'NaN' && e.comp_report && e.items > 0 ? (
                          <span>
                            {e.comp_report ? (
                              e.midAVG > e.comp_report.midAVG ? (
                                <sup
                                  className={
                                    e.comp_report
                                      ? e.midAVG > e.comp_report.midAVG
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.midAVG < e.comp_report.midAVG
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sup>
                              ) : (
                                <sub
                                  className={
                                    e.comp_report
                                      ? e.midAVG > e.comp_report.midAVG
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.midAVG < e.comp_report.midAVG
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sub>
                              )
                            ) : (
                              <></>
                            )}
                            {parseFloat(
                              ((e.midAVG - e.comp_report.midAVG) /
                                e.comp_report.midAVG) *
                                100
                            ).toFixed(1)}
                          </span>
                        ) : e.midAVG !== 'NaN' && e.items < 0 ? (
                          'NED'
                        ) : (
                          '--'
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                      }}
                    >
                      &emsp;&emsp;Average of Lowest Rates
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e.lowAVG !== 'NaN' && e.comp_report && e.items > 0 ? (
                          <span>
                            {e.comp_report ? (
                              e.lowAVG > e.comp_report.lowAVG ? (
                                <sup
                                  className={
                                    e.comp_report
                                      ? e.lowAVG > e.comp_report.lowAVG
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.lowAVG < e.comp_report.lowAVG
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sup>
                              ) : (
                                <sub
                                  className={
                                    e.comp_report
                                      ? e.lowAVG > e.comp_report.lowAVG
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.lowAVG < e.comp_report.lowAVG
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sub>
                              )
                            ) : (
                              <></>
                            )}
                            {parseFloat(
                              ((e.lowAVG - e.comp_report.lowAVG) /
                                e.comp_report.lowAVG) *
                                100
                            ).toFixed(1)}
                          </span>
                        ) : e.lowAVG !== 'NaN' && e.items < 0 ? (
                          'NED'
                        ) : (
                          '--'
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>

                  <StyledTableRow Key={stars}>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      &emsp;Lowest Rate
                    </StyledTableCell>

                    {cluster.map((e, index) => (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {e.min !== 'NaN' && e.comp_report && e.items > 0 ? (
                          <span>
                            {e.comp_report ? (
                              e.min > e.comp_report.min ? (
                                <sup
                                  className={
                                    e.comp_report
                                      ? e.min > e.comp_report.min
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.min < e.comp_report.min
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sup>
                              ) : (
                                <sub
                                  className={
                                    e.comp_report
                                      ? e.min > e.comp_report.min
                                        ? 'fa fa-long-arrow-up text-success'
                                        : e.min < e.comp_report.min
                                        ? 'fa fa-long-arrow-down  text-danger'
                                        : ''
                                      : ''
                                  }
                                  aria-hidden="true"
                                >
                                  &nbsp;
                                </sub>
                              )
                            ) : (
                              <></>
                            )}
                            {parseFloat(
                              ((e.min - e.comp_report.min) /
                                e.comp_report.min) *
                                100
                            ).toFixed(1)}
                          </span>
                        ) : e.min !== 'NaN' && e.items < 0 ? (
                          'NED'
                        ) : (
                          '--'
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableBody>
              </Table>
              <br />
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!load) {
      set_cluster_load(true);
    }
  });

  return (
    <>
      {insight_data ? (
        <GetInsightsTableData />
      ) : (
        <>
          {!load && cluster.length > 0 ? (
            <>
              <TableContainer component={Paper} className="my-5">
                <Box width={100}>
                  <Table
                    id={`clusters-${stars}-to-xls`}
                    className={classes.table}
                    size="medium"
                    aria-label="customized table"
                    bodyStyle={{ overflow: 'visible' }}
                    stickyHeader
                  >
                    <TableHead>
                      <StyledTableCell
                        style={{
                          backgroundColor: CLUSTER_BACKGROUND[stars - 2],
                          width: '250px',
                          zIndex: 100,
                          fontFamily: FONT_FAMILY,
                        }}
                      >
                        <TableSortLabel disabled>
                          {' '}
                          {`${CLUSTER_LABELS[stars - 2]} Bucket Matrix`}
                        </TableSortLabel>{' '}
                        <hr style={{ 'background-color': '#f4f4f4' }} />
                        <TableSortLabel disabled>Days Out</TableSortLabel>
                      </StyledTableCell>
                      {cluster.map((e, index) =>
                        (() => {
                          let _date = moment(e.date, 'YYYY-MM-DD');
                          let daysOut = _date.diff(selectedDate, 'days');
                          let date = _date.format('dddd').substring(0, 3);
                          return (
                            <StyledTableCell
                              size="small"
                              key={index}
                              className={
                                date === 'Sat' || date === 'Fri'
                                  ? 'bg-secondary text-light text-center'
                                  : 'text-center'
                              }
                              style={{
                                fontSize: '12px',
                                borderRight:
                                  index == daily_fetch_len
                                    ? '5px solid rgba(66, 66, 66, 1)'
                                    : '',
                              }}
                            >
                              <>
                                {date === 'Sat' || date === 'Fri'
                                  ? 'WEND'
                                  : 'WDAY'}
                              </>
                              <br />
                              <>{date.toUpperCase()}</>
                              <br />
                              <>{moment(_date).format(date_format)}</>{' '}
                              <div class="dropdown-divider"></div>
                              {daysOut}
                            </StyledTableCell>
                          );
                        })()
                      )}
                    </TableHead>
                    <TableBody>
                      <StyledTableRow Key={stars}>
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          className={classes.sticky + ' p-2'}
                          style={{ fontSize: '15px', width: '250px' }}
                        >
                          <span className="font-italic font-weight-bold">
                            Rate Strength
                          </span>
                        </StyledTableCell>

                        {/* {rateStrength.map((e, index) => (
                    <StyledTableCell
                      size="small"
                      key={index}
                      style={{ fontSize: '10px' }}
                    >
                      <span className="">{e}</span>
                    </StyledTableCell>
                  ))} */}
                        {cluster.map((e, index) => (
                          <StyledTableCell
                            size="small"
                            key={index}
                            style={{
                              fontSize: '12px',
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            {e.rateStrength ? (
                              <span className="">{e.rateStrength}</span>
                            ) : (
                              ''
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>

                      <StyledTableRow Key={stars}>
                  <StyledTableCell
                    size="small"
                    component="th"
                    scope="row"
                    className={classes.sticky}
                    style={{
                      fontWeight: 'bold',
                      width: '250px',
                    }}
                  >
                    Rate Movement
                  </StyledTableCell>

                  {cluster.map((e, index) => (
                    <StyledTableCell
                      size="small"
                      key={index}
                      className={classes.rates}
                      style={{
                        fontSize: '12px',
                        borderRight:
                          index == daily_fetch_len
                            ? '5px solid rgba(66, 66, 66, 1)'
                            : '',
                      }}
                    >
                      {e.comp_report && e.items > 0 ? (
                        <span
                          className={
                            e.rate_movements ? 'text-success' : 'text-danger'
                          }
                        >
                          {e.rate_movements ? 'Up' : 'Down'}
                        </span>
                      ) : (
                        ''
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>

                <StyledTableRow Key={stars}>
                  <StyledTableCell
                    size="small"
                    component="th"
                    scope="row"
                    className={classes.sticky}
                    style={{
                      fontWeight: 'bold',
                      width: '250px',
                    }}
                  >
                    Movement Strength
                  </StyledTableCell>

                  {cluster.map((e, index) => (
                    <StyledTableCell
                      size="small"
                      key={index}
                      style={{
                        borderRight:
                          index == daily_fetch_len
                            ? '5px solid rgba(66, 66, 66, 1)'
                            : '',
                        fontSize: '12px',
                      }}
                    >
                      {e.midAVG !== 'NaN' && e.comp_report && e.items > 0
                        ? getVarience(
                            Math.abs(
                              ((e.midAVG - e.comp_report.midAVG) /
                                e.comp_report.midAVG) *
                                100
                            ),
                            rateVarianceStrength_avg,
                            rateVarianceStrength_stdev
                          )
                        : ''}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>

                      <StyledTableRow Key={stars}>
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          className={classes.sticky}
                          style={{ fontWeight: 'bold', width: '250px' }}
                        >
                          Average Rate
                        </StyledTableCell>

                        {cluster.map((e, index) => (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={classes.rates}
                            style={{
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            {e.mean !== 'NaN' && e.items > 0 ? (
                              <span>
                                {e.comp_report ? (
                                  e.mean > e.comp_report.mean ? (
                                    <sup
                                      className={
                                        e.comp_report
                                          ? e.mean > e.comp_report.mean
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.mean < e.comp_report.mean
                                            ? 'fa fa-long-arrow-down text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sup>
                                  ) : (
                                    <sub
                                      className={
                                        e.comp_report
                                          ? e.mean > e.comp_report.mean
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.mean < e.comp_report.mean
                                            ? 'fa fa-long-arrow-down text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sub>
                                  )
                                ) : (
                                  <></>
                                )}
                                {Math.round(e.mean)}
                              </span>
                            ) : e.mean !== 'NaN' && e.items < 0 ? (
                              'NED'
                            ) : (
                              '--'
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>

                      <StyledTableRow Key={stars}>
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          className={classes.sticky}
                          style={{ fontWeight: 'bold', width: '250px' }}
                        >
                          Most Repeated rate
                        </StyledTableCell>

                        {cluster.map((e, index) => (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={classes.rates}
                            style={{
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            {e.mod !== 'NaN' && e.items > 0 ? (
                              <span className="text-wrap">
                                {e.comp_report ? (
                                  e.mod > e.comp_report.mod ? (
                                    <sup
                                      className={
                                        e.comp_report
                                          ? e.mod > e.comp_report.mod
                                            ? 'fa fa-long-arrow-up  text-success'
                                            : e.mod < e.comp_report.mod
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sup>
                                  ) : (
                                    <sub
                                      className={
                                        e.comp_report
                                          ? e.mod > e.comp_report.mod
                                            ? 'fa fa-long-arrow-up  text-success'
                                            : e.mod < e.comp_report.mod
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sub>
                                  )
                                ) : (
                                  <></>
                                )}
                                {Math.round(e.mod)}
                                <sup className="font-weight-bold">
                                &nbsp;{e.cluster.filter(r => r == e.mod).length}
                                </sup>
                              </span>
                            ) : e.mod !== 'NaN' && e.items < 0 ? (
                              'NED'
                            ) : (
                              '--'
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>

                      <StyledTableRow Key={stars}>
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          className={classes.sticky}
                          style={{ fontWeight: 'bold', width: '250px' }}
                        >
                          Middle Rate
                        </StyledTableCell>

                        {cluster.map((e, index) => (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={classes.rates}
                            style={{
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            {e.median !== 'NaN' && e.items > 0 ? (
                              <span>
                                {e.comp_report ? (
                                  e.median > e.comp_report.median ? (
                                    <sup
                                      className={
                                        e.comp_report
                                          ? e.median > e.comp_report.median
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.median < e.comp_report.median
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sup>
                                  ) : (
                                    <sub
                                      className={
                                        e.comp_report
                                          ? e.median > e.comp_report.median
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.median < e.comp_report.median
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sub>
                                  )
                                ) : (
                                  <></>
                                )}
                                {Math.round(e.median)}
                              </span>
                            ) : e.median !== 'NaN' && e.items < 0 ? (
                              'NED'
                            ) : (
                              '--'
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                      <StyledTableRow Key={stars}>
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          className={classes.sticky}
                          style={{ fontWeight: 'bold', width: '250px' }}
                        >
                          &emsp;Highest Rate
                        </StyledTableCell>

                        {cluster.map((e, index) => (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={classes.rates}
                            style={{
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            {e.max !== 'NaN' && e.items > 0 ? (
                              <span>
                                {e.comp_report ? (
                                  e.max > e.comp_report.max ? (
                                    <sup
                                      className={
                                        e.comp_report
                                          ? e.max > e.comp_report.max
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.max < e.comp_report.max
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sup>
                                  ) : (
                                    <sub
                                      className={
                                        e.comp_report
                                          ? e.max > e.comp_report.max
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.max < e.comp_report.max
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sub>
                                  )
                                ) : (
                                  <></>
                                )}
                                {Math.round(e.max)}
                              </span>
                            ) : e.max !== 'NaN' && e.items < 0 ? (
                              'NED'
                            ) : (
                              '--'
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                      <StyledTableRow Key={stars}>
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          className={classes.sticky}
                          style={{
                            fontWeight: 'bold',
                            width: '250px',
                            borderTop: '2px solid grey',
                          }}
                        >
                          &emsp;&emsp;Average of Highest Rates
                        </StyledTableCell>

                        {cluster.map((e, index) => (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={classes.rates}
                            style={{
                              borderTop: '3px solid grey',
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            {e.highAVG !== 'NaN' && e.items > 0 ? (
                              <span>
                                {e.comp_report ? (
                                  e.highAVG > e.comp_report.highAVG ? (
                                    <sup
                                      className={
                                        e.comp_report
                                          ? e.highAVG > e.comp_report.highAVG
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.highAVG < e.comp_report.highAVG
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sup>
                                  ) : (
                                    <sub
                                      className={
                                        e.comp_report
                                          ? e.highAVG > e.comp_report.highAVG
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.highAVG < e.comp_report.highAVG
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sub>
                                  )
                                ) : (
                                  <></>
                                )}
                                {Math.round(e.highAVG)}
                              </span>
                            ) : e.highAVG !== 'NaN' && e.items < 0 ? (
                              'NED'
                            ) : (
                              '--'
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                      <StyledTableRow Key={stars}>
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          className={classes.sticky}
                          style={{ fontWeight: 'bold', width: '250px' }}
                        >
                          &emsp;&emsp;Average of Middle Rates
                        </StyledTableCell>

                        {cluster.map((e, index) => (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={classes.rates}
                            style={{
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            {e.midAVG !== 'NaN' && e.items > 0 ? (
                              <span>
                                {e.comp_report ? (
                                  e.midAVG > e.comp_report.midAVG ? (
                                    <sup
                                      className={
                                        e.comp_report
                                          ? e.midAVG > e.comp_report.midAVG
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.midAVG < e.comp_report.midAVG
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sup>
                                  ) : (
                                    <sub
                                      className={
                                        e.comp_report
                                          ? e.midAVG > e.comp_report.midAVG
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.midAVG < e.comp_report.midAVG
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sub>
                                  )
                                ) : (
                                  <></>
                                )}
                                {Math.round(e.midAVG)}
                              </span>
                            ) : e.midAVG !== 'NaN' && e.items < 0 ? (
                              'NED'
                            ) : (
                              '--'
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                      <StyledTableRow Key={stars}>
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          className={classes.sticky}
                          style={{
                            fontWeight: 'bold',
                            width: '250px',
                            borderBottom: '3px solid grey',
                          }}
                        >
                          &emsp;&emsp;Average of Lowest Rates
                        </StyledTableCell>

                        {cluster.map((e, index) => (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={classes.rates}
                            style={{
                              borderBottom: '3px solid grey',
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            {e.lowAVG !== 'NaN' && e.items > 0 ? (
                              <span>
                                {e.comp_report ? (
                                  e.lowAVG > e.comp_report.lowAVG ? (
                                    <sup
                                      className={
                                        e.comp_report
                                          ? e.lowAVG > e.comp_report.lowAVG
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.lowAVG < e.comp_report.lowAVG
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sup>
                                  ) : (
                                    <sub
                                      className={
                                        e.comp_report
                                          ? e.lowAVG > e.comp_report.lowAVG
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.lowAVG < e.comp_report.lowAVG
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sub>
                                  )
                                ) : (
                                  <></>
                                )}
                                {Math.round(e.lowAVG)}
                              </span>
                            ) : e.lowAVG !== 'NaN' && e.items < 0 ? (
                              'NED'
                            ) : (
                              '--'
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                      <StyledTableRow Key={stars}>
                        <StyledTableCell
                          size="small"
                          component="th"
                          scope="row"
                          className={classes.sticky}
                          style={{ fontWeight: 'bold', width: '250px' }}
                        >
                          &emsp;Lowest Rate
                        </StyledTableCell>

                        {cluster.map((e, index) => (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={classes.rates}
                            style={{
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            {e.min !== 'NaN' && e.items > 0 ? (
                              <span>
                                {e.comp_report ? (
                                  e.min > e.comp_report.min ? (
                                    <sup
                                      className={
                                        e.comp_report
                                          ? e.min > e.comp_report.min
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.min < e.comp_report.min
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sup>
                                  ) : (
                                    <sub
                                      className={
                                        e.comp_report
                                          ? e.min > e.comp_report.min
                                            ? 'fa fa-long-arrow-up text-success'
                                            : e.min < e.comp_report.min
                                            ? 'fa fa-long-arrow-down  text-danger'
                                            : ''
                                          : ''
                                      }
                                      aria-hidden="true"
                                    >
                                      &nbsp;
                                    </sub>
                                  )
                                ) : (
                                  <></>
                                )}
                                {Math.round(e.min)}
                              </span>
                            ) : e.min !== 'NaN' && e.items < 0 ? (
                              'NED'
                            ) : (
                              '--'
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                  <br />
                </Box>
              </TableContainer>

              {/* <GetClusterVarience /> */}
            </>
          ) : (
            // <LoadingOverlay show={load} />
            <></>
          )}
        </>
      )}
    </>
  );
}
