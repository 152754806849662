import {
  Box,
  makeStyles,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { CLUSTER_BACKGROUND, FONT_FAMILY } from '../utils/const';
import { LoadingOverlay } from './UI/LoadingOverlay';
import { useSelector } from 'react-redux';
import $ from 'jquery';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 14,
    minWidth: 100,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'block',
  },
  rates: {
    fontFamily: FONT_FAMILY,
    textAlign: 'center',
  },
});

export default function OverallMarketAnalysis({
  selectedDate,
  rows_only,
  tbl_ref,
  executeScroll,
  sub_tab,
  specailDates,
  dates_filter,
}) {
  const classes = useStyles();

  const getClusterDataSet = useSelector((state) => state.clusterDataSet);

  const {
    clusterData,
    cluster1,
    hotels,
    report_len,
    comparison_report,
    date_format,
  } = getClusterDataSet;

  const [load, setLoad] = useState(true);

  const [dataset, setDataset] = useState([]);

  const daily_fetch_len = selectedDate
    ? moment(moment(selectedDate).add(180, 'days'))
        .endOf('month')
        .day('sunday')
        .diff(selectedDate, 'days')
    : 0;

  const [avg, setAvg] = useState(0);
  const [stdev, setStdev] = useState(0);

  const overall_analysis_tbl = useRef(null);

  function checkRatebucketMovement(a, b, c) {
    if ((a && b && c) || (a && b && !c) || (a && !b && c) || (!a && b && c)) {
      return 1;
    } else {
      return -1;
    }
  }

  const getAvg = (arr) => {
    if (arr.length > 0) {
      return arr.reduce((a, b) => a + b) / arr.length;
    }
  };

  function getStandardDeviation(array) {
    if (array.length === 0) {
      return 0;
    }
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );
  }

  const getVarience = (value) => {
    if (value >= avg + 1.65 * stdev) {
      return 'V Strong';
    } else if (value >= avg + stdev) {
      return 'Strong';
    } else {
      return '';
    }
  };

  useEffect(() => {
    const CompareReport = async () => {
      setLoad(true);

      clusterData.map((day, stars) => {
        day.map((e, idx) => {
          if (e && e != undefined) {
            let dateMatch = comparison_report.clusterData.find((obj) => {
              try {
                if (obj && obj != undefined && obj[stars] != undefined) {
                  if (
                    moment(e.date).format('YYYY-MM-DD') ===
                    moment(obj[stars].date).format('YYYY-MM-DD')
                  ) {
                    return obj;
                  }
                }
              } catch (e) {}
            });

            if (dateMatch) {
              let comp_report = dateMatch[stars];
              delete comp_report.cluster;
              delete comp_report.unwanted;

              e.comp_report = comp_report;

              e.rate_movements = checkRatebucketMovement(
                ((e.highAVG - comp_report.highAVG) / comp_report.highAVG) *
                  100 >
                  0,
                ((e.midAVG - comp_report.midAVG) / comp_report.midAVG) * 100 >
                  0,
                ((e.lowAVG - comp_report.lowAVG) / comp_report.lowAVG) * 100 > 0
              );
            }
          }
        });
      });

      let _dataset = [];

      [...Array(report_len).keys()].map((i, day) => {
        let rate_movements_5 = '';
        let rate_movements_4 = '';
        let rate_movements_3 = '';
        let rate_movements_2 = '';

        let hotels_showing_5 = 0;
        let hotels_showing_4 = 0;
        let hotels_showing_3 = 0;
        let hotels_showing_2 = 0;

        let avg_mid_5 = 0;
        let avg_mid_4 = 0;
        let avg_mid_3 = 0;
        let avg_mid_2 = 0;

        clusterData[day].map((cluster, ix) => {
          if (cluster && cluster != undefined) {
            let dateMatch = comparison_report.clusterData.find((obj) => {
              try {
                if (obj && obj != undefined && obj[ix] != undefined) {
                  if (
                    moment(cluster.date).format('YYYY-MM-DD') ===
                    moment(obj[ix].date).format('YYYY-MM-DD')
                  ) {
                    return obj;
                  }
                }
              } catch (e) {}
            });

            if (dateMatch) {
              let comp_report = dateMatch[ix];
              delete comp_report.cluster;
              delete comp_report.unwanted;

              let rate_movements = checkRatebucketMovement(
                ((cluster.highAVG - comp_report.highAVG) /
                  comp_report.highAVG) *
                  100 >
                  0,
                ((cluster.midAVG - comp_report.midAVG) / comp_report.midAVG) *
                  100 >
                  0,
                ((cluster.lowAVG - comp_report.lowAVG) / comp_report.lowAVG) *
                  100 >
                  0
              );

              if (ix == 0) {
                rate_movements_2 = rate_movements;
                hotels_showing_2 = cluster.unwanted.length;
                avg_mid_2 = parseFloat(
                  ((cluster.mean - comp_report.mean) / comp_report.mean) * 100
                ).toFixed(1);
              }
              if (ix == 1) {
                rate_movements_3 = rate_movements;
                hotels_showing_3 = cluster.unwanted.length;
                avg_mid_3 = parseFloat(
                  ((cluster.mean - comp_report.mean) / comp_report.mean) * 100
                ).toFixed(1);
              }
              if (ix == 2) {
                rate_movements_4 = rate_movements;
                hotels_showing_4 = cluster.unwanted.length;
                avg_mid_4 = parseFloat(
                  ((cluster.mean - comp_report.mean) / comp_report.mean) * 100
                ).toFixed(1);
              }
              if (ix == 3) {
                rate_movements_5 = rate_movements;
                hotels_showing_5 = cluster.unwanted.length;
                avg_mid_5 = parseFloat(
                  ((cluster.mean - comp_report.mean) / comp_report.mean) * 100
                ).toFixed(1);
              }
            }
          }
        });

        _dataset.push({
          date: moment(clusterData[day][0].date).format('YYYY-MM-DD'),
          rate_movements_2: rate_movements_2,
          rate_movements_3: rate_movements_3,
          rate_movements_4: rate_movements_4,
          rate_movements_5: rate_movements_5,
          hotels_showing_2: hotels_showing_2,
          hotels_showing_3: hotels_showing_3,
          hotels_showing_4: hotels_showing_4,
          hotels_showing_5: hotels_showing_5,
          avg_mid_2: avg_mid_2,
          avg_mid_3: avg_mid_3,
          avg_mid_4: avg_mid_4,
          avg_mid_5: avg_mid_5,
        });
      });

      _dataset.map((data) => {
        data.weighted_movement_5 =
          data.rate_movements_5 *
          (data.hotels_showing_5 /
            (data.hotels_showing_2 +
              data.hotels_showing_3 +
              data.hotels_showing_4 +
              data.hotels_showing_5));
        data.weighted_movement_4 =
          data.rate_movements_4 *
          (data.hotels_showing_4 /
            (data.hotels_showing_2 +
              data.hotels_showing_3 +
              data.hotels_showing_4 +
              data.hotels_showing_5));
        data.weighted_movement_3 =
          data.rate_movements_3 *
          (data.hotels_showing_3 /
            (data.hotels_showing_2 +
              data.hotels_showing_3 +
              data.hotels_showing_4 +
              data.hotels_showing_5));
        data.weighted_movement_2 =
          data.rate_movements_2 *
          (data.hotels_showing_2 /
            (data.hotels_showing_2 +
              data.hotels_showing_3 +
              data.hotels_showing_4 +
              data.hotels_showing_5));

        data.sum_of_weighted_movements =
          data.weighted_movement_2 +
          data.weighted_movement_3 +
          data.weighted_movement_4 +
          data.weighted_movement_5;

        data.weighted_strength_2 =
          data.avg_mid_2 *
          (data.hotels_showing_2 /
            (data.hotels_showing_2 +
              data.hotels_showing_3 +
              data.hotels_showing_4 +
              data.hotels_showing_5));
        data.weighted_strength_3 =
          data.avg_mid_3 *
          (data.hotels_showing_3 /
            (data.hotels_showing_2 +
              data.hotels_showing_3 +
              data.hotels_showing_4 +
              data.hotels_showing_5));
        data.weighted_strength_4 =
          data.avg_mid_4 *
          (data.hotels_showing_4 /
            (data.hotels_showing_2 +
              data.hotels_showing_3 +
              data.hotels_showing_4 +
              data.hotels_showing_5));
        data.weighted_strength_5 =
          data.avg_mid_5 *
          (data.hotels_showing_5 /
            (data.hotels_showing_2 +
              data.hotels_showing_3 +
              data.hotels_showing_4 +
              data.hotels_showing_5));

        const sum_of_weighted_strengths = Math.abs(
          parseFloat(
            data.weighted_strength_2 +
              data.weighted_strength_3 +
              data.weighted_strength_4 +
              data.weighted_strength_5
          ).toFixed(1)
        );

        if (sum_of_weighted_strengths >= 0 && sum_of_weighted_strengths !== Infinity) {
          data.sum_of_weighted_strengths = sum_of_weighted_strengths;
        } else {
          data.sum_of_weighted_strengths = 0;
        }
      });

      const sum_of_weighted_strengths_arr = _dataset.map(
        (e) => e.sum_of_weighted_strengths
      )

      const weighted_strengths_avg = getAvg(sum_of_weighted_strengths_arr)
      const weighted_strengths_stdev= getStandardDeviation(sum_of_weighted_strengths_arr)

      setAvg(weighted_strengths_avg);
      setStdev(weighted_strengths_stdev);

      // console.log(sum_of_weighted_strengths_arr)
      
      // console.log(weighted_strengths_stdev)
      // console.log(weighted_strengths_avg)

      setDataset(_dataset);
      setLoad(false);
    };

    if (comparison_report && clusterData.length > 0) {
      CompareReport();
    }
  }, [clusterData, comparison_report]);

  const getDateByIndex = (index) => {
    if (cluster1.length > 0) {
      if (index >= 0) {
        return cluster1[index].date;
      }
    }
  };

  const GetOverallMarketDataset = () => {
    return (
      <>
        {!load && dataset.length > 0 ? (
          <>
            <StyledTableRow>
              <StyledTableCell
                size="small"
                component="th"
                scope="col"
                className={classes.sticky}
                style={{ fontWeight: 'bold', width: '300px' }}
              >
                Overall Market Rates Movement &nbsp;{' '}
                <i
                  class="fa fa-external-link"
                  aria-hidden="true"
                  style={{ cursor: 'pointer' }}
                  onClick={() => executeScroll(0, 1, sub_tab)}
                ></i>
              </StyledTableCell>

              {dataset.map((e, index) =>
                (() => {
                  if (dates_filter == 1) {
                    return (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          fontSize: '12px',
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        <span
                          className={
                            e.sum_of_weighted_movements > 0
                              ? 'text-success'
                              : e.sum_of_weighted_movements < 0
                              ? 'text-danger'
                              : ''
                          }
                        >
                          {e.sum_of_weighted_movements > 0
                            ? 'Up'
                            : e.sum_of_weighted_movements < 0
                            ? 'Down'
                            : isNaN(e.sum_of_weighted_movements)
                            ? '-'
                            : ''}
                        </span>
                      </StyledTableCell>
                    );
                  } else if (dates_filter == 2) {
                    const found = specailDates.filter(
                      (obj) =>
                        moment(obj.date).format('YYYY-MM-DD') ==
                        moment(getDateByIndex(index)).format('YYYY-MM-DD')
                    );
                    if (found.length > 0) {
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={classes.rates}
                          style={{
                            fontSize: '12px',
                            borderRight:
                              index == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                        >
                          <span
                            className={
                              e.sum_of_weighted_movements > 0
                                ? 'text-success'
                                : e.sum_of_weighted_movements < 0
                                ? 'text-danger'
                                : ''
                            }
                          >
                            {e.sum_of_weighted_movements > 0
                              ? 'Up'
                              : e.sum_of_weighted_movements < 0
                              ? 'Down'
                              : isNaN(e.sum_of_weighted_movements)
                              ? '-'
                              : ''}
                          </span>
                        </StyledTableCell>
                      );
                    }
                  }
                })()
              )}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                size="small"
                component="th"
                scope="col"
                className={classes.sticky}
                style={{ fontWeight: 'bold', width: '300px' }}
              >
                Overall Market Rate Movement Strength &nbsp;{' '}
                <i
                  class="fa fa-external-link"
                  aria-hidden="true"
                  style={{ cursor: 'pointer' }}
                  onClick={() => executeScroll(0, 1, sub_tab)}
                ></i>
              </StyledTableCell>

              {dataset.map((e, index) =>
                (() => {
                  if (dates_filter == 1) {
                    return (
                      <StyledTableCell
                        size="small"
                        key={index}
                        className={classes.rates}
                        style={{
                          fontSize: '12px',
                          borderRight:
                            index == daily_fetch_len
                              ? '5px solid rgba(66, 66, 66, 1)'
                              : '',
                        }}
                      >
                        {!isNaN(e.sum_of_weighted_movements)
                          ? getVarience(e.sum_of_weighted_strengths)
                          : '-'}
                      </StyledTableCell>
                    );
                  } else if (dates_filter == 2) {
                    const found = specailDates.filter(
                      (obj) =>
                        moment(obj.date).format('YYYY-MM-DD') ==
                        moment(getDateByIndex(index)).format('YYYY-MM-DD')
                    );
                    if (found.length > 0) {
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={classes.rates}
                          style={{
                            fontSize: '12px',
                            borderRight:
                              index == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                        >
                          {!isNaN(e.sum_of_weighted_movements)
                            ? getVarience(e.sum_of_weighted_strengths)
                            : '-'}
                        </StyledTableCell>
                      );
                    }
                  }
                })()
              )}
            </StyledTableRow>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  useEffect(() => {
    const handdle_scroll = () => {
      if (
        !load &&
        hotels.length > 0 &&
        comparison_report &&
        clusterData.length > 0
      ) {
        if (tbl_ref == 1) {
          overall_analysis_tbl.current.scrollIntoView();
        }
      }
    };
    handdle_scroll();
  }, [load]);

  return (
    <>
      {!load && dataset.length > 0 ? (
        <>
          {rows_only ? (
            <GetOverallMarketDataset />
          ) : (
            <TableContainer
              component={Paper}
              className="my-5"
              ref={overall_analysis_tbl}
            >
              <Box width={100}>
                <Table
                  className={classes.table}
                  size="medium"
                  aria-label="customized table"
                  bodyStyle={{ overflow: 'visible' }}
                  stickyHeader
                >
                  <TableHead>
                    <StyledTableCell
                      style={{
                        width: '250px',
                        zIndex: 100,
                        fontFamily: FONT_FAMILY,
                      }}
                    >
                      <TableSortLabel disabled>
                        Overall Market Analysis
                      </TableSortLabel>{' '}
                      <hr style={{ 'background-color': '#f4f4f4' }} />
                      <TableSortLabel disabled>Days Out</TableSortLabel>
                    </StyledTableCell>
                    {cluster1.map((e, index) =>
                      (() => {
                        let _date = moment(e.date, 'YYYY-MM-DD');
                        let daysOut = _date.diff(selectedDate, 'days');
                        let date = _date.format('dddd').substring(0, 3);
                        return (
                          <StyledTableCell
                            size="small"
                            key={index}
                            className={
                              date === 'Sat' || date === 'Fri'
                                ? 'bg-secondary text-light text-center'
                                : 'text-center'
                            }
                            style={{
                              fontSize: '12px',
                              borderRight:
                                index == daily_fetch_len
                                  ? '5px solid rgba(66, 66, 66, 1)'
                                  : '',
                            }}
                          >
                            <>
                              {date === 'Sat' || date === 'Fri'
                                ? 'WEND'
                                : 'WDAY'}
                            </>
                            <br />
                            <>{date.toUpperCase()}</>
                            <br />
                            <>{moment(_date).format(date_format)}</>{' '}
                            <div class="dropdown-divider"></div>
                            {daysOut}
                          </StyledTableCell>
                        );
                      })()
                    )}
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                        }}
                      >
                        Overall Market Rates Movement
                      </StyledTableCell>

                      {dataset.map((e, index) => (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={classes.rates}
                          style={{
                            fontSize: '12px',
                            borderRight:
                              index == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                        >
                          <span
                            className={
                              e.sum_of_weighted_movements > 0
                                ? 'text-success'
                                : e.sum_of_weighted_movements < 0
                                ? 'text-danger'
                                : ''
                            }
                          >
                            {e.sum_of_weighted_movements > 0
                              ? 'Up'
                              : e.sum_of_weighted_movements < 0
                              ? 'Down'
                              : isNaN(e.sum_of_weighted_movements)
                              ? '-'
                              : ''}
                          </span>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky}
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                        }}
                      >
                        Overall Market Rate Movement Strength
                      </StyledTableCell>

                      {dataset.map((e, index) => (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={classes.rates}
                          style={{
                            fontSize: '12px',
                            borderRight:
                              index == daily_fetch_len
                                ? '5px solid rgba(66, 66, 66, 1)'
                                : '',
                          }}
                        >
                          {!isNaN(e.sum_of_weighted_movements)
                            ? getVarience(e.sum_of_weighted_strengths)
                            : '-'}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableBody>
                </Table>
                <br />
              </Box>
            </TableContainer>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
