import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Scatter } from 'react-chartjs-2';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';
import { CLUSTER_BACKGROUND, CLUSTER_LABELS, FONT_FAMILY } from '../utils/const';

import { Chart } from 'chart.js';
import * as annotationPlugin from 'chartjs-plugin-annotation';
import { Tooltip } from '@mui/material';

Chart.pluginService.register(annotationPlugin);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bolder',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);
const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'block',
  },
  rates: {
    fontFamily: FONT_FAMILY,
    textAlign: 'center',
  },
  formControl: {
    minWidth: 120,
  },
});

export function HotelsPlot({ hotels, selectedDate, insights_data, tbl_ref }) {
  const classes = useStyles();
  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    loading,
    err,
    clusterData,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    reqHotel,
    report_len,
    date_format,
  } = getClusterDataSet;

  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const [plotData, setPlotData] = useState([]);

  const [plotLabels, setPlotLabels] = useState([]);

  const [hotelsDataPlot, setHotelsDataPlot] = useState([]);

  const [plotDataset, setPlotDataset] = useState([]);

  const [analysisLabels, setAnalysisLabels] = useState([]);
  const [analysisPositionData, setAnalysisPositionData] = useState([]);
  const [analysisQuadrantData, setAnalysisQuadrantData] = useState([]);

  const [analysisShoppersData, setAnalysisShoppersData] = useState([]);
  const [analysisShoppersLabels, setAnalysisShoppersLabels] = useState([]);

  const [shoppersDatasetLabels, setShoppersDatasetLabels] = useState([]);
  const [shoppersDatasetData, setShoppersDatasetData] = useState([]);

  const _report_len = 90;

  const [dateSelection, setDateSelection] = useState([0, 30]);

  const [plotDateSelection, setPlotDateSelection] = useState(0);

  const [load, setLoad] = useState(true);

  const [hotelsFilter, setHotelsFilter] = useState(0);

  const quard_colors = [
    { color: '#C5CAE9', label: 'More Expensive Lower Rated' },
    { color: '#C8E6C9', label: 'More Expensive Better Rated' },
    { color: '#FFCCBC', label: 'Less Expensive Lower Rated' },
    { color: '#FFF9C4', label: 'Less Expensive Better Rated' },
  ];

  const [quardrent_movement_exception, set_quardrent_movement_exception] =
    useState([]);

  const [plugins, setPlugins] = useState([
    {
      beforeDraw: function (chart, easing) {
        var chartArea = chart.chartArea;
        var ctx = chart.chart.ctx;

        // Replace these IDs if you have given your axes IDs in the config
        var xScale = chart.scales['x-axis-1'];
        var yScale = chart.scales['y-axis-1'];

        var midX = xScale.getPixelForValue(0);
        var midY = yScale.getPixelForValue(0);

        // Top left quadrant
        ctx.fillStyle = '#C5CAE9';
        ctx.fillRect(
          chartArea.left,
          chartArea.top,
          midX - chartArea.left,
          midY - chartArea.top
        );

        // Top right quadrant
        ctx.fillStyle = '#C8E6C9';
        ctx.fillRect(
          midX,
          chartArea.top,
          chartArea.right - midX,
          midY - chartArea.top
        );

        // Bottom right quadrant
        ctx.fillStyle = '#FFF9C4';
        ctx.fillRect(
          midX,
          midY,
          chartArea.right - midX,
          chartArea.bottom - midY
        );

        // Bottom left quadrant
        ctx.fillStyle = '#FFCCBC';
        ctx.fillRect(
          chartArea.left,
          midY,
          midX - chartArea.left,
          chartArea.bottom - midY
        );
      },
    },
  ]);

  const [cluster_margin_annotations, set_cluster_margin_annotations] = useState(
    []
  );

  const getDateRange = () => {
    let dateRange_arr = [];
    let totalDays = 0;

    if (_report_len > 0) {
      const no_chuncks = Math.ceil(_report_len / 30);

      for (let i = 1; i <= no_chuncks; i++) {
        totalDays += 30;
        if (totalDays <= _report_len) {
          dateRange_arr.push([(i - 1) * 30, i * 30]);
        } else if (totalDays > _report_len) {
          dateRange_arr.push([
            (i - 1) * 30,
            i * 30 - (totalDays - (_report_len - 1)),
          ]);
        }
      }
    }
    return dateRange_arr;
  };

  const [dateRange, setDateRange] = useState(getDateRange());

  const [datePage, setDatePage] = useState(0);

  const [lelr_hotels, set_lelr_hotels] = useState([]);

  const [melr_hotels, set_melr_hotels] = useState([]);

  const [lebr_hotels, set_lebr_hotels] = useState([]);

  const [mebr_hotels, set_mebr_hotels] = useState([]);

  const [zero_lebr_floor_prices, set_zero_lebr_floor_prices] = useState([]);

  const quardrent_movements_tbl = useRef(null);

  const quardrent_movements_exp_tbl = useRef(null);

  const getClusterByPrice = (rate, ix) => {
    let clustered = [];
    let res = 4;

    if (cluster1.length > 0 && cluster1[ix]) {
      clustered.push(cluster1[ix]);
    }
    if (cluster2.length > 0 && cluster2[ix]) {
      clustered.push(cluster2[ix]);
    }
    if (cluster3.length > 0 && cluster3[ix]) {
      clustered.push(cluster3[ix]);
    }
    if (cluster4.length > 0 && cluster4[ix]) {
      clustered.push(cluster4[ix]);
    }

    clustered.sort((a, b) => a.mean - b.mean);

    // console.log(clustered);

    try {
      clustered.map((cl, id) => {
        if (rate >= cl.min && rate <= cl.max) {
          res = id;
          return;
        }
      });
    } catch (e) {}

    return res;
  };

  const mode = (arr) => {
    return arr
      .sort(
        (a, b) =>
          arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
      )
      .pop();
  };

  const getFreqBucketMinMaxRatings = (bucket, dt) => {
    // console.log(`dt: ${dt}, bucket: ${bucket}`);
    let min = -1;
    let max = -1;

    let hotelsArr = [];

    if (clusterData.length > 0) {
      if (clusterData[dt][bucket]) {
        if (clusterData[dt][bucket].unwanted.length > 0) {
          hotelsArr = [...hotelsArr, ...clusterData[dt][bucket].unwanted];
        }
      }
    }
    // console.log(`hotelsArr.length = ${hotelsArr.length}`);
    min = Math.min.apply(
      null,
      hotelsArr.map((item) => item.raings)
    );
    max = Math.max.apply(
      null,
      hotelsArr.map((item) => item.raings)
    );
    return { min: min, max: max };
  };

  const getFreqStarMinMaxRatings = (stars, dt) => {
    // console.log(`dt: ${dt}, bucket: ${bucket}`);
    let min = -1;
    let max = -1;

    let hotelsArr = [];

    if (clusterData.length > 0) {
      if (clusterData[dt]) {
        clusterData[dt].map((cl) => {
          if (cl.unwanted.length > 0) {
            hotelsArr = [...hotelsArr, ...cl.unwanted];
          }
        });
      }
    }

    hotelsArr = hotelsArr.filter(
      (f) => Math.floor(f.stars) == Math.floor(f.stars)
    );

    // console.log(`hotelsArr.length = ${hotelsArr.length}`);

    min = Math.min.apply(
      null,
      hotelsArr.map((item) => item.raings)
    );
    max = Math.max.apply(
      null,
      hotelsArr.map((item) => item.raings)
    );
    return { min: min, max: max };
  };

  const getPrice = (arr) => {
    const price = arr.findIndex((e) => e > 0);
    return price;
  };

  Array.prototype.median = function () {
    return this.slice().sort((a, b) => a - b)[Math.floor(this.length / 2)];
  };

  const getReqHotelData = () => {
    let name = null;
    if (reqHotel.length > 0) {
      reqHotel.map((e, index) => {
        if (e.name !== null) {
          name = e.name;
        }
      });
    }

    return name;
  };

  const getReqHotelID = () => {
    let id = null;
    if (reqHotel.length > 0) {
      reqHotel.map((e, index) => {
        if (e.hotelID !== null) {
          id = e.hotelID;
        }
      });
    }

    return id;
  };

  const getAvg = (arr) => {
    if (arr.length > 0) {
      return arr.reduce((a, b) => a + b) / arr.length;
    }
  };

  const getStandardDeviation = (array) => {
    const n = array.length;
    if (n > 0) {
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      );
    } else {
      return -1;
    }
  };

  const getVarience = (value, avg, stdev) => {
    if (avg <= 0 || stdev <= 0 || value <= 0) {
      return '';
    } else if (value >= avg + stdev) {
      return 'High';
    } else {
      return '';
    }
  };

  const checkHotelAvailability = (id, day) => {
    let clustered = [];

    if (cluster1.length > 0 && cluster1[day]) {
      clustered.push(cluster1[day].unwanted);
    }
    if (cluster2.length > 0 && cluster2[day]) {
      clustered.push(cluster2[day].unwanted);
    }
    if (cluster3.length > 0 && cluster3[day]) {
      clustered.push(cluster3[day].unwanted);
    }
    if (cluster4.length > 0 && cluster4[day]) {
      clustered.push(cluster4[day].unwanted);
    }
    let hotels_arr = [];

    for (var i = 0; i < clustered.length; i++) {
      hotels_arr = hotels_arr.concat(clustered[i]);
    }

    const exists = hotels_arr.some((obj) => obj.id == id);

    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const buildPlot = () => {
      setLoad(true);
      if (hotels.length > 0) {
        setHotelsDataPlot(hotels);

        let dt = plotDateSelection;
        let scaterLabels = [];
        let scaterDataset = [];

        const reqHotelName = reqHotel.length > 0
        ? (reqHotel.find((el) => el.name != null) || {}).name
        : undefined;
        
        let reqHotelData = reqHotelName 
          ? hotels.find((o) => reqHotelName.localeCompare(o.hotelName) === 0)
          : undefined;

        let _plotData = [];

        let anlysis_plotData = [];
        let anlysis_scaterDataset = [];
        let anlysis_scaterLabels = [];

        let shoppersPlotData = [];
        let shoppersPlotlabels = [];

        let analysis_shoppersData = [];
        let analysis_shoppersLabels = [];

      try{
        hotels.map((_hotel) => {
          if (
            _hotel.prices[dt] != null &&
            _hotel.prices[dt] !== undefined &&
            reqHotelData.prices[dt] != null &&
            reqHotelData.prices[dt] != undefined
          ) {
            let cluster_dt =
              getClusterByPrice(
                _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)],
                dt
              ) + 2;

            let reqHotelCluster =
              getClusterByPrice(
                reqHotelData.prices[dt].price[
                  getPrice(reqHotelData.prices[dt].price)
                ],
                dt
              ) + 2;

            const FrqBucketMinMax = getFreqBucketMinMaxRatings(
              cluster_dt - 2,
              dt
            );

            const reqHotelFrqStarMinMax = getFreqStarMinMaxRatings(
              reqHotelData.stars,
              dt
            );

            const FrqStarMinMax = getFreqStarMinMaxRatings(_hotel.stars, dt);

            if (
              FrqStarMinMax.min > 0 &&
              FrqStarMinMax.max > 0 &&
              checkHotelAvailability(_hotel.hotelID, dt)
            ) {
              let frq_rating_stars = Number(
                Math.abs(_hotel.ratings - FrqStarMinMax.min) /
                  (FrqStarMinMax.max - FrqStarMinMax.min) +
                  Math.floor(_hotel.stars)
              ).toFixed(2);

              let reqHotel_frq_rating_stars = Number(
                Math.abs(reqHotelData.ratings - reqHotelFrqStarMinMax.min) /
                  (reqHotelFrqStarMinMax.max - reqHotelFrqStarMinMax.min) +
                  Math.floor(reqHotelData.stars)
              ).toFixed(2);

              let plotData_x = Number(
                frq_rating_stars - reqHotel_frq_rating_stars
              ).toFixed(2);
              let plotData_y =
                _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)] -
                reqHotelData.prices[dt].price[
                  getPrice(reqHotelData.prices[dt].price)
                ];

              if (
                frq_rating_stars != Infinity &&
                plotData_x != Infinity &&
                plotData_y != Infinity
              ) {
                shoppersPlotlabels.push(_hotel.hotelName);
                shoppersPlotData.push({
                  label: _hotel.hotelName,
                  pointRadius:
                    getReqHotelData().localeCompare(_hotel.hotelName) == 0
                      ? 6
                      : 3,
                  pointHoverRadius:
                    getReqHotelData().localeCompare(_hotel.hotelName) == 0
                      ? 6
                      : 3,
                  backgroundColor:
                    getReqHotelData().localeCompare(_hotel.hotelName) == 0
                      ? '#BA68C8'
                      : '#757575',
                  data: [
                    {
                      x: frq_rating_stars,
                      y: _hotel.prices[dt].price[
                        getPrice(_hotel.prices[dt].price)
                      ],
                    },
                  ],
                });

                _plotData.push({
                  label: _hotel.hotelName,
                  pointRadius:
                    getReqHotelData().localeCompare(_hotel.hotelName) == 0
                      ? 6
                      : 3,
                  pointHoverRadius:
                    getReqHotelData().localeCompare(_hotel.hotelName) == 0
                      ? 6
                      : 3,
                  backgroundColor:
                    getReqHotelData().localeCompare(_hotel.hotelName) == 0
                      ? '#BA68C8'
                      : '#757575',
                  data: [
                    {
                      x: plotData_x,
                      y: plotData_y,
                    },
                  ],
                });

                if (_hotel.hotelID == getReqHotelID()) {
                  analysis_shoppersLabels.push(_hotel.hotelName);

                  analysis_shoppersData.push({
                    label: _hotel.hotelName,
                    pointRadius:
                      getReqHotelData().localeCompare(_hotel.hotelName) == 0
                        ? 6
                        : 3,
                    pointHoverRadius:
                      getReqHotelData().localeCompare(_hotel.hotelName) == 0
                        ? 6
                        : 3,
                    backgroundColor:
                      getReqHotelData().localeCompare(_hotel.hotelName) == 0
                        ? '#BA68C8'
                        : '#757575',
                    data: [
                      {
                        x: frq_rating_stars,
                        y: _hotel.prices[dt].price[
                          getPrice(_hotel.prices[dt].price)
                        ],
                      },
                    ],
                  });

                  anlysis_plotData.push({
                    label: _hotel.hotelName,
                    pointRadius:
                      getReqHotelData().localeCompare(_hotel.hotelName) == 0
                        ? 6
                        : 3,
                    pointHoverRadius:
                      getReqHotelData().localeCompare(_hotel.hotelName) == 0
                        ? 6
                        : 3,
                    backgroundColor:
                      getReqHotelData().localeCompare(_hotel.hotelName) == 0
                        ? '#BA68C8'
                        : '#757575',
                    data: [
                      {
                        x: plotData_x,
                        y: plotData_y,
                      },
                    ],
                  });
                }

                user.application.candidate_properties.map((_filterHotel) => {
                  if (_hotel.hotelID == _filterHotel.id) {
                    analysis_shoppersLabels.push(_hotel.hotelName);

                    analysis_shoppersData.push({
                      label: _hotel.hotelName,
                      pointRadius:
                        getReqHotelData().localeCompare(_hotel.hotelName) == 0
                          ? 6
                          : 3,
                      pointHoverRadius:
                        getReqHotelData().localeCompare(_hotel.hotelName) == 0
                          ? 6
                          : 3,
                      backgroundColor:
                        getReqHotelData().localeCompare(_hotel.hotelName) == 0
                          ? '#BA68C8'
                          : '#757575',
                      data: [
                        {
                          x: frq_rating_stars,
                          y: _hotel.prices[dt].price[
                            getPrice(_hotel.prices[dt].price)
                          ],
                        },
                      ],
                    });

                    anlysis_plotData.push({
                      label: _hotel.hotelName,
                      pointRadius:
                        getReqHotelData().localeCompare(_hotel.hotelName) == 0
                          ? 6
                          : 3,
                      pointHoverRadius:
                        getReqHotelData().localeCompare(_hotel.hotelName) == 0
                          ? 6
                          : 3,
                      backgroundColor:
                        getReqHotelData().localeCompare(_hotel.hotelName) == 0
                          ? '#BA68C8'
                          : '#757575',
                      data: [
                        {
                          x: plotData_x,
                          y: plotData_y,
                        },
                      ],
                    });
                  }
                });
              }
            }

            if (FrqBucketMinMax.min > 0 && FrqBucketMinMax.max > 0) {
              let frq_rating = Number(
                Math.abs(_hotel.ratings - FrqBucketMinMax.min) /
                  (FrqBucketMinMax.max - FrqBucketMinMax.min) +
                  cluster_dt
              ).toFixed(2);

              let plotDataset_x = frq_rating;

              _hotel.frq_rating = frq_rating;

              let plotDataset_y =
                _hotel.prices[dt].price[getPrice(_hotel.prices[dt].price)];

              if (plotDataset_x != Infinity && plotDataset_y != Infinity) {
                scaterLabels.push(_hotel.hotelName);
                scaterDataset.push({
                  label: _hotel.hotelName,
                  pointRadius:
                    getReqHotelData().localeCompare(_hotel.hotelName) == 0
                      ? 6
                      : 3,
                  pointHoverRadius:
                    getReqHotelData().localeCompare(_hotel.hotelName) == 0
                      ? 6
                      : 3,
                  backgroundColor:
                    getReqHotelData().localeCompare(_hotel.hotelName) == 0
                      ? '#BA68C8'
                      : '#757575',
                  data: [
                    {
                      x: plotDataset_x,
                      y: plotDataset_y,
                    },
                  ],
                });

                if (_hotel.hotelID == getReqHotelID()) {
                  anlysis_scaterLabels.push(_hotel.hotelName);

                  anlysis_scaterDataset.push({
                    label: _hotel.hotelName,
                    pointRadius:
                      getReqHotelData().localeCompare(_hotel.hotelName) == 0
                        ? 6
                        : 3,
                    pointHoverRadius:
                      getReqHotelData().localeCompare(_hotel.hotelName) == 0
                        ? 6
                        : 3,
                    backgroundColor:
                      getReqHotelData().localeCompare(_hotel.hotelName) == 0
                        ? '#BA68C8'
                        : '#757575',
                    data: [
                      {
                        x: plotDataset_x,
                        y: plotDataset_y,
                      },
                    ],
                  });
                }

                user.application.candidate_properties.map((_filterHotel) => {
                  if (_hotel.hotelID == _filterHotel.id) {
                    anlysis_scaterLabels.push(_hotel.hotelName);

                    anlysis_scaterDataset.push({
                      label: _hotel.hotelName,
                      pointRadius:
                        getReqHotelData().localeCompare(_hotel.hotelName) == 0
                          ? 6
                          : 3,
                      pointHoverRadius:
                        getReqHotelData().localeCompare(_hotel.hotelName) == 0
                          ? 6
                          : 3,
                      backgroundColor:
                        getReqHotelData().localeCompare(_hotel.hotelName) == 0
                          ? '#BA68C8'
                          : '#757575',
                      data: [
                        {
                          x: plotDataset_x,
                          y: plotDataset_y,
                        },
                      ],
                    });
                  }
                });
              }
            }
          }
        });
      }catch(e) {

      }

        setShoppersDatasetLabels(shoppersPlotlabels);
        setShoppersDatasetData(shoppersPlotData);
        setAnalysisShoppersData(analysis_shoppersData);
        setAnalysisShoppersLabels(analysis_shoppersLabels);

        setPlotDataset(scaterDataset);
        setPlotLabels(scaterLabels);
        setPlotData(_plotData);

        setAnalysisLabels(anlysis_scaterLabels);
        setAnalysisPositionData(anlysis_scaterDataset);
        setAnalysisQuadrantData(anlysis_plotData);

        // console.log(_plotData);

        try {
          let cluster_margins = [];

          if (cluster1.length > 0 && cluster1[dt]) {
            cluster_margins.push({
              drawTime: 'afterDraw',
              id: `margin-${dt}-1`,
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: cluster1[dt].min,
              borderColor: CLUSTER_BACKGROUND[0],
              borderWidth: 1,
            });
          }
          if (cluster2.length > 0 && cluster2[dt]) {
            cluster_margins.push({
              drawTime: 'afterDraw',
              id: `margin-${dt}-2`,
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: cluster2[dt].min,
              borderColor: CLUSTER_BACKGROUND[1],
              borderWidth: 1,
            });
          }
          if (cluster3.length > 0 && cluster3[dt]) {
            cluster_margins.push({
              drawTime: 'afterDraw',
              id: `margin-${dt}-3`,
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: cluster3[dt].min,
              borderColor: CLUSTER_BACKGROUND[2],
              borderWidth: 1,
            });
          }
          if (cluster4.length > 0 && cluster4[dt]) {
            cluster_margins.push({
              drawTime: 'afterDraw',
              id: `margin-${dt}-4`,
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: cluster4[dt].min,
              borderColor: CLUSTER_BACKGROUND[3],
              borderWidth: 1,
            });
          }

          if (cluster1[dt].cluster.length > 0 && cluster2[dt].cluster.length > 0) {
            cluster_margins.push({
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              id: `box-${dt}-1`,
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: 2,
              xMax: 3,
              yMax: cluster2[dt].min,
              yMin: cluster1[dt].min,
              backgroundColor: '#7acaff',
            });
          }else {
            cluster_margins.push({
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              id: `box-${dt}-3`,
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: 4,
              xMax: 5,
              yMax: cluster1[dt].max,
              yMin: cluster1[dt].min,
              backgroundColor: '#7acaff',
            });
          }

          if (cluster2[dt].cluster.length > 0 && cluster3[dt].cluster.length > 0) {
            cluster_margins.push({
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              id: `box-${dt}-2`,
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: 3,
              xMax: 4,
              yMax: cluster3[dt].min,
              yMin: cluster2[dt].min,
              backgroundColor: '#ffe47a',
            });
          }else {
            cluster_margins.push({
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              id: `box-${dt}-3`,
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: 4,
              xMax: 5,
              yMax: cluster2[dt].max,
              yMin: cluster2[dt].min,
              backgroundColor: '#ffe47a',
            });
          }

          if (cluster3[dt].cluster.length > 0 && cluster4[dt].cluster.length > 0) {
            cluster_margins.push({
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              id: `box-${dt}-3`,
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: 4,
              xMax: 5,
              yMax: cluster4[dt].min,
              yMin: cluster3[dt].min,
              backgroundColor: '#FFA726',
            });
          }else {
            cluster_margins.push({
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              id: `box-${dt}-3`,
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: 4,
              xMax: 5,
              yMax: cluster3[dt].max,
              yMin: cluster3[dt].min,
              backgroundColor: '#FFA726',
            });
          }

          if (cluster4[dt].cluster.length > 0 ) {
            cluster_margins.push({
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              id: `box-${dt}-4`,
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: 5,
              xMax: 6,
              yMax: cluster4[dt].min,
              yMin: cluster4[dt].max,
              backgroundColor: '#00eb80',
            });
          }

          set_cluster_margin_annotations(cluster_margins);
        } catch (e) {}
      }
      setLoad(false);
    };

    if (hotels.length > 0 && clusterData.length > 0 && !insights_data) {
      buildPlot();
    }
  }, [dateSelection, plotDateSelection, hotels]);

  useEffect(() => {
    const build_quadrent_dataset = () => {
      setLoad(true);
      let lelr_hotels = [];
      let melr_hotels = [];
      let mebr_hotels = [];
      let lebr_hotels = [];

      let lebr_floor_prices_arr = [];

      const reqHotelName = reqHotel.length > 0
      ? (reqHotel.find((el) => el.name != null) || {}).name
      : undefined;
      
      let reqHotelData = reqHotelName 
        ? hotels.find((o) => reqHotelName.localeCompare(o.hotelName) === 0)
        : undefined;


      [...Array(report_len).keys()].map((obj, index) => {
        let lelr = [];
        let melr = [];
        let mebr = [];
        let lebr = [];

        let all_hotels = [];

        let lebr_floor_price =
          reqHotelData.prices[index] != null &&
          reqHotelData.prices[index] != undefined
            ? reqHotelData.prices[index].price[
                getPrice(reqHotelData.prices[index].price)
              ]
            : '--';

        let lebr_floor_hotel =
          reqHotelData.prices[index] != null &&
          reqHotelData.prices[index] != undefined
            ? reqHotelData.hotelName
            : '--';

        hotels.map((_hotel) => {
          if (
            _hotel.prices[index] != null &&
            _hotel.prices[index] !== undefined &&
            reqHotelData.prices[index] != null &&
            reqHotelData.prices[index] != undefined
          ) {
            const reqHotelFrqStarMinMax = getFreqStarMinMaxRatings(
              reqHotelData.stars,
              index
            );

            const FrqStarMinMax = getFreqStarMinMaxRatings(_hotel.stars, index);

            if (FrqStarMinMax.min > 0 && FrqStarMinMax.max > 0) {
              let FreqStarRatingDiff =
                FrqStarMinMax.max - FrqStarMinMax.min > 0
                  ? FrqStarMinMax.max - FrqStarMinMax.min
                  : 1;

              let reqHotel_FreqStarRatingDiff =
                reqHotelFrqStarMinMax.max - reqHotelFrqStarMinMax.min > 0
                  ? reqHotelFrqStarMinMax.max - reqHotelFrqStarMinMax.min
                  : 1;

              let frq_rating_stars = Number(
                Math.abs(_hotel.ratings - FrqStarMinMax.min) /
                  FreqStarRatingDiff +
                  Math.floor(_hotel.stars)
              ).toFixed(2);

              let reqHotel_frq_rating_stars = Number(
                Math.abs(reqHotelData.ratings - reqHotelFrqStarMinMax.min) /
                  reqHotel_FreqStarRatingDiff +
                  Math.floor(reqHotelData.stars)
              ).toFixed(2);

              let x = (frq_rating_stars - reqHotel_frq_rating_stars).toFixed(2);
              let y =
                _hotel.prices[index].price[
                  getPrice(_hotel.prices[index].price)
                ] -
                reqHotelData.prices[index].price[
                  getPrice(reqHotelData.prices[index].price)
                ];

              all_hotels.push({ x: x, y: y, hotelName: _hotel.hotelName });

              if (x == 0 && y == 0) {
              } else if (x < 0 && y < 0) {
                _hotel.prices[index].quadrant = 'LELR';
                lelr.push(_hotel.hotelName);
              } else if (x <= 0 && y >= 0) {
                _hotel.prices[index].quadrant = 'MELR';
                melr.push(_hotel.hotelName);
              } else if (x > 0 && y > 0) {
                _hotel.prices[index].quadrant = 'MEBR';
                mebr.push(_hotel.hotelName);
              } else if (x >= 0 && y <= 0) {
                _hotel.prices[index].quadrant = 'LEBR';
                lebr.push(_hotel.hotelName);
              }
            }
          }
        });

        if (
          reqHotelData.prices[index] != null &&
          reqHotelData.prices[index] != undefined
        ) {
          const max_price_difference_hotels = all_hotels
            .filter((item) => item.x >= 0 && item.y <= 0)
            .sort((a, b) => b.x - a.x || a.y - b.y);

          if (max_price_difference_hotels.length > 0) {
            lebr_floor_price =
              reqHotelData.prices[index].price[
                getPrice(reqHotelData.prices[index].price)
              ] - Math.abs(max_price_difference_hotels[0].y);

            lebr_floor_hotel = max_price_difference_hotels[0].hotelName;
          }
        }

        lebr_floor_prices_arr.push({
          hotel: lebr_floor_hotel,
          rate: lebr_floor_price,
        });

        lelr_hotels.push({ hotels: lelr.length });
        melr_hotels.push({ hotels: melr.length });
        mebr_hotels.push({ hotels: mebr.length });
        lebr_hotels.push({ hotels: lebr.length });
      });

      set_zero_lebr_floor_prices(lebr_floor_prices_arr);

      const lelr_avg = getAvg(lelr_hotels.map((obj) => obj.hotels));
      const lelr_stdev = getStandardDeviation(
        lelr_hotels.map((obj) => obj.hotels)
      );

      lelr_hotels.map((obj) => {
        obj.variance = getVarience(obj.hotels, lelr_avg, lelr_stdev);
      });

      const melr_avg = getAvg(melr_hotels.map((obj) => obj.hotels));
      const melr_stdev = getStandardDeviation(
        melr_hotels.map((obj) => obj.hotels)
      );

      melr_hotels.map((obj) => {
        obj.variance = getVarience(obj.hotels, melr_avg, melr_stdev);
      });

      const mebr_avg = getAvg(mebr_hotels.map((obj) => obj.hotels));
      const mebr_stdev = getStandardDeviation(
        mebr_hotels.map((obj) => obj.hotels)
      );

      mebr_hotels.map((obj) => {
        obj.variance = getVarience(obj.hotels, mebr_avg, mebr_stdev);
      });

      const lebr_avg = getAvg(lebr_hotels.map((obj) => obj.hotels));
      const lebr_stdev = getStandardDeviation(
        lebr_hotels.map((obj) => obj.hotels)
      );

      lebr_hotels.map((obj) => {
        obj.variance = getVarience(obj.hotels, lebr_avg, lebr_stdev);
      });

      set_lelr_hotels(lelr_hotels);

      set_melr_hotels(melr_hotels);

      set_mebr_hotels(mebr_hotels);

      set_lebr_hotels(lebr_hotels);

      setLoad(false);
    };

    const build_data = async () => {
      if (hotels.length > 0 && clusterData.length > 0) {
        await build_quadrent_dataset();
      }
    };

    build_data();
  }, [hotels]);

  useEffect(() => {
    const handdle_scroll = () => {
      if (!load && hotels.length > 0 && clusterData.length > 0) {
        if (tbl_ref == 1) {
          window.scrollTo(0, quardrent_movements_tbl.current.offsetTop - 250);
        } else if (tbl_ref == 2) {
          window.scrollTo(
            0,
            quardrent_movements_exp_tbl.current.offsetTop - 250
          );
        }
      }
    };
    handdle_scroll();
  }, [load]);

  const getHotelDataByXY = (x, y) => {
    let names = [];
    if (plotDataset.length > 0) {
      plotDataset.map((e) => {
        if (e.data[0].x == x && e.data[0].y == y) {
          names.push(e.label);
        }
      });
    }
    return names;
  };

  const handleDatePage = (e) => {
    setDatePage(e);
    setDateSelection([dateRange[e][0], dateRange[e][1]]);
  };

  const handlePlotDateSelection = (e) => {
    setPlotDateSelection(e);
  };

  const QuadrantMovements = () => {
    return (
      <>
        {!load ? (
          <TableContainer
            component={Paper}
            className="my-5"
            ref={quardrent_movements_tbl}
          >
            <Box width={100}>
              <Table
                className={classes.table}
                size="medium"
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      width: '250px',
                      zIndex: 100,
                      fontFamily: FONT_FAMILY,
                    }}
                  >
                    <TableSortLabel disabled>Quadrant Count</TableSortLabel>{' '}
                    <hr />
                    <TableSortLabel disabled>Days Out</TableSortLabel>
                  </StyledTableCell>
                  {reqHotel.map((e, index) =>
                    (() => {
                      let _date = moment(e.checkIn);
                      let daysOut = _date.diff(selectedDate, 'days');
                      let date = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            date === 'Sat' || date === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          <>
                            {date === 'Sat' || date === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{date.toUpperCase()}</>
                          <br />
                          <>{moment(e.checkIn).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      size="medium"
                      component="th"
                      scope="col"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      Less Expensive Better Rated
                    </StyledTableCell>
                    {lebr_hotels.map((obj, dt) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={dt}
                          className={classes.rates}
                        >
                          {obj.hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      More Expensive Lower Rated
                    </StyledTableCell>

                    {melr_hotels.map((obj, dt) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={dt}
                          className={classes.rates}
                        >
                          {obj.hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        zIndex: 100,
                      }}
                    >
                      Less Expensive Lower Rated
                    </StyledTableCell>

                    {lelr_hotels.map((obj, dt) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={dt}
                          className={classes.rates}
                        >
                          {obj.hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        zIndex: 100,
                      }}
                    >
                      More Expensive Better Rated
                    </StyledTableCell>

                    {mebr_hotels.map((obj, dt) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={dt}
                          className={classes.rates}
                        >
                          {obj.hotels}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        zIndex: 100,
                      }}
                    >
                      Zero Less Expensive Better Rated Floor
                    </StyledTableCell>
                    {zero_lebr_floor_prices.map((obj, dt) => {
                      return (
                        <Tooltip
                          title={obj.hotel != 'N/A' ? obj.hotel : ''}
                          followCursor
                        >
                          <StyledTableCell
                            size="small"
                            key={dt}
                            className={classes.rates}
                            style={{
                              backgroundColor:
                                obj.rate != 'N/A'
                                  ? CLUSTER_BACKGROUND[
                                      getClusterByPrice(obj.rate, dt)
                                    ]
                                  : '',
                            }}
                          >
                            {obj.rate}
                          </StyledTableCell>
                        </Tooltip>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>

              <br />
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  const QuadrantMovementsException = () => {
    return (
      <>
        {!load ? (
          <TableContainer
            component={Paper}
            className="my-5"
            ref={quardrent_movements_exp_tbl}
          >
            <Box width={100}>
              <Table
                className={classes.table}
                size="medium"
                aria-label="customized table"
                bodyStyle={{ overflow: 'visible' }}
                stickyHeader
              >
                <TableHead>
                  <StyledTableCell
                    style={{
                      width: '250px',
                      zIndex: 100,
                      fontFamily: FONT_FAMILY,
                    }}
                  >
                    <TableSortLabel disabled>
                      High Quadrant Count
                    </TableSortLabel>{' '}
                    <hr />
                    <TableSortLabel disabled>Days Out</TableSortLabel>
                  </StyledTableCell>
                  {reqHotel.map((e, index) =>
                    (() => {
                      let _date = moment(e.checkIn);
                      let daysOut = _date.diff(selectedDate, 'days');
                      let date = _date.format('dddd').substring(0, 3);
                      return (
                        <StyledTableCell
                          size="small"
                          key={index}
                          className={
                            date === 'Sat' || date === 'Fri'
                              ? 'bg-secondary text-light text-center'
                              : 'text-center'
                          }
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          <>
                            {date === 'Sat' || date === 'Fri' ? 'WEND' : 'WDAY'}
                          </>
                          <br />
                          <>{date.toUpperCase()}</>
                          <br />
                          <>{moment(e.checkIn).format(date_format)}</>{' '}
                          <div class="dropdown-divider"></div>
                          {daysOut}
                        </StyledTableCell>
                      );
                    })()
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      size="medium"
                      component="th"
                      scope="col"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      Less Expensive Better Rated
                    </StyledTableCell>
                    {lebr_hotels.map((obj, dt) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={dt}
                          className={classes.rates}
                          style={{ fontSize: '12px' }}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{ fontWeight: 'bold', width: '250px' }}
                    >
                      More Expensive Lower Rated
                    </StyledTableCell>

                    {melr_hotels.map((obj, dt) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={dt}
                          className={classes.rates}
                          style={{ fontSize: '12px' }}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        zIndex: 100,
                      }}
                    >
                      More Expensive Better Rated
                    </StyledTableCell>

                    {mebr_hotels.map((obj, dt) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={dt}
                          className={classes.rates}
                          style={{ fontSize: '12px' }}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell
                      size="small"
                      component="th"
                      scope="row"
                      className={classes.sticky}
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        zIndex: 100,
                      }}
                    >
                      Less Expensive Lower Rated
                    </StyledTableCell>

                    {lelr_hotels.map((obj, dt) => {
                      return (
                        <StyledTableCell
                          size="small"
                          key={dt}
                          style={{
                            fontSize: '12px',
                          }}
                          className={classes.rates}
                        >
                          {obj.variance}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
              <br />
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  const GetInsightsTableData = () => {
    return (
      <>
        <StyledTableRow>
          <StyledTableCell
            size="small"
            component="th"
            scope="col"
            className={classes.sticky}
            style={{ fontWeight: 'bold', width: '250px' }}
          >
            Less Expensive Better Rated - Count
          </StyledTableCell>
          {lebr_hotels.map((obj, dt) => {
            return (
              <StyledTableCell size="small" key={dt} className={classes.rates}>
                {obj.hotels}
              </StyledTableCell>
            );
          })}
        </StyledTableRow>

        <StyledTableRow>
          <StyledTableCell
            size="small"
            component="th"
            scope="col"
            className={classes.sticky}
            style={{ fontWeight: 'bold', width: '250px' }}
          >
            Less Expensive Better Rated - High Up Movement
          </StyledTableCell>
          {lebr_hotels.map((obj, dt) => {
            return (
              <StyledTableCell
                size="small"
                key={dt}
                className={classes.rates}
                style={{ fontSize: '12px' }}
              >
                {obj.variance}
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      </>
    );
  };

  const GetShoppersViewPlot = () => {
    return (
      <>
        <Grid container justify="space-around" className="my-5">
          <h3>Conventional View</h3>

          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="grouped-native-select"
              style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
            >
              Hotels Filter
            </InputLabel>
            <Select
              native
              id="grouped-native-select"
              onChange={(event) => setHotelsFilter(event.target.value)}
              value={hotelsFilter}
              style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
            >
              <option value={0}>All Hotels</option>
              <option value={1}>Analysis Set</option>
              {/* <option value={2}>Best Rated Hotels</option> */}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-native-select">Select Date</InputLabel>
            <Select
              native={true}
              onChange={(e) => handlePlotDateSelection(e.target.value)}
              id="grouped-native-select"
              value={plotDateSelection}
            >
              {reqHotel.map((e, i) => (
                <option value={i}>
                  {moment(e.checkIn).format(date_format)}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {shoppersDatasetData.length > 0 && shoppersDatasetLabels.length > 0 ? (
          <Scatter
            options={{
              legend: {
                display: false,
              },
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    return (
                      data.datasets[tooltipItem.datasetIndex].label +
                      ': (' +
                      tooltipItem.xLabel +
                      ', ' +
                      tooltipItem.yLabel +
                      ')'
                    );
                  },
                },
              },
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Rate',
                    },
                    id: 'y-axis-0',
                    // ticks: {
                    //   beginAtZero: false,
                    //   min: 0,
                    //   max:
                    //     hotelsFilter == 1
                    //       ? Math.max(
                    //           ...analysisShoppersData.map((d) => d.data[0].y)
                    //         )
                    //       : Math.max(
                    //           ...shoppersDatasetData.map((d) => d.data[0].y)
                    //         ),
                    // },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Stars and Rating Index',
                    },
                    id: 'x-axis-0',
                  },
                ],
              },
              annotation: {
                drawTime: 'afterDatasetsDraw',
                dblClickSpeed: 350,
                annotations: cluster_margin_annotations.filter(
                  (margin) => margin.type === 'line'
                ),
              },
            }}
            data={{
              labels:
                hotelsFilter == 1
                  ? analysisShoppersLabels
                  : shoppersDatasetLabels,
              datasets:
                hotelsFilter == 1 ? analysisShoppersData : shoppersDatasetData,
            }}
            height={'25%'}
            width={'100%'}
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      {insights_data && !load ? (
        <GetInsightsTableData />
      ) : !load ? (
        <>
          <Grid container justify="space-around" className="my-5">
            <h3>Bucket Position</h3>

            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="grouped-native-select"
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                Hotels Filter
              </InputLabel>
              <Select
                native
                id="grouped-native-select"
                onChange={(event) => setHotelsFilter(event.target.value)}
                value={hotelsFilter}
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                <option value={0}>All Hotels</option>
                <option value={1}>Analysis Set</option>
                {/* <option value={2}>Best Rated Hotels</option> */}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-native-select">
                Select Date
              </InputLabel>
              <Select
                native={true}
                onChange={(e) => handlePlotDateSelection(e.target.value)}
                id="grouped-native-select"
                value={plotDateSelection}
              >
                {reqHotel.map((e, i) => (
                  <option value={i}>
                    {moment(e.checkIn).format(date_format)}
                  </option>
                ))}
              </Select>
            </FormControl>

          </Grid>
          <Grid container justify="space-around" className="my-5">
                <span
                  className="p-1 rounded font-weight-bold text-secondary"
                  style={{ backgroundColor: '#7acaff' }}
                >
                  {CLUSTER_LABELS[0]} Bucket
                </span>
                <span
                  className="p-1 rounded font-weight-bold text-secondary"
                  style={{ backgroundColor: '#ffe47a' }}
                >
                  {CLUSTER_LABELS[1]} Bucket
                </span>
                <span
                  className="p-1 rounded font-weight-bold text-secondary"
                  style={{ backgroundColor: '#FFA726' }}
                >
                  {CLUSTER_LABELS[2]} Bucket
                </span>

                <span
                  className="p-1 rounded font-weight-bold text-secondary"
                  style={{ backgroundColor: '#00eb80' }}
                >
                   {CLUSTER_LABELS[3]} Bucket
                </span>
          </Grid>
          {plotDataset.length > 0 && plotLabels.length > 0 ? (
            <Scatter
              options={{
                legend: {
                  display: false,
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      return (
                        data.datasets[tooltipItem.datasetIndex].label +
                        ': (' +
                        tooltipItem.xLabel +
                        ', ' +
                        tooltipItem.yLabel +
                        ')'
                      );
                    },
                  },
                },
                scales: {
                  yAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: 'Rate',
                      },
                      id: 'y-axis-0',
                    },
                  ],
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: 'Bucket and Rating Index',
                      },
                      id: 'x-axis-0',
                      ticks: {
                        display: false
                      }
                    },
                  ],
                },
                annotation: {
                  drawTime: 'afterDatasetsDraw',
                  dblClickSpeed: 350,
                  annotations: cluster_margin_annotations,
                },
              }}
              data={{
                labels: hotelsFilter == 1 ? analysisLabels : plotLabels,
                datasets:
                  hotelsFilter == 1 ? analysisPositionData : plotDataset,
              }}
              height={'25%'}
              width={'100%'}
            />
          ) : (
            <></>
          )}

          <GetShoppersViewPlot />

          <Grid container justify="space-around" className="my-5">
            <h3>Competitive Quadrant</h3>

            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="grouped-native-select"
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                Hotels Filter
              </InputLabel>
              <Select
                native
                id="grouped-native-select"
                onChange={(event) => setHotelsFilter(event.target.value)}
                value={hotelsFilter}
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                <option value={0}>All Hotels</option>
                <option value={1}>Analysis Set</option>
                {/* <option value={2}>Best Rated Hotels</option> */}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-native-select">
                Select Date
              </InputLabel>
              <Select
                native={true}
                onChange={(e) => handlePlotDateSelection(e.target.value)}
                id="grouped-native-select"
                value={plotDateSelection}
              >
                {reqHotel.map((e, i) => (
                  <option value={i}>
                    {moment(e.checkIn).format(date_format)}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {plotData.length > 0 && shoppersDatasetLabels.length > 0 ? (
            <>
              <Grid container justify="space-around" className="my-5">
                <span
                  className="p-1 rounded font-weight-bold text-secondary"
                  style={{ backgroundColor: quard_colors[0].color }}
                >
                  {quard_colors[0].label}
                </span>
                <span
                  className="p-1 rounded font-weight-bold text-secondary"
                  style={{ backgroundColor: quard_colors[2].color }}
                >
                  {quard_colors[2].label}
                </span>
                <span
                  className="p-1 rounded font-weight-bold text-secondary"
                  style={{ backgroundColor: quard_colors[1].color }}
                >
                  {quard_colors[1].label}
                </span>

                <span
                  className="p-1 rounded font-weight-bold text-secondary"
                  style={{ backgroundColor: quard_colors[3].color }}
                >
                  {quard_colors[3].label}
                </span>
              </Grid>
              <Scatter
                options={{
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    callbacks: {
                      label: function (tooltipItem, data) {
                        return (
                          data.datasets[tooltipItem.datasetIndex].label +
                          ': (' +
                          tooltipItem.xLabel +
                          ', ' +
                          tooltipItem.yLabel +
                          ')'
                        );
                      },
                    },
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          zeroLineColor: '#000000',
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Difference in Rate',
                        },
                      },
                    ],
                    xAxes: [
                      {
                        gridLines: {
                          zeroLineColor: '#000000',
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Difference in Rating Index',
                        },
                      },
                    ],
                  },
                }}
                plugins={plugins}
                data={{
                  labels:
                    hotelsFilter == 1
                      ? analysisShoppersLabels
                      : shoppersDatasetLabels,
                  datasets: hotelsFilter == 1 ? analysisQuadrantData : plotData,
                }}
                height={'25%'}
                width={'100%'}
              />
            </>
          ) : (
            <></>
          )}

          <QuadrantMovements />

          <QuadrantMovementsException />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
