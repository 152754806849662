import { makeStyles } from '@material-ui/core/styles';

export const AUTHORIZATION_KEY = 'AUTHORIZATION';
export const REFRESH_KEY = 'REFRESH_KEY';

export const CLUSTER_BACKGROUND = [
  '#009AFF',
  '#FFCC00',
  '#FB6B3C',
  '#00CC6F',
  '#9E9E9E',
];

export const CLUSTER_LABELS = [
  'LOW',
  'MID',
  'HIGH',
  'ELITE',
  'OUT'
];

export const FONT_FAMILY = 'Inter, sans-serif';

const multiSelectStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

export const get_cluster_day_temp = (date) => {
  return {
    cluster: [],
    date: date,
    unwanted: [],
    items: 'N/A',
    total: 'N/A',
    min: 'N/A',
    max: 'N/A',
    mean: 'N/A',
    median: 'N/A',
    mod: 'N/A',
    highAVG: 'N/A',
    midAVG: 'N/A',
    lowAVG: 'N/A',
    stars2: {},
    stars3: [],
    stars4: [],
    stars5: [],
    outliers_up: [],
    outliers_down: [],
  };
};

export const getDateFormatByMarket = (market) => {
  if (
    market == 169995 ||
    market == 1447930 ||
    market == 1535616 ||
    market == 3073
  ) {
    return 'MM/DD';
  } else {
    return 'DD/MM';
  }
};

export { multiSelectStyles, MenuProps };
