import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
} from '@material-ui/core';
import { get } from 'jquery';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Bar, Line, Scatter } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { CLUSTER_BACKGROUND, CLUSTER_LABELS } from '../utils/const';
import { HotelsPlot } from './HotelsPlot';

import { Chart } from 'chart.js';
import * as annotationPlugin from 'chartjs-plugin-annotation';

Chart.pluginService.register(annotationPlugin);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export const Graphs = ({ selectedDate, selectedProperty }) => {
  const classes = useStyles();
  const [matrix, setMatrix] = useState('avg');
  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    loading,
    err,
    clusterData,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels,
    reqHotel,
    report_len,
    date_format,
  } = getClusterDataSet;

  const getDateRange = () => {
    let dateRange_arr = [];
    let totalDays = 0;

    if (report_len > 0) {
      const no_chuncks = Math.ceil(report_len / 30);

      for (let i = 1; i <= no_chuncks; i++) {
        totalDays += 30;
        if (totalDays <= report_len) {
          dateRange_arr.push([(i - 1) * 30, i * 30]);
        } else if (totalDays > report_len) {
          dateRange_arr.push([
            (i - 1) * 30,
            i * 30 - (totalDays - (report_len - 1)),
          ]);
        }
      }
    }
    return dateRange_arr;
  };

  const [dateRange, setDateRange] = useState(getDateRange());

  const [datePage, setDatePage] = useState(0);

  const [chartData, setChartDatae] = useState({
    labels: cluster1
      .slice(dateRange[datePage][0], dateRange[datePage][1])
      .map((a) => moment(a.date).format(date_format)),
    datasets: [
      cluster1.length > 0
        ? {
            label: 'Stars 2',
            backgroundColor: CLUSTER_BACKGROUND[0],
            borderColor: CLUSTER_BACKGROUND[0],
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: CLUSTER_BACKGROUND[0],
            hoverBorderColor: CLUSTER_BACKGROUND[0],
            data: cluster1
              .slice(dateRange[datePage][0], dateRange[datePage][1])
              .map((a) => (a.items > 0 ? a.mean : 'NED')),
          }
        : {},

      cluster2.length > 0
        ? {
            label: 'Stars 3',
            backgroundColor: CLUSTER_BACKGROUND[1],
            borderColor: CLUSTER_BACKGROUND[1],
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: CLUSTER_BACKGROUND[1],
            hoverBorderColor: CLUSTER_BACKGROUND[1],
            data: cluster2
              .slice(dateRange[datePage][0], dateRange[datePage][1])
              .map((a) => (a.items > 0 ? a.mean : 'NED')),
          }
        : {},
      cluster3.length > 0
        ? {
            label: 'Stars 4',
            backgroundColor: CLUSTER_BACKGROUND[2],
            borderColor: CLUSTER_BACKGROUND[2],
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: CLUSTER_BACKGROUND[2],
            hoverBorderColor: CLUSTER_BACKGROUND[2],
            data: cluster3
              .slice(dateRange[datePage][0], dateRange[datePage][1])
              .map((a) => (a.items > 0 ? a.mean : 'NED')),
          }
        : {},
      cluster4.length > 0
        ? {
            label: 'Stars 5',
            backgroundColor: CLUSTER_BACKGROUND[3],
            borderColor: CLUSTER_BACKGROUND[3],
            borderWidth: 1,
            // stack: 1,
            hoverBackgroundColor: CLUSTER_BACKGROUND[3],
            hoverBorderColor: CLUSTER_BACKGROUND[3],
            data: cluster4
              .slice(dateRange[datePage][0], dateRange[datePage][1])
              .map((a) => (a.items > 0 ? a.mean : 'NED')),
          }
        : {},
    ],
  });
  const [lineData, setLineData] = useState({
    labels: cluster1
      .slice(dateRange[datePage][0], dateRange[datePage][1])
      .map((a) => moment(a.date).format(date_format)),
    datasets: [
      cluster1.length > 0
        ? {
            label: `${CLUSTER_LABELS[0]} Bucket`,
            fill: true,
            borderColor: CLUSTER_BACKGROUND[0],
            borderWidth: 5,
            data: cluster1
              .slice(dateRange[datePage][0], dateRange[datePage][1])
              .map((a) => (a.items > 0 ? a.mean : 'NED')),
          }
        : {
            label: `${CLUSTER_LABELS[0]} Bucket`,
            fill: true,
            borderColor: CLUSTER_BACKGROUND[0],
            borderWidth: 5,
          },
      cluster2.length > 0
        ? {
            label: `${CLUSTER_LABELS[1]} Bucket`,
            fill: true,
            borderColor: CLUSTER_BACKGROUND[1],
            borderWidth: 5,
            data: cluster2
              .slice(dateRange[datePage][0], dateRange[datePage][1])
              .map((a) => (a.items > 0 ? a.mean : 'NED')),
          }
        : {
            label: `${CLUSTER_LABELS[1]} Bucket`,
            fill: true,
            borderColor: CLUSTER_BACKGROUND[1],
            borderWidth: 5,
          },
      cluster3.length > 0
        ? {
            label: `${CLUSTER_LABELS[2]} Bucket`,
            fill: true,
            borderColor: CLUSTER_BACKGROUND[2],
            borderWidth: 5,
            data: cluster3
              .slice(dateRange[datePage][0], dateRange[datePage][1])
              .map((a) => (a.items > 0 ? a.mean : 'NED')),
          }
        : {
            label: `${CLUSTER_LABELS[2]} Bucket`,
            fill: true,
            borderColor: CLUSTER_BACKGROUND[2],
            borderWidth: 5,
          },
      cluster4.length > 0
        ? {
            label: `${CLUSTER_LABELS[3]} Bucket`,
            fill: true,
            // backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: CLUSTER_BACKGROUND[3],
            borderWidth: 5,
            data: cluster4
              .slice(dateRange[datePage][0], dateRange[datePage][1])
              .map((a) => (a.items > 0 ? a.mean : 'NED')),
          }
        : {
            label: `${CLUSTER_LABELS[3]} Bucket`,
            fill: true,
            // backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: CLUSTER_BACKGROUND[3],
            borderWidth: 5,
          },
      reqHotel.length > 0
        ? {
            label: (reqHotel.find((el) => el.name != null) || {}).name,
            //new option, type will default to bar as that what is used to create the scale
            type: 'line',
            fill: true,
            borderColor: '#2e2e2e',
            borderWidth: 5,
            data: reqHotel
              .slice(dateRange[datePage][0], dateRange[datePage][1])
              .map((a) => a.rate),
          }
        : {
            label: (reqHotel.find((el) => el.name != null) || {}).name,
            //new option, type will default to bar as that what is used to create the scale
            type: 'line',
            fill: true,
            borderColor: '#2e2e2e',
            borderWidth: 5,
          },
    ],
  });
  const [options, setOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    type: 'bar',
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Rates',
          },
        },
      ],
    },
  });

  const [
    subject_hotel_avg_rank_annotations,
    set_subject_hotel_avg_rank_annotations,
  ] = useState([]);

  const [
    zone_freq_range_high_annotations,
    set_zone_freq_range_high_annotations,
  ] = useState([]);

  const [zone_freq_range_low_annotations, set_zone_freq_range_low_annotations] =
    useState([]);

  const [stackedOptions, setStackedOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    type: 'bar',
    scales: {
      xAxes: [
        {
          stacked: true,
          id: 'x-axis-0',
        },
      ],
      yAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Hotel Rank',
          },
          id: 'y-axis-0',
          ticks: {
            reverse: true,
            max: hotels.length,
          },
        },
      ],
    },
  });

  const [_hotelData_wd, set_HotelData_wd] = useState({
    labels: [],
    datasets: [
      {
        label: (reqHotel.find((el) => el.name != null) || {}).name,
        type: 'line',
        fill: false,
        showLine: false,
        backgroundColor: '#2e2e2e',
        borderColor: '#2e2e2e',
        data: [],
      },
      {
        label: 'Stars 5',
        backgroundColor: CLUSTER_BACKGROUND[3],
        borderColor: CLUSTER_BACKGROUND[3],
        borderWidth: 1,
        // stack: 1,
        hoverBackgroundColor: CLUSTER_BACKGROUND[3],
        hoverBorderColor: CLUSTER_BACKGROUND[3],
        data: [],
      },
      {
        label: 'Stars 4',
        backgroundColor: CLUSTER_BACKGROUND[2],
        borderColor: CLUSTER_BACKGROUND[2],
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: CLUSTER_BACKGROUND[2],
        hoverBorderColor: CLUSTER_BACKGROUND[2],
        data: [],
      },
      {
        label: 'Stars 3',
        backgroundColor: CLUSTER_BACKGROUND[1],
        borderColor: CLUSTER_BACKGROUND[1],
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: CLUSTER_BACKGROUND[1],
        hoverBorderColor: CLUSTER_BACKGROUND[1],
        data: [],
      },

      {
        label: 'Stars 2',
        backgroundColor: CLUSTER_BACKGROUND[0],
        borderColor: CLUSTER_BACKGROUND[0],
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: CLUSTER_BACKGROUND[0],
        hoverBorderColor: CLUSTER_BACKGROUND[0],
        data: [],
      },
      {
        label: 'Outliers',
        backgroundColor: '#9E9E9E',
        borderColor: '#9E9E9E',
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: '#9E9E9E',
        hoverBorderColor: '#9E9E9E',
        data: [],
      },
      {
        label: 'No Rates',
        backgroundColor: '#B0BEC5',
        borderColor: '#B0BEC5',
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: '#B0BEC5',
        hoverBorderColor: '#B0BEC5',
        data: [],
      },
    ],
  });

  const [_hotelData_we, set_HotelData_we] = useState({
    labels: [],
    datasets: [
      {
        label: (reqHotel.find((el) => el.name != null) || {}).name,
        type: 'line',
        fill: false,
        showLine: false,
        backgroundColor: '#2e2e2e',
        borderColor: '#2e2e2e',
        data: [],
      },
      {
        label: 'Stars 5',
        backgroundColor: CLUSTER_BACKGROUND[3],
        borderColor: CLUSTER_BACKGROUND[3],
        borderWidth: 1,
        // stack: 1,
        hoverBackgroundColor: CLUSTER_BACKGROUND[3],
        hoverBorderColor: CLUSTER_BACKGROUND[3],
        data: [],
      },
      {
        label: 'Stars 4',
        backgroundColor: CLUSTER_BACKGROUND[2],
        borderColor: CLUSTER_BACKGROUND[2],
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: CLUSTER_BACKGROUND[2],
        hoverBorderColor: CLUSTER_BACKGROUND[2],
        data: [],
      },
      {
        label: 'Stars 3',
        backgroundColor: CLUSTER_BACKGROUND[1],
        borderColor: CLUSTER_BACKGROUND[1],
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: CLUSTER_BACKGROUND[1],
        hoverBorderColor: CLUSTER_BACKGROUND[1],
        data: [],
      },

      {
        label: 'Stars 2',
        backgroundColor: CLUSTER_BACKGROUND[0],
        borderColor: CLUSTER_BACKGROUND[0],
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: CLUSTER_BACKGROUND[0],
        hoverBorderColor: CLUSTER_BACKGROUND[0],
        data: [],
      },
      {
        label: 'Outliers',
        backgroundColor: '#9E9E9E',
        borderColor: '#9E9E9E',
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: '#9E9E9E',
        hoverBorderColor: '#9E9E9E',
        data: [],
      },
      {
        label: 'No Rates',
        backgroundColor: '#B0BEC5',
        borderColor: '#B0BEC5',
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: '#B0BEC5',
        hoverBorderColor: '#B0BEC5',
        data: [],
      },
    ],
  });

  const [dateSelection, setDateSelection] = useState(0);

  const [scatterPlot, setScatterPlot] = useState(2);

  const [scatterPlotLabels, setScatterPlotLabels] = useState();

  const getClusterByPrice = (rate, ix) => {
    let clustered = [];
    let res = 4;

    if (cluster1.length > 0 && cluster1[ix]) {
      clustered.push(cluster1[ix]);
    }
    if (cluster2.length > 0 && cluster2[ix]) {
      clustered.push(cluster2[ix]);
    }
    if (cluster3.length > 0 && cluster3[ix]) {
      clustered.push(cluster3[ix]);
    }
    if (cluster4.length > 0 && cluster4[ix]) {
      clustered.push(cluster4[ix]);
    }

    clustered.sort((a, b) => a.mean - b.mean);

    // console.log(clustered);

    try {
      clustered.map((cl, id) => {
        if (rate >= cl.min && rate <= cl.max) {
          res = id;
          return;
        }
      });
    } catch (e) {}

    return res;
  };

  const [scatterData2avg, setScatterData2avg] = useState([]);
  const [scatterData2high, setScatterData2high] = useState([]);
  const [scatterData2low, setScatterData2low] = useState([]);

  const [scatterData3avg, setScatterData3avg] = useState([]);
  const [scatterData3high, setScatterData3high] = useState([]);
  const [scatterData3low, setScatterData3low] = useState([]);

  const [scatterData4avg, setScatterData4avg] = useState([]);
  const [scatterData4high, setScatterData4high] = useState([]);
  const [scatterData4low, setScatterData4low] = useState([]);

  const [scatterData5avg, setScatterData5avg] = useState([]);
  const [scatterData5high, setScatterData5high] = useState([]);
  const [scatterData5low, setScatterData5low] = useState([]);

  const [_hotelsCountDataset, set_hotelsCountDataset] = useState([]);

  const [bind, setBind] = useState(false);

  const [req_hotel_prices_wd, set_req_hotel_prices_wd] = useState([]);
  const [req_hotel_prices_we, set_req_hotel_prices_we] = useState([]);



  const getStandardDeviation = (array) => {
    const n = array.length;
    if (n > 0) {
      const mean = array.reduce((a, b) => a + b) / n;
      return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
      );
    } else {
      return -1;
    }
  };

  useEffect(() => {
    const set_req_hotel_prices = () => {
      let wd_prices = [];
      let we_prices = [];
      if (reqHotel.length > 0) {
        reqHotel.map((e, i) => {
          if (moment(e.checkIn).day() == 5 || moment(e.checkIn).day() == 6) {
            if (e.rate != 'N/A') {
              we_prices.push(e.rate);
            } else {
              we_prices.push('');
            }
          } else {
            if (e.rate != 'N/A') {
              wd_prices.push(e.rate);
            } else {
              wd_prices.push('');
            }
          }
        });
      }
      set_req_hotel_prices_wd(wd_prices);
      set_req_hotel_prices_we(we_prices);
    };

    set_req_hotel_prices();
  }, []);

  const getReqHotelName = () => {
    let name = null;
    if (reqHotel.length > 0) {
      reqHotel.map((e, index) => {
        if (e.name !== null) {
          name = e.name;
        }
      });
    }

    return name;
  };

  const getAverage = (arr) => {
    let array = arr.filter((n) => n > 0 || n);
    if (array.length > 0) {
      let avg = array.reduce((a, b) => a + b) / array.length;
      return avg;
    } else {
      return -1;
    }
  };

  function toggleLabel(ctx, event, id) {
    const chart = ctx.chart;
    const annotationOpts = chart.options.annotation.annotations;
    if (Object.prototype.toString.call(annotationOpts) === '[object Array]') {
      annotationOpts.map((an, i) => {
        if (i == id) {
          an.label.enabled = !an.label.enabled;
        } else {
          an.label.enabled = false;
        }
        an.label.position = event.x;
      });
    }

    chart.update();
  }

  const getRankedHotels = (arr) => {
    var sorted = arr
      .filter((e) => e.rate != 'NaN')
      .sort((a, b) => b.rate - a.rate || b.ratings - a.ratings);

    for (var i = 0; i < sorted.length; i++) {
      sorted[i].day_rank = i + 1;
    }
    return sorted;
  };

  useEffect(() => {
    const getPropertyRankInCluster = (day, others) => {
      let hotel_rates_by_day = [];
      hotels.map((_hotel) => {
        let dt = _hotel.prices[day];

        try {
          if (
            dt !== null &&
            checkHotelAvailability(_hotel.hotelID, day)
          ) {
            hotel_rates_by_day.push({
              hotel_id: _hotel.hotelID,
              rate: dt.price[getPrice(dt.price)],
              date: dt.date,
              ratings: _hotel.ratings,
            });
          }
        } catch (error) {}
      });

      const ranked_hotels = getRankedHotels(hotel_rates_by_day);

      let obj =
      ranked_hotels.findIndex((e) => e.hotel_id == selectedProperty) +
      1;

      if (obj > 0) {
        return obj
      }else {
        return null
      }

  };

    const buildHotelsDataSet = async () => {
      let datebyhotelcount = [];

      await [...Array(report_len).keys()].map((e, ix) => {
        let cls5h = [];
        let cls4h = [];
        let cls3h = [];
        let cls2h = [];
        let outliers = [];
        let noRateHotels = [];

        hotels.map((_hotel, index) => {
          if (_hotel.prices[ix] != null) {
            if (checkHotelAvailability(_hotel.hotelID, ix)) {
              let clstr =
                getClusterByPrice(
                  _hotel.prices[ix].price[getPrice(_hotel.prices[ix].price)],
                  ix
                ) + 2;
              clstr === 5
                ? cls5h.push(_hotel)
                : clstr === 4
                ? cls4h.push(_hotel)
                : clstr === 3
                ? cls3h.push(_hotel)
                : clstr === 2
                ? cls2h.push(_hotel)
                : outliers.push(_hotel);
            } else {
              outliers.push(_hotel);
            }
          } else {
            noRateHotels.push(_hotel);
          }
        });

        const day = {
          // date: hotels[0].prices[ix].date,
          date: clusterData[ix].length > 0
          ? (clusterData[ix].find((el) => el.date != null) || {}).date
          : '',
          cls5h: cls5h.length,
          cls4h: cls4h.length,
          cls3h: cls3h.length,
          cls2h: cls2h.length,
          outliers: outliers.length,
          noRateHotels: noRateHotels.length,
          subject_hotel_rank: getPropertyRankInCluster(
            ix,
            noRateHotels.length + outliers.length
          ),
        };

        datebyhotelcount.push(day);

        reqHotel[ix].overallRank = getPropertyRankInCluster(
          ix,
          noRateHotels.length + outliers.length
        );

        // console.log(
        //   `date: ${hotels[0].prices[ix].date}, cls5h: ${cls5h.length}, cls4h: ${cls4h.length}, cls3h: ${cls3h.length}, cls2h: ${cls2h.length}, outliers: ${outliers.length}, noRateHotels: ${noRateHotels.length}`
        // );
      });

      let subject_hotel_rank_wd = [];
      let subject_hotel_rank_we = [];

      sanitize_dataset();

      datebyhotelcount.map((x) => {
        if (x.subject_hotel_rank !== null) {
          
            if (moment(x.date).day() == 5 || moment(x.date).day() == 6) {
              subject_hotel_rank_we.push(x.subject_hotel_rank);
            } else {
              subject_hotel_rank_wd.push(x.subject_hotel_rank);
            }
          
        }

        
      });

      datebyhotelcount
        .slice(dateRange[datePage][0], dateRange[datePage][1])
        .map((x) => {
          if (moment(x.date).day() == 5 || moment(x.date).day() == 6) {
            _hotelData_we.labels.push(moment(x.date).format(date_format));
            _hotelData_we.datasets[0].data.push(x.subject_hotel_rank);
            _hotelData_we.datasets[6].data.push(x.noRateHotels);
            _hotelData_we.datasets[5].data.push(x.outliers);
            _hotelData_we.datasets[4].data.push(x.cls2h);
            _hotelData_we.datasets[3].data.push(x.cls3h);
            _hotelData_we.datasets[2].data.push(x.cls4h);
            _hotelData_we.datasets[1].data.push(x.cls5h);
          } else {
            _hotelData_wd.labels.push(moment(x.date).format(date_format));
            _hotelData_wd.datasets[0].data.push(x.subject_hotel_rank);
            _hotelData_wd.datasets[6].data.push(x.noRateHotels);
            _hotelData_wd.datasets[5].data.push(x.outliers);
            _hotelData_wd.datasets[4].data.push(x.cls2h);
            _hotelData_wd.datasets[3].data.push(x.cls3h);
            _hotelData_wd.datasets[2].data.push(x.cls4h);
            _hotelData_wd.datasets[1].data.push(x.cls5h);
          }
        });

      let avg_rank_wd = getAverage(subject_hotel_rank_wd);
      let avg_rank_we = getAverage(subject_hotel_rank_we);
      let stdev_wd = getStandardDeviation(subject_hotel_rank_wd);
      let stdev_we = getStandardDeviation(subject_hotel_rank_we);


      set_zone_freq_range_high_annotations([
        {
          type: 'line',
          borderColor: '#4F4F4F',
          borderDashOffset: 0,
          borderWidth: 2,
          drawTime: 'afterDatasetsDraw',
          label: {
            enabled: true,
            backgroundColor: '#4F4F4F',
            borderWidth: 0,
            drawTime: 'afterDatasetsDraw',
            color: 'white',
            content: `${Math.ceil(avg_rank_wd - 1 * stdev_wd)}`,
            position: 'left',
          },
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: Math.ceil(avg_rank_wd - 1 * stdev_wd),
          // onMouseover: function (e) {
          //   toggleLabel(this.chartInstance, e, 1);
          // },
        },
        {
          type: 'line',
          borderColor: '#4F4F4F',
          borderDashOffset: 0,
          borderWidth: 2,
          drawTime: 'afterDatasetsDraw',
          label: {
            enabled: true,
            backgroundColor: '#4F4F4F',
            borderWidth: 0,
            drawTime: 'afterDatasetsDraw',
            color: 'white',
            content: `${Math.ceil(avg_rank_we - 1 * stdev_we)}`,
            position: 'left',
          },
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: Math.ceil(avg_rank_we - 1 * stdev_we),
          // onMouseover: function (e) {
          //   toggleLabel(this.chartInstance, e, 1);
          // },
        },
      ]);

      set_zone_freq_range_low_annotations([
        {
          type: 'line',
          borderColor: '#4F4F4F',
          borderDashOffset: 0,
          borderWidth: 2,
          drawTime: 'afterDatasetsDraw',
          label: {
            enabled: true,
            backgroundColor: '#4F4F4F',
            borderWidth: 0,
            drawTime: 'afterDatasetsDraw',
            color: 'white',
            content: `${Math.ceil(avg_rank_wd + 1 * stdev_wd)}`,
            position: 'left',
          },
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: Math.ceil(avg_rank_wd + 1 * stdev_wd),
          // onMouseover: function (e) {
          //   toggleLabel(this.chartInstance, e, 2);
          // },
        },
        {
          type: 'line',
          borderColor: '#4F4F4F',
          borderDashOffset: 0,
          borderWidth: 2,
          drawTime: 'afterDatasetsDraw',
          label: {
            enabled: true,
            backgroundColor: '#4F4F4F',
            borderWidth: 0,
            drawTime: 'afterDatasetsDraw',
            color: 'white',
            content: `${Math.ceil(avg_rank_we + 1 * stdev_we)}`,
            position: 'left',
          },
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: Math.ceil(avg_rank_we + 1 * stdev_we),
          // onMouseover: function (e) {
          //   toggleLabel(this.chartInstance, e, 2);
          // },
        },
      ]);

      set_subject_hotel_avg_rank_annotations([
        {
          type: 'line',
          borderColor: '#4F4F4F',
          borderDashOffset: 0,
          borderWidth: 3,
          drawTime: 'afterDatasetsDraw',
          label: {
            enabled: true,
            backgroundColor: '#4F4F4F',
            borderWidth: 0,
            drawTime: 'afterDatasetsDraw',
            color: 'white',
            content: `${Math.round(getAverage(subject_hotel_rank_wd))}`,
            position: 'left',
          },
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: Math.round(getAverage(subject_hotel_rank_wd)),
          // onMouseover: function (e) {
          //   toggleLabel(this.chartInstance, e, 0);
          // },
        },
        {
          type: 'line',
          borderColor: '#4F4F4F',
          borderDashOffset: 0,
          borderWidth: 3,
          drawTime: 'afterDatasetsDraw',
          label: {
            enabled: true,
            backgroundColor: '#4F4F4F',
            borderWidth: 0,
            drawTime: 'afterDatasetsDraw',
            color: 'white',
            content: `${Math.round(getAverage(subject_hotel_rank_we))}`,
            position: 'left',
          },
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: Math.round(getAverage(subject_hotel_rank_we)),
          // onMouseover: function (e) {
          //   toggleLabel(this.chartInstance, e, 0);
          // },
        },
      ]);

      set_hotelsCountDataset(datebyhotelcount);

      setBind(true);
      // console.log(reqHotel);
    };

    if (hotels.length > 0) {
      buildHotelsDataSet();
    }
  }, [hotels]);

  const hanndleMatrixChange = (m) => {
    setMatrix(m);
    setChartDataset(datePage, m);
  };

  const sanitize_dataset = () => {
    _hotelData_wd.labels = [];
    _hotelData_wd.datasets[0].data = [];
    _hotelData_wd.datasets[1].data = [];
    _hotelData_wd.datasets[2].data = [];
    _hotelData_wd.datasets[3].data = [];
    _hotelData_wd.datasets[4].data = [];
    _hotelData_wd.datasets[5].data = [];
    _hotelData_wd.datasets[6].data = [];

    _hotelData_we.labels = [];
    _hotelData_we.datasets[0].data = [];
    _hotelData_we.datasets[1].data = [];
    _hotelData_we.datasets[2].data = [];
    _hotelData_we.datasets[3].data = [];
    _hotelData_we.datasets[4].data = [];
    _hotelData_we.datasets[5].data = [];
    _hotelData_we.datasets[6].data = [];
  };

  const setChartDataset = (datePage, matrix) => {
    chartData.labels = cluster1
      .slice(dateRange[datePage][0], dateRange[datePage][1])
      .map((a) => moment(a.date).format(date_format));
    lineData.labels = cluster1
      .slice(dateRange[datePage][0], dateRange[datePage][1])
      .map((a) => moment(a.date).format(date_format));

    sanitize_dataset();

    _hotelsCountDataset
      .slice(dateRange[datePage][0], dateRange[datePage][1])
      .map((x) => {
        if (moment(x.date).day() == 5 || moment(x.date).day() == 6) {
          _hotelData_we.labels.push(moment(x.date).format(date_format));
          _hotelData_we.datasets[0].data.push(x.subject_hotel_rank);
          _hotelData_we.datasets[1].data.push(x.noRateHotels);
          _hotelData_we.datasets[2].data.push(x.outliers);
          _hotelData_we.datasets[3].data.push(x.cls2h);
          _hotelData_we.datasets[4].data.push(x.cls3h);
          _hotelData_we.datasets[5].data.push(x.cls4h);
          _hotelData_we.datasets[6].data.push(x.cls5h);
        } else {
          _hotelData_wd.labels.push(moment(x.date).format(date_format));
          _hotelData_wd.datasets[0].data.push(x.subject_hotel_rank);
          _hotelData_wd.datasets[1].data.push(x.noRateHotels);
          _hotelData_wd.datasets[2].data.push(x.outliers);
          _hotelData_wd.datasets[3].data.push(x.cls2h);
          _hotelData_wd.datasets[4].data.push(x.cls3h);
          _hotelData_wd.datasets[5].data.push(x.cls4h);
          _hotelData_wd.datasets[6].data.push(x.cls5h);
        }
      });

    lineData.datasets[4].data = reqHotel
      .slice(dateRange[datePage][0], dateRange[datePage][1])
      .map((a) => a.rate);

    try {
      if (matrix === 'avg') {
        chartData.datasets.map((set, ix) => {
          if (ix === 0) {
            if (cluster1.length > 0) {
              set.data = cluster1
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mean : 'NED'));
            }
          }

          if (ix === 1) {
            if (cluster2.length > 0) {
              set.data = cluster2
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mean : 'NED'));
            }
          }

          if (ix === 2) {
            if (cluster3.length > 0) {
              set.data = cluster3
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mean : 'NED'));
            }
          }

          if (ix === 3) {
            if (cluster4.length > 0) {
              set.data = cluster4
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mean : 'NED'));
            }
          }
        });
        lineData.datasets.map((set, ix) => {
          if (ix === 0) {
            if (cluster1.length > 0) {
              set.data = cluster1
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mean : 'NED'));
            }
          }

          if (ix === 1) {
            if (cluster2.length > 0) {
              set.data = cluster2
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mean : 'NED'));
            }
          }
          if (ix === 2) {
            if (cluster3.length > 0) {
              set.data = cluster3
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mean : 'NED'));
            }
          }
          if (ix === 3) {
            if (cluster4.length > 0) {
              set.data = cluster4
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mean : 'NED'));
            }
          }
        });
      }
      if (matrix === 'max') {
        chartData.datasets.map((set, ix) => {
          if (ix === 0) {
            if (cluster1.length > 0) {
              set.data = cluster1
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.max : 'NED'));
            }
          }

          if (ix === 1) {
            if (cluster2.length > 0) {
              set.data = cluster2
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.max : 'NED'));
            }
          }

          if (ix === 2) {
            if (cluster3.length > 0) {
              set.data = cluster3
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.max : 'NED'));
            }
          }

          if (ix === 3) {
            if (cluster4.length > 0) {
              set.data = cluster4
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.max : 'NED'));
            }
          }
        });
        lineData.datasets.map((set, ix) => {
          if (ix === 0) {
            if (cluster1.length > 0) {
              set.data = cluster1
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.max : 'NED'));
            }
          }

          if (ix === 1) {
            if (cluster2.length > 0) {
              set.data = cluster2
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.max : 'NED'));
            }
          }

          if (ix === 2) {
            if (cluster3.length > 0) {
              set.data = cluster3
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.max : 'NED'));
            }
          }

          if (ix === 3) {
            if (cluster4.length > 0) {
              set.data = cluster4
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.max : 'NED'));
            }
          }
        });
      }
      if (matrix === 'min') {
        chartData.datasets.map((set, ix) => {
          if (ix === 0) {
            if (cluster1.length > 0) {
              set.data = cluster1
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.min : 'NED'));
            }
          }

          if (ix === 1) {
            if (cluster2.length > 0) {
              set.data = cluster2
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.min : 'NED'));
            }
          }

          if (ix === 2) {
            if (cluster3.length > 0) {
              set.data = cluster3
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.min : 'NED'));
            }
          }

          if (ix === 3) {
            if (cluster4.length > 0) {
              set.data = cluster4
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.min : 'NED'));
            }
          }
        });
        lineData.datasets.map((set, ix) => {
          if (ix === 0) {
            if (cluster1.length > 0) {
              set.data = cluster1
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.min : 'NED'));
            }
          }

          if (ix === 1) {
            if (cluster2.length > 0) {
              set.data = cluster2
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.min : 'NED'));
            }
          }

          if (ix === 2) {
            if (cluster3.length > 0) {
              set.data = cluster3
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.min : 'NED'));
            }
          }

          if (ix === 3) {
            if (cluster4.length > 0) {
              set.data = cluster4
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.min : 'NED'));
            }
          }
        });
      }
      if (matrix === 'mod') {
        chartData.datasets.map((set, ix) => {
          if (ix === 0) {
            if (cluster1.length > 0) {
              set.data = cluster1
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mod : 'NED'));
            }
          }

          if (ix === 1) {
            if (cluster2.length > 0) {
              set.data = cluster2
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mod : 'NED'));
            }
          }

          if (ix === 2) {
            if (cluster3.length > 0) {
              set.data = cluster3
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mod : 'NED'));
            }
          }

          if (ix === 3) {
            if (cluster4.length > 0) {
              set.data = cluster4
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mod : 'NED'));
            }
          }
        });
        lineData.datasets.map((set, ix) => {
          if (ix === 0) {
            if (cluster1.length > 0) {
              set.data = cluster1
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mod : 'NED'));
            }
          }

          if (ix === 1) {
            if (cluster2.length > 0) {
              set.data = cluster2
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mod : 'NED'));
            }
          }

          if (ix === 2) {
            if (cluster3.length > 0) {
              set.data = cluster3
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mod : 'NED'));
            }
          }

          if (ix === 3) {
            if (cluster4.length > 0) {
              set.data = cluster4
                .slice(dateRange[datePage][0], dateRange[datePage][1])
                .map((a) => (a.items > 0 ? a.mod : 'NED'));
            }
          }
        });
      }
    } catch (e) {}
  };

  const handleScatterPlotMatrix = (e) => {
    setScatterPlot(e);
  };

  const handleDatePage = (e) => {
    setDatePage(e);
    setChartDataset(e, matrix);
    // console.log(
    //   'datePage = ' + datePage + 'dateRange = ' + dateRange[datePage][0],
    //   dateRange[datePage][1]
    // );
  };

  const handleDateSelection = (e) => {
    setDateSelection(e);
  };

  const getPrice = (arr) => {
    const price = arr.findIndex((e) => e > 0);
    return price;
  };

  const checkHotelAvailability = (id, day) => {
    let clustered = [];

    if (cluster1.length > 0 && cluster1[day]) {
      clustered.push(cluster1[day].unwanted);
    }
    if (cluster2.length > 0 && cluster2[day]) {
      clustered.push(cluster2[day].unwanted);
    }
    if (cluster3.length > 0 && cluster3[day]) {
      clustered.push(cluster3[day].unwanted);
    }
    if (cluster4.length > 0 && cluster4[day]) {
      clustered.push(cluster4[day].unwanted);
    }

    let hotels_arr = [];

    for (var i = 0; i < clustered.length; i++) {
      hotels_arr = hotels_arr.concat(clustered[i]);
    }

    const exists = hotels_arr.some((obj) => obj.id == id);

    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (clusterData.length > 0) {
      setScatterPlotLabels(
        cluster1
          .slice(dateRange[datePage][0], dateRange[datePage][1])
          .map((a) => moment(a.date).format(date_format))
      );
      if (cluster1.length > 0) {
        cluster1
          .slice(dateRange[datePage][0], dateRange[datePage][1])
          .map((cl) => {
            if (cl.items > 0) {
              setScatterData2avg((state) => [...state, cl.mean]);
              setScatterData2high((state) => [...state, cl.max]);
              setScatterData2low((state) => [...state, cl.min]);
            } else {
              setScatterData2avg((state) => [...state, 'NED']);
              setScatterData2high((state) => [...state, 'NED']);
              setScatterData2low((state) => [...state, 'NED']);
            }
          });
      }
      if (cluster2.length > 0) {
        cluster2
          .slice(dateRange[datePage][0], dateRange[datePage][1])
          .map((cl) => {
            if (cl.items > 0) {
              setScatterData3avg((state) => [...state, cl.mean]);
              setScatterData3high((state) => [...state, cl.max]);
              setScatterData3low((state) => [...state, cl.min]);
            } else {
              setScatterData3avg((state) => [...state, 'NED']);
              setScatterData3high((state) => [...state, 'NED']);
              setScatterData3low((state) => [...state, 'NED']);
            }
          });
      }
      if (cluster3.length > 0) {
        cluster3
          .slice(dateRange[datePage][0], dateRange[datePage][1])
          .map((cl) => {
            if (cl.items > 0) {
              setScatterData4avg((state) => [...state, cl.mean]);
              setScatterData4high((state) => [...state, cl.max]);
              setScatterData4low((state) => [...state, cl.min]);
            } else {
              setScatterData4avg((state) => [...state, 'NED']);
              setScatterData4high((state) => [...state, 'NED']);
              setScatterData4low((state) => [...state, 'NED']);
            }
          });
      }
      if (cluster4.length > 0) {
        cluster4
          .slice(dateRange[datePage][0], dateRange[datePage][1])
          .map((cl) => {
            if (cl.items > 0) {
              setScatterData5avg((state) => [...state, cl.mean]);
              setScatterData5high((state) => [...state, cl.max]);
              setScatterData5low((state) => [...state, cl.min]);
            } else {
              setScatterData5avg((state) => [...state, 'NED']);
              setScatterData5high((state) => [...state, 'NED']);
              setScatterData5low((state) => [...state, 'NED']);
            }
          });
      }
    }
  }, [datePage]);

  return (
    <div>
      {bind ? (
        <>
          <Grid className="my-5" container justify="space-around">
            <h3>Rate Position - Weekdays</h3>

            <FormControl className={classes.formControl}></FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-native-select">
                Date Range
              </InputLabel>
              <Select
                native={true}
                onChange={(e) => handleDatePage(e.target.value)}
                id="grouped-native-select"
                value={datePage}
              >
                {dateRange.length > 0 ? (
                  dateRange.map((e, i) => (
                    <option key={i} value={i}>
                      {clusterData[e[0]] && clusterData[e[0]][0]
                        ? moment(clusterData[e[0]][0].date).format(date_format)
                        : 'N/A'}{' '}
                      -{' '}
                      {clusterData[e[1]] && clusterData[e[1]][0]
                        ? moment(clusterData[e[1]][0].date).format(date_format)
                        : 'N/A'}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Box className="my-5">
            <Bar
              height={400}
              width={100}
              data={_hotelData_wd}
              options={{
                ...stackedOptions,
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      if (tooltipItem.datasetIndex == 0) {
                        const price = req_hotel_prices_wd[tooltipItem.index];

                        return `${
                          data.datasets[tooltipItem.datasetIndex].label
                        } | Rank: ${tooltipItem.yLabel} | Rate: ${price}`;
                      }
                      return (
                        data.datasets[tooltipItem.datasetIndex].label +
                        ': (' +
                        tooltipItem.yLabel +
                        ')'
                      );
                    },
                  },
                },
                annotation: {
                  drawTime: 'afterDatasetsDraw',
                  events: ['mouseover'],
                  annotations: [
                    subject_hotel_avg_rank_annotations[0],
                    zone_freq_range_high_annotations[0],
                    zone_freq_range_low_annotations[0],
                  ],
                },
              }}
            />
          </Box>

          <Grid className="my-5" container justify="space-around">
            <h3>Rate Position - Weekends</h3>

            <FormControl className={classes.formControl}></FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-native-select">
                Date Range
              </InputLabel>
              <Select
                native={true}
                onChange={(e) => handleDatePage(e.target.value)}
                id="grouped-native-select"
                value={datePage}
              >
                {dateRange.length > 0 ? (
                  dateRange.map((e, i) => (
                    <option key={i} value={i}>
                      {clusterData[e[0]] && clusterData[e[0]][0]
                        ? moment(clusterData[e[0]][0].date).format(date_format)
                        : 'N/A'}{' '}
                      -{' '}
                      {clusterData[e[1]] && clusterData[e[1]][0]
                        ? moment(clusterData[e[1]][0].date).format(date_format)
                        : 'N/A'}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Box className="my-5">
            <Bar
              height={400}
              width={100}
              data={_hotelData_we}
              options={{
                ...stackedOptions,
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      if (tooltipItem.datasetIndex == 0) {
                        const price = req_hotel_prices_we[tooltipItem.index];

                        return `${
                          data.datasets[tooltipItem.datasetIndex].label
                        } | Rank: ${tooltipItem.yLabel} | Rate: ${price}`;
                      }
                      return (
                        data.datasets[tooltipItem.datasetIndex].label +
                        ': (' +
                        tooltipItem.yLabel +
                        ')'
                      );
                    },
                  },
                },
                annotation: {
                  drawTime: 'afterDatasetsDraw',
                  dblClickSpeed: 350,
                  events: ['mouseover'],
                  annotations: [
                    subject_hotel_avg_rank_annotations[1],
                    zone_freq_range_high_annotations[1],
                    zone_freq_range_low_annotations[1],
                  ],
                },
              }}
            />
          </Box>

          <hr className="my-5"></hr>
          <Grid container justify="space-around" className="my-5">
            <h3>Bucket Rates</h3>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-native-select">
                Generate Graphs By
              </InputLabel>
              <Select
                native={true}
                onChange={(e) => hanndleMatrixChange(e.target.value)}
                id="grouped-native-select"
                value={matrix}
              >
                <option value="avg">Average Rate</option>
                <option value="max">Highest Rate</option>
                <option value="min">Lowest Rate</option>
                <option value="mod">Most Repeated rate </option>
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-native-select">
                Date Range
              </InputLabel>
              <Select
                native={true}
                onChange={(e) => handleDatePage(e.target.value)}
                id="grouped-native-select"
                value={datePage}
              >
                {dateRange.length > 0 ? (
                  dateRange.map((e, i) => (
                    <option key={i} value={i}>
                      {clusterData[e[0]] && clusterData[e[0]][0]
                        ? moment(clusterData[e[0]][0].date).format(date_format)
                        : 'N/A'}{' '}
                      -{' '}
                      {clusterData[e[1]] && clusterData[e[1]][0]
                        ? moment(clusterData[e[1]][0].date).format(date_format)
                        : 'N/A'}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Line
            data={lineData}
            height={100}
            options={{
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Rates',
                    },
                  },
                ],
              },
            }}
          />

          <Box className="my-5">
            <Bar height={400} width={100} data={chartData} options={options} />
          </Box>

          <hr className="my-5"></hr>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
