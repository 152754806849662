import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core';
import { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {
  CLUSTER_BACKGROUND,
  CLUSTER_LABELS,
  FONT_FAMILY,
  MenuProps,
  multiSelectStyles,
} from '../utils/const';
import SearchBar from 'material-ui-search-bar';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HotelsPlot } from './HotelsPlot';
import { getMiamiBeachEvents } from '../services/apiServices';
import { fetcSpecialDates } from '../redux/actions/auth.actions';
import { getDate } from 'date-fns';

import InfoIcon from '@mui/icons-material/Info';
import { HotelSharp } from '@material-ui/icons';
import { Button } from '@mui/material';

import SaveAltIcon from '@mui/icons-material/SaveAlt';

const Excel = require('exceljs');

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    minWidth: 90,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: window.innerHeight - 275,
  },
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '2px 2px 2px grey',
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  rates: {
    fontFamily: FONT_FAMILY,
    alignContent: 'center'
  },
  tabularNavStyle: {
    backgroundColor: '#607D8B',
    fontFamily: FONT_FAMILY,
    fontWeight: 'bold',
  },
}));

export default function HotelDataTable({
  selectedDate,
  tbl_ref,
  sub_tab,
  specailDates,
}) {
  const options = [2, 3, 4, 5];

  const classes = useStyles();
  const dispatch = useDispatch();
  const multiSelectClasses = multiSelectStyles();

  const [dates, setDates] = useState([]);
  const [sortDir, setSortDir] = useState('desc');

  const [selectedStars, setSelectedStars] = useState([]);
  const [selectedBuckets, setSelectedBuckets] = useState([]);

  const isAllSelectedStars =
    options.length > 0 && selectedStars.length === options.length;

  const isAllSelectedBuckets =
    options.length > 0 && selectedBuckets.length === options.length;

  const [sortBy, setSortBy] = useState(1);

  // const [hotelsList, setHotelsList] = useState([]);
  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const {
    loading,
    err,
    reqHotel,
    cluster1,
    cluster2,
    cluster3,
    cluster4,
    hotels,
    report_len,
    ratingCluster,
    date_format,
  } = getClusterDataSet;

  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const [hotelsList, setHotelsList] = useState([]);

  const [datesList, setDatesList] = useState([]);

  const [originalRows, setOriginalRows] = useState([]);

  const [nights, setNights] = useState(0);

  const [searched, setSearched] = useState('');

  const tableRef = useRef(null);

  const [tab, setTab] = useState(sub_tab);

  const [dates_filter, set_dates_filter] = useState(1);

  const [allDatesHotelsList, setAllDatesHotelsList] = useState([]);
  const [specialDatesHotelsList, setSpecialDatesHotelsList] = useState([]);

  const [downloadLink, setDownloadLink] = useState(null);

  const [load, setLoad] = useState(true);

  const getReportName = () => {
    let name = null;
    if (reqHotel.length > 0) {
      reqHotel.map((e, index) => {
        if (e.name !== null) {
          name = e.name;
          return;
        }
      });
    }

    return `${name}-Hotle_Radar-${moment(selectedDate).format(
      'YYYY-MM-DD'
    )}.xlsx`;
  };

  const daily_fetch_len = selectedDate
    ? moment(moment(selectedDate).add(180, 'days'))
        .endOf('month')
        .day('sunday')
        .diff(selectedDate, 'days')
    : 0;

  const handleExport = () => {
    // create a new workbook object
    const workbook = new Excel.Workbook();

    // create a new worksheet
    const worksheet = workbook.addWorksheet('Radar');

    // get the data from the HTML table
    const table = document.getElementById('hotel-radar-tbl');
    const data = [];
    for (let i = 0; i < table.rows.length; i++) {
      const row = [];
      for (let j = 0; j < table.rows[i].cells.length; j++) {
        row.push(table.rows[i].cells[j].textContent);
      }
      data.push(row);
    }

    // add the data to the worksheet
    worksheet.addRows(data);

    // generate a binary string representation of the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      // convert the binary string to a Blob object
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // create a URL that can be used to download the Blob
      const url = URL.createObjectURL(blob);

      // force the browser to download the file
      const link = document.createElement('a');
      link.href = url;
      link.download = getReportName();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const requestSearch = (searchedVal) => {
    // setSearched(searchedVal);
    const filteredRows = originalRows.filter((row) => {
      return row.hotelName.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setHotelsList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  useEffect(() => {
    requestSearch(searched);
  }, [searched]);

  useEffect(() => {
    if (hotels.length > 0) {
      setHotelsList(
        hotels.sort(
          (a, b) => b.stars - a.stars || a.hotelName.localeCompare(b.hotelName)
        )
      );
      setOriginalRows(
        hotels.sort(
          (a, b) => b.stars - a.stars || a.hotelName.localeCompare(b.hotelName)
        )
      );
    }
    // if (tbl_ref > 0) {
    //   setTab(2);
    // }
  }, []);

  // useEffect(() => {
  //   const handleHotelsDataset = () => {
  //     setLoad(true);
  //     const special_dates_hotels = [];
  //     const all_dates_hotels = [];
  //     if (hotels.length > 0) {
  //       hotels.map((_hotel, idx) => {
  //         var special_day_prices = [];
  //         var all_day_prices = [];
  //         var cluster_arr = [];
  //         if (_hotel.prices.length > 0) {
  //           _hotel.prices.map((dt, ix) => {
  //             const _date = getDateByIndex(ix);

  //             if (dt !== null) {
  //               const _dt = {
  //                 date: _date,
  //                 price: dt.price[getPrice(dt.price)],
  //                 nights: getPrice(dt.price) + 1,
  //                 available: checkHotelAvailability(_hotel.hotelID, ix),
  //                 cluster: getClusterByPrice(dt.price[getPrice(dt.price)], ix),
  //               };
  //               if (
  //                 specailDates.find(
  //                   (obj) =>
  //                     moment(obj.date).format('YYYY-MM-DD') ==
  //                     moment(dt.date).format('YYYY-MM-DD')
  //                 )
  //               ) {
  //                 special_day_prices.push(_dt);
  //               }
  //               all_day_prices.push(_dt);
  //               cluster_arr.push(
  //                 getClusterByPrice(dt.price[getPrice(dt.price)], ix) + 2
  //               );
  //             } else {
  //               const _date = getDateByIndex(ix);
  //               if (
  //                 specailDates.find(
  //                   (obj) =>
  //                     moment(obj.date).format('YYYY-MM-DD') ==
  //                     moment(_date).format('YYYY-MM-DD')
  //                 )
  //               ) {
  //                 special_day_prices.push({
  //                   date: _date,
  //                   price: '--',
  //                   nights: '',
  //                   available: false,
  //                   cluster: -1,
  //                 });
  //               }
  //               all_day_prices.push({
  //                 date: _date,
  //                 price: '--',
  //                 nights: '',
  //                 available: false,
  //                 cluster: -1,
  //               });
  //             }
  //           });
  //         }

  //         special_dates_hotels.push({
  //           ..._hotel,
  //           prices: special_day_prices,
  //           freq_bucket: mode(cluster_arr),
  //         });
  //         all_dates_hotels.push({
  //           ..._hotel,
  //           prices: all_day_prices,
  //           freq_bucket: mode(cluster_arr),
  //         });
  //       });
  //     }

  //     special_dates_hotels.sort(
  //       (a, b) => b.stars - a.stars || a.hotelName.localeCompare(b.hotelName)
  //     );

  //     all_dates_hotels.sort(
  //       (a, b) => b.stars - a.stars || a.hotelName.localeCompare(b.hotelName)
  //     );

  //     setSpecialDatesHotelsList(special_dates_hotels);

  //     setAllDatesHotelsList(all_dates_hotels);

  //     setHotelsList(all_dates_hotels);

  //     setOriginalRows(all_dates_hotels);

  //     setLoad(false);
  //   };

  //   // handleHotelsDataset();

  //   if (tbl_ref > 0) {
  //     setTab(2);
  //   }
  // }, []);

  // useEffect(() => {
  //   const setDatesArr = () => {
  //     let _datesList = [];
  //     cluster1.map((e, i) => {
  //       let _date = moment(e.date);
  //       let daysOut = _date.diff(selectedDate, 'days');
  //       let day = _date.format('dddd').substring(0, 3);
  //       let found = [];

  //       if (specailDates.length > 0) {
  //         found = specailDates.filter(
  //           (obj) =>
  //             moment(obj.date).format('YYYY-MM-DD') ==
  //             moment(_date).format('YYYY-MM-DD')
  //         );
  //       }

  //       if (dates_filter == 1) {
  //         _datesList.push({
  //           date: _date,
  //           daysOut: daysOut,
  //           day: day,
  //           labels: found.length > 0 ? found.map((e) => e.label) : [],
  //         });
  //       } else if (dates_filter == 2) {
  //         if (found.length > 0) {
  //           _datesList.push({
  //             date: _date,
  //             daysOut: daysOut,
  //             day: day,
  //             labels: found.map((e) => e.label),
  //           });
  //         }
  //       }
  //     });

  //     setDatesList(_datesList);
  //   };

  //   if (cluster1.length > 0) {
  //     // setDatesArr();
  //   }
  // }, [cluster1, dates_filter, specailDates]);

  const getDateByIndex = (index) => {
    if (cluster1.length > 0) {
      if (index >= 0) {
        return cluster1[index].date;
      }
    }
  };

  const getClusterByPrice = (rate, ix) => {
    let clustered = [];
    let res;

    if (cluster1.length > 0 && cluster1[ix]) {
      clustered.push(cluster1[ix]);
    }
    if (cluster2.length > 0 && cluster2[ix]) {
      clustered.push(cluster2[ix]);
    }
    if (cluster3.length > 0 && cluster3[ix]) {
      clustered.push(cluster3[ix]);
    }
    if (cluster4.length > 0 && cluster4[ix]) {
      clustered.push(cluster4[ix]);
    }

    clustered.sort((a, b) => a.mean - b.mean);

    try {
      clustered.map((cl, id) => {
        if (rate >= cl.min && rate <= cl.max) {
          res = id;
          return;
        }
      });
    } catch (e) {}

    return res;
  };

  const TabularNav = () => {
    const [tabularNavCls] = useState(
      'text-light border-bottom-0 border-secondary  ' + classes.tabularNavStyle
    );
    return (
      <div style={{ position: 'sticky', top: 185, zIndex: 200 }}>
        {' '}
        <Nav variant="tabs" justify="space-around">
          <Nav.Item>
            <Nav.Link
              className={
                tab === 1
                  ? tabularNavCls
                  : 'text-dark font-weight-bold bg-light  shadow '
              }
              eventKey="link-1"
              onClick={() => setTab(1)}
            >
              Hotels
            </Nav.Link>
          </Nav.Item>{' '}
          <Nav.Item>
            <Nav.Link
              className={
                tab === 2
                  ? tabularNavCls
                  : 'text-dark font-weight-bold bg-light  shadow '
              }
              eventKey="link-1"
              onClick={() => setTab(2)}
            >
              Visuals
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    );
  };

  const sortData = (sortBy, sortOrder) => {
    // alert(`sortData (${sortBy}, ${sortOrder})`);
    if (sortBy === 0) {
      if (sortOrder === 'asc') {
        hotelsList.sort(
          (a, b) => a.hotelName.localeCompare(b.hotelName) || b.stars - a.stars
        );
      } else {
        hotelsList.sort(
          (a, b) => b.hotelName.localeCompare(a.hotelName) || b.stars - a.stars
        );
      }
    }

    if (sortBy === 1) {
      if (sortOrder === 'asc') {
        hotelsList.sort(
          (a, b) => a.stars - b.stars || a.hotelName.localeCompare(b.hotelName)
        );
      } else {
        hotelsList.sort(
          (a, b) => b.stars - a.stars || a.hotelName.localeCompare(b.hotelName)
        );
      }
    }
  };

  const handleSort = async (sb, sd) => {
    setSortBy(sb);
    setSortDir(sd);

    await sortData(sb, sd);

    //console.log('hotelList ', hotelsList);
  };

  const handleDatesFilter = (event) => {
    set_dates_filter(event.target.value);

    if (event.target.value == 1) {
      setOriginalRows(allDatesHotelsList);
      setHotelsList(allDatesHotelsList);
    } else if (event.target.value == 2) {
      setOriginalRows(specialDatesHotelsList);
      setHotelsList(specialDatesHotelsList);
    }
  };

  const handleHotelsFilter = async (event) => {
    if (event.target.value == 0) {
      const selectedHotels = [hotels.filter((_h) => _h.distance == 0)[0]];
      // console.log(user.application.candidate_properties);
      user.application.candidate_properties.map((_filterHotel) =>
        hotels.map((_hotel) => {
          if (_hotel.hotelID == _filterHotel.id) {
            selectedHotels.push(_hotel);
          }
        })
      );
      setHotelsList(selectedHotels);
    } else if (event.target.value == 2) {
      if (ratingCluster.min_rating) {
        if (originalRows.length > 0) {
          setHotelsList(
            originalRows.filter((h) => h.ratings >= ratingCluster.min_rating)
          );
        }
      }
    } else {
      setHotelsList(hotels);
    }
  };

  const handleNightsFilter = async (event) => {
    setNights(event.target.value);
  };

  const handleBucketsChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelectedBuckets(
        selectedBuckets.length === options.length ? [] : options
      );
      return;
    }
    setSelectedBuckets(value);
  };

  const handleStarsChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelectedStars(selectedStars.length === options.length ? [] : options);
      return;
    }
    setSelectedStars(value);
  };

  useEffect(() => {
    const handleStarsSelect = () => {
      if (selectedStars.length > 0) {
        const selectedHotels = [];
        selectedStars.map((star) => {
          originalRows.some((_filterHotel) => {
            if (star === Math.floor(_filterHotel.stars)) {
              selectedHotels.push(_filterHotel);
            }
          });
        });
        setHotelsList(
          selectedHotels.sort(
            (a, b) =>
              b.stars - a.stars || a.hotelName.localeCompare(b.hotelName)
          )
        );
      }
    };
    handleStarsSelect();
  }, [selectedStars]);

  const getPrice = (arr) => {
    const price = arr.findIndex((e) => e > 0);
    return price;
  };

  const mode = (arr) => {
    return arr
      .sort(
        (a, b) =>
          arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
      )
      .pop();
  };

  const checkHotelAvailability = (id, day) => {
    let clustered = [];

    if (cluster1.length > 0 && cluster1[day]) {
      clustered.push(cluster1[day].unwanted);
    }
    if (cluster2.length > 0 && cluster2[day]) {
      clustered.push(cluster2[day].unwanted);
    }
    if (cluster3.length > 0 && cluster3[day]) {
      clustered.push(cluster3[day].unwanted);
    }
    if (cluster4.length > 0 && cluster4[day]) {
      clustered.push(cluster4[day].unwanted);
    }
    let hotels_arr = [];

    for (var i = 0; i < clustered.length; i++) {
      hotels_arr = hotels_arr.concat(clustered[i]);
    }

    const exists = hotels_arr.some((obj) => obj.id == id);

    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  const GetSpecialDatesTbl = () => {
    return <></>;
  };

  const GetAllDatesTbl = () => {
    return (
      <>
        {hotelsList.length > 0 ? (
          <TableContainer
            component={Paper}
            className={classes.container + ' mt-3'}
          >
            <Box width={100}>
              <Table
                id="table-to-xls"
                className={classes.table}
                size="medium"
                aria-label="customized table"
                stickyHeader
                bodystyle={{ overflow: 'visible' }}
                ref={tableRef}
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell size="small">#</StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontWeight: 'bold',
                        width: '250px',
                        zIndex: 100,
                        fontFamily: FONT_FAMILY,
                      }}
                    >
                      <TableSortLabel
                        active={sortBy === 0}
                        direction={sortDir}
                        onClick={() => {
                          handleSort(0, sortDir === 'asc' ? 'desc' : 'asc');
                        }}
                      >
                        Hotel Name
                      </TableSortLabel>
                      <hr />
                      <TableSortLabel disabled>Days Out</TableSortLabel>
                      {/* <TableSortLabel onClick={handleSort(0)}></TableSortLabel> */}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TableSortLabel
                        active={sortBy === 1}
                        direction={sortDir}
                        onClick={() => {
                          handleSort(1, sortDir === 'asc' ? 'desc' : 'asc');
                        }}
                      >
                        Stars
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell className="text-center">
                      Freq Bucket
                    </StyledTableCell>
                    {cluster1.map((e, i) =>
                      (() => {
                        let _date = moment(e.date);
                        let daysOut = _date.diff(selectedDate, 'days');
                        let day = _date.format('dddd').substring(0, 3);

                        const labels = specailDates.filter(
                          (obj) =>
                            moment(obj.date).format('YYYY-MM-DD') ==
                            moment(_date).format('YYYY-MM-DD')
                        );

                        return (
                          <StyledTableCell
                            size="small"
                            key={i}
                            className={
                              day === 'Sat' || day === 'Fri'
                                ? 'bg-secondary text-light text-center '
                                : 'text-center '
                            }
                            style={{ fontSize: '12px' }}
                          >
                            <span>
                              {day === 'Sat' || day === 'Fri' ? 'WEND' : 'WDAY'}
                              &nbsp;
                              {labels.length > 0 ? (
                                <sup>
                                  <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-${i}`}>
                                        {labels.map((l) => l.label).join(' | ')}
                                      </Tooltip>
                                    }
                                  >
                                    <i
                                      class="fa fa-info-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </OverlayTrigger>
                                </sup>
                              ) : (
                                <></>
                              )}
                            </span>
                            <br />
                            {`${day.toUpperCase()}\n${moment(e.date).format(
                              date_format
                            )}`}{' '}
                            <div class="dropdown-divider"></div>
                            {daysOut}
                          </StyledTableCell>
                        );
                      })()
                    )}
                  </StyledTableRow>
                </TableHead>

                <TableBody>
                  {hotelsList.map((_hotel, index) => (
                    <StyledTableRow>
                      <StyledTableCell size="small">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        size="medium"
                        component="th"
                        scope="col"
                        className={classes.sticky}
                        style={{ fontWeight: 'bold', width: '300px' }}
                      >
                        {_hotel.hotelName}
                      </StyledTableCell>
                      <StyledTableCell size="small" className={classes.rates}>
                        {_hotel.stars}
                      </StyledTableCell>
                      <StyledTableCell className={classes.rates}>
                        {_hotel.freq_bucket}
                      </StyledTableCell>

                      {_hotel.prices.map((dt, ix) => (
                        <>
                          {dt.price != '--' ? (
                            <>
                              <StyledTableCell
                                size="small"
                                className={classes.rates}
                                style={
                                  dt.available
                                    ? {
                                        backgroundColor:
                                          CLUSTER_BACKGROUND[dt.cluster],
                                      }
                                    : { backgroundColor: '#9E9E9E' }
                                }
                              >
                                <span className="font-weight-bold">
                                  {dt.price}&nbsp;
                                  {dt.nights > 1 ? (
                                    <sup className="text-light font-weight-bold">
                                      {dt.nights}
                                    </sup>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </StyledTableCell>
                            </>
                          ) : (
                            <>
                              <StyledTableCell
                                size="small"
                                className={classes.rates}
                              >
                                {dt.price}
                              </StyledTableCell>
                            </>
                          )}
                        </>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <br />
            </Box>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      {' '}
      {/* <TabularNav /> */}
      {hotelsList.length > 0 && originalRows.length > 0 ? (
        <>
          <Grid container justify="space-evenly" className="my-3">
            <FormGroup className={classes.formControl}>
              <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
            </FormGroup>
            <FormGroup className={classes.formControl}>
              <InputLabel
                htmlFor="grouped-native-select"
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                Hotels Filter
              </InputLabel>
              <Select
                native
                id="grouped-native-select"
                onChange={handleHotelsFilter}
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                <option value={1}>All Hotels</option>
                {/* <option value={2}>Best Rated Hotels</option> */}
                <option value={0}>Analysis Set</option>
              </Select>
            </FormGroup>

            {/* <FormGroup className={classes.formControl}>
              <InputLabel
                htmlFor="grouped-native-select"
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                Dates Filter
              </InputLabel>
              <Select
                native
                value={dates_filter}
                onChange={handleDatesFilter}
                style={{ backgroundColor: 'white', fontFamily: FONT_FAMILY }}
              >
                <option value={1}>All Dates</option>
                <option value={2}>Special Dates</option>
              </Select>
            </FormGroup> */}

            <FormControl className={classes.formControl}>
              <InputLabel id="mutiple-select-label">Stars</InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={selectedStars}
                onChange={handleStarsChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelectedStars ? classes.selectedAll : '',
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelectedStars}
                      indeterminate={
                        selectedStars.length > 0 &&
                        selectedStars.length < options.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={selectedStars.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="mutiple-select-label">Bucket</InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={selectedBuckets}
                onChange={handleBucketsChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelectedBuckets ? classes.selectedAll : '',
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelectedBuckets}
                      indeterminate={
                        selectedBuckets.length > 0 &&
                        selectedBuckets.length < options.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedBuckets.indexOf(option) > -1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl className={classes.formControl} onClick={handleExport}>
              <Button
                variant="contained"
                endIcon={<SaveAltIcon />}
                style={{ backgroundColor: '#4F4F4F' }}
              >
                Export
              </Button>
            </FormControl> */}
          </Grid>

          {hotelsList.length > 0 ? (
            <TableContainer
              component={Paper}
              className={classes.container + ' mt-3'}
            >
              <Box width={100}>
                <Table
                  id="hotel-radar-tbl"
                  className={classes.table}
                  size="medium"
                  aria-label="customized table"
                  stickyHeader
                  bodystyle={{ overflow: 'visible' }}
                  ref={tableRef}
                >
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell size="small">#</StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontWeight: 'bold',
                          width: '250px',
                          zIndex: 100,
                          fontFamily: FONT_FAMILY,
                        }}
                      >
                        <TableSortLabel
                          active={sortBy === 0}
                          direction={sortDir}
                          onClick={() => {
                            handleSort(0, sortDir === 'asc' ? 'desc' : 'asc');
                          }}
                        >
                          Hotel Name
                        </TableSortLabel>
                        <hr />
                        <TableSortLabel disabled>Days Out</TableSortLabel>
                        {/* <TableSortLabel onClick={handleSort(0)}></TableSortLabel> */}
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortBy === 1}
                          direction={sortDir}
                          onClick={() => {
                            handleSort(1, sortDir === 'asc' ? 'desc' : 'asc');
                          }}
                        >
                          Stars
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell className="text-center">
                        Freq Bucket
                      </StyledTableCell>
                      <StyledTableCell className="text-center">
                        Ratings
                      </StyledTableCell>
                      {cluster1.map((e, i) =>
                        (() => {
                          let _date = moment(e.date);
                          let daysOut = _date.diff(selectedDate, 'days');
                          let day = _date.format('dddd').substring(0, 3);

                          const found = specailDates.filter(
                            (obj) =>
                              moment(obj.date).format('YYYY-MM-DD') ==
                              moment(_date).format('YYYY-MM-DD')
                          );

                          if (dates_filter == 1) {
                            return (
                              <StyledTableCell
                                size="small"
                                key={i}
                                className={
                                  day === 'Sat' || day === 'Fri'
                                    ? 'bg-secondary text-light text-center '
                                    : 'text-center '
                                }
                                style={{ fontSize: '12px' }}
                              >
                                <span>
                                  {day === 'Sat' || day === 'Fri'
                                    ? 'WEND'
                                    : 'WDAY'}
                                  &nbsp;
                                  {found.length > 0 ? (
                                    <sup>
                                      <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-${i}`}>
                                            {found
                                              .map((l) => l.label)
                                              .join(' | ')}
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          class="fa fa-info-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </OverlayTrigger>
                                    </sup>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                                <br />
                                {`${day.toUpperCase()}\n${moment(e.date).format(
                                  date_format
                                )}`}{' '}
                                <div class="dropdown-divider"></div>
                                {daysOut}
                              </StyledTableCell>
                            );
                          } else if (dates_filter == 2) {
                            if (found.length > 0) {
                              return (
                                <StyledTableCell
                                  size="small"
                                  key={i}
                                  className={
                                    day === 'Sat' || day === 'Fri'
                                      ? 'bg-secondary text-light text-center '
                                      : 'text-center '
                                  }
                                  style={{ fontSize: '12px' }}
                                >
                                  <span>
                                    {day === 'Sat' || day === 'Fri'
                                      ? 'WEND'
                                      : 'WDAY'}
                                    &nbsp;
                                    {found.length > 0 ? (
                                      <sup>
                                        <OverlayTrigger
                                          key="top"
                                          placement="top"
                                          overlay={
                                            <Tooltip id={`tooltip-${i}`}>
                                              {found
                                                .map((l) => l.label)
                                                .join(' | ')}
                                            </Tooltip>
                                          }
                                        >
                                          <i
                                            class="fa fa-info-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </OverlayTrigger>
                                      </sup>
                                    ) : (
                                      <></>
                                    )}
                                  </span>
                                  <br />
                                  {`${day.toUpperCase()}\n${moment(
                                    e.date
                                  ).format(date_format)}`}{' '}
                                  <div class="dropdown-divider"></div>
                                  {daysOut}
                                </StyledTableCell>
                              );
                            }
                          }
                        })()
                      )}
                    </StyledTableRow>
                  </TableHead>

                  <TableBody>
                    {hotelsList.map((_hotel, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell size="small">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          size="medium"
                          component="th"
                          scope="col"
                          className={classes.sticky}
                          style={{ fontWeight: 'bold', width: '300px' }}
                        >
                          {_hotel.hotelName}
                        </StyledTableCell>
                        <StyledTableCell size="small" className={`${classes.rates} text-center`}>
                          {_hotel.stars}
                        </StyledTableCell>
                        {(() => {
                          let cluster_arr = [];
                          _hotel.prices.map((dt, ix) => {
                            if (dt !== null) {
                              if (checkHotelAvailability(_hotel.hotelID, ix)){
                                  cluster_arr.push(getClusterByPrice(
                                    dt.price[getPrice(dt.price)],
                                    ix
                                  ) + 2);
                              }
                            }
                          });
                          const freq_bucket = mode(cluster_arr)
                          return (
                            <StyledTableCell className={`${classes.rates} text-center font-weight-bold`} style={{ fontSize: '12px' }}>
                              {typeof freq_bucket === "number" ? CLUSTER_LABELS[freq_bucket - 2]: '--'}
                            </StyledTableCell>
                          );
                        })()}

                        <StyledTableCell size="small" className={`${classes.rates} text-center`}>
                          {_hotel.ratings}
                        </StyledTableCell>

                        {_hotel.prices.map((dt, ix) => (
                          <>
                            {dt != null ? (
                              <>
                                <StyledTableCell
                                  size="small"
                                  className={`${classes.rates} text-center`}
                                  style={
                                    checkHotelAvailability(_hotel.hotelID, ix)
                                      ? {
                                          borderRight:
                                            ix == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                          backgroundColor:
                                            CLUSTER_BACKGROUND[
                                              getClusterByPrice(
                                                dt.price[getPrice(dt.price)],
                                                ix
                                              )
                                            ],
                                        }
                                      : {
                                          borderRight:
                                            ix == daily_fetch_len
                                              ? '5px solid rgba(66, 66, 66, 1)'
                                              : '',
                                          backgroundColor: '#9E9E9E',
                                        }
                                  }
                                >
                                  <span className="font-weight-bold">
                                    {dt.price[getPrice(dt.price)]}&nbsp;
                                    {getPrice(dt.price) > 0 ? (
                                      <sup className="text-light font-weight-bold">
                                        {getPrice(dt.price) + 1}
                                      </sup>
                                    ) : (
                                      <></>
                                    )}
                                  </span>
                                </StyledTableCell>
                              </>
                            ) : (
                              <>
                                <StyledTableCell
                                  size="small"
                                  className={`${classes.rates} text-center`}
                                >
                                  --
                                </StyledTableCell>
                              </>
                            )}
                          </>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <br />
              </Box>
            </TableContainer>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
